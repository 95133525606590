import Router from 'next/router';
import { getAmplitude } from '@lib/analytics/amplitude';
import * as triggers from './gtag-triggers';
import FBPixel from './pixel';
import * as Sentry from '@sentry/nextjs';
import GoogleAnalyticsManager from '@lib/analytics/ga-manager';
import { CurrentUser, ProgramCategory } from '@gql/generated';

type initLibrariesParams = [
  {
    pixel?: boolean;
    amplitude?: boolean;
    ga?: boolean;
  },
  CurrentUser?,
];

const defaultConfig = {
  pixel: true,
  ga: true,
  amplitude: true,
};

type UserProperties = Pick<
  CurrentUser,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'address'
  | 'city'
  | 'zipcode'
  | 'country'
>;

class AnalyticsManager_ {
  amplitude: ReturnType<typeof getAmplitude>;
  ga: GoogleAnalyticsManager;
  _userAttributes: UserProperties;
  //
  pixelEnabled = false;
  amplitudeEnabled = false;
  gaEnabled = false;

  initLibraries(...params: initLibrariesParams) {
    const [config, currentUser] = params;
    const _config = Object.assign({}, defaultConfig, config);
    this.ga = new GoogleAnalyticsManager();
    //
    if (_config.pixel) {
      this.pixelEnabled = true;
      FBPixel.load();
    }
    //
    if (_config.ga) {
      this.gaEnabled = true;
      if (currentUser) {
        this._userAttributes = currentUser;
        this.ga.setUserProperties(this._getUserDataProperties());
      }
    }
    //
    if (_config.amplitude) {
      this.amplitudeEnabled = true;
      this.amplitude = getAmplitude({});
    }

    this.trackPageChange();
    this.trackPage(window.location.pathname);
  }

  _getUserDataProperties() {
    return {
      user_id: this._userAttributes?.id,
      user_name: this._userAttributes?.firstName
        ? this._userAttributes?.firstName + ' ' + this._userAttributes?.lastName
        : '',
      user_email: this._userAttributes?.email,
      user_phone: this._userAttributes?.phone,
      user_address: this._userAttributes?.address,
      user_city: this._userAttributes?.city,
      user_zipcode: this._userAttributes?.zipcode,
      user_country: this._userAttributes?.country,
    };
  }

  setUserProperties(userProperties: UserProperties) {
    this._userAttributes = userProperties;
    //
    if (this.amplitudeEnabled) {
      this.amplitude = getAmplitude(userProperties);
      this.amplitude.setUserProperties(userProperties);
    }

    if (this.gaEnabled) {
      this.ga.setUserProperties(this._getUserDataProperties());
    }
  }

  trackPageChange() {
    Router.events.on('routeChangeComplete', this.trackPage.bind(this));

    window.addEventListener('beforeunload', () => {
      Router.events.off('routeChangeComplete', this.trackPage.bind(this));
    });
  }

  trackPage(url) {
    if (this.pixelEnabled) {
      FBPixel.trackPage(url);
    }
  }

  login(data: { user_id: string }) {
    if (this.gaEnabled) {
      this.ga.login({ user_id: data.user_id });
    }
  }

  sendPlayVideoEvent(data: {
    instructor: string;
    duration: number;
    genre: string;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendPlayVideoEvent(data);
    }
  }

  sendPlaylistPlayEvent() {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendPlaylistPlayEvent();
    }
  }

  sendVideoAddedToFavorites(data: { video_id: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendVideoAddedToFavorites(data);
    }

    if (this.gaEnabled) {
      this.ga.favorite_session(data);
    }
  }

  sendPlaylistAddedToFavorites(data: { title: string; playlist_id: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendPlaylistAddedToFavorites(data);
    }

    if (this.gaEnabled) {
      this.ga.favorite_playlist(data);
    }
  }

  sendVideoAddToPlaylistEvent(data: { video_id: string; playlist_id: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendVideoAddToPlaylistEvent(data);
    }

    if (this.gaEnabled) {
      this.ga.addto_playlist(data);
    }
  }

  sendPlaylistCreatedEvent(data: { playlist_id: string }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendPlaylistCreatedEvent(data);
    }
  }

  sendClickedScheduleEvent(data: { video_id: string }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendClickedScheduleEvent(data);
    }
  }

  sendClickedShareVideoEvent(data: { video_id: string }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendClickedShareVideoEvent(data);
    }
  }

  // NOT USED
  // sendClickedSharePlaylistEvent(data = {}) {
  //   //
  //   if (this.amplitudeEnabled) {
  //     this.amplitude.sendClickedSharePlaylistEvent(data);
  //   }
  // }

  sendRecommendationsSetupBeginEvent() {
    if (this.gaEnabled) {
      this.ga.recommendations_begin();
    }
  }

  sendRecommendationsSetupCompleteEvent() {
    if (this.gaEnabled) {
      this.ga.recommendations_complete();
    }
  }

  sendRecommendationsEditedEvent() {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendRecommendationsEditedEvent();
    }
  }

  sendUpgradedPlanEvent(data: {
    months: number;
    amount: number;
    currency: string;
    payment_type: string;
    content_type?: string;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendUpgradedPlanEvent(data);
    }

    if (this.gaEnabled) {
      // triggers.sendSubscriptionTrigger({
      //   ...data,
      //   ...this._getUserDataProperties(),
      // });
    }

    const CYCLES = {
      1: 'monthly',
      3: 'quarterly',
      12: 'yearly',
    };

    if (this.pixelEnabled) {
      FBPixel.track('Subscribe', false, {
        content_type: CYCLES[data.months],
        value: data.amount,
        currency: data.currency,
        content_category: 'subscription',
      });
    }

    if (this.gaEnabled) {
      // TODO: abstract this
      const GA_SUBS = {
        1: { label: '1month', value: 189 },
        3: { label: '3months', value: 499 },
        12: { label: '12months', value: 1899 },
      };
      this.ga.event({
        category: 'subscription',
        action: 'subscribe',
        label: GA_SUBS[data.months]?.label,
        value: GA_SUBS[data.months]?.value,
        currency: data.currency,
      });
    }
  }

  sendSubscriptionPurchaseBeginEvent(data) {
    if (this.gaEnabled) {
      this.ga.purchase_subscriptionBegin(data);
    }

    if (this.pixelEnabled) {
      FBPixel.track('InitiateCheckout', false, {
        value: data.value,
        currency: data.currency,
        content_category: 'subscription',
        content_type: data.plan,
        items: [
          {
            content_name: data.payment_type,
            months: data.months,
            value: data.value,
          },
        ],
      });
    }
  }

  sendSubscriptionPurchaseCompleteEvent(data: {
    order_id: string;
    months: number;
    value: number;
    original_amount: number;
    currency: string;
    payment_type: string;
    source_page: string;
    new_customer: boolean;
    wellness?: boolean;
    wellness_provider?: string;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_subscriptionComplete(data);
    }

    if (this.pixelEnabled) {
      FBPixel.track('Subscribe', false, {
        value: data.value,
        currency: data.currency,
        content_category: 'subscription',
        items: [
          {
            item_id: data.payment_type,
            content_name: data.payment_type,
            months: data.months,
            value: data.value,
          },
        ],
      });
    }
  }
  SendSubscriptionPurchaseFailEvent(data: {
    order_id: string;
    months: number;
    value: number;
    original_amount: number;
    currency: string;
    payment_type: string;
    sourcePage: string;
    wellness?: boolean;
    wellness_provider?: string;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_subscriptionFail(data);
    }
  }

  //
  sendPurchaseCustomAccessBegin(data: {
    slug: string;
    name: string;
    value: number;
    original_amount: number;
    currency: string;
    wellness_provider: string;
    access_days: number;
    wellness: boolean;
    payment_type: string;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_CustomAccessBegin(data);
    }
  }
  sendPurchaseCustomAccessCompleteEvent(data: {
    order_id: string;
    name: string;
    value: number;
    original_amount: number;
    currency: string;
    access_days: number;
    payment_type: string;
    wellness: boolean;
    new_customer: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_CustomAccessComplete(data);
    }

    if (this.pixelEnabled) {
      FBPixel.track('Subscribe', false, {
        value: data.value,
        currency: data.currency,
        content_name: data.name,
        content_type: 'wellness_custom_access',
        content_category: 'wellness',
        contents: [
          {
            id: data.name,
            quantity: 1,
            access_days: data.access_days,
          },
        ],
      });
    }
  }
  sendPurchaseCustomAccessFailEvent(data: {
    order_id: string;
    name: string;
    value: number;
    original_amount: number;
    currency: string;
    access_days: number;
    payment_type: string;
    wellness?: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_CustomAccessFail(data);
    }
  }

  //
  sendPurchaseWellnessAccessBegin(data: {
    access_days: number;
    slug: string;
    name: string;
    value: number;
    original_amount: number;
    currency: string;
    wellness_provider: string;
    payment_type: string;
    wellness: boolean;
    new_customer: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_WellnessAccessBegin(data);
    }
  }
  sendWellnessAccessPurchaseCompleteEvent(data: {
    order_id: string;
    name: string;
    value: number;
    original_amount: number;
    currency: string;
    access_days: number;
    payment_type: string;
    wellness: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_WellnessAccessComplete(data);
    }

    if (this.pixelEnabled) {
      FBPixel.track('Subscribe', false, {
        value: data.value,
        currency: data.currency,
        content_name: data.name,
        content_type: 'wellness_access',
        content_category: 'wellness',
        contents: [
          {
            id: data.name,
            quantity: 1,
            access_days: data.access_days,
          },
        ],
      });
    }
  }

  //
  sendPurchaseLivePackageBegin(data: {
    slug: string;
    name: string;
    value: number;
    original_amount: number;
    currency: string;
    payment_type: string;
    wellness?: boolean;
    wellness_provider?: string;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_LivePackageBegin(data);
    }
  }
  sendPurchaseLivePackageCompleteEvent(data: {
    order_id: string;
    name: string;
    value: number;
    original_amount: number;
    currency: string;
    payment_type: string;
    wellness: boolean;
    new_customer: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_LivePackageComplete(data);
    }

    if (this.pixelEnabled) {
      FBPixel.track('Purchase', false, {
        value: data.value,
        currency: data.currency,
        content_name: data.name,
        content_type: 'wellness_live_package',
        content_category: 'wellness',
        contents: [
          {
            id: data.name,
            quantity: 1,
          },
        ],
      });
    }
  }
  sendPurchaseLivePackageFailEvent(data: {
    order_id: string;
    name: string;
    value: number;
    original_amount: number;
    currency: string;
    payment_type: string;
    wellness: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_LivePackageFail(data);
    }
  }

  //
  sendSubscriptionCancelledEvent(data: {
    amount: number;
    current: string;
    plan: string;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendSubscriptionCancelledEvent(data);
    }

    if (this.gaEnabled) {
      this.ga.subscription_cancel(data);
    }
  }

  sendSearchEvent(data: { taxonomies: string; query: string; genre: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendSearchEvent(data);
    }

    if (this.gaEnabled) {
      this.ga.search_library(data);
    }
  }

  sendJoinedLiveEvent(data: {
    name: string;
    theme: string;
    currency: string;
    value: number;
    discountedAmount?: number;
    instructor: string;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendJoinedLiveEvent(data);
    }

    if (this.gaEnabled) {
      // triggers.sendLiveEventFreeActivateTrigger({
      //   event_name: data.name,
      //   event_theme: data.theme,
      //   value: data.value,
      //   currency: data.currency,
      //   ...this._getUserDataProperties(),
      // });
      //
      this.ga.book_event({
        title: data.name,
        instructor: data.instructor,
        value: data.value / 100,
        currency: data.currency,
        discount:
          Math.floor(
            data.discountedAmount > 0 ? data.value - data.discountedAmount : 0
          ) / 100,
      });
      //
      this.ga.event({
        category: 'live',
        action: 'free_activation',
        label: data.name,
        value: data.value,
        currency: data.currency,
      });
    }
  }

  sendUsedLiveEventCode(data: {
    name: string;
    theme: string;
    currency: string;
    amount: number;
    instructor: string;
    code: string;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendUsedLiveEventCode({
        name: data.name,
        amount: data.amount,
        currency: data.currency,
        type: data.theme,
        instructor: data.instructor,
        code: data.code,
      });
    }
  }

  sendClickedLiveRecordingEvent(data: {
    name: string;
    amount: number;
    type: string;
    instructor: string;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendClickedLiveRecordingEvent(data);
    }
  }

  sendLiveSignupEvent(data: { event: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendLiveSignupEvent(data);
    }
  }

  sendProgramAccessGrantedEvent(data: {
    id: string;
    title: string;
    category: string;
    slug: string;
    access_code?: string;
    duration?: string;
    themes?: string;
  }) {
    const {
      id,
      title,
      category,
      slug,
      access_code = '',
      themes = '',
      duration = '',
    } = data;
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendProgramAccessGrantedEvent({
        title,
        slug,
        themes,
        duration,
        access_code,
      });
    }

    if (this.gaEnabled) {
      let begin_fns: Function = null;
      if (category === ProgramCategory['Courses']) {
        begin_fns = this.ga.course_begin;
      } else if (category === ProgramCategory['Programs']) {
        begin_fns = this.ga.program_begin;
      } else if (category === ProgramCategory['Challenges']) {
        begin_fns = this.ga.challenge_begin;
      }

      if (begin_fns) {
        begin_fns.apply(this.ga, [
          {
            id,
            title,
            slug,
            category,
          },
        ]);
      }
    }
  }

  sendProgramPurchaseEvent(data: {
    id: string;
    name: string;
    slug: string;
    currency: string;
    value: number;
    discountedAmount: number;
    category: ProgramCategory;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendProgramPurchaseEvent({
        title: data.name,
        slug: data.slug,
        amount: data.value,
        discountedAmount: data.discountedAmount,
        category: data.category,
      });
    }

    let programType = 'program';
    if (data.category === ProgramCategory['Courses']) {
      programType = 'course';
    }
    if (this.gaEnabled) {
      // triggers.SendProgramPurchaseEvent({
      //   category: programType,
      //   program_name: data.name,
      //   program_slug: data.slug,
      //   currency: data.currency,
      //   amount: data.value,
      //   ...this._getUserDataProperties(),
      // });
      // this.ga.event({
      //   category: programType,
      //   action: 'purchase',
      //   label: data.name,
      //   value: data.value,
      //   currency: data.currency,
      // });
    }

    if (this.pixelEnabled) {
      FBPixel.track('Purchase', false, {
        value: data.discountedAmount || data.value,
        currency: data.currency,
        content_name: data.name,
        content_type: programType,
        content_category: 'program',
        contents: [
          {
            id: data.name,
            quantity: 1,
          },
        ],
      });
    }
  }

  sendChallengeAccessGrantedEvent(data: {
    title: string;
    themes: string;
    duration: string;
    access_code: string;
  }) {
    const { title, themes, duration, access_code } = data;
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendChallengeAccessGrantedEvent({
        title,
        themes,
        duration,
        access_code,
      });
    }

    if (this.gaEnabled) {
      this.ga.challenge_begin({
        title: title,
        themes: themes,
        duration,
        access_code,
      });
    }

    if (this.pixelEnabled) {
      FBPixel.track('Purchase', false, {
        value: '0',
        currency: 'SEK',
        content_name: title,
        content_type: ProgramCategory['Challenges'],
        content_category: 'program',
        contents: [
          {
            id: title,
            quantity: 1,
          },
        ],
      });
    }
  }

  sendTrialStarted() {
    if (this.gaEnabled) {
      this.ga.event({
        category: 'registration',
        action: 'trial',
        label: 'trial',
        value: 80,
      });
    }

    if (this.pixelEnabled) {
      FBPixel.track('StartTrial', false, {});
    }
  }

  SendRegistrationStep1visited() {
    if (this.gaEnabled) {
      this.ga.registration_begin();
    }

    if (this.pixelEnabled) {
      FBPixel.track('Step 1 - visited', true, {});
    }
  }

  SendRegistrationStep1Completed({ registration_source }) {
    if (this.gaEnabled) {
      this.ga.registration_step1Complete({ registration_source });
    }

    if (this.pixelEnabled) {
      FBPixel.track('CompleteRegistration', false, {});
      FBPixel.track('Step 1 - completed', true, {});
    }
  }

  sendRegisterCodeUser(data: {
    code: string;
    type: string;
    discount: number;
    access_time: number;
  }) {
    const { code, type, discount, access_time } = data;
    if (this.amplitudeEnabled) {
      this.amplitude.sendRegisterCodeUser({
        code,
        type,
        discount,
        access_time,
      });
    }
  }
  sendRegisterPlayTrialStarted(data: {
    plan: string;
    amount: number;
    currency: string;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendRegisterPlayTrialStarted(data);
    }
    if (this.gaEnabled) {
      this.ga.trial_started({
        transaction_id: `UserTrial-${this._userAttributes.id}`,
        plan: data.plan,
        value: data.amount,
        currency: data.currency,
      });
    }
    if (this.pixelEnabled) {
      FBPixel.track('StartTrial', false, data);
    }
  }
  sendRegisterPlaySubscriptionStarted(data: {
    plan: string;
    amount: number;
    currency: string;
    ab_test: string;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendRegisterPlaySubscriptionStarted(data);
    }
  }
  sendRegisterFreePlanSelected() {
    if (this.amplitudeEnabled) {
      this.amplitude.sendRegisterFreePlanSelected();
    }
    if (this.ga) {
      this.ga.trial_plan_selected({
        plan: 'free_plan',
      });
    }
  }
  sendRegisterFreeTrialSelected() {
    if (this.amplitudeEnabled) {
      this.amplitude.sendRegisterFreeTrialSelected();
    }
  }
  sendRegisterInstantSubscriptionSelected() {
    if (this.amplitudeEnabled) {
      this.amplitude.sendRegisterInstantSubscriptionSelected();
    }
  }
  sendRegisterFreePlanUpsellSuccess() {
    if (this.amplitudeEnabled) {
      // this.amplitude.sendRegisterFreePlanUpsellSuccess();
    }
  }
  sendRegisterFreePlanUpsellSkip() {
    if (this.amplitudeEnabled) {
      // this.amplitude.sendRegisterFreePlanUpsellSkip();
    }
  }
  sendRegisterFreeTrialUpsellSuccess() {
    if (this.amplitudeEnabled) {
      // this.amplitude.sendRegisterFreeTrialUpsellSuccess();
    }
  }
  sendRegisterFreeTrialUpsellSkip() {
    if (this.amplitudeEnabled) {
      // this.amplitude.sendRegisterFreeTrialUpsellSkip();
    }
  }
  sendRegisterWellnessTrialStarted() {
    if (this.amplitudeEnabled) {
      this.amplitude.sendRegisterWellnessTrialStarted();
    }
    if (this.gaEnabled) {
      this.ga.trial_started({
        transaction_id: `WellnessTrial-${this._userAttributes.id}`,
        plan: 'wellness',
        value: 0,
        currency: 'SEK',
      });
    }
  }
  sendRegisterTrialPlanSelected(data: {
    offer_name: string;
    plan: string;
    amount: number;
    currency: string;
  }) {
    const { offer_name, plan, amount, currency } = data;
    if (this.amplitudeEnabled) {
      this.amplitude.sendRegisterTrialPlanSelected({
        offer_name,
        plan,
        amount,
        currency,
      });
    }
    if (this.ga) {
      this.ga.trial_plan_selected({
        plan,
        amount,
        currency,
      });
    }
  }

  sendInstantTrialSubscriptionPayment(data: {
    payment_type: string;
    plan: string;
    amount: string;
    currency: string;
  }) {
    const { plan, amount, currency, payment_type } = data;
    if (this.amplitudeEnabled) {
      this.amplitude.sendInstantTrialSubscriptionPayment({
        plan,
        amount,
        currency,
        payment_type,
      });
    }
  }

  sendRegisterSubscriptionUpsellSkip(data: {
    offer_name: string;
    plan: string;
    amount: number;
    currency: string;
    payment_type: string;
  }) {
    const { offer_name, plan, amount, currency, payment_type } = data;
    if (this.amplitudeEnabled) {
      // this.amplitude.sendRegisterSubscriptionUpsellSkip({
      //   offer_name,
      //   plan,
      //   amount,
      //   currency,
      //   payment_type,
      // });
    }
  }

  sendRegisterSubscriptionUpsellSuccess(data: {
    offer_name: string;
    plan: string;
    amount: number;
    currency: string;
    payment_type: string;
  }) {
    if (this.amplitudeEnabled) {
      // this.amplitude.sendRegisterSubscriptionUpsellSuccess({
      //   offer_name: data.offer_name,
      //   plan: data.plan,
      //   payment_type: data.payment_type,
      //   amount: data.amount,
      //   currency: data.currency,
      // });
    }
  }
  sendRegisterPaymentMethodAdded(data: { method_type: string }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendRegisterPaymentMethodAdded({
        method_type: data.method_type,
      });
    }
  }

  registrationCampaignCodeUsed(data: { code: string }) {
    if (this.gaEnabled) {
      this.ga.registration_campaignCodeUsed({ code: data.code });
    }
  }

  sendUpgradedFromProgramPageEvent(data: {
    title: string;
    subscription: string;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendUpgradedFromProgramPageEvent(data);
    }
  }

  sendSignupFromProgramPageEvent(data: { title: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendSignupFromProgramPageEvent({
        title: data.title,
      });
    }
  }

  sendProgramCompletionEvent(data: {
    category: string;
    slug: string;
    title: string;
  }) {
    const { category, title, slug } = data;
    if (this.amplitudeEnabled) {
      this.amplitude.sendProgramCompletionEvent({
        title,
      });
    }

    let complete_fnc: Function = null;
    if (category === ProgramCategory['Courses']) {
      complete_fnc = this.ga.course_complete;
    } else if (category === ProgramCategory['Programs']) {
      complete_fnc = this.ga.program_complete;
    } else if (category === ProgramCategory['Challenges']) {
      complete_fnc = this.ga.challenge_complete;
    }

    if (complete_fnc) {
      complete_fnc.apply(this.ga, [
        {
          title,
          category,
          slug,
        },
      ]);
    }
  }

  sendProgramVideoCompletionEvent(data: {
    programTitle: string;
    videoTitle: string;
    weekTitle: string;
    videoSlug: string;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendProgramVideoCompletionEvent(data);
    }
  }

  sendProgramPurchasedGiftEvent(data: {
    category: string;
    title: string;
    slug: string;
    value: number;
    currency: string;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendProgramPurchasedGiftEvent({
        title: data.title,
        slug: data.slug,
        amount: data.value,
        currency: data.currency,
      });
    }

    if (this.gaEnabled) {
      //
      // triggers.SendProgramGiftCodePurchaseEvent({
      //   category: data.category,
      //   program_name: data.title,
      //   program_slug: data.slug,
      //   value: data.value,
      //   currency: data.currency,
      //   ...this._getUserDataProperties(),
      // });
    }
  }

  sendCoursePurchasedGiftEvent(data: {
    category: string;
    title: string;
    slug: string;
    value: number;
    currency: string;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendCoursePurchasedGiftEvent({
        title: data.title,
        slug: data.slug,
        amount: data.value,
        currency: data.currency,
      });
    }

    if (this.gaEnabled) {
      //
      // triggers.SendProgramGiftCodePurchaseEvent({
      //   category: data.category,
      //   program_name: data.title,
      //   program_slug: data.slug,
      //   value: data.value,
      //   currency: data.currency,
      //   ...this._getUserDataProperties(),
      // });
    }
  }

  sendProgramGiftActivatedEvent(data: {
    title: string;
    slug: string;
    amount: number;
    currency: string;
    access_code: string;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendProgramGiftActivatedEvent(data);
    }
  }

  sendCourseGiftActivatedEvent(data: {
    title: string;
    slug: string;
    amount: number;
    currency: string;
    access_code: string;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendCourseGiftActivatedEvent(data);
    }
  }

  sendSurveyStartedEvent(data: {
    title: string;
    program_title: string;
    program_slug: string;
    part_slug: string;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendSurveyStartedEvent(data);
    }
  }

  sendSurveyCompletedEvent(data: {
    title: string;
    program_title: string;
    program_slug: string;
    part_slug: string;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendSurveyCompletedEvent(data);
    }
  }

  sendRegisterEvent(data: { source: string }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendRegisterEvent(data);
    }

    if (this.gaEnabled) {
      this.ga.registration_step1Complete({ registration_source: data.source });
    }

    if (this.pixelEnabled) {
      FBPixel.track('CompleteRegistration', false, {});
      FBPixel.track('Step 1 - completed', true, {});
    }
  }

  sendProfileCompletedEvent(data: { funnel: string }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendProfileCompletedEvent(data);
    }

    if (this.gaEnabled) {
      this.ga.registration_step2Complete();
    }

    if (this.pixelEnabled) {
      FBPixel.track('Step 2 - completed', true, {});
    }
  }
  //

  // this event is sent on search page
  sendSearchTerm(data: { keyword: string; category: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendSearchTerm(data);
    }

    if (this.gaEnabled) {
      this.ga.search_global({ query: data.keyword, category: data.category });
    }
  }
  sendSearchViewAll(data: { category: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendSearchViewAll(data);
    }
  }
  sendSearchClickedTab(data: { tab: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendSearchClickedTab(data);
    }
  }
  sendSearchClickedSuggestion(data: {
    searchQuery: string;
    suggestion: string;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendSearchClickedSuggestion(data);
    }
  }
  sendSearchClickedCorrection(data: { keyword: string; correction: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendSearchClickedCorrection(data);
    }
  }

  sendJoinedBundleWaitList(data: {
    name: string;
    slug: string;
    client_name: string;
    category: string;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendJoinedBundleWaitList(data);
    }
  }

  sendJoinedBundle(data: {
    name: string;
    slug: string;
    client_name: string;
    code: string;
    category: string;
  }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendJoinedBundle(data);
    }

    if (this.gaEnabled) {
      this.ga.bundle_begin(data);
    }
  }

  sendTrialReclaimed(data: { source: string }) {
    //
    if (this.amplitudeEnabled) {
      this.amplitude.sendTrialReclaimed(data);
    }
  }

  sendPlayOverviewClicked(data: {
    id: string;
    slug: string;
    title: string;
    type: string;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendPlayOverviewClicked(data);
    }
  }

  sendTrialRejected() {
    if (this.amplitudeEnabled) {
      this.amplitude.sendTrialRejected();
    }
  }

  sendLivePaymentBeginEvent(data: {
    id: string;
    title: string;
    slug: string;
    value: number;
    currency: string;
    instructor_name: string;
    event_theme: string;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_LiveBegin(data);
    }
  }

  sendLivePaymentCompleteEvent(data: {
    order_id: string;
    id: string;
    title: string;
    slug: string;
    value: number;
    currency: string;
    event_theme: string;
    instructor_name: string;
    new_customer: boolean;
  }) {
    if (this.amplitudeEnabled) {
      this.amplitude.sendPaidLiveEvent({
        order_id: data.order_id,
        name: data.title,
        amount: data.value,
        type: data.event_theme,
        instructor: data.instructor_name,
      });
    }

    if (this.gaEnabled) {
      this.ga.purchase_LiveComplete(data);

      // triggers.sendLiveEventPurchaseTrigger({
      //   order_id: data.order_id,
      //   event_name: data.title,
      //   event_theme: data.event_theme,
      //   currency: data.currency,
      //   amount: data.value,
      //   ...this._getUserDataProperties(),
      // });
    }

    if (this.pixelEnabled) {
      FBPixel.track('Purchase', false, {
        value: data.value,
        currency: data.currency,
        content_name: data.title,
        content_category: 'live_event',
        content_type: 'live_event',
        contents: [
          {
            content_name: data.title,
            quantity: 1,
          },
        ],
      });
    }
  }

  sendLiveVoucherPurchaseEvent(data: {
    id: string;
    order_id: string;
    title: string;
    slug: string;
    value: number;
    currency: string;
    new_customer: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_LiveVoucherComplete(data);
    }

    if (this.amplitudeEnabled) {
      this.amplitude.sendLiveEventVoucherPurchase(data);
    }

    if (this.pixelEnabled) {
      FBPixel.track('Purchase', false, {
        value: data.value,
        currency: data.currency,
        content_name: data.title,
        content_category: 'live_event',
        content_type: 'live_event',
        contents: [
          {
            id: data.title,
            quantity: 1,
          },
        ],
      });
    }
  }

  sendLivePaymentFailedEvent(data: {
    order_id: string;
    id: string;
    title: string;
    slug: string;
    value: number;
    currency: string;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_LiveFail(data);
    }
  }

  sendCoursePaymentBeginEvent(data: {
    item_type: string;
    id: string;
    title: string;
    slug: string;
    value: number;
    currency: string;
    discount_code: string;
    voucher?: boolean;
    original_price?: number;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_courseBegin(data);
    }
  }

  sendCoursePaymentCompleteEvent(data: {
    order_id: string;
    item_type: string;
    id: string;
    title: string;
    slug: string;
    value: number;
    original_amount: number;
    currency: string;
    payment_type?: string;
    voucher_purchase?: boolean;
    new_customer: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_courseComplete(data);
    }

    if (this.pixelEnabled) {
      FBPixel.track('Purchase', false, {
        value: data.original_amount,
        currency: data.currency,
        content_name: data.title,
        content_type: ProgramCategory['Courses'],
        content_category: 'program',
        contents: [
          {
            id: data.title,
            quantity: 1,
          },
        ],
      });
    }
  }

  sendCoursePaymentFailedEvent(data: {
    order_id: string;
    item_type: string;
    id: string;
    title: string;
    slug: string;
    value: number;
    original_amount: number;
    currency: string;
    payment_type: string;
    voucher_purchase: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_courseFail(data);
    }
  }

  HardChurnCancelationStarted(data: { plan_type: string }) {
    if (this.amplitude) {
      this.amplitude.HardChurnCancelationStarted(data);
    }
  }
  HardChurnReasonSelected(data: { plan_type: string; reason: string }) {
    if (this.amplitude) {
      this.amplitude.HardChurnReasonSelected(data);
    }
  }
  HardChurnUpsellOfferTaken(data: {
    plan_name: string;
    plan_type: string;
    plan_amount: number;
    plan_currency: string;
  }) {
    if (this.amplitude) {
      this.amplitude.HardChurnUpsellOfferTaken(data);
    }
  }
  HardChurnUpsellOfferDismissed(data: {
    plan_name: string;
    plan_type: string;
    plan_amount: number;
    plan_currency: string;
  }) {
    if (this.amplitude) {
      this.amplitude.HardChurnUpsellOfferDismissed(data);
    }
  }

  //
  sendBundlePaymentBeginEvent(data) {
    if (this.gaEnabled) {
      this.ga.purchase_bundleBegin(data);
    }
  }

  sendBundlePurchaseCompleteEvent(data: {
    order_id: string;
    name: string;
    id: string;
    value: number;
    original_amount: number;
    currency: string;
    payment_type: string;
    sourcePage: string;
    new_customer: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.purchase_bundleComplete(data);
    }
  }

  SendBundlePurchaseFailEvent(data) {
    if (this.gaEnabled) {
      this.ga.purchase_bundleFail(data);
    }
  }

  sendItemViewEvent(data: {
    category: 'Subscription' | 'LiveEvent' | 'Course' | 'Bundle';
    slug: string;
    title: string;
    price: number;
    currency: string;
    new_customer: boolean;
  }) {
    if (this.gaEnabled) {
      this.ga.item_view(data);
    }
  }

  HardChurnCancelationConfirmed(data: { plan_name: string }) {
    if (this.amplitude) {
      this.amplitude.HardChurnCancelationConfirmed(data);
    }
  }

  _PIXEL_TRACK = (...params) => {
    if (this.pixelEnabled) FBPixel.track.apply(null, params);
  };
}

let manager: AnalyticsManager_;
function AnalyticsManager(): AnalyticsManager_ {
  try {
    if (typeof window === 'undefined') {
      return null;
    }

    if (manager) {
      return manager;
    }

    manager = new AnalyticsManager_();
    return manager;
  } catch (ex) {
    Sentry.captureException(ex);
  }
}

export default AnalyticsManager;
