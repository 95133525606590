import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export * from './apollo-helpers';
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents non-fractional signed whole numeric values. Since the value may exceed the size of a 32-bit integer, it's encoded as a string. */
  BigInt: any;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: { [key: string]: any };
  Upload: any;
};

export type AccessPeriod = {
  __typename?: 'AccessPeriod';
  unit: Maybe<AccessPeriodUnitKind>;
  value: Maybe<Scalars['Int']>;
};

export enum AccessPeriodUnitKind {
  Days = 'days',
  Months = 'months',
  Weeks = 'weeks',
}

export type AccessToken = {
  __typename?: 'AccessToken';
  accessUntil: Maybe<Scalars['String']>;
  code: Scalars['String'];
  id: Scalars['ID'];
};

export type Accessable = Bundle | GenericProgram | LiveEvent;

export enum ActionInput {
  Complete = 'COMPLETE',
  Draft = 'DRAFT',
}

export enum ActionsKind {
  Dismiss = 'Dismiss',
  Feedback = 'Feedback',
  Success = 'Success',
}

/** Autogenerated return type of ActivateAccessCode */
export type ActivateAccessCodePayload = {
  __typename?: 'ActivateAccessCodePayload';
  accessToken: Maybe<AccessToken>;
  errors: Maybe<Array<Maybe<ProcessingError>>>;
};

/** Autogenerated return type of ActivateBundle */
export type ActivateBundlePayload = {
  __typename?: 'ActivateBundlePayload';
  errors: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ActivateChallenge */
export type ActivateChallengePayload = {
  __typename?: 'ActivateChallengePayload';
  challengeParticipation: Maybe<ChallengeParticipant>;
  challengePass: Maybe<ChallengePass>;
  errors: Maybe<Array<Error>>;
};

/** Autogenerated return type of ActivateFullAccessCode */
export type ActivateFullAccessCodePayload = {
  __typename?: 'ActivateFullAccessCodePayload';
  errors: Maybe<Array<Error>>;
  success: Maybe<Scalars['Boolean']>;
  user: Maybe<CurrentUser>;
};

/** Autogenerated return type of ActivateGenericProgram */
export type ActivateGenericProgramPayload = {
  __typename?: 'ActivateGenericProgramPayload';
  errors: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ActivateLegacyPausaSmart */
export type ActivateLegacyPausaSmartPayload = {
  __typename?: 'ActivateLegacyPausaSmartPayload';
  accessDays: Maybe<Scalars['Int']>;
  accessUntil: Maybe<Scalars['ISO8601Date']>;
  action: Maybe<Scalars['String']>;
  errors: Maybe<Array<Maybe<ProcessingError>>>;
};

/** Autogenerated return type of ActivateLiveEvent */
export type ActivateLiveEventPayload = {
  __typename?: 'ActivateLiveEventPayload';
  errors: Maybe<Scalars['String']>;
  liveEventAccess: Maybe<UserAccess>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ActivateOneTimeCode */
export type ActivateOneTimeCodePayload = {
  __typename?: 'ActivateOneTimeCodePayload';
  errors: Maybe<Array<Error>>;
  success: Maybe<Scalars['Boolean']>;
  user: Maybe<CurrentUser>;
};

/** Autogenerated return type of ActivatePausaSmart */
export type ActivatePausaSmartPayload = {
  __typename?: 'ActivatePausaSmartPayload';
  success: Scalars['Boolean'];
};

/** Autogenerated return type of Activate */
export type ActivatePayload = {
  __typename?: 'ActivatePayload';
  accessToken: Maybe<AccessToken>;
  errors: Array<ProcessingError>;
  genericProgram: Maybe<GenericProgram>;
};

/** Autogenerated return type of ActivateTrialExtension */
export type ActivateTrialExtensionPayload = {
  __typename?: 'ActivateTrialExtensionPayload';
  errors: Maybe<Array<Error>>;
  success: Maybe<Scalars['Boolean']>;
  user: Maybe<CurrentUser>;
};

/** Autogenerated return type of AddAdyenPaymentMethod */
export type AddAdyenPaymentMethodPayload = {
  __typename?: 'AddAdyenPaymentMethodPayload';
  errors: Maybe<Array<Error>>;
  newRecord: Maybe<Scalars['Boolean']>;
  orderId: Maybe<Scalars['String']>;
  resultAction: Maybe<AdyenAction>;
  resultCode: Maybe<PaymentResultCodeKind>;
  transaction: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddComment */
export type AddCommentPayload = {
  __typename?: 'AddCommentPayload';
  comment: Maybe<Comment>;
};

/** Autogenerated return type of AddFavoritePlaylist */
export type AddFavoritePlaylistPayload = {
  __typename?: 'AddFavoritePlaylistPayload';
  playlist: Maybe<Playlist>;
};

/** Autogenerated return type of AddFavoriteVideo */
export type AddFavoriteVideoPayload = {
  __typename?: 'AddFavoriteVideoPayload';
  video: Maybe<Video>;
};

/** Autogenerated return type of AddMoodTrackerAnswer */
export type AddMoodTrackerAnswerPayload = {
  __typename?: 'AddMoodTrackerAnswerPayload';
  errors: Array<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type AddPayment = OrderPaymentInterface & {
  __typename?: 'AddPayment';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyKind;
  failedReason: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  originalAmountCents: Maybe<Scalars['Int']>;
  paymentProvider: PaymentProviderKind;
  paymentProviderVariant: Maybe<PaymentProviderVariantKind>;
  sourcePage: Maybe<Scalars['String']>;
  state: PaymentStatusKind;
  status: Maybe<OrderStatus>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of AddPlaylist */
export type AddPlaylistPayload = {
  __typename?: 'AddPlaylistPayload';
  playlist: Maybe<Playlist>;
};

/** Autogenerated return type of AddSearchResult */
export type AddSearchResultPayload = {
  __typename?: 'AddSearchResultPayload';
  documentId: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddStory */
export type AddStoryPayload = {
  __typename?: 'AddStoryPayload';
  story: Maybe<Story>;
};

/** Autogenerated return type of AddTrustlyPaymentMethod */
export type AddTrustlyPaymentMethodPayload = {
  __typename?: 'AddTrustlyPaymentMethodPayload';
  errors: Maybe<Array<Error>>;
  orderId: Maybe<Scalars['String']>;
  resultUrl: Maybe<Scalars['String']>;
  transaction: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddUserFeedback */
export type AddUserFeedbackPayload = {
  __typename?: 'AddUserFeedbackPayload';
  success: Scalars['Boolean'];
};

/** Autogenerated return type of AddUserSchedule */
export type AddUserSchedulePayload = {
  __typename?: 'AddUserSchedulePayload';
  filename: Maybe<Scalars['String']>;
  result: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AddVideoToPlaylist */
export type AddVideoToPlaylistPayload = {
  __typename?: 'AddVideoToPlaylistPayload';
  playlist: Maybe<Playlist>;
};

/** Autogenerated return type of AddWaitingItem */
export type AddWaitingItemPayload = {
  __typename?: 'AddWaitingItemPayload';
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type AdyenAction = {
  __typename?: 'AdyenAction';
  actionType: Scalars['String'];
  authorisationToken: Maybe<Scalars['String']>;
  httpMethod: Maybe<Scalars['String']>;
  paymentData: Maybe<Scalars['String']>;
  paymentMethodType: Scalars['String'];
  qrCodeData: Maybe<Scalars['String']>;
  redirectData: Maybe<Scalars['String']>;
  subtype: Maybe<Scalars['String']>;
  token: Maybe<Scalars['String']>;
  type: Maybe<AdyenActionTypeKind>;
  url: Maybe<Scalars['String']>;
};

export enum AdyenActionTypeKind {
  QrCode = 'qrCode',
  Redirect = 'redirect',
  ThreeDs2 = 'threeDS2',
}

export enum AdyenResultCodeKind {
  Authorised = 'Authorised',
  Cancelled = 'Cancelled',
  Error = 'Error',
  Refused = 'Refused',
}

export type AnswerInput = {
  id: Scalars['ID'];
  values: InputMaybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of AnswerSurvey */
export type AnswerSurveyPayload = {
  __typename?: 'AnswerSurveyPayload';
  errors: Array<ProcessingError>;
};

export type AppleSubscriptionPrecheck = {
  __typename?: 'AppleSubscriptionPrecheck';
  /** User to which this original_transaction_id is bounded */
  existingUser: Maybe<CurrentUser>;
  /** Shows whether this original_transaction_id is already bounded to some user or not */
  exists: Maybe<Scalars['Boolean']>;
};

export enum ArchiveItemKind {
  Bundle = 'Bundle',
  GenericProgram = 'GenericProgram',
  LiveEvent = 'LiveEvent',
}

/** Autogenerated return type of ArchiveItem */
export type ArchiveItemPayload = {
  __typename?: 'ArchiveItemPayload';
  errors: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ArchivedItems = {
  __typename?: 'ArchivedItems';
  bundles: Maybe<Array<UserAccess>>;
  challenges: Maybe<Array<ProgramAccess>>;
  courses: Maybe<Array<CourseAccess>>;
  liveEvents: Maybe<Array<UserAccess>>;
  programs: Maybe<Array<ProgramAccess>>;
};

export type Assets = {
  __typename?: 'Assets';
  avatar: Maybe<Scalars['String']>;
  headerImage: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AssignFinalFunnel */
export type AssignFinalFunnelPayload = {
  __typename?: 'AssignFinalFunnelPayload';
  success: Scalars['Boolean'];
};

/** Autogenerated return type of AssignMobileDevice */
export type AssignMobileDevicePayload = {
  __typename?: 'AssignMobileDevicePayload';
  errors: Maybe<Array<Error>>;
  user: Maybe<CurrentUser>;
};

/** Autogenerated return type of AssignPlanToTrial */
export type AssignPlanToTrialPayload = {
  __typename?: 'AssignPlanToTrialPayload';
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of AuthenticateUser */
export type AuthenticateUserPayload = {
  __typename?: 'AuthenticateUserPayload';
  error: Maybe<Error>;
  token: Maybe<Scalars['String']>;
  user: Maybe<UserInterface>;
};

export type Autocomplete = {
  __typename?: 'Autocomplete';
  error: Maybe<Scalars['String']>;
  query: Maybe<Scalars['String']>;
  results: Array<Scalars['String']>;
};

export type AvailableSubscriptionPlan = {
  __typename?: 'AvailableSubscriptionPlan';
  /** Code that user applied */
  code: Maybe<Scalars['String']>;
  /** Kind may be one of global, extra_global, subscription_discount_code, subscription_full_access_code, trial_campaign_code, one_time_code, trial_referral_code, trial_reclaim, upsell, extra_subscription_discount_code */
  codeType: Maybe<Code>;
  /** Subscriptions that are full accesses will show up here after inputing a valid access code by user */
  fullAccesses: Maybe<Array<UserSubscription>>;
  isCodeValid: Maybe<Scalars['Boolean']>;
  /** One time code promotions will show up after inputing valid code. They do not have subscription plan assigned. They are extending access by defined days or access_until */
  oneTimeCodes: Maybe<Array<UserSubscription>>;
  /** Subscriptions including basic and promotional */
  subscriptions: Array<UserSubscription>;
  /** Codes that will only extend trial period days, but are connected to referring user and can be used by referred user, giving the reffered user some extra days fo access_until and reffering user another extra days of access_until when reffered user will make first payment */
  trialReferralCodes: Maybe<Array<UserSubscription>>;
  /** Promotional trial plans if available */
  trials: Maybe<Array<UserSubscription>>;
  /** Localized, written by admins, custom success/failure message for user to show after applying the code */
  validationMessage: Maybe<Scalars['String']>;
};

export type BlogPost = {
  __typename?: 'BlogPost';
  author: Maybe<Scalars['String']>;
  authors: Array<Scalars['ID']>;
  body: Scalars['String'];
  categories: Array<Scalars['String']>;
  collection: Maybe<Scalars['String']>;
  facebookImage: Maybe<Scalars['String']>;
  featuredImage: Scalars['String'];
  id: Scalars['ID'];
  instructors: Array<Instructor>;
  keywords2: Maybe<Array<Scalars['String']>>;
  language: Scalars['String'];
  market: Array<Scalars['String']>;
  metaDescription: Maybe<Scalars['String']>;
  metaTitle: Maybe<Scalars['String']>;
  processedBody: Maybe<Scalars['String']>;
  publishedAt: Scalars['ISO8601DateTime'];
  republishedAt: Maybe<Scalars['ISO8601DateTime']>;
  showPricingBanner: Scalars['Boolean'];
  slug: Scalars['String'];
  tags: Array<Maybe<Scalars['String']>>;
  title: Scalars['String'];
};

export type BlogPostCategory = {
  __typename?: 'BlogPostCategory';
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type BrowserInfoInput = {
  acceptHeader: InputMaybe<Scalars['String']>;
  colorDepth: InputMaybe<Scalars['Int']>;
  javaEnabled: InputMaybe<Scalars['Boolean']>;
  language: InputMaybe<Scalars['String']>;
  screenHeight: InputMaybe<Scalars['Int']>;
  screenWidth: InputMaybe<Scalars['Int']>;
  timeZoneOffset: InputMaybe<Scalars['Int']>;
  userAgent: InputMaybe<Scalars['String']>;
};

export type Bundle = {
  __typename?: 'Bundle';
  accessPeriodUnit: Maybe<Scalars['String']>;
  accessPeriodValue: Maybe<Scalars['Int']>;
  accessStrategy: BundleAccessStrategyKind;
  active: Scalars['Boolean'];
  bundleCategory: Maybe<BundleCategoryKind>;
  bundleItems: BundleItem;
  cardImage: Maybe<Scalars['String']>;
  client: Maybe<Client>;
  footerCta: BundleFooterCta;
  heroImage: Maybe<Scalars['String']>;
  heroMobileImage: Maybe<Scalars['String']>;
  hiddenInListing: Scalars['Boolean'];
  hideInfoText: Scalars['Boolean'];
  id: Scalars['ID'];
  infoPanel: Maybe<BundleInfoPanel>;
  infoPanel2: Maybe<BundleInfoPanel2>;
  infoPanel3: Maybe<BundleInfoPanel>;
  isUserEligible: Scalars['Boolean'];
  kind: BundleKind;
  liveBannerSection: Maybe<BundleLiveBannerSection>;
  metaDescription: Scalars['String'];
  metaImage: Maybe<Scalars['String']>;
  preview: Maybe<BundlePreview>;
  price: Maybe<Price>;
  promotionSection: Maybe<BundlePromotionSection>;
  promotionalImage: Maybe<Scalars['String']>;
  publishedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated Use `relatedBundlesSection` instead. */
  relatedBundles: Array<Bundle>;
  relatedBundlesSection: Maybe<BundleRelatedBundlesSection>;
  sections: Array<BundleSectionKind>;
  shortDescription: Scalars['String'];
  slug: Scalars['String'];
  specialOfferCode: Maybe<Scalars['String']>;
  subscriptionPromotion: Maybe<UserSubscription>;
  tags: Array<Maybe<Scalars['String']>>;
  title: Scalars['String'];
  trailer: Maybe<Video>;
  twoColumnsSection: Maybe<BundleTwoColumnsSection>;
  userAccess: Maybe<UserAccess>;
  waitingItemInfo: WaitingItemInfo;
};

export enum BundleAccessStrategyKind {
  ByCode = 'by_code',
  ForAll = 'for_all',
  ForPremiumOnly = 'for_premium_only',
  ForUsersWithAccess = 'for_users_with_access',
  WithPrice = 'with_price',
}

export enum BundleCategoryInput {
  All = 'all',
  ContentHub = 'content_hub',
  Healthcare = 'healthcare',
}

export enum BundleCategoryKind {
  FaR = 'FaR',
  ContentHub = 'content_hub',
  Healthcare = 'healthcare',
  Partners = 'partners',
}

export type BundleFooterCta = {
  __typename?: 'BundleFooterCta';
  description: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type BundleInfoPanel = {
  __typename?: 'BundleInfoPanel';
  buttonContent: Maybe<Scalars['String']>;
  buttonLink: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type BundleInfoPanel2 = {
  __typename?: 'BundleInfoPanel2';
  buttonContent: Maybe<Scalars['String']>;
  buttonLink: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  longText: Maybe<Scalars['String']>;
  previewText: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type BundleItem = {
  __typename?: 'BundleItem';
  blogPosts: BundleSection;
  challenges: BundleSection;
  documents: BundleSection;
  liveEvents: BundleSection;
  playlists: BundleSection;
  programs: BundleSection;
  sectionedVideos: Array<BundleSection>;
  videos: BundleSection;
};

export enum BundleKind {
  BySubscription = 'by_subscription',
  WithAccess = 'with_access',
}

export type BundleLiveBannerSection = {
  __typename?: 'BundleLiveBannerSection';
  buttonLink: Maybe<Scalars['String']>;
  buttonName: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type BundlePayment = OrderPaymentInterface & {
  __typename?: 'BundlePayment';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyKind;
  bundle: Bundle;
  failedReason: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  originalAmountCents: Maybe<Scalars['Int']>;
  paymentProvider: PaymentProviderKind;
  paymentProviderVariant: Maybe<PaymentProviderVariantKind>;
  sourcePage: Maybe<Scalars['String']>;
  state: PaymentStatusKind;
  status: Maybe<OrderStatus>;
  success: Scalars['Boolean'];
};

export type BundlePreview = {
  __typename?: 'BundlePreview';
  description: Maybe<Scalars['String']>;
  label: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  video: Maybe<Video>;
};

export type BundlePromotionSection = {
  __typename?: 'BundlePromotionSection';
  buttonContent: Maybe<Scalars['String']>;
  buttonLink: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type BundleRelatedBundlesSection = {
  __typename?: 'BundleRelatedBundlesSection';
  bundles: Maybe<Array<Bundle>>;
  headline: Maybe<Scalars['String']>;
};

export type BundleSection = {
  __typename?: 'BundleSection';
  headline: Maybe<Scalars['String']>;
  items: Array<Item>;
  viewAllUrl: Maybe<Scalars['String']>;
};

export enum BundleSectionKind {
  BlogPosts = 'blog_posts',
  Challenges = 'challenges',
  Documents = 'documents',
  InfoPanel = 'info_panel',
  InfoPanel_2 = 'info_panel_2',
  InfoPanel_3 = 'info_panel_3',
  LiveBannerSection = 'live_banner_section',
  LiveEvents = 'live_events',
  Playlists = 'playlists',
  Programs = 'programs',
  PromotionSection = 'promotion_section',
  RelatedBundles = 'related_bundles',
  SubscriptionPromotions = 'subscription_promotions',
  TwoColumnsSection = 'two_columns_section',
  VideoSection_1 = 'video_section_1',
  VideoSection_2 = 'video_section_2',
  VideoSection_3 = 'video_section_3',
  Videos = 'videos',
}

export type BundleTwoColumnsSection = {
  __typename?: 'BundleTwoColumnsSection';
  leftContent: Maybe<Scalars['String']>;
  rightContent: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type CampaignBlogSection = {
  __typename?: 'CampaignBlogSection';
  blog1: Maybe<BlogPost>;
  blog2: Maybe<BlogPost>;
  blog3: Maybe<BlogPost>;
  linkText: Maybe<Scalars['String']>;
  linkUrl: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type CampaignBundleSection = {
  __typename?: 'CampaignBundleSection';
  bundle1: Maybe<Bundle>;
  bundle2: Maybe<Bundle>;
  bundle3: Maybe<Bundle>;
  linkText: Maybe<Scalars['String']>;
  linkUrl: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type CampaignCode = {
  __typename?: 'CampaignCode';
  code: Scalars['String'];
  days: Scalars['Int'];
  id: Scalars['ID'];
};

export type CampaignCtaSection = {
  __typename?: 'CampaignCtaSection';
  image: Maybe<Image>;
  linkText: Maybe<Scalars['String']>;
  linkUrl: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type CampaignPage = {
  __typename?: 'CampaignPage';
  blogSection: Maybe<CampaignBlogSection>;
  bundleSection: Maybe<CampaignBundleSection>;
  createdAt: Maybe<Scalars['ISO8601DateTime']>;
  ctaSection1: Maybe<CampaignCtaSection>;
  ctaSection2: Maybe<CampaignCtaSection>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metaDescription: Maybe<Scalars['String']>;
  metaImage: Maybe<Image>;
  metaTitle: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  path: Maybe<Scalars['String']>;
  publishedAt: Maybe<Scalars['ISO8601DateTime']>;
  qASection: Maybe<Array<QAndA>>;
  slug: Maybe<Scalars['String']>;
  textSection: Maybe<CampaignTextSection>;
  title: Maybe<Scalars['String']>;
  trailerImage: Maybe<Image>;
  trailerVideo: Maybe<Video>;
  updatedAt: Maybe<Scalars['ISO8601DateTime']>;
  videoSection: Maybe<CampaignVideoSection>;
};

export type CampaignSection = {
  __typename?: 'CampaignSection';
  active: Maybe<Scalars['Boolean']>;
  buttonLink: Maybe<Scalars['String']>;
  buttonText: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  markets: Maybe<Array<Scalars['String']>>;
  tag: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type CampaignTextSection = {
  __typename?: 'CampaignTextSection';
  content1: Maybe<Scalars['String']>;
  content2: Maybe<Scalars['String']>;
  content3: Maybe<Scalars['String']>;
  content4: Maybe<Scalars['String']>;
  content5: Maybe<Scalars['String']>;
  image: Maybe<Image>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type CampaignVideoSection = {
  __typename?: 'CampaignVideoSection';
  linkText: Maybe<Scalars['String']>;
  linkUrl: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  video1: Maybe<Video>;
  video2: Maybe<Video>;
  video3: Maybe<Video>;
};

/** Autogenerated return type of CancelSubscription */
export type CancelSubscriptionPayload = {
  __typename?: 'CancelSubscriptionPayload';
  success: Scalars['Boolean'];
};

export type Challenge = {
  __typename?: 'Challenge';
  name: Scalars['String'];
};

export type ChallengeParticipant = {
  __typename?: 'ChallengeParticipant';
  accessUntil: Scalars['ISO8601DateTime'];
  challenge: Challenge;
};

export type ChallengePass = {
  __typename?: 'ChallengePass';
  challenge: Challenge;
  code: Maybe<Scalars['String']>;
  days: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of ChangeEmailPreference */
export type ChangeEmailPreferencePayload = {
  __typename?: 'ChangeEmailPreferencePayload';
  errors: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ChangePassword */
export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  user: User;
};

/** Autogenerated return type of ChangePreferences */
export type ChangePreferencesPayload = {
  __typename?: 'ChangePreferencesPayload';
  user: UserInterface;
};

/** Autogenerated return type of ChangeProductEmailPreference */
export type ChangeProductEmailPreferencePayload = {
  __typename?: 'ChangeProductEmailPreferencePayload';
  errors: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ChangeProfileData */
export type ChangeProfileDataPayload = {
  __typename?: 'ChangeProfileDataPayload';
  user: UserInterface;
};

/** Autogenerated return type of ChangeProfileInfo */
export type ChangeProfileInfoPayload = {
  __typename?: 'ChangeProfileInfoPayload';
  user: UserInterface;
};

/** Autogenerated return type of ChangeProfilePrivacy */
export type ChangeProfilePrivacyPayload = {
  __typename?: 'ChangeProfilePrivacyPayload';
  user: UserInterface;
};

/** Autogenerated return type of ChangeUserPreferences */
export type ChangeUserPreferencesPayload = {
  __typename?: 'ChangeUserPreferencesPayload';
  user: UserInterface;
};

export type ChurnAnswer = {
  __typename?: 'ChurnAnswer';
  cause: Maybe<Scalars['String']>;
  feedback: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  solution: Maybe<Scalars['String']>;
  state: Scalars['String'];
};

/** Autogenerated return type of ChurnStart */
export type ChurnStartPayload = {
  __typename?: 'ChurnStartPayload';
  answer: Maybe<ChurnAnswer>;
  errors: Array<Error>;
  previousAnswer: Maybe<ChurnAnswer>;
};

/** Autogenerated return type of ChurnStep */
export type ChurnStepPayload = {
  __typename?: 'ChurnStepPayload';
  answer: Maybe<ChurnAnswer>;
  errors: Array<Error>;
};

export enum ClassName {
  EmailPreference = 'EmailPreference',
  GenericProgram = 'GenericProgram',
  LiveEvent = 'LiveEvent',
}

/** Autogenerated return type of Click */
export type ClickPayload = {
  __typename?: 'ClickPayload';
  errors: Maybe<Array<Error>>;
};

/** Autogenerated return type of ClickhouseEventProxy */
export type ClickhouseEventProxyPayload = {
  __typename?: 'ClickhouseEventProxyPayload';
  errors: Maybe<Array<Error>>;
};

export enum ClickhouseProxyEventKind {
  PurchaseSubscription = 'purchase_subscription',
}

export type Client = {
  __typename?: 'Client';
  active: Scalars['Boolean'];
  bundles: Array<Bundle>;
  id: Scalars['ID'];
  info: Maybe<Scalars['String']>;
  logo: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export enum Code {
  ExtraSubscriptionDiscountCode = 'extra_subscription_discount_code',
  OneTimeCode = 'one_time_code',
  SubscriptionDiscountCode = 'subscription_discount_code',
  SubscriptionFullAccessCode = 'subscription_full_access_code',
  TrialCampaignCode = 'trial_campaign_code',
  TrialReferralCode = 'trial_referral_code',
}

export type CodeDetails = {
  __typename?: 'CodeDetails';
  provider: Maybe<WellnessProviderKind>;
  type: PromotionCodeTypeKind;
};

export enum CodeSourceKind {
  App = 'app',
  Voucher = 'voucher',
}

export enum CodeStatusKind {
  Invalid = 'invalid',
  Mismatch = 'mismatch',
  Valid = 'valid',
}

export enum CodeTypeKind {
  AccessCode = 'access_code',
  AccessToken = 'access_token',
  ChallengePass = 'challenge_pass',
  DiscountCode = 'discount_code',
}

export type Comment = {
  __typename?: 'Comment';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resourceClass: Maybe<Scalars['String']>;
  resourceId: Scalars['ID'];
  userId: Scalars['ID'];
};

/** Autogenerated return type of CompleteProfile */
export type CompleteProfilePayload = {
  __typename?: 'CompleteProfilePayload';
  errors: Maybe<Array<Error>>;
  user: Maybe<CurrentUser>;
};

/** Autogenerated return type of ConfirmEmail */
export type ConfirmEmailPayload = {
  __typename?: 'ConfirmEmailPayload';
  errors: Maybe<Array<Error>>;
  user: Maybe<CurrentUser>;
};

export type Content = {
  __typename?: 'Content';
  externalLede: Maybe<Scalars['String']>;
  externalTitle: Maybe<Scalars['String']>;
  internalLede: Maybe<Scalars['String']>;
  internalTitle: Maybe<Scalars['String']>;
  lede: Maybe<Scalars['String']>;
};

export type ContentAnswer = {
  __typename?: 'ContentAnswer';
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type Course = {
  __typename?: 'Course';
  content: Content;
  externalImageUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legacy: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  path: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  themes: Maybe<Array<Scalars['String']>>;
  themesV2: Maybe<Array<Theme>>;
  titleImageUrl: Maybe<Scalars['String']>;
};

export type CourseAccess = {
  __typename?: 'CourseAccess';
  accessStatus: ProgramAccessStatus;
  accessUntil: Maybe<Scalars['BigInt']>;
  archivedAt: Maybe<Scalars['ISO8601DateTime']>;
  course: Maybe<Course>;
  courseProgress: Maybe<Scalars['Int']>;
  joinedAt: Maybe<Scalars['ISO8601DateTime']>;
};

export type CoursesSection = {
  __typename?: 'CoursesSection';
  description: Maybe<Scalars['String']>;
  group1ButtonLink: Maybe<Scalars['String']>;
  group1ButtonText: Maybe<Scalars['String']>;
  group1Description: Maybe<Scalars['String']>;
  group1ImageUrl: Maybe<Scalars['String']>;
  group1Title: Maybe<Scalars['String']>;
  group2ButtonLink: Maybe<Scalars['String']>;
  group2ButtonText: Maybe<Scalars['String']>;
  group2Description: Maybe<Scalars['String']>;
  group2ImageUrl: Maybe<Scalars['String']>;
  group2Title: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateNewPassword */
export type CreateNewPasswordPayload = {
  __typename?: 'CreateNewPasswordPayload';
  success: Scalars['Boolean'];
};

export type CrossPromotionalBanner = {
  __typename?: 'CrossPromotionalBanner';
  imageUrl: Maybe<Scalars['String']>;
  link: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CtaSection = {
  __typename?: 'CtaSection';
  description: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export enum CurrencyKind {
  Eur = 'EUR',
  Sek = 'SEK',
}

export type CurrentElement = {
  __typename?: 'CurrentElement';
  path: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type CurrentUser = UserInterface & {
  __typename?: 'CurrentUser';
  accessToken: Maybe<Scalars['String']>;
  accessUntil: Maybe<Scalars['String']>;
  accountActivatedAt: Maybe<Scalars['ISO8601DateTime']>;
  address: Maybe<Scalars['String']>;
  assets: Maybe<Assets>;
  availableForTrial: Scalars['Boolean'];
  birthday: Maybe<Scalars['ISO8601Date']>;
  canUseTrustly: Maybe<Scalars['Boolean']>;
  challengeAccesses: Array<Maybe<ProgramAccess>>;
  challengeParticipations: Array<Maybe<ChallengeParticipant>>;
  city: Maybe<Scalars['String']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']>;
  country: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['String']>;
  courseAccesses: Array<Maybe<CourseAccess>>;
  dateOfBirth: Maybe<Scalars['Int']>;
  eligibleForDelete: Maybe<Scalars['Boolean']>;
  eligibleForStartDiscount: Maybe<Scalars['Boolean']>;
  eligibleForTrial: Maybe<Scalars['Boolean']>;
  email: Maybe<Scalars['String']>;
  emailLanguage: Maybe<Scalars['String']>;
  extraPayments: Maybe<Array<Maybe<ExtraPayment>>>;
  favorites: Maybe<Favorites>;
  finalFunnel: Maybe<FunnelKind>;
  firstName: Maybe<Scalars['String']>;
  funnel: Maybe<FunnelKind>;
  gender: Maybe<Scalars['String']>;
  group: Scalars['String'];
  hasWebPayments: Maybe<Scalars['Boolean']>;
  hasWellness: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  impersonated: Maybe<Scalars['Boolean']>;
  inGracePeriod: Maybe<Scalars['Boolean']>;
  interests: Maybe<Array<Interest>>;
  isEmailActivated: Maybe<Scalars['Boolean']>;
  isNoneTrialPremium: Maybe<Scalars['Boolean']>;
  isPremium: Maybe<Scalars['Boolean']>;
  isPublic: Maybe<Scalars['Boolean']>;
  kind: Maybe<Scalars['String']>;
  lastAccessPaymentProvider: Maybe<PaymentProviderKind>;
  lastName: Maybe<Scalars['String']>;
  lastWellnessAccessProvider: Maybe<Scalars['String']>;
  liveEventsAccess: Maybe<Array<Maybe<LiveEventAccess>>>;
  locale: Maybe<Scalars['String']>;
  meditationSettings: Maybe<MeditationSettings>;
  nextBillingCycle: Maybe<NextBillingCycle>;
  onboardingCompleted: Scalars['Boolean'];
  onboardingInterestsSkipped: Scalars['Boolean'];
  onboardingSkipped: Scalars['Boolean'];
  onboardingTopicsSkipped: Scalars['Boolean'];
  paymentFailedAt: Maybe<Scalars['String']>;
  paymentType: Maybe<Scalars['String']>;
  paymentTypeRenews: Maybe<Scalars['Boolean']>;
  personNumber: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  playlists: Array<Maybe<Playlist>>;
  preferences: Maybe<Preferences>;
  preferredCurrency: Maybe<CurrencyKind>;
  previousEmail: Maybe<Scalars['String']>;
  privacySettings: Maybe<PrivacySettings>;
  profession: Maybe<Scalars['String']>;
  profile: Maybe<Profile>;
  programAccess: Array<Maybe<ProgramAccess>>;
  programs: Maybe<Array<Maybe<Page>>>;
  provider: Provider;
  receipts: Array<Maybe<Payment>>;
  referralCode: Maybe<Scalars['String']>;
  registrationDetail: RegistrationDetail;
  registrationSource: Maybe<RegistrationSourceKind>;
  signUpPlatform: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  statistics: Maybe<Statistics>;
  stories: Array<Story>;
  subscription: Subscription;
  topics: Maybe<Array<Topic>>;
  trial: Maybe<Trial>;
  trialPeriod: Maybe<Scalars['Int']>;
  trialPlanSelected: Maybe<Scalars['Boolean']>;
  trialRequired: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  videoNotes: Maybe<VideoNote>;
  zipcode: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteAccount */
export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  errors: Maybe<Array<Error>>;
  user: Maybe<UserInterface>;
};

/** Autogenerated return type of DeleteAvatar */
export type DeleteAvatarPayload = {
  __typename?: 'DeleteAvatarPayload';
  user: Maybe<UserInterface>;
};

/** Autogenerated return type of DeleteFavoritePlaylist */
export type DeleteFavoritePlaylistPayload = {
  __typename?: 'DeleteFavoritePlaylistPayload';
  playlist: Maybe<Playlist>;
};

/** Autogenerated return type of DeleteFavoriteVideo */
export type DeleteFavoriteVideoPayload = {
  __typename?: 'DeleteFavoriteVideoPayload';
  video: Maybe<Video>;
};

/** Autogenerated return type of DeletePaymentMethod */
export type DeletePaymentMethodPayload = {
  __typename?: 'DeletePaymentMethodPayload';
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of DeletePlaylist */
export type DeletePlaylistPayload = {
  __typename?: 'DeletePlaylistPayload';
  status: Maybe<PlaylistStatus>;
};

export enum DevicePlatformKind {
  Android = 'android',
  Ios = 'ios',
  Web = 'web',
}

export type Discount = {
  __typename?: 'Discount';
  discountType: DiscountTypeKind;
  discountValue: Maybe<Scalars['Int']>;
  discountedPrice: Maybe<Scalars['Int']>;
  endDate: Maybe<Scalars['ISO8601DateTime']>;
  identifier: Maybe<PriceTypeKind>;
  startDate: Maybe<Scalars['ISO8601DateTime']>;
};

export enum DiscountTypeKind {
  ByValue = 'by_value',
  Percentage = 'percentage',
}

export type Document = {
  __typename?: 'Document';
  contentType: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Maybe<Scalars['String']>;
};

export type Duration = {
  __typename?: 'Duration';
  unit: Maybe<DurationUnitKind>;
  value: Maybe<Scalars['Int']>;
};

export type DurationDisplay = {
  __typename?: 'DurationDisplay';
  unit: Maybe<DurationDisplayUnitKind>;
  value: Maybe<Scalars['Int']>;
};

export enum DurationDisplayUnitKind {
  DaysDisplay = 'days_display',
  PartsDisplay = 'parts_display',
  WeeksDisplay = 'weeks_display',
}

export enum DurationUnitKind {
  Days = 'days',
  Weeks = 'weeks',
}

export type Email = {
  __typename?: 'Email';
  email: Maybe<Scalars['String']>;
  errors: Maybe<Array<Error>>;
  exists: Maybe<Scalars['Boolean']>;
};

export type EmailPreference = {
  __typename?: 'EmailPreference';
  description: Scalars['String'];
  id: Scalars['ID'];
  isMuted: Scalars['Boolean'];
  key: Scalars['String'];
  mutable: Scalars['Boolean'];
  name: Scalars['String'];
};

/** A user-readable error */
export type Error = {
  __typename?: 'Error';
  /** A description of the error */
  message: Maybe<Scalars['String']>;
  /** Which input value this error came from, if error is validation error */
  path: Maybe<Array<Scalars['String']>>;
};

export enum ErrorValue {
  /** Current access is longer than given from access token */
  AccessShorter = 'AccessShorter',
  /** New Access Until is missing */
  AccessUntilMissing = 'AccessUntilMissing',
  /** User already has active program access */
  AlreadyAccessed = 'AlreadyAccessed',
  /** Not allowed */
  AlreadyUsed = 'AlreadyUsed',
  /** Answers are invalid */
  AnswersInvalid = 'AnswersInvalid',
  /** Answers have not been saved */
  AnswersNotSaved = 'AnswersNotSaved',
  /** User does not have access to this program */
  NoAccess = 'NoAccess',
  /** No appropriate program */
  NoActiveProgram = 'NoActiveProgram',
  /** 403 Forbidden */
  NotAuthorized = 'NotAuthorized',
  /** Survey has not been marked as completed */
  NotCompleted = 'NotCompleted',
  /** 404 Not found */
  NotFound = 'NotFound',
  /** Program has not been unlocked */
  NotUnlocked = 'NotUnlocked',
  /** ProgramProgress not created successfully */
  ProgramProgressNotCreated = 'ProgramProgressNotCreated',
  /** Survey part has not been found */
  SurveyNotFound = 'SurveyNotFound',
  /** User is missing */
  UserMissing = 'UserMissing',
}

export type Event = {
  __typename?: 'Event';
  body1: Maybe<Scalars['String']>;
  body2: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  endsAt: Maybe<Scalars['ISO8601DateTime']>;
  externalImageUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructor: Scalars['String'];
  location: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizer: Maybe<EventOrganizer>;
  publishedAt: Maybe<Scalars['ISO8601DateTime']>;
  slug: Scalars['String'];
  startsAt: Maybe<Scalars['ISO8601DateTime']>;
  titleImageUrl: Maybe<Scalars['String']>;
};

export type EventOrganizer = {
  __typename?: 'EventOrganizer';
  companyName: Scalars['String'];
  companyType: Scalars['String'];
  email: Scalars['String'];
  responsibleContact: Scalars['String'];
};

export type EventPropertyInput = {
  property: InputMaybe<Scalars['String']>;
  value: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of EventProxy */
export type EventProxyPayload = {
  __typename?: 'EventProxyPayload';
  errors: Maybe<Array<Error>>;
};

export type ExtraPayment = {
  __typename?: 'ExtraPayment';
  amount: Scalars['String'];
  currency: Scalars['String'];
  date: Scalars['String'];
  downloadUrl: Maybe<Scalars['String']>;
  number: Maybe<Scalars['String']>;
  paymentProvider: Maybe<Scalars['String']>;
  paymentProviderVariant: Maybe<PaymentProviderVariantKind>;
  paymentType: ExtraPaymentTargetKind;
  target: Maybe<ExtraPaymentTarget>;
  transact: Maybe<Scalars['String']>;
};

export type ExtraPaymentTarget = {
  __typename?: 'ExtraPaymentTarget';
  targetType: ExtraPaymentTargetKind;
  title: Maybe<Scalars['String']>;
};

export enum ExtraPaymentTargetKind {
  Bundle = 'Bundle',
  Course = 'Course',
  GenericProgram = 'GenericProgram',
  LiveEvent = 'LiveEvent',
  PromotionCode = 'PromotionCode',
  WellnessPackage = 'WellnessPackage',
}

/** Autogenerated return type of Favorite */
export type FavoritePayload = {
  __typename?: 'FavoritePayload';
  errors: Maybe<Array<Error>>;
};

export type Favorites = {
  __typename?: 'Favorites';
  blogPosts: Maybe<Array<Maybe<BlogPost>>>;
  playlists: Array<Maybe<Scalars['ID']>>;
  videos: Array<Maybe<Scalars['ID']>>;
};

export type FeaturesSection = {
  __typename?: 'FeaturesSection';
  description: Maybe<Scalars['String']>;
  featureBullets: Maybe<Array<Scalars['String']>>;
  imageUrl: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type FlipperFlag = {
  __typename?: 'FlipperFlag';
  name: Scalars['String'];
  value: Scalars['Boolean'];
};

export type FormFields = {
  __typename?: 'FormFields';
  accepturl: Scalars['String'];
  amount: Scalars['Int'];
  billingAddress: Maybe<Scalars['String']>;
  billingFirstName: Maybe<Scalars['String']>;
  callbackurl: Scalars['String'];
  currency: Scalars['String'];
  decorator: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  lang: Maybe<Scalars['String']>;
  md5key: Maybe<Scalars['String']>;
  merchant: Scalars['Int'];
  orderid: Scalars['String'];
  ordline0_0: Maybe<Scalars['String']>;
  ordline0_1: Maybe<Scalars['String']>;
  ordline1_0: Maybe<Scalars['String']>;
  ordline1_1: Maybe<Scalars['String']>;
  ordline2_0: Maybe<Scalars['String']>;
  ordline2_1: Maybe<Scalars['String']>;
  preauth: Maybe<Scalars['Int']>;
  test: Maybe<Scalars['Int']>;
};

export enum FunnelKind {
  B2hFar = 'b2h_far',
  Blog = 'blog',
  Bundle = 'bundle',
  BundleContentHub = 'bundle_content_hub',
  BundleFar = 'bundle_far',
  BundleHealthcare = 'bundle_healthcare',
  BundlePaid = 'bundle_paid',
  BundlePartner = 'bundle_partner',
  Challenge = 'challenge',
  Course = 'course',
  Live = 'live',
  LiveCampaign = 'live_campaign',
  LiveExternal = 'live_external',
  LiveFree = 'live_free',
  LiveGlobal = 'live_global',
  LivePaid = 'live_paid',
  LivePremium = 'live_premium',
  LiveWeekly = 'live_weekly',
  Paying = 'paying',
  Play = 'play',
  PlaySubscriptionBlog = 'play_subscription_blog',
  PlaySubscriptionChallenge = 'play_subscription_challenge',
  PlaySubscriptionGlobal = 'play_subscription_global',
  PlaySubscriptionProgram = 'play_subscription_program',
  PlaySubscriptionVideo = 'play_subscription_video',
  PlayTrialBlog = 'play_trial_blog',
  PlayTrialChallenge = 'play_trial_challenge',
  PlayTrialGlobal = 'play_trial_global',
  PlayTrialProgram = 'play_trial_program',
  PlayTrialVideo = 'play_trial_video',
  Program = 'program',
  ProgramHealthcare = 'program_healthcare',
  Trial = 'trial',
  Video = 'video',
  WeeklyLive = 'weekly_live',
  Wellness = 'wellness',
  WellnessTrial = 'wellness_trial',
}

export enum GenderKind {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other',
}

export type GenericProgram = {
  __typename?: 'GenericProgram';
  accessPeriod: AccessPeriod;
  accessStatus: ProgramAccessStatus;
  category: ProgramCategory;
  description: Maybe<Scalars['String']>;
  discount: Maybe<Discount>;
  duration: Maybe<Duration>;
  durationDisplay: Maybe<DurationDisplay>;
  id: Scalars['ID'];
  instructors: Maybe<Array<Maybe<Instructor>>>;
  kind: ProgramKind;
  language: Maybe<LanguageKind>;
  markets: Maybe<Array<LanguageKind>>;
  meta: Maybe<ProgramMeta>;
  postSurvey: Maybe<ProgramSurvey>;
  preSurvey: Maybe<ProgramSurvey>;
  price: Maybe<Price>;
  priceType: Maybe<PriceTypeKind>;
  programAccess: Maybe<ProgramAccess>;
  progress: Maybe<Scalars['Float']>;
  promotionalImageUrl: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  publishedAt: Maybe<Scalars['ISO8601DateTime']>;
  recomendation: Maybe<Scalars['String']>;
  shortDescription: Maybe<Scalars['String']>;
  simplifiedOverviewForEmbeds: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  summaryAsset: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  themes: Maybe<Array<Scalars['String']>>;
  themesV2: Maybe<Array<Theme>>;
  title: Maybe<Scalars['String']>;
  titleImageUrl: Maybe<Scalars['String']>;
  trailer: Maybe<Video>;
};

export type GenericProgramPayment = OrderPaymentInterface & {
  __typename?: 'GenericProgramPayment';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyKind;
  failedReason: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  originalAmountCents: Maybe<Scalars['Int']>;
  paymentProvider: PaymentProviderKind;
  paymentProviderVariant: Maybe<PaymentProviderVariantKind>;
  program: GenericProgram;
  sourcePage: Maybe<Scalars['String']>;
  state: PaymentStatusKind;
  status: Maybe<OrderStatus>;
  success: Scalars['Boolean'];
  voucher: Maybe<Scalars['Boolean']>;
};

export enum GenericProgramSortAttribute {
  Alphabetical = 'alphabetical',
  Duration = 'duration',
  NewestFirst = 'newest_first',
}

export type GenericProgramWithCode = {
  __typename?: 'GenericProgramWithCode';
  code: Maybe<Scalars['String']>;
  codeStatus: Maybe<CodeStatusKind>;
  price: Maybe<Price>;
};

export type GenresSection = {
  __typename?: 'GenresSection';
  description: Maybe<Scalars['String']>;
  genre: Maybe<Array<PageSection>>;
  title: Maybe<Scalars['String']>;
};

export enum GlobalSearch {
  All = 'All',
  Blog = 'Blog',
  CourseTravel = 'CourseTravel',
  Live = 'Live',
  Play = 'Play',
  Resources = 'Resources',
}

export type HealthcareProgramToken = {
  __typename?: 'HealthcareProgramToken';
  accessUntil: Scalars['ISO8601DateTime'];
  activatedAt: Scalars['ISO8601DateTime'];
  genericProgram: GenericProgram;
  token: Scalars['String'];
  weeklyProgram: WeeklyProgram;
};

export type HealthcareVideoToken = {
  __typename?: 'HealthcareVideoToken';
  accessUntil: Scalars['ISO8601DateTime'];
  activatedAt: Scalars['ISO8601DateTime'];
  token: Scalars['String'];
  video: Video;
};

export type HeroSection = {
  __typename?: 'HeroSection';
  description: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  mobileImageUrl: Maybe<Scalars['String']>;
  smallNote: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  altText: Maybe<Scalars['String']>;
  largeImageUrl: Scalars['String'];
  mainImageUrl: Scalars['String'];
  mediumImageUrl: Scalars['String'];
  originalImageUrl: Scalars['String'];
  thumbnailImageUrl: Scalars['String'];
};

export type Instructor = {
  __typename?: 'Instructor';
  activeLiveEvents: Maybe<Array<LiveEvent>>;
  assets: Maybe<InstructorAssets>;
  blogDescription: Maybe<Scalars['String']>;
  dateOfBirth: Maybe<Scalars['ISO8601Date']>;
  description: Maybe<Scalars['String']>;
  education: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructorPage: InstructorPage;
  languages: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  philosophy: Maybe<Scalars['String']>;
  quote: Maybe<Scalars['String']>;
  shortDescription: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  speciality: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  team: Maybe<Scalars['String']>;
  titleImageUrl: Scalars['String'];
  videos: Maybe<PagedVideos>;
  videosCount: Scalars['Int'];
  webpage: Maybe<Scalars['String']>;
  youtubeId: Maybe<Scalars['String']>;
};

export type InstructorVideosArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type InstructorAssets = {
  __typename?: 'InstructorAssets';
  externalImage: Maybe<Scalars['String']>;
  squareImage: Maybe<Scalars['String']>;
  titleImage: Maybe<Scalars['String']>;
};

export type InstructorInGenre = {
  __typename?: 'InstructorInGenre';
  instructor: Maybe<Instructor>;
  videosCountInGenres: Maybe<Array<Maybe<VideosCountByGenre>>>;
};

export type InstructorPage = {
  __typename?: 'InstructorPage';
  blogPosts: PagedBlogPosts;
  courses: PagedGenericPrograms;
  defaultCampaignCode: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  favoritedVideos: PagedVideos;
  heroShortDescription: Maybe<Scalars['String']>;
  liveEvents: PagedLiveEvents;
  playlists: PagedPlaylists;
  programs: PagedGenericPrograms;
  videos: PagedVideos;
};

export type InstructorPageBlogPostsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type InstructorPageCoursesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type InstructorPageFavoritedVideosArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type InstructorPageLiveEventsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type InstructorPagePlaylistsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type InstructorPageProgramsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type InstructorPageVideosArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type Interest = {
  __typename?: 'Interest';
  hasContent: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Item =
  | BlogPost
  | Document
  | GenericProgram
  | LiveEvent
  | Playlist
  | Video;

export enum ItemKind {
  Bundle = 'Bundle',
  Course = 'Course',
  GenericProgram = 'GenericProgram',
  LiveEvent = 'LiveEvent',
  WellnessPackage = 'WellnessPackage',
}

export enum ItemTypeKind {
  Bundle = 'Bundle',
}

export enum LanguageKind {
  En = 'en',
  Fi = 'fi',
  No = 'no',
  Se = 'se',
}

export type LinkToRecording = {
  __typename?: 'LinkToRecording';
  eventDate: Scalars['ISO8601DateTime'];
  expiresAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  isExpired: Maybe<Scalars['Boolean']>;
  link: Maybe<Scalars['String']>;
  video: Maybe<Video>;
};

export type LiveEvent = {
  __typename?: 'LiveEvent';
  accessories: Array<Scalars['String']>;
  availableRecordings: Scalars['Boolean'];
  availableSpots: Scalars['Int'];
  bookingAvailable: Scalars['Boolean'];
  customDiscount: Maybe<Scalars['Int']>;
  descriptionLong: Scalars['String'];
  /** duration in minutes. Should step by 15 minutes, so 0, 15, 30, etc. */
  duration: Scalars['Int'];
  eventDates: Array<Scalars['ISO8601DateTime']>;
  flexibleMaxAmountCents: Maybe<Scalars['Int']>;
  flexibleMinAmountCents: Maybe<Scalars['Int']>;
  hostedBy: Maybe<Scalars['String']>;
  hostedByPageMessage: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructors: Maybe<Array<Maybe<Instructor>>>;
  isClosed: Maybe<Scalars['Boolean']>;
  isComingSoon: Maybe<Scalars['Boolean']>;
  isExpired: Maybe<Scalars['Boolean']>;
  isExternal: Scalars['Boolean'];
  isUserEligible: Maybe<Scalars['Boolean']>;
  kind: LiveEventKind;
  language: LanguageKind;
  level: LiveEventLevel;
  linkToRecordings: Maybe<Array<LinkToRecording>>;
  maximumParticipants: Scalars['Int'];
  otherInstructors: Array<Instructor>;
  paymentKind: LiveEventPaymentKind;
  price: Maybe<Price>;
  primaryInstructor: Maybe<Instructor>;
  publishedAt: Maybe<Scalars['ISO8601DateTime']>;
  registrationEndDate: Scalars['ISO8601DateTime'];
  registrationStartDate: Scalars['ISO8601DateTime'];
  slug: Scalars['String'];
  status: LiveEventStatus;
  theme: Maybe<Scalars['String']>;
  themeLabel: Maybe<Scalars['String']>;
  thumbnail: Scalars['String'];
  title: Scalars['String'];
  userAccess: Maybe<LiveEventAccess>;
};

export type LiveEventAccess = {
  __typename?: 'LiveEventAccess';
  liveEvent: Maybe<LiveEvent>;
  zoomLink: Scalars['String'];
  zoomPassword: Scalars['String'];
};

export type LiveEventCodes = {
  __typename?: 'LiveEventCodes';
  campaign: Array<PromotionCode>;
  trial: Array<PromotionCode>;
  wellness: Array<PromotionCode>;
};

export enum LiveEventKind {
  Campaign = 'campaign',
  Classe = 'classe',
  DonationClass = 'donation_class',
  FreeEvent = 'free_event',
  Lecture = 'lecture',
  PartOfOnlineCourse = 'part_of_online_course',
  Premium = 'premium',
  Skills = 'skills',
  Talk = 'talk',
  Workshop = 'workshop',
}

export enum LiveEventLevel {
  Advanced = 'advanced',
  Beginner = 'beginner',
  Everyone = 'everyone',
  Intermediate = 'intermediate',
  Other = 'other',
}

export type LiveEventPayment = OrderPaymentInterface & {
  __typename?: 'LiveEventPayment';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyKind;
  failedReason: Maybe<Scalars['String']>;
  live: LiveEvent;
  orderId: Scalars['String'];
  originalAmountCents: Maybe<Scalars['Int']>;
  paymentProvider: PaymentProviderKind;
  paymentProviderVariant: Maybe<PaymentProviderVariantKind>;
  sourcePage: Maybe<Scalars['String']>;
  state: PaymentStatusKind;
  status: Maybe<OrderStatus>;
  success: Scalars['Boolean'];
  voucher: Maybe<Scalars['Boolean']>;
};

export enum LiveEventPaymentKind {
  CampaignLive = 'campaign_live',
  DiscountForPaying = 'discount_for_paying',
  Flexible = 'flexible',
  FreeForAll = 'free_for_all',
  FreeForPaying = 'free_for_paying',
  PremiumLive = 'premium_live',
}

export enum LiveEventSortAttribute {
  AmountCents = 'amount_cents',
  FirstEventDate = 'first_event_date',
}

export enum LiveEventStatus {
  Archived = 'archived',
  Canceled = 'canceled',
  Draft = 'draft',
  Published = 'published',
}

/** Autogenerated return type of Login */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  user: Maybe<UserInterface>;
};

/** Autogenerated return type of Logout */
export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  status: Maybe<Scalars['String']>;
};

export type MeditationOverview = {
  __typename?: 'MeditationOverview';
  needs: Maybe<Array<Need>>;
  playlists: Maybe<Array<Playlist>>;
};

export type MeditationSettings = {
  __typename?: 'MeditationSettings';
  meditationNotificationEnabled: Maybe<Scalars['Boolean']>;
  meditationSessionDuration: Maybe<Scalars['Int']>;
  meditationWeeklyGoals: Maybe<Scalars['Int']>;
};

export type MeditationStats = {
  __typename?: 'MeditationStats';
  recentlyWatched: Array<Video>;
  sessionsCompleted: Scalars['Int'];
  totalWatchTime: Maybe<Scalars['Int']>;
};

export type Meta = {
  __typename?: 'Meta';
  imageUrl: Maybe<Scalars['String']>;
  minutes: Maybe<Scalars['Int']>;
  videoCount: Maybe<Scalars['Int']>;
};

export type MetaSection = {
  __typename?: 'MetaSection';
  description: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export enum MethodTypes {
  Code = 'code',
  Token = 'token',
}

export type MobileAppSection = {
  __typename?: 'MobileAppSection';
  description: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export enum ModelNameKind {
  All = 'all',
  Blog = 'blog',
  BlogPosts = 'blog_posts',
  Challenges = 'challenges',
  CourseTravel = 'course_travel',
  Courses = 'courses',
  Events = 'events',
  GenericPrograms = 'generic_programs',
  Instructors = 'instructors',
  LandingPages = 'landing_pages',
  Live = 'live',
  LiveEvents = 'live_events',
  Play = 'play',
  Playlists = 'playlists',
  Resources = 'resources',
  Videos = 'videos',
}

export type MoodTracker = {
  __typename?: 'MoodTracker';
  finished: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  kind: MoodTrackerKind;
};

export enum MoodTrackerKind {
  Post = 'post',
  Pre = 'pre',
}

export type Mutation = {
  __typename?: 'Mutation';
  accessTokensActivate: Maybe<ActivatePayload>;
  accessTokensValidate: Maybe<ValidatePayload>;
  /** legacy registration access code activate */
  activateAccessCode: Maybe<ActivateAccessCodePayload>;
  /** Activate bundle by access code */
  activateBundle: Maybe<ActivateBundlePayload>;
  /** Activate challenge by access code */
  activateChallenge: Maybe<ActivateChallengePayload>;
  /** Activate access code after checking it in availableSubscriptions query */
  activateFullAccessCode: Maybe<ActivateFullAccessCodePayload>;
  /** Activate generic program if user has access */
  activateGenericProgram: Maybe<ActivateGenericProgramPayload>;
  activateLegacyPausaSmart: Maybe<ActivateLegacyPausaSmartPayload>;
  activateLiveEvent: Maybe<ActivateLiveEventPayload>;
  /** Activate one time code after checking it in availableSubscriptions query */
  activateOneTimeCode: Maybe<ActivateOneTimeCodePayload>;
  activatePausaSmart: Maybe<ActivatePausaSmartPayload>;
  /** Set trial days to extend the access_until, but do not extend yet */
  activateTrialExtension: Maybe<ActivateTrialExtensionPayload>;
  addAdyenPaymentMethod: Maybe<AddAdyenPaymentMethodPayload>;
  addComment: Maybe<AddCommentPayload>;
  addFavoritePlaylist: Maybe<AddFavoritePlaylistPayload>;
  addFavoriteVideo: Maybe<AddFavoriteVideoPayload>;
  addMoodTrackerAnswer: Maybe<AddMoodTrackerAnswerPayload>;
  addPlaylist: Maybe<AddPlaylistPayload>;
  addSearchResult: Maybe<AddSearchResultPayload>;
  addStory: Maybe<AddStoryPayload>;
  addTrustlyPaymentMethod: Maybe<AddTrustlyPaymentMethodPayload>;
  addUserFeedback: Maybe<AddUserFeedbackPayload>;
  addUserSchedule: Maybe<AddUserSchedulePayload>;
  addVideoToPlaylist: Maybe<AddVideoToPlaylistPayload>;
  addWaitingItem: Maybe<AddWaitingItemPayload>;
  answerSurvey: Maybe<AnswerSurveyPayload>;
  archiveItem: Maybe<ArchiveItemPayload>;
  assignFinalFunnel: Maybe<AssignFinalFunnelPayload>;
  assignMobileDevice: Maybe<AssignMobileDevicePayload>;
  assignPlanToTrial: Maybe<AssignPlanToTrialPayload>;
  authenticateUser: Maybe<AuthenticateUserPayload>;
  cancelSubscription: Maybe<CancelSubscriptionPayload>;
  changeEmailPreference: Maybe<ChangeEmailPreferencePayload>;
  changePassword: Maybe<ChangePasswordPayload>;
  changePreferences: Maybe<ChangePreferencesPayload>;
  changeProductEmailPreference: Maybe<ChangeProductEmailPreferencePayload>;
  changeProfileData: Maybe<ChangeProfileDataPayload>;
  changeProfileInfo: Maybe<ChangeProfileInfoPayload>;
  changeProfilePrivacy: Maybe<ChangeProfilePrivacyPayload>;
  changeUserPreferences: Maybe<ChangeUserPreferencesPayload>;
  churnStart: Maybe<ChurnStartPayload>;
  churnStep: Maybe<ChurnStepPayload>;
  clickhouseEventProxy: Maybe<ClickhouseEventProxyPayload>;
  completeProfile: Maybe<CompleteProfilePayload>;
  /** Confirms email and extends current user access_until (whatever it is) by default 14 days given during registration or by trialPeriod set previously in activateTrialExtension mutation */
  confirmEmail: Maybe<ConfirmEmailPayload>;
  createNewPassword: Maybe<CreateNewPasswordPayload>;
  deleteAccount: Maybe<DeleteAccountPayload>;
  deleteAvatar: Maybe<DeleteAvatarPayload>;
  deleteFavoritePlaylist: Maybe<DeleteFavoritePlaylistPayload>;
  deleteFavoriteVideo: Maybe<DeleteFavoriteVideoPayload>;
  deletePaymentMethod: Maybe<DeletePaymentMethodPayload>;
  deletePlaylist: Maybe<DeletePlaylistPayload>;
  eventProxy: Maybe<EventProxyPayload>;
  login: Maybe<LoginPayload>;
  logout: Maybe<LogoutPayload>;
  participateInAnEvent: Maybe<ParticipateInAnEventPayload>;
  patchPlaylist: Maybe<PatchPlaylistPayload>;
  patchStory: Maybe<PatchStoryPayload>;
  patchUser: Maybe<ChangePreferencesPayload>;
  payForItem: Maybe<PayForItemPayload>;
  payForVoucher: Maybe<PayForVoucherPayload>;
  payForWellnessItem: Maybe<PayForWellnessItemPayload>;
  paymentsStoreAdyenDropin: Maybe<StoreAdyenDropinPayload>;
  paymentsStorePaymentDetails: Maybe<StorePaymentDetailsPayload>;
  paymentsStoreTrustly: Maybe<StoreTrustlyPayload>;
  processTrialPlan: Maybe<ProcessTrialPlanPayload>;
  registrationStep: Maybe<StepMutationPayload>;
  removeVideoFromPlaylist: Maybe<RemoveVideoFromPlaylistPayload>;
  resendConfirmationEmail: Maybe<ResendConfirmationEmailPayload>;
  resetEmailConfirmation: Maybe<ResetEmailConfirmationPayload>;
  resetPassword: Maybe<ResetPasswordPayload>;
  resetSummerCampaign: Maybe<ResetSummerCampaignPayload>;
  /** Restore the subscription foruser that has another Yogobe account on his mobile account (appstore/playstore) */
  restoreMobileSubscription: Maybe<RestoreMobileSubscriptionPayload>;
  saveOnboardingChoices: Maybe<SaveOnboardingChoicesPayload>;
  savePhasedProgramProgress: Maybe<SavePhasedProgramProgressPayload>;
  saveProgramProgress: Maybe<SaveProgramProgressPayload>;
  signUp: Maybe<RegistrationPayload>;
  signUpV2: Maybe<RegistrationV2Payload>;
  socialSignIn: Maybe<SignInPayload>;
  socialSignUp: Maybe<SignUpPayload>;
  startSubscription: Maybe<StartSubscriptionPayload>;
  startTrialPlanSubscription: Maybe<StartTrialPlanSubscriptionPayload>;
  storeEpassi: Maybe<StoreEpassiPayload>;
  storeTransactionId: Maybe<StoreTransactionIdPayload>;
  storeUserDecision: Maybe<StoreUserDecisionPayload>;
  submitEventApplication: Maybe<SubmitEventApplicationPayload>;
  submitInterestForm: Maybe<SubmitInterestFormPayload>;
  unarchiveItem: Maybe<UnarchiveItemPayload>;
  updateMeditationSettings: Maybe<UpdateMeditationSettingsPayload>;
  updatePaymentMethodSettings: Maybe<UpdatePaymentMethodSettingsPayload>;
  validateBundleCode: Maybe<ValidateBundleCodePayload>;
  /** Validate challenge access code */
  validateChallengeToken: Maybe<ValidateTokenPayload>;
  validateProgramCode: Maybe<ValidateProgramCodePayload>;
  videoClick: Maybe<ClickPayload>;
  videoFavorite: Maybe<FavoritePayload>;
  videoWatch: Maybe<WatchPayload>;
  videoWatchCompleted: Maybe<WatchCompletedPayload>;
};

export type MutationAccessTokensActivateArgs = {
  accessToken: Scalars['String'];
};

export type MutationAccessTokensValidateArgs = {
  accessToken: Scalars['String'];
};

export type MutationActivateAccessCodeArgs = {
  accessCode: Scalars['String'];
  userId: Scalars['ID'];
};

export type MutationActivateBundleArgs = {
  client: Scalars['String'];
  code: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type MutationActivateChallengeArgs = {
  accessCode: InputMaybe<Scalars['String']>;
  challenge: InputMaybe<Scalars['String']>;
};

export type MutationActivateFullAccessCodeArgs = {
  code: Scalars['String'];
  promotionName: Scalars['String'];
};

export type MutationActivateGenericProgramArgs = {
  accessCode: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type MutationActivateLegacyPausaSmartArgs = {
  accessCode: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
};

export type MutationActivateLiveEventArgs = {
  code: Scalars['String'];
  slug: Scalars['String'];
};

export type MutationActivateOneTimeCodeArgs = {
  code: Scalars['String'];
  promotionName: Scalars['String'];
};

export type MutationActivateTrialExtensionArgs = {
  code: Scalars['String'];
  platform: DevicePlatformKind;
  promotionName: Scalars['String'];
};

export type MutationAddAdyenPaymentMethodArgs = {
  browserInfo: InputMaybe<BrowserInfoInput>;
  paymentMethod: PaymentMethodInput;
  recurringModel?: InputMaybe<RecurringModelInputKind>;
  redirectToFailed: InputMaybe<Scalars['String']>;
  redirectToSuccess: InputMaybe<Scalars['String']>;
  riskData: InputMaybe<RiskDataInput>;
};

export type MutationAddCommentArgs = {
  description: Scalars['String'];
  resourceClass: InputMaybe<Scalars['String']>;
  resourceId: Scalars['ID'];
};

export type MutationAddFavoritePlaylistArgs = {
  playlistId: Scalars['ID'];
};

export type MutationAddFavoriteVideoArgs = {
  videoId: Scalars['ID'];
};

export type MutationAddMoodTrackerAnswerArgs = {
  answer1: Scalars['Int'];
  answer2: Scalars['Int'];
  answer3: Scalars['Int'];
  answer4: Scalars['Int'];
  kind: SurveyKind;
  moodTrackerId: Scalars['ID'];
};

export type MutationAddPlaylistArgs = {
  description?: InputMaybe<Scalars['String']>;
  publishedAt: InputMaybe<Scalars['ISO8601DateTime']>;
  publishedState?: InputMaybe<Scalars['String']>;
  title: InputMaybe<Scalars['String']>;
  videos: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visibility: InputMaybe<Scalars['String']>;
};

export type MutationAddSearchResultArgs = {
  documentId: Scalars['String'];
  modelType: ModelNameKind;
  query: Scalars['String'];
};

export type MutationAddStoryArgs = {
  resourceClass: InputMaybe<Scalars['String']>;
  resourceId: Scalars['ID'];
};

export type MutationAddTrustlyPaymentMethodArgs = {
  redirectTo: InputMaybe<Scalars['String']>;
};

export type MutationAddUserFeedbackArgs = {
  bugs: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  rating: InputMaybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type MutationAddUserScheduleArgs = {
  datetime: Scalars['ISO8601DateTime'];
  resourceId: Scalars['ID'];
  returnFormat: Scalars['String'];
};

export type MutationAddVideoToPlaylistArgs = {
  playlistId: Scalars['ID'];
  videoId: Scalars['ID'];
};

export type MutationAddWaitingItemArgs = {
  itemId: Scalars['ID'];
  itemType: ItemTypeKind;
};

export type MutationAnswerSurveyArgs = {
  answers: InputMaybe<Array<AnswerInput>>;
  surveyAction: ActionInput;
  surveyAnswerId: InputMaybe<Scalars['ID']>;
  surveyPartId: Scalars['ID'];
};

export type MutationArchiveItemArgs = {
  itemId: Scalars['ID'];
  itemType: ArchiveItemKind;
};

export type MutationAssignFinalFunnelArgs = {
  funnel: FunnelKind;
};

export type MutationAssignMobileDeviceArgs = {
  appLabel: InputMaybe<Scalars['String']>;
  appVersion: InputMaybe<Scalars['String']>;
  platform: DevicePlatformKind;
  token: Scalars['String'];
};

export type MutationAssignPlanToTrialArgs = {
  code: InputMaybe<Scalars['String']>;
  planType: PlanTypeKind;
  subscriptionPromotionId: InputMaybe<Scalars['String']>;
};

export type MutationAuthenticateUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCancelSubscriptionArgs = {
  feedback: InputMaybe<Scalars['String']>;
};

export type MutationChangeEmailPreferenceArgs = {
  email: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  mute: Scalars['Boolean'];
  token: InputMaybe<Scalars['String']>;
};

export type MutationChangePasswordArgs = {
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};

export type MutationChangePreferencesArgs = {
  contentLanguages: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludeTerms: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoLevels: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationChangeProductEmailPreferenceArgs = {
  email: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  mute: Scalars['Boolean'];
  productName: ClassName;
  token: InputMaybe<Scalars['String']>;
};

export type MutationChangeProfileDataArgs = {
  avatar: InputMaybe<Scalars['Upload']>;
  displayName: InputMaybe<Scalars['String']>;
  facebookUrl: InputMaybe<Scalars['String']>;
  instagramUrl: InputMaybe<Scalars['String']>;
  linkedinUrl: InputMaybe<Scalars['String']>;
  shortBio: InputMaybe<Scalars['String']>;
  timezone: InputMaybe<Scalars['String']>;
};

export type MutationChangeProfileInfoArgs = {
  address: InputMaybe<Scalars['String']>;
  city: InputMaybe<Scalars['String']>;
  dateOfBirth: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  lastName: InputMaybe<Scalars['String']>;
  personNumber: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  profession: InputMaybe<Scalars['String']>;
  zipcode: InputMaybe<Scalars['String']>;
};

export type MutationChangeProfilePrivacyArgs = {
  isPrivateFavorites: InputMaybe<Scalars['Boolean']>;
  isPrivateProfile: InputMaybe<Scalars['Boolean']>;
  isPrivateStats: InputMaybe<Scalars['Boolean']>;
};

export type MutationChangeUserPreferencesArgs = {
  country: InputMaybe<Scalars['String']>;
  countryCode: InputMaybe<Scalars['String']>;
  emailLanguage: InputMaybe<Scalars['String']>;
  preferredCurrency: InputMaybe<CurrencyKind>;
};

export type MutationChurnStepArgs = {
  action: ActionsKind;
  answerId: Scalars['String'];
  cause: InputMaybe<Scalars['String']>;
  feedback: InputMaybe<Scalars['String']>;
  solution: InputMaybe<Scalars['String']>;
};

export type MutationClickhouseEventProxyArgs = {
  event: ClickhouseProxyEventKind;
  properties?: InputMaybe<Array<EventPropertyInput>>;
};

export type MutationCompleteProfileArgs = {
  address: InputMaybe<Scalars['String']>;
  birthday: InputMaybe<Scalars['String']>;
  campaign: InputMaybe<Scalars['String']>;
  city: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  email: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  gender: InputMaybe<GenderKind>;
  lastName: InputMaybe<Scalars['String']>;
  oneTimeCode: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  platform: DevicePlatformKind;
  postalCode: InputMaybe<Scalars['String']>;
  trialExtensionCode: InputMaybe<Scalars['String']>;
};

export type MutationConfirmEmailArgs = {
  token: Scalars['String'];
};

export type MutationCreateNewPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  passwordToken: Scalars['String'];
};

export type MutationDeleteAccountArgs = {
  deletionReason: Scalars['String'];
};

export type MutationDeleteFavoritePlaylistArgs = {
  playlistId: Scalars['ID'];
};

export type MutationDeleteFavoriteVideoArgs = {
  videoId: Scalars['ID'];
};

export type MutationDeletePaymentMethodArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePlaylistArgs = {
  id: Scalars['ID'];
};

export type MutationEventProxyArgs = {
  event: ProxyEventKind;
  properties?: InputMaybe<Array<EventPropertyInput>>;
};

export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationParticipateInAnEventArgs = {
  slug: Scalars['String'];
};

export type MutationPatchPlaylistArgs = {
  description: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  title: InputMaybe<Scalars['String']>;
  videos: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  visibility: InputMaybe<Scalars['String']>;
};

export type MutationPatchStoryArgs = {
  id: Scalars['ID'];
  progress: Scalars['Int'];
  resourceClass: InputMaybe<Scalars['String']>;
  resourceId: Scalars['ID'];
};

export type MutationPatchUserArgs = {
  contentLanguages: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  excludeTerms: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  videoLevels: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type MutationPayForItemArgs = {
  amountCents: InputMaybe<Scalars['Int']>;
  browserInfo: InputMaybe<BrowserInfoInput>;
  discountCode: InputMaybe<Scalars['String']>;
  itemKind: ItemKind;
  paymentMethod: PaymentMethodInput;
  paymentMethodId: InputMaybe<Scalars['String']>;
  redirectToFailed: InputMaybe<Scalars['String']>;
  redirectToSuccess: InputMaybe<Scalars['String']>;
  riskData: InputMaybe<RiskDataInput>;
  slug: Scalars['String'];
  sourcePage: InputMaybe<Scalars['String']>;
};

export type MutationPayForVoucherArgs = {
  amountCents: InputMaybe<Scalars['Int']>;
  browserInfo: InputMaybe<BrowserInfoInput>;
  customMessage: InputMaybe<Scalars['String']>;
  discountCode: InputMaybe<Scalars['String']>;
  itemKind: ItemKind;
  paymentMethod: PaymentMethodInput;
  paymentMethodId: InputMaybe<Scalars['String']>;
  recipientEmail: Scalars['String'];
  recipientName: Scalars['String'];
  redirectToFailed: InputMaybe<Scalars['String']>;
  redirectToSuccess: InputMaybe<Scalars['String']>;
  riskData: InputMaybe<RiskDataInput>;
  sendGiftEmail: Scalars['Boolean'];
  senderName: Scalars['String'];
  slug: Scalars['String'];
};

export type MutationPayForWellnessItemArgs = {
  amountCents: InputMaybe<Scalars['Int']>;
  discountCode: InputMaybe<Scalars['String']>;
  itemKind: ItemKind;
  redirectToFailed: InputMaybe<Scalars['String']>;
  redirectToSuccess: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  sourcePage: InputMaybe<Scalars['String']>;
  wellnessProvider: PaymentWellnessProviderKind;
};

export type MutationPaymentsStoreAdyenDropinArgs = {
  browserInfo: InputMaybe<BrowserInfoInput>;
  code: InputMaybe<Scalars['String']>;
  paymentMethod: PaymentMethodInput;
  paymentMethodId: InputMaybe<Scalars['String']>;
  paymentType: Scalars['String'];
  redirectTo: InputMaybe<Scalars['String']>;
  redirectToFailed: InputMaybe<Scalars['String']>;
  riskData: InputMaybe<RiskDataInput>;
  sourcePage: InputMaybe<Scalars['String']>;
};

export type MutationPaymentsStorePaymentDetailsArgs = {
  orderId: Scalars['String'];
  payload: InputMaybe<Scalars['String']>;
  paymentData: InputMaybe<Scalars['String']>;
  threedsResult: InputMaybe<Scalars['String']>;
};

export type MutationPaymentsStoreTrustlyArgs = {
  code: InputMaybe<Scalars['String']>;
  paymentMethodId: InputMaybe<Scalars['String']>;
  paymentType: Scalars['String'];
  redirectTo: InputMaybe<Scalars['String']>;
  redirectToFailed: InputMaybe<Scalars['String']>;
  sourcePage: InputMaybe<Scalars['String']>;
};

export type MutationRegistrationStepArgs = {
  funnel: FunnelKind;
  step: RegistrationStepEnum;
};

export type MutationRemoveVideoFromPlaylistArgs = {
  playlistId: Scalars['ID'];
  videoId: Scalars['ID'];
};

export type MutationResendConfirmationEmailArgs = {
  email: Scalars['String'];
};

export type MutationResetEmailConfirmationArgs = {
  token: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationResetSummerCampaignArgs = {
  programId: Scalars['ID'];
};

export type MutationRestoreMobileSubscriptionArgs = {
  platform: DevicePlatformKind;
  purchaseIdentifier: InputMaybe<Scalars['String']>;
};

export type MutationSaveOnboardingChoicesArgs = {
  interestSlugs?: InputMaybe<Array<Scalars['String']>>;
  topicSlugs?: InputMaybe<Array<Scalars['String']>>;
};

export type MutationSavePhasedProgramProgressArgs = {
  documentPartId: InputMaybe<Scalars['ID']>;
  liveEventPartId: InputMaybe<Scalars['ID']>;
  programPartElementId: InputMaybe<Scalars['ID']>;
  token: InputMaybe<Scalars['String']>;
  videoPartId: InputMaybe<Scalars['ID']>;
};

export type MutationSaveProgramProgressArgs = {
  programId: Scalars['ID'];
};

export type MutationSignUpArgs = {
  address: Scalars['String'];
  campaign: InputMaybe<Scalars['String']>;
  campaignCode: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender: InputMaybe<GenderKind>;
  lastName: Scalars['String'];
  oneTimeCode: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  paymentType: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  platform: DevicePlatformKind;
  postalCode: Scalars['String'];
  redirectTo: InputMaybe<Scalars['String']>;
  trialExtensionCode: InputMaybe<Scalars['String']>;
  utmCampaign: InputMaybe<Scalars['String']>;
  utmMedium: InputMaybe<Scalars['String']>;
  utmSource: InputMaybe<Scalars['String']>;
};

export type MutationSignUpV2Args = {
  address: InputMaybe<Scalars['String']>;
  birthday: InputMaybe<Scalars['String']>;
  campaign: InputMaybe<Scalars['String']>;
  campaignCode: InputMaybe<Scalars['String']>;
  city: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: InputMaybe<Scalars['String']>;
  funnel: InputMaybe<FunnelKind>;
  gender: InputMaybe<GenderKind>;
  lastName: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  paymentType: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  platform: DevicePlatformKind;
  postalCode: InputMaybe<Scalars['String']>;
  redirectTo: InputMaybe<Scalars['String']>;
  registrationSource: InputMaybe<RegistrationSourceKind>;
  registrationSourceId: InputMaybe<Scalars['String']>;
  utmCampaign: InputMaybe<Scalars['String']>;
  utmMedium: InputMaybe<Scalars['String']>;
  utmSource: InputMaybe<Scalars['String']>;
};

export type MutationSocialSignInArgs = {
  accessToken: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
  identityToken: InputMaybe<Scalars['String']>;
  method: MethodTypes;
  platform: DevicePlatformKind;
  provider: ProviderTypes;
};

export type MutationSocialSignUpArgs = {
  accessToken: InputMaybe<Scalars['String']>;
  avatarFromUrl: InputMaybe<Scalars['String']>;
  campaignCode: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  identityToken: InputMaybe<Scalars['String']>;
  lastName: InputMaybe<Scalars['String']>;
  method: MethodTypes;
  paymentType: InputMaybe<Scalars['String']>;
  platform: DevicePlatformKind;
  provider: ProviderTypes;
  redirectTo: InputMaybe<Scalars['String']>;
  registrationSource: InputMaybe<RegistrationSourceKind>;
  registrationSourceId: InputMaybe<Scalars['String']>;
  utmCampaign: InputMaybe<Scalars['String']>;
  utmMedium: InputMaybe<Scalars['String']>;
  utmSource: InputMaybe<Scalars['String']>;
};

export type MutationStartSubscriptionArgs = {
  planType: PaidPlanTypeKind;
};

export type MutationStoreEpassiArgs = {
  paymentType: Scalars['String'];
  redirectToFailed: Scalars['String'];
  redirectToSuccess: Scalars['String'];
  sourcePage: InputMaybe<Scalars['String']>;
};

export type MutationStoreTransactionIdArgs = {
  transactionId: Scalars['String'];
};

export type MutationStoreUserDecisionArgs = {
  decision: UserDecisionKind;
  value: InputMaybe<Scalars['Boolean']>;
};

export type MutationSubmitEventApplicationArgs = {
  body: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  subject: Scalars['String'];
};

export type MutationSubmitInterestFormArgs = {
  comment: InputMaybe<Scalars['String']>;
  companyName: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  page: Scalars['String'];
};

export type MutationUnarchiveItemArgs = {
  itemId: Scalars['ID'];
  itemType: ArchiveItemKind;
};

export type MutationUpdateMeditationSettingsArgs = {
  meditationNotificationEnabled: InputMaybe<Scalars['Boolean']>;
  meditationSessionDuration: InputMaybe<Scalars['Int']>;
  meditationWeeklyGoals: InputMaybe<Scalars['Int']>;
};

export type MutationUpdatePaymentMethodSettingsArgs = {
  id: Scalars['ID'];
  setAsDefault: InputMaybe<Scalars['Boolean']>;
  setAsFallback: InputMaybe<Scalars['Boolean']>;
};

export type MutationValidateBundleCodeArgs = {
  client: Scalars['String'];
  code: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type MutationValidateChallengeTokenArgs = {
  accessToken: Scalars['String'];
};

export type MutationValidateProgramCodeArgs = {
  code: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type MutationVideoClickArgs = {
  recommended: InputMaybe<Scalars['Boolean']>;
  sourceId: InputMaybe<Scalars['String']>;
  sourceType: InputMaybe<Scalars['String']>;
  videoId: Scalars['String'];
};

export type MutationVideoFavoriteArgs = {
  videoId: Scalars['String'];
};

export type MutationVideoWatchArgs = {
  cursorAt: Scalars['Int'];
  origin?: InputMaybe<VideoStatsOriginInput>;
  programToken: InputMaybe<Scalars['String']>;
  seconds: Scalars['Int'];
  token: InputMaybe<Scalars['String']>;
  videoId: Scalars['String'];
};

export type MutationVideoWatchCompletedArgs = {
  cursorAt: Scalars['Int'];
  origin?: InputMaybe<VideoStatsOriginInput>;
  programToken: InputMaybe<Scalars['String']>;
  seconds: Scalars['Int'];
  token: InputMaybe<Scalars['String']>;
  videoId: Scalars['String'];
};

export enum MyGenericProgramSortKind {
  AccessUntilDesc = 'access_until_desc',
  LatestJoinedFirst = 'latest_joined_first',
}

export type Need = {
  __typename?: 'Need';
  imageUrl: Scalars['String'];
  index: Scalars['Int'];
  name: Scalars['String'];
  settings: Scalars['JSON'];
  settingsV2: Scalars['JSON'];
  slug: Scalars['String'];
};

export type NewTaxonomyItem = {
  __typename?: 'NewTaxonomyItem';
  name: Maybe<Scalars['String']>;
  nonSubcategorized: Maybe<Array<Maybe<TermTypeV2>>>;
  slug: Maybe<Scalars['String']>;
  subcategories: Maybe<Array<Maybe<TaxonomySubcategory>>>;
  titleImageUrl: Maybe<Scalars['String']>;
};

export type NextBillingCycle = {
  __typename?: 'NextBillingCycle';
  amount: Maybe<Scalars['String']>;
  billingDate: Maybe<Scalars['ISO8601Date']>;
  currentAmountCharged: Maybe<Scalars['String']>;
  paymentStatus: Maybe<PaymentStatusKind>;
  planName: Maybe<Scalars['String']>;
};

export type OnboardingChoices = {
  __typename?: 'OnboardingChoices';
  interests: Array<Interest>;
  topics: Array<Topic>;
};

export enum Option {
  All = 'all',
  CourseInstructorsOnly = 'course_instructors_only',
  HealthcareInstructorsOnly = 'healthcare_instructors_only',
  LiveEventsInstructorsOnly = 'live_events_instructors_only',
  ProgramInstructorsOnly = 'program_instructors_only',
}

export type Options = {
  __typename?: 'Options';
  professionLabels: Maybe<Array<Scalars['String']>>;
  professions: Maybe<Array<Scalars['String']>>;
};

export type Order =
  | AddPayment
  | BundlePayment
  | GenericProgramPayment
  | LiveEventPayment
  | SubscriptionPayment
  | WellnessPackagePayment;

export type OrderPaymentInterface = {
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyKind;
  failedReason: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  originalAmountCents: Maybe<Scalars['Int']>;
  paymentProvider: PaymentProviderKind;
  paymentProviderVariant: Maybe<PaymentProviderVariantKind>;
  sourcePage: Maybe<Scalars['String']>;
  state: PaymentStatusKind;
  success: Scalars['Boolean'];
};

export enum OrderStatus {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
}

export type OtherFeaturesSection = {
  __typename?: 'OtherFeaturesSection';
  description: Maybe<Scalars['String']>;
  otherFeatures: Maybe<Array<PageSection>>;
  title: Maybe<Scalars['String']>;
};

export enum OtherTypeKind {
  Bundles = 'bundles',
  Challenges = 'challenges',
  Live = 'live',
  Playlists = 'playlists',
  Programs = 'programs',
  Videos = 'videos',
}

export type OverviewPlaylist = {
  __typename?: 'OverviewPlaylist';
  name: Scalars['String'];
  playlistId: Scalars['ID'];
  term: Scalars['String'];
};

export type Page = {
  __typename?: 'Page';
  assets: Maybe<PageAssets>;
  blogPosts: Maybe<Array<Maybe<BlogPost>>>;
  content: Maybe<PageContent>;
  externalLede: Maybe<Scalars['String']>;
  externalTitle: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalLede: Maybe<Scalars['String']>;
  internalTitle: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  playlists: Maybe<Array<Maybe<Playlist>>>;
  programs: Maybe<Array<Maybe<Page>>>;
  programsDetails: Maybe<Array<Maybe<ProgramsDetails>>>;
  slug: Scalars['String'];
  taxonomies: Maybe<TaxonomyForLandingPage>;
  testimonials: Maybe<Array<Maybe<Testimonial>>>;
  trailerVideo: Maybe<Video>;
  utmCampaign: Maybe<Scalars['String']>;
  videos: Maybe<Array<Maybe<Video>>>;
};

export type PageAssets = {
  __typename?: 'PageAssets';
  externalImage: Scalars['String'];
  internalImage: Scalars['String'];
  ledeVideos: Maybe<Array<Maybe<Video>>>;
  titleImage: Scalars['String'];
  titleVideo: Maybe<Video>;
};

export type PageContent = {
  __typename?: 'PageContent';
  body: Maybe<Scalars['String']>;
  body1: Maybe<Scalars['String']>;
  body2: Maybe<Scalars['String']>;
  body3: Maybe<Scalars['String']>;
  body4: Maybe<Scalars['String']>;
  body5: Maybe<Scalars['String']>;
  body6: Maybe<Scalars['String']>;
  englishTitle: Maybe<Scalars['String']>;
  externalLede: Maybe<Scalars['String']>;
  externalTitle: Maybe<Scalars['String']>;
  extraImages: Maybe<Scalars['String']>;
  internalLede: Maybe<Scalars['String']>;
  internalTitle: Maybe<Scalars['String']>;
  lede: Maybe<Scalars['String']>;
  localLanguageTitle: Maybe<Scalars['String']>;
  sanskritTitle: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
};

export type PageSection = {
  __typename?: 'PageSection';
  description: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  otherType: Maybe<OtherTypeKind>;
  sectionType: Maybe<Scalars['String']>;
  slug: Maybe<SlugTypeKind>;
  title: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};

export type PagedBlogPosts = PagedInterface & {
  __typename?: 'PagedBlogPosts';
  data: Array<BlogPost>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedBundles = PagedInterface & {
  __typename?: 'PagedBundles';
  data: Array<Bundle>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedEvents = PagedInterface & {
  __typename?: 'PagedEvents';
  data: Array<Event>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedGenericPrograms = PagedInterface & {
  __typename?: 'PagedGenericPrograms';
  data: Array<GenericProgram>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedInstructors = PagedInterface & {
  __typename?: 'PagedInstructors';
  data: Array<Instructor>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedInterface = {
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedLiveEvents = PagedInterface & {
  __typename?: 'PagedLiveEvents';
  availableKinds: Array<LiveEventKind>;
  data: Array<LiveEvent>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedPages = PagedInterface & {
  __typename?: 'PagedPages';
  data: Array<Page>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedPlaylists = PagedInterface & {
  __typename?: 'PagedPlaylists';
  data: Array<Playlist>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedSurveys = PagedInterface & {
  __typename?: 'PagedSurveys';
  data: Array<Survey>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedUsers = PagedInterface & {
  __typename?: 'PagedUsers';
  data: Array<UserInterface>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedVideos = PagedInterface & {
  __typename?: 'PagedVideos';
  data: Array<Video>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type PagedWellnessPackage = PagedInterface & {
  __typename?: 'PagedWellnessPackage';
  data: Array<WellnessPackage>;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum PaidPlanTypeKind {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly',
}

/** Autogenerated return type of ParticipateInAnEvent */
export type ParticipateInAnEventPayload = {
  __typename?: 'ParticipateInAnEventPayload';
  amountCents: Maybe<Scalars['Int']>;
  amountCurrency: Maybe<Scalars['String']>;
  errors: Maybe<Array<Error>>;
  liveEvent: Maybe<LiveEvent>;
  message: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  user: Maybe<CurrentUser>;
};

/** Autogenerated return type of PatchPlaylist */
export type PatchPlaylistPayload = {
  __typename?: 'PatchPlaylistPayload';
  playlist: Maybe<Playlist>;
};

/** Autogenerated return type of PatchStory */
export type PatchStoryPayload = {
  __typename?: 'PatchStoryPayload';
  story: Maybe<Story>;
};

/** Autogenerated return type of PayForItem */
export type PayForItemPayload = {
  __typename?: 'PayForItemPayload';
  errors: Maybe<Array<Error>>;
  orderId: Maybe<Scalars['String']>;
  resultAction: Maybe<AdyenAction>;
  resultCode: Maybe<PaymentResultCodeKind>;
  transaction: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PayForVoucher */
export type PayForVoucherPayload = {
  __typename?: 'PayForVoucherPayload';
  errors: Maybe<Array<Error>>;
  orderId: Maybe<Scalars['String']>;
  resultAction: Maybe<AdyenAction>;
  resultCode: Maybe<PaymentResultCodeKind>;
  transaction: Maybe<Scalars['String']>;
};

/** Autogenerated return type of PayForWellnessItem */
export type PayForWellnessItemPayload = {
  __typename?: 'PayForWellnessItemPayload';
  amount: Maybe<Scalars['String']>;
  cancelUrl: Maybe<Scalars['String']>;
  fee: Maybe<Scalars['String']>;
  mac: Maybe<Scalars['String']>;
  notifyUrl: Maybe<Scalars['String']>;
  rejectUrl: Maybe<Scalars['String']>;
  returnUrl: Maybe<Scalars['String']>;
  site: Maybe<Scalars['String']>;
  stamp: Maybe<Scalars['String']>;
  vatValue: Maybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['String'];
  currency: Scalars['String'];
  date: Scalars['String'];
  downloadUrl: Maybe<Scalars['String']>;
  mobilePayment: Scalars['Boolean'];
  months: Scalars['Int'];
  number: Scalars['String'];
  paymentProvider: Scalars['String'];
  paymentProviderVariant: Maybe<PaymentProviderVariantKind>;
  paymentType: Scalars['String'];
  receiptTitle: Maybe<Scalars['String']>;
  target: PaymentTarget;
  transact: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  expiryMonth: Maybe<Scalars['String']>;
  expiryYear: Maybe<Scalars['String']>;
  fallback: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDefault: Maybe<Scalars['Boolean']>;
  lastFour: Scalars['String'];
  name: Scalars['String'];
  provider: Maybe<ProviderKind>;
  status: Maybe<PaymentMethodStatusKind>;
  variant: Maybe<Scalars['String']>;
};

export type PaymentMethodInput = {
  brand: InputMaybe<Scalars['String']>;
  checkoutAttemptId: InputMaybe<Scalars['String']>;
  encryptedCardNumber: InputMaybe<Scalars['String']>;
  encryptedExpiryMonth: InputMaybe<Scalars['String']>;
  encryptedExpiryYear: InputMaybe<Scalars['String']>;
  encryptedSecurityCode: InputMaybe<Scalars['String']>;
  holderName: InputMaybe<Scalars['String']>;
  providerType: InputMaybe<Scalars['String']>;
};

export enum PaymentMethodStatusKind {
  Active = 'active',
  Expired = 'expired',
  Expiring = 'expiring',
}

export enum PaymentProviderKind {
  Adyen = 'adyen',
  AdyenDibs = 'adyen_dibs',
  Apple = 'apple',
  Dibs = 'dibs',
  Epassi = 'epassi',
  Free = 'free',
  Google = 'google',
  Trustly = 'trustly',
  Wellness = 'wellness',
}

export enum PaymentProviderVariantKind {
  Actiway = 'actiway',
  Benefits = 'benefits',
  Benify = 'benify',
  Card = 'card',
  EPassi = 'ePassi',
  Epassi = 'epassi',
  Swish = 'swish',
  Trustly = 'trustly',
  Wellnet = 'wellnet',
}

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  formFields: Maybe<FormFields>;
  url: Scalars['String'];
};

export enum PaymentResultCodeKind {
  Authorised = 'authorised',
  Pending = 'pending',
  Redirect = 'redirect',
  Refused = 'refused',
}

export enum PaymentStatusKind {
  Archived = 'archived',
  Authorized = 'authorized',
  Done = 'done',
  Draft = 'draft',
  Failed = 'failed',
  Pending = 'pending',
  Processed = 'processed',
  Refunded = 'refunded',
  Rejected = 'rejected',
  Timeout = 'timeout',
}

export type PaymentTarget = {
  __typename?: 'PaymentTarget';
  targetType: PaymentTargetKind;
  title: Maybe<Scalars['String']>;
};

export enum PaymentTargetKind {
  CustomWellnessAccess = 'CustomWellnessAccess',
  Subscription = 'Subscription',
  Wellness = 'Wellness',
}

export enum PaymentWellnessProviderKind {
  Epassi = 'epassi',
}

export type PhasedProgram = {
  __typename?: 'PhasedProgram';
  currentElement: Maybe<CurrentElement>;
  id: Scalars['ID'];
  instructors: Maybe<Array<Maybe<Instructor>>>;
  intro: Maybe<SpecialSection>;
  language: Maybe<Scalars['String']>;
  outro: Maybe<SpecialSection>;
  phases: Maybe<Array<Maybe<ProgramPhase>>>;
  phasesCount: Maybe<Scalars['Int']>;
  progress: Maybe<Scalars['Float']>;
  slug: Scalars['String'];
  title: Maybe<Scalars['String']>;
  videosCount: Maybe<Scalars['Int']>;
  weeksCount: Maybe<Scalars['Int']>;
};

export enum PlanTypeKind {
  FreePlan = 'free_plan',
  FreeTrial = 'free_trial',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Wellness = 'wellness',
  Yearly = 'yearly',
}

export type PlayOverview = {
  __typename?: 'PlayOverview';
  challenges: Maybe<Array<GenericProgram>>;
  liveEvents: Maybe<Array<LiveEvent>>;
  needs: Maybe<Array<Need>>;
  playlists: Maybe<Array<Playlist>>;
  programs: Maybe<Array<GenericProgram>>;
  videoSettings: Scalars['JSON'];
  videos: Maybe<PagedVideos>;
};

export type Playlist = {
  __typename?: 'Playlist';
  assets: Maybe<PlaylistAssets>;
  description: Maybe<Scalars['String']>;
  favorited: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identifier: Maybe<Scalars['String']>;
  languages: Maybe<Array<Scalars['String']>>;
  meta: Maybe<Meta>;
  owner: Maybe<UserInterface>;
  public: Maybe<Scalars['Boolean']>;
  publishedState: Scalars['String'];
  removedText: Maybe<Scalars['String']>;
  thumbnailUrl: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userName: Maybe<Scalars['String']>;
  videos: Maybe<Array<Maybe<Video>>>;
  visibility: Maybe<Scalars['String']>;
};

export type PlaylistAssets = {
  __typename?: 'PlaylistAssets';
  thumbnailUrl: Maybe<Scalars['String']>;
};

export enum PlaylistSortAttribute {
  Alphabetical = 'alphabetical',
  NewestFirst = 'newest_first',
}

export type PlaylistStatus = {
  __typename?: 'PlaylistStatus';
  destroyed: Maybe<Scalars['Boolean']>;
};

export type Preferences = {
  __typename?: 'Preferences';
  contentLanguages: Maybe<Array<Maybe<Scalars['String']>>>;
  excludeTerms: Maybe<Array<Maybe<Scalars['String']>>>;
  preferTerms: Maybe<Array<Maybe<Scalars['String']>>>;
  searchFilters: Maybe<Array<Maybe<Scalars['String']>>>;
  videoLevels: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Price = {
  __typename?: 'Price';
  currency: Maybe<Scalars['String']>;
  discountApplied: Scalars['Boolean'];
  discountedPrice: Maybe<Scalars['Int']>;
  originalPrice: Maybe<Scalars['Int']>;
};

export type PriceBreakdown = {
  __typename?: 'PriceBreakdown';
  accessDays: Maybe<Scalars['Int']>;
  amountPerDay: Maybe<Scalars['Int']>;
  currency: Maybe<Scalars['String']>;
  extraDays: Maybe<Scalars['Int']>;
  extraDaysAmount: Maybe<Scalars['Int']>;
  originalPlanAmount: Maybe<Scalars['Int']>;
  planCount: Maybe<Scalars['Int']>;
  planType: Maybe<Scalars['String']>;
  totalAmount: Maybe<Scalars['Int']>;
};

export enum PriceTypeKind {
  AccessCodesOnly = 'access_codes_only',
  BySubscription = 'by_subscription',
  DiscountForAll = 'discount_for_all',
  DiscountForPaying = 'discount_for_paying',
  FreeForAll = 'free_for_all',
  FreeForPaying = 'free_for_paying',
  RegularPrice = 'regular_price',
}

export type PricingPage = {
  __typename?: 'PricingPage';
  buttonLink: Maybe<Scalars['String']>;
  buttonName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type PrivacySettings = {
  __typename?: 'PrivacySettings';
  isPrivateFavorites: Maybe<Scalars['Boolean']>;
  isPrivateHistory: Maybe<Scalars['Boolean']>;
  isPrivatePlaylists: Maybe<Scalars['Boolean']>;
  isPrivateProfile: Maybe<Scalars['Boolean']>;
  isPrivateStats: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of ProcessTrialPlan */
export type ProcessTrialPlanPayload = {
  __typename?: 'ProcessTrialPlanPayload';
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

/** The usual errors like 404, 403 etc. */
export type ProcessingError = {
  __typename?: 'ProcessingError';
  /** A description of the error */
  message: Maybe<Scalars['String']>;
  /** The error class */
  status: Maybe<ErrorValue>;
};

export type Profile = {
  __typename?: 'Profile';
  displayName: Maybe<Scalars['String']>;
  facebookURL: Maybe<Scalars['String']>;
  instagramURL: Maybe<Scalars['String']>;
  linkedinURL: Maybe<Scalars['String']>;
  shortBio: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  timezone: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  body: Maybe<Scalars['String']>;
  category: Maybe<ProgramCategory>;
  children: Maybe<Array<Maybe<Program>>>;
  duration: Duration;
  id: Scalars['ID'];
  instructorIds: Maybe<Array<Scalars['ID']>>;
  instructors: Maybe<Array<Maybe<Instructor>>>;
  kind: Scalars['String'];
  language: Maybe<Scalars['String']>;
  lede: Maybe<Scalars['String']>;
  legacy: Scalars['Boolean'];
  name: Scalars['String'];
  postRollQuiz: Maybe<Scalars['String']>;
  preRollQuiz: Maybe<Scalars['String']>;
  progressIsCurrent: Scalars['Boolean'];
  progressNext: Maybe<Program>;
  progressPercentage: Maybe<Scalars['Int']>;
  progressState: Scalars['String'];
  quizCount: Maybe<Scalars['Int']>;
  shortDescription: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  themes: Maybe<Array<Scalars['String']>>;
  themesV2: Maybe<Array<Theme>>;
  title: Maybe<Scalars['String']>;
  titleImageUrl: Maybe<Scalars['String']>;
  video: Maybe<Video>;
  videoCount: Maybe<Scalars['Int']>;
  videoId: Maybe<Scalars['String']>;
  videoObject: Maybe<Video>;
  weekCount: Maybe<Scalars['Int']>;
};

export type ProgramAccess = {
  __typename?: 'ProgramAccess';
  accessStatus: ProgramAccessStatus;
  accessUntil: Maybe<Scalars['BigInt']>;
  archivedAt: Maybe<Scalars['ISO8601DateTime']>;
  joinedAt: Maybe<Scalars['ISO8601DateTime']>;
  path: Maybe<Scalars['String']>;
  program: Maybe<Program>;
  progressPercentage: Maybe<Scalars['Int']>;
};

export enum ProgramAccessStatus {
  /** Access to program is active */
  Active = 'active',
  /** Access to program is expired */
  Expired = 'expired',
  /** Access to program is expiring in 30 days */
  ExpiringSoon = 'expiring_soon',
  /** No access to program */
  Locked = 'locked',
}

export type ProgramActiveState = {
  __typename?: 'ProgramActiveState';
  day: Maybe<Program>;
  phase: Maybe<Program>;
  sequence: Maybe<Program>;
};

export enum ProgramCategory {
  Challenges = 'challenges',
  Courses = 'courses',
  Healthcare = 'healthcare',
  LiveCourse = 'live_course',
  Programs = 'programs',
}

export type ProgramDocument = {
  __typename?: 'ProgramDocument';
  customPdfThumbnail: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  document: Document;
  finished: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  kind: Maybe<Scalars['String']>;
  locked: Scalars['Boolean'];
  position: Scalars['Int'];
  positionLabel: Scalars['String'];
  progressIsCurrent: Scalars['Boolean'];
  slug: Scalars['String'];
  subheader: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ProgramElement =
  | ProgramDocument
  | ProgramLiveEvent
  | ProgramPartElement
  | ProgramSurveyElement
  | ProgramVideo;

export type ProgramElementable = LiveEvent;

export enum ProgramKind {
  CompletionBased = 'completion_based',
  ContentBased = 'content_based',
  DailyBased = 'daily_based',
  DateBased = 'date_based',
  PhaseBased = 'phase_based',
  WeeklyBased = 'weekly_based',
}

export type ProgramLiveEvent = {
  __typename?: 'ProgramLiveEvent';
  description: Maybe<Scalars['String']>;
  finished: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isExpired: Maybe<Scalars['Boolean']>;
  kind: Maybe<Scalars['String']>;
  liveEvent: Maybe<LiveEvent>;
  locked: Scalars['Boolean'];
  position: Scalars['Int'];
  positionLabel: Scalars['String'];
  progressIsCurrent: Scalars['Boolean'];
  slug: Scalars['String'];
  subheader: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ProgramMeta = {
  __typename?: 'ProgramMeta';
  description: Maybe<Scalars['String']>;
  imageUrl: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ProgramPartElement = {
  __typename?: 'ProgramPartElement';
  description: Maybe<Scalars['String']>;
  element: Maybe<ProgramElementable>;
  finished: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  kind: Maybe<Scalars['String']>;
  locked: Scalars['Boolean'];
  position: Scalars['Int'];
  positionLabel: Scalars['String'];
  progressIsCurrent: Scalars['Boolean'];
  slug: Scalars['String'];
  subheader: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type ProgramPhase = {
  __typename?: 'ProgramPhase';
  description: Maybe<Scalars['String']>;
  finished: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  label: Maybe<Scalars['String']>;
  locked: Scalars['Boolean'];
  progress: Maybe<Scalars['Float']>;
  slug: Scalars['String'];
  videosCount: Maybe<Scalars['Int']>;
  videosDuration: Maybe<Scalars['String']>;
  weeks: Maybe<Array<Maybe<ProgramWeek>>>;
  weeksRange: Maybe<Scalars['String']>;
};

export type ProgramSurvey = {
  __typename?: 'ProgramSurvey';
  finished: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  kind: SurveyKind;
  label: Scalars['String'];
  link: Scalars['String'];
  locked: Scalars['Boolean'];
};

export type ProgramSurveyCompletion = {
  __typename?: 'ProgramSurveyCompletion';
  programAccessId: Scalars['ID'];
  programSurveyId: Scalars['ID'];
};

export type ProgramSurveyElement = {
  __typename?: 'ProgramSurveyElement';
  description: Maybe<Scalars['String']>;
  finished: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastSubmittedAt: Maybe<Scalars['ISO8601Date']>;
  locked: Scalars['Boolean'];
  position: Scalars['Int'];
  positionLabel: Scalars['String'];
  progressIsCurrent: Scalars['Boolean'];
  slug: Scalars['String'];
  subheader: Maybe<Scalars['String']>;
  survey: Survey;
  surveysAnswer: Maybe<SurveyAnswer>;
  title: Maybe<Scalars['String']>;
};

export type ProgramVideo = {
  __typename?: 'ProgramVideo';
  customDescription: Maybe<Scalars['String']>;
  customTitle: Maybe<Scalars['String']>;
  finished: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  locked: Scalars['Boolean'];
  position: Scalars['Int'];
  positionLabel: Scalars['String'];
  postMoodTracker: Maybe<MoodTracker>;
  preMoodTracker: Maybe<MoodTracker>;
  progressIsCurrent: Scalars['Boolean'];
  repeatRecomendation: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  subheader: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  video: Video;
  videoKind: Maybe<VideoKind>;
  watched: Maybe<Scalars['Boolean']>;
};

export type ProgramWeek = {
  __typename?: 'ProgramWeek';
  description: Maybe<Scalars['String']>;
  duration: Maybe<Scalars['String']>;
  elements: Array<ProgramElement>;
  finished: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  indexInProgram: Maybe<Scalars['Int']>;
  label: Scalars['String'];
  locked: Scalars['Boolean'];
  progress: Maybe<Scalars['Float']>;
  slug: Scalars['String'];
  videos: Array<ProgramVideo>;
  videosCount: Maybe<Scalars['Int']>;
};

export type ProgramsDetails = {
  __typename?: 'ProgramsDetails';
  description: Scalars['String'];
  instructor: Maybe<Instructor>;
  label: Scalars['String'];
  perks: Maybe<Array<Maybe<Scalars['String']>>>;
  title: Scalars['String'];
  video: Maybe<Video>;
};

export type Promotion = {
  __typename?: 'Promotion';
  name: Scalars['String'];
  promotionItems: Array<PromotionCodeResource>;
};

export type PromotionCode = {
  __typename?: 'PromotionCode';
  code: Scalars['String'];
  creationDate: Scalars['ISO8601DateTime'];
  expirationDate: Maybe<Scalars['ISO8601Date']>;
  matchingResources: Array<PromotionCodeResource>;
  provider: Maybe<Scalars['String']>;
  usageLimit: Maybe<Scalars['Int']>;
  usedIn: Array<PromotionCodeUser>;
};

export type PromotionCodeResource = Bundle | GenericProgram | LiveEvent;

export enum PromotionCodeTypeKind {
  Trial = 'trial',
  Wellness = 'wellness',
}

export type PromotionCodeUser = {
  __typename?: 'PromotionCodeUser';
  live: Maybe<LiveEvent>;
  usageDate: Scalars['ISO8601DateTime'];
};

export enum PromotionKind {
  ExtraGlobal = 'extra_global',
  ExtraSubscriptionDiscountCode = 'extra_subscription_discount_code',
  Global = 'global',
  OneTimeCode = 'one_time_code',
  SubscriptionDiscountCode = 'subscription_discount_code',
  SubscriptionFullAccessCode = 'subscription_full_access_code',
  TrailCampaignCode = 'trail_campaign_code',
  TrialCampaignCode = 'trial_campaign_code',
  TrialReclaim = 'trial_reclaim',
  TrialReferralCode = 'trial_referral_code',
  Upsell = 'upsell',
}

export enum Provider {
  Apple = 'apple',
  Email = 'email',
  Facebook = 'facebook',
  Google = 'google',
}

export enum ProviderKind {
  Adyen = 'adyen',
  Trustly = 'trustly',
}

export enum ProviderTypes {
  Apple = 'Apple',
  Facebook = 'Facebook',
  Google = 'Google',
}

export enum ProxyEventKind {
  RegistrationCodeUsed = 'REGISTRATION_CODE_USED',
  RegistrationFreePlanStarted = 'REGISTRATION_FREE_PLAN_STARTED',
  RegistrationFreePlanUpsellSuccess = 'REGISTRATION_FREE_PLAN_UPSELL_SUCCESS',
  RegistrationPayingSubscriptionStarted = 'REGISTRATION_PAYING_SUBSCRIPTION_STARTED',
  RegistrationPaymentMethodAdded = 'REGISTRATION_PAYMENT_METHOD_ADDED',
  RegistrationSubscriptionUpsellSuccess = 'REGISTRATION_SUBSCRIPTION_UPSELL_SUCCESS',
  RegistrationTrialStarted = 'REGISTRATION_TRIAL_STARTED',
  RegistrationWellnessTrialStarted = 'REGISTRATION_WELLNESS_TRIAL_STARTED',
  SurveyStarted = 'SURVEY_STARTED',
  TrialPeriodStarted = 'TRIAL_PERIOD_STARTED',
}

export type QAndA = {
  __typename?: 'QAndA';
  answer: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position: Maybe<Scalars['Int']>;
  question: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activateCampaignCode: CampaignCode;
  archivedItems: ArchivedItems;
  autocomplete: Autocomplete;
  availableAllCodes: AvailableSubscriptionPlan;
  availableOneTimeCode: AvailableSubscriptionPlan;
  availableSubscriptionPlans: AvailableSubscriptionPlan;
  availableTrial: Maybe<Trial>;
  availableTrialExtensions: AvailableSubscriptionPlan;
  /** Search video */
  batchVideos: Array<Video>;
  blogPostCategories: Maybe<Array<BlogPostCategory>>;
  bundle: Maybe<Bundle>;
  bundles: Maybe<PagedBundles>;
  calculateCustomDays: Maybe<WellnessPackage>;
  campaignCode: Maybe<CampaignCode>;
  churnUpsellOffers: Array<Upsell>;
  crossPromotionalBanners: Array<CrossPromotionalBanner>;
  currentUser: Maybe<CurrentUser>;
  document: Maybe<Document>;
  emailCheck: Maybe<Email>;
  emailPreferences: Maybe<Array<EmailPreference>>;
  favoritePlaylists: Maybe<PagedPlaylists>;
  favoriteVideos: PagedVideos;
  fetchBlogPost: Maybe<BlogPost>;
  fetchCrossPromotions: Maybe<Search>;
  flipperFlags: Maybe<Array<FlipperFlag>>;
  genericProgram: Maybe<GenericProgram>;
  genericPrograms: Maybe<PagedGenericPrograms>;
  getFavoritePlaylists: Maybe<PagedPlaylists>;
  getFavoriteVideos: Maybe<PagedVideos>;
  getUser: Maybe<User>;
  giftGenericProgram: Maybe<GenericProgramWithCode>;
  healthcareProgramToken: Maybe<HealthcareProgramToken>;
  healthcareVideoToken: Maybe<HealthcareVideoToken>;
  instructor: Maybe<Instructor>;
  instructorByGenre: InstructorInGenre;
  instructors: Maybe<PagedInstructors>;
  landingPageV2: Maybe<CampaignPage>;
  lastWatchedSessions: Array<Video>;
  latestViewedVideos: PagedVideos;
  liveEvent: Maybe<LiveEvent>;
  liveEventCodes: LiveEventCodes;
  liveEvents: Maybe<PagedLiveEvents>;
  marketplaceEvent: Event;
  marketplaceEvents: PagedEvents;
  meditationOverview: MeditationOverview;
  meditationStats: MeditationStats;
  myBundles: Maybe<PagedBundles>;
  myGenericPrograms: Maybe<PagedGenericPrograms>;
  myPaymentMethods: Maybe<Array<PaymentMethod>>;
  onboardingChoices: OnboardingChoices;
  order: Maybe<Order>;
  overviewPlaylists: Maybe<Array<Maybe<OverviewPlaylist>>>;
  pageByRoute: Maybe<Page>;
  pages: Maybe<PagedPages>;
  pay: PaymentResponse;
  phasedProgram: Maybe<PhasedProgram>;
  playOverview: PlayOverview;
  playlist: Maybe<Playlist>;
  playlists: Maybe<PagedPlaylists>;
  popularVideos: Maybe<Array<Maybe<Video>>>;
  posts: Maybe<PagedBlogPosts>;
  precheckMobileSubscription: Maybe<AppleSubscriptionPrecheck>;
  predictedLtv: Scalars['Int'];
  pricingPage: Maybe<PricingPage>;
  program: Maybe<Program>;
  programActiveState: Maybe<ProgramActiveState>;
  programById: Maybe<Program>;
  programBySlug: Maybe<Program>;
  programSurveyCompletion: Maybe<ProgramSurveyCompletion>;
  programThemes: Maybe<Array<Scalars['String']>>;
  programThemesV2: Maybe<Array<Theme>>;
  receipts: Maybe<Scalars['String']>;
  recentVideos: Maybe<Array<Maybe<Video>>>;
  recommendations: Recommendations;
  recommendedOnboardingPlaylists: PagedPlaylists;
  recommendedOnboardingVideos: PagedVideos;
  recommendedPlaylists: Maybe<PagedPlaylists>;
  registrationPage: RegistrationPlayPage;
  relatedPagesByRoute: Maybe<Array<Maybe<Page>>>;
  relatedVideos: Maybe<Array<Maybe<Video>>>;
  search: Maybe<Search>;
  searchSuggestions: Maybe<Array<Maybe<Scalars['String']>>>;
  specificSearch: Maybe<SpecificSearch>;
  startOverview: StartOverview;
  subPagesByRoute: Maybe<PagedPages>;
  survey: Maybe<Survey>;
  surveyPart: Maybe<ProgramSurveyElement>;
  surveys: Maybe<PagedSurveys>;
  taxonomies: Maybe<Array<Maybe<TaxonomyItem>>>;
  taxonomiesV2: Array<Maybe<NewTaxonomyItem>>;
  teams: Array<Team>;
  testimonials: Array<Testimonial>;
  trialSubscriptionOffer: Maybe<Upsell>;
  trials: Maybe<Array<Trial>>;
  upsellOffers: Maybe<Array<Upsell>>;
  userOptions: Maybe<Options>;
  userPlaylists: Maybe<PagedPlaylists>;
  userStats: Stats;
  users: Maybe<PagedUsers>;
  validateBundleCode: Maybe<ValidateCode>;
  validateLiveEventCode: ValidateCode;
  validateSubscriptionTransfer: Maybe<ValidateTransfer>;
  /** Search video */
  video: Maybe<Video>;
  videos: PagedVideos;
  videosByFeature: Maybe<PagedVideos>;
  waitingListBundles: Maybe<PagedBundles>;
  weeklyProgram: Maybe<WeeklyProgram>;
  wellness: Wellness;
  wellnesses: Array<Wellness>;
};

export type QueryActivateCampaignCodeArgs = {
  code: Scalars['String'];
};

export type QueryAutocompleteArgs = {
  query: Scalars['String'];
  searchType?: GlobalSearch;
};

export type QueryAvailableAllCodesArgs = {
  code: InputMaybe<Scalars['String']>;
  platform: DevicePlatformKind;
  registration: InputMaybe<Scalars['Boolean']>;
};

export type QueryAvailableOneTimeCodeArgs = {
  code: InputMaybe<Scalars['String']>;
  platform: DevicePlatformKind;
};

export type QueryAvailableSubscriptionPlansArgs = {
  code: InputMaybe<Scalars['String']>;
  platform: DevicePlatformKind;
};

export type QueryAvailableTrialExtensionsArgs = {
  code: InputMaybe<Scalars['String']>;
  platform: DevicePlatformKind;
};

export type QueryBatchVideosArgs = {
  ids: Array<Scalars['String']>;
};

export type QueryBundleArgs = {
  client: Scalars['String'];
  previewParameter: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type QueryBundlesArgs = {
  category?: InputMaybe<BundleCategoryInput>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryCalculateCustomDaysArgs = {
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyKind;
  wellnessId: Scalars['Int'];
};

export type QueryCampaignCodeArgs = {
  code: Scalars['String'];
};

export type QueryDocumentArgs = {
  id: InputMaybe<Scalars['String']>;
  name: InputMaybe<Scalars['String']>;
};

export type QueryEmailCheckArgs = {
  email: Scalars['String'];
};

export type QueryEmailPreferencesArgs = {
  email: InputMaybe<Scalars['String']>;
  token: InputMaybe<Scalars['String']>;
};

export type QueryFavoritePlaylistsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<PlaylistSortAttribute>;
};

export type QueryFavoriteVideosArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<VideoSortAttribute>;
};

export type QueryFetchBlogPostArgs = {
  slug: Scalars['String'];
};

export type QueryFetchCrossPromotionsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  resourceId: Scalars['String'];
  resourceType: ResourceTypeKind;
};

export type QueryFlipperFlagsArgs = {
  names: Array<Scalars['String']>;
};

export type QueryGenericProgramArgs = {
  slug: Scalars['String'];
};

export type QueryGenericProgramsArgs = {
  category: InputMaybe<ProgramCategory>;
  instructor: InputMaybe<Scalars['String']>;
  languages: InputMaybe<Array<LanguageKind>>;
  onlyMine?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<GenericProgramSortAttribute>;
  tags: InputMaybe<Array<Scalars['String']>>;
  theme: InputMaybe<Scalars['String']>;
};

export type QueryGetFavoritePlaylistsArgs = {
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<PlaylistSortAttribute>;
};

export type QueryGetFavoriteVideosArgs = {
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<VideoSortAttribute>;
};

export type QueryGetUserArgs = {
  id: Scalars['ID'];
};

export type QueryGiftGenericProgramArgs = {
  discountCode: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type QueryHealthcareProgramTokenArgs = {
  token: Scalars['String'];
};

export type QueryHealthcareVideoTokenArgs = {
  token: Scalars['String'];
};

export type QueryInstructorArgs = {
  contentLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['ID'];
};

export type QueryInstructorByGenreArgs = {
  genre: InputMaybe<Scalars['String']>;
  instructor: Scalars['String'];
  languages: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryInstructorsArgs = {
  contentLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  option?: InputMaybe<Option>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy: InputMaybe<SortAttribute>;
  sortOrder?: InputMaybe<SortOrder>;
  theme: InputMaybe<Scalars['String']>;
};

export type QueryLandingPageV2Args = {
  slug: Scalars['String'];
};

export type QueryLatestViewedVideosArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryLiveEventArgs = {
  id: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type QueryLiveEventsArgs = {
  instructor: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  kind: InputMaybe<LiveEventKind>;
  languages: InputMaybe<Array<LanguageKind>>;
  maxEventDate: InputMaybe<Scalars['ISO8601DateTime']>;
  minEventDate: InputMaybe<Scalars['ISO8601DateTime']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<LiveEventSortAttribute>;
  sortOrder?: InputMaybe<SortOrder>;
};

export type QueryMarketplaceEventArgs = {
  slug: Scalars['String'];
};

export type QueryMarketplaceEventsArgs = {
  archive: InputMaybe<Scalars['Boolean']>;
  category: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryMeditationOverviewArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryMeditationStatsArgs = {
  end: InputMaybe<Scalars['ISO8601DateTime']>;
  period?: InputMaybe<StatsPeriodKind>;
  start: InputMaybe<Scalars['ISO8601DateTime']>;
};

export type QueryMyBundlesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryMyGenericProgramsArgs = {
  category: InputMaybe<ProgramCategory>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<MyGenericProgramSortKind>;
};

export type QueryOrderArgs = {
  id: Scalars['String'];
};

export type QueryPageByRouteArgs = {
  path: Scalars['String'];
};

export type QueryPagesArgs = {
  feature?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryPayArgs = {
  paymentType: InputMaybe<Scalars['String']>;
};

export type QueryPhasedProgramArgs = {
  slug: Scalars['String'];
};

export type QueryPlayOverviewArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryPlaylistArgs = {
  id: Scalars['ID'];
};

export type QueryPlaylistsArgs = {
  collection: InputMaybe<Scalars['String']>;
  languages: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<PlaylistSortAttribute>;
};

export type QueryPostsArgs = {
  category?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryPrecheckMobileSubscriptionArgs = {
  platform: DevicePlatformKind;
  purchaseIdentifier: Scalars['String'];
};

export type QueryProgramArgs = {
  id: Scalars['ID'];
};

export type QueryProgramActiveStateArgs = {
  id: Scalars['String'];
};

export type QueryProgramByIdArgs = {
  id: Scalars['String'];
};

export type QueryProgramBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryProgramSurveyCompletionArgs = {
  genericProgramSlug: Scalars['String'];
  programSurveyKind: Scalars['String'];
};

export type QueryProgramThemesArgs = {
  category: InputMaybe<ProgramCategory>;
};

export type QueryProgramThemesV2Args = {
  category: InputMaybe<ProgramCategory>;
};

export type QueryRecommendationsArgs = {
  languages: Array<InputMaybe<Scalars['String']>>;
};

export type QueryRecommendedOnboardingPlaylistsArgs = {
  languages: Array<InputMaybe<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  slugs: Array<InputMaybe<Scalars['String']>>;
};

export type QueryRecommendedOnboardingVideosArgs = {
  languages: Array<InputMaybe<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  slugs: Array<InputMaybe<Scalars['String']>>;
};

export type QueryRecommendedPlaylistsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryRelatedPagesByRouteArgs = {
  path: Scalars['String'];
};

export type QueryRelatedVideosArgs = {
  id: Scalars['ID'];
};

export type QuerySearchArgs = {
  languages: Array<LanguageKind>;
  query: Scalars['String'];
  searchType: GlobalSearch;
};

export type QuerySearchSuggestionsArgs = {
  query: Scalars['String'];
};

export type QuerySpecificSearchArgs = {
  languages: Array<LanguageKind>;
  modelType: ModelNameKind;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type QuerySubPagesByRouteArgs = {
  page?: InputMaybe<Scalars['Int']>;
  path: Scalars['String'];
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QuerySurveyArgs = {
  slug: Scalars['String'];
};

export type QuerySurveyPartArgs = {
  surveyPartId: Scalars['Int'];
};

export type QuerySurveysArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryTaxonomiesArgs = {
  filter: InputMaybe<Scalars['String']>;
  genre: InputMaybe<Scalars['String']>;
  languages: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryTaxonomiesV2Args = {
  genre: InputMaybe<Scalars['String']>;
  languages: InputMaybe<Array<Scalars['String']>>;
};

export type QueryUserPlaylistsArgs = {
  id: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<PlaylistSortAttribute>;
};

export type QueryUserStatsArgs = {
  period?: InputMaybe<StatsPeriodKind>;
};

export type QueryUsersArgs = {
  page: InputMaybe<Scalars['Int']>;
  perPage: InputMaybe<Scalars['Int']>;
  query: InputMaybe<Scalars['String']>;
};

export type QueryValidateBundleCodeArgs = {
  client: Scalars['String'];
  code: Scalars['String'];
  slug: Scalars['String'];
};

export type QueryValidateLiveEventCodeArgs = {
  code: Scalars['String'];
  slug: Scalars['String'];
};

export type QueryValidateSubscriptionTransferArgs = {
  newUser: Scalars['String'];
  oldUser: Scalars['String'];
};

export type QueryVideoArgs = {
  id: Scalars['ID'];
};

export type QueryVideosArgs = {
  contentLanguages: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  durations?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  excludeTerms?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  flavor?: InputMaybe<Scalars['String']>;
  genre?: InputMaybe<Scalars['String']>;
  kinds?: InputMaybe<Array<VideoKindInput>>;
  orderBy?: InputMaybe<VideoOrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  quickstartCategory?: InputMaybe<Scalars['String']>;
  taxonomies?: InputMaybe<Array<TaxonomyInput>>;
  useLocale?: InputMaybe<Scalars['Boolean']>;
  videoLevels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryVideosByFeatureArgs = {
  feature?: Scalars['String'];
  languages: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  q: InputMaybe<Scalars['String']>;
};

export type QueryWaitingListBundlesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryWeeklyProgramArgs = {
  slug: Scalars['String'];
};

export type QueryWellnessArgs = {
  allowance: InputMaybe<Scalars['Float']>;
  amountCurrency?: InputMaybe<CurrencyKind>;
  code: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type Recommendations = {
  __typename?: 'Recommendations';
  courses: Maybe<Array<Course>>;
  playlists: Maybe<Array<Playlist>>;
  videos: Maybe<Array<Video>>;
};

export enum RecurringModelInputKind {
  CardOnFile = 'CardOnFile',
  Subscription = 'Subscription',
}

export type RegistrationDetail = {
  __typename?: 'RegistrationDetail';
  campaign: Maybe<Scalars['String']>;
  campaignCode: Maybe<Scalars['String']>;
  paymentType: Maybe<Scalars['String']>;
  redirectTo: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Registration */
export type RegistrationPayload = {
  __typename?: 'RegistrationPayload';
  errors: Maybe<Array<Error>>;
  user: Maybe<CurrentUser>;
};

export type RegistrationPlayPage = {
  __typename?: 'RegistrationPlayPage';
  contentHubImageUrl: Maybe<Scalars['String']>;
  courseImageUrl: Maybe<Scalars['String']>;
  healthcareImageUrl: Maybe<Scalars['String']>;
  liveImageUrl: Maybe<Scalars['String']>;
  playImageUrl: Maybe<Scalars['String']>;
  subscriptionPlansImageUrl: Maybe<Scalars['String']>;
  trialImageUrl: Maybe<Scalars['String']>;
};

export enum RegistrationSourceKind {
  B2hFar = 'b2h_far',
  B2hProgram = 'b2h_program',
  Blog = 'blog',
  Bundle = 'bundle',
  Challenge = 'challenge',
  ContentHubBundle = 'content_hub_bundle',
  Course = 'course',
  Instructor = 'instructor',
  Live = 'live',
  PaidLive = 'paid_live',
  Playlist = 'playlist',
  Program = 'program',
  Video = 'video',
  Wellness = 'wellness',
}

export enum RegistrationStepEnum {
  Other = 'other',
  Play_2 = 'play_2',
  Play_3A = 'play_3_a',
  Play_3A_1 = 'play_3_a_1',
  Play_3A_2 = 'play_3_a_2',
  Play_3A_3 = 'play_3_a_3',
  Play_3A_4 = 'play_3_a_4',
  Play_3B = 'play_3_b',
  Play_3B_1 = 'play_3_b_1',
  Play_3B_2 = 'play_3_b_2',
  Play_3B_3 = 'play_3_b_3',
  Play_3B_4 = 'play_3_b_4',
  Play_3C = 'play_3_c',
  Play_3C_1 = 'play_3_c_1',
  Play_3C_2 = 'play_3_c_2',
  Play_3C_3 = 'play_3_c_3',
  Play_3C_4 = 'play_3_c_4',
  Registration = 'registration',
  RegistrationVisit = 'registration_visit',
}

/** Autogenerated return type of RegistrationV2 */
export type RegistrationV2Payload = {
  __typename?: 'RegistrationV2Payload';
  errors: Maybe<Array<Error>>;
  user: Maybe<CurrentUser>;
};

/** Autogenerated return type of RemoveVideoFromPlaylist */
export type RemoveVideoFromPlaylistPayload = {
  __typename?: 'RemoveVideoFromPlaylistPayload';
  playlist: Maybe<Playlist>;
};

/** Autogenerated return type of ResendConfirmationEmail */
export type ResendConfirmationEmailPayload = {
  __typename?: 'ResendConfirmationEmailPayload';
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ResetEmailConfirmation */
export type ResetEmailConfirmationPayload = {
  __typename?: 'ResetEmailConfirmationPayload';
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ResetPassword */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  success: Scalars['Boolean'];
};

/** Autogenerated return type of ResetSummerCampaign */
export type ResetSummerCampaignPayload = {
  __typename?: 'ResetSummerCampaignPayload';
  action: Maybe<Scalars['String']>;
  errors: Maybe<Array<ProcessingError>>;
};

export enum ResourceTypeKind {
  BlogPost = 'BlogPost',
  Bundle = 'Bundle',
  GenericProgram = 'GenericProgram',
  LiveEvent = 'LiveEvent',
  Playlist = 'Playlist',
  Video = 'Video',
}

/** Autogenerated return type of RestoreMobileSubscription */
export type RestoreMobileSubscriptionPayload = {
  __typename?: 'RestoreMobileSubscriptionPayload';
  errors: Maybe<Array<Error>>;
  user: Maybe<CurrentUser>;
};

export type RiskDataInput = {
  clientData: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of SaveOnboardingChoices */
export type SaveOnboardingChoicesPayload = {
  __typename?: 'SaveOnboardingChoicesPayload';
  errors: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
  user: Maybe<User>;
};

/** Autogenerated return type of SavePhasedProgramProgress */
export type SavePhasedProgramProgressPayload = {
  __typename?: 'SavePhasedProgramProgressPayload';
  errors: Array<Scalars['String']>;
};

/** Autogenerated return type of SaveProgramProgress */
export type SaveProgramProgressPayload = {
  __typename?: 'SaveProgramProgressPayload';
  errors: Array<Scalars['String']>;
};

export type Search = {
  __typename?: 'Search';
  blogPosts: Maybe<SearchResults>;
  bundles: Maybe<SearchResults>;
  challenges: Maybe<SearchResults>;
  courses: Maybe<SearchResults>;
  events: Maybe<SearchResults>;
  genericPrograms: Maybe<SearchResults>;
  instructors: Maybe<SearchResults>;
  landingPages: Maybe<SearchResults>;
  liveEvents: Maybe<SearchResults>;
  playlists: Maybe<SearchResults>;
  query: Maybe<Scalars['String']>;
  suggestions: Maybe<Array<Scalars['String']>>;
  videos: Maybe<SearchResults>;
};

export type SearchBlogPost = {
  __typename?: 'SearchBlogPost';
  featuredImageUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type SearchBundle = {
  __typename?: 'SearchBundle';
  accessUntil: Maybe<Scalars['String']>;
  bundleCategory: Maybe<Scalars['String']>;
  cardImageUrl: Maybe<Scalars['String']>;
  client: Maybe<Client>;
  heroImageUrl: Maybe<Scalars['String']>;
  hideInDashboard: Maybe<Scalars['Boolean']>;
  hideInfoText: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['ID']>;
  joinAutomatically: Maybe<Scalars['Boolean']>;
  kind: Maybe<Scalars['String']>;
  metaDescription: Maybe<Scalars['String']>;
  primaryInstructor: Maybe<Scalars['String']>;
  processedAt: Maybe<Scalars['String']>;
  publishedAt: Maybe<Scalars['String']>;
  shortDescription: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  startTime: Maybe<Scalars['String']>;
  tags: Maybe<Array<Scalars['String']>>;
  themes: Maybe<Array<Scalars['String']>>;
  themesV2: Maybe<Array<Theme>>;
  title: Maybe<Scalars['String']>;
  viewAllBlogPostsUrl: Maybe<Scalars['String']>;
  viewAllChallengesUrl: Maybe<Scalars['String']>;
  viewAllPlaylistsUrl: Maybe<Scalars['String']>;
  viewAllProgramsUrl: Maybe<Scalars['String']>;
  viewAllVideosUrl: Maybe<Scalars['String']>;
  waitingListEnabled: Maybe<Scalars['Boolean']>;
  waitingListEndDate: Maybe<Scalars['String']>;
  waitingListStartDate: Maybe<Scalars['String']>;
};

export type SearchEvent = {
  __typename?: 'SearchEvent';
  description: Maybe<Scalars['String']>;
  endsAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  instructor: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Maybe<Scalars['String']>;
  startsAt: Maybe<Scalars['ISO8601DateTime']>;
  titleImageUrl: Maybe<Scalars['String']>;
};

export type SearchGenericProgram = {
  __typename?: 'SearchGenericProgram';
  accessPeriod: Maybe<AccessPeriod>;
  category: Maybe<Scalars['String']>;
  description: Scalars['String'];
  durationUnit: Maybe<Scalars['String']>;
  durationValue: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructorName: Maybe<Scalars['String']>;
  instructorSlug: Maybe<Scalars['String']>;
  price: Maybe<Price>;
  priceType: Maybe<PriceTypeKind>;
  slug: Maybe<Scalars['String']>;
  themes: Maybe<Array<Scalars['String']>>;
  themesV2: Maybe<Array<Theme>>;
  title: Scalars['String'];
  titleImageUrl: Maybe<Scalars['String']>;
};

export type SearchInstructor = {
  __typename?: 'SearchInstructor';
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  squareImageUrl: Maybe<Scalars['String']>;
};

export type SearchLandingPage = {
  __typename?: 'SearchLandingPage';
  id: Scalars['ID'];
  lede: Maybe<Scalars['String']>;
  locale: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type SearchLiveEvent = {
  __typename?: 'SearchLiveEvent';
  availableSpots: Maybe<Scalars['Int']>;
  bookingAvailable: Maybe<Scalars['Boolean']>;
  /** duration in minutes. Should step by 15 minutes, so 0, 15, 30, etc. */
  duration: Scalars['Int'];
  eventDates: Array<Scalars['ISO8601DateTime']>;
  flexibleMaxAmountCents: Maybe<Scalars['String']>;
  flexibleMinAmountCents: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructorName: Maybe<Scalars['String']>;
  instructorSlug: Maybe<Scalars['String']>;
  isClosed: Maybe<Scalars['Boolean']>;
  isComingSoon: Maybe<Scalars['Boolean']>;
  isExpired: Maybe<Scalars['Boolean']>;
  kind: LiveEventKind;
  maximumParticipants: Maybe<Scalars['Int']>;
  paymentKind: Maybe<Scalars['String']>;
  price: Maybe<Price>;
  registrationEndDate: Maybe<Scalars['ISO8601DateTime']>;
  registrationStartDate: Maybe<Scalars['ISO8601DateTime']>;
  slug: Maybe<Scalars['String']>;
  theme: Maybe<Scalars['String']>;
  themeLabel: Maybe<Scalars['String']>;
  thumbnailUrl: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type SearchPlaylist = {
  __typename?: 'SearchPlaylist';
  description: Scalars['String'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  minutes: Maybe<Scalars['String']>;
  ownerId: Maybe<Scalars['String']>;
  thumbnailUrl: Maybe<Scalars['String']>;
  title: Scalars['String'];
  videoCount: Maybe<Scalars['String']>;
  visibility: Maybe<Scalars['String']>;
};

export type SearchResult =
  | SearchBlogPost
  | SearchBundle
  | SearchEvent
  | SearchGenericProgram
  | SearchInstructor
  | SearchLandingPage
  | SearchLiveEvent
  | SearchPlaylist
  | SearchVideo;

export type SearchResults = PagedInterface & {
  __typename?: 'SearchResults';
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  results: Maybe<Array<SearchResult>>;
  totalCount: Scalars['Int'];
};

export type SearchVideo = {
  __typename?: 'SearchVideo';
  id: Scalars['ID'];
  identifier: Scalars['String'];
  instructorName: Maybe<Scalars['String']>;
  instructorSlug: Maybe<Scalars['String']>;
  kind: Scalars['String'];
  longDescription: Scalars['String'];
  minutes: Scalars['String'];
  primaryStyleName: Maybe<Scalars['String']>;
  primaryStyleSlug: Maybe<Scalars['String']>;
  shortDescription: Scalars['String'];
  thumbnailUrl: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Autogenerated return type of SignIn */
export type SignInPayload = {
  __typename?: 'SignInPayload';
  success: Maybe<Scalars['Boolean']>;
  user: Maybe<UserInterface>;
};

/** Autogenerated return type of SignUp */
export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  success: Maybe<Scalars['Boolean']>;
  user: Maybe<UserInterface>;
};

export enum SlugTypeKind {
  Breath = 'breath',
  Grow = 'grow',
  Meditate = 'meditate',
  Move = 'move',
  Yoga = 'yoga',
}

export enum SortAttribute {
  Name = 'name',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SpecialSection = {
  __typename?: 'SpecialSection';
  assetUrl: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  slug: Scalars['String'];
  videos: Maybe<Array<Maybe<ProgramVideo>>>;
};

export type SpecificSearch = {
  __typename?: 'SpecificSearch';
  blogPosts: Maybe<SearchResults>;
  bundles: Maybe<SearchResults>;
  challenges: Maybe<SearchResults>;
  courses: Maybe<SearchResults>;
  events: Maybe<SearchResults>;
  genericPrograms: Maybe<SearchResults>;
  instructors: Maybe<SearchResults>;
  landingPages: Maybe<SearchResults>;
  liveEvents: Maybe<SearchResults>;
  playlists: Maybe<SearchResults>;
  query: Maybe<Scalars['String']>;
  suggestions: Maybe<Array<Scalars['String']>>;
  videos: Maybe<SearchResults>;
};

export type StartOverview = {
  __typename?: 'StartOverview';
  campaignSection: Maybe<CampaignSection>;
  campaignSection2: Maybe<CampaignSection>;
  campaignSection3: Maybe<CampaignSection>;
  coursesSection: Maybe<CoursesSection>;
  ctaSection: Maybe<CtaSection>;
  featuresSection: Maybe<FeaturesSection>;
  genresSection: Maybe<GenresSection>;
  heroSection: Maybe<HeroSection>;
  highlightedBundles: Array<Bundle>;
  metaSection: Maybe<MetaSection>;
  mobileAppSection: Maybe<MobileAppSection>;
  otherFeaturesSection: Maybe<OtherFeaturesSection>;
  videoLibrarySection: Maybe<VideosLibrarySection>;
};

/** Autogenerated return type of StartSubscription */
export type StartSubscriptionPayload = {
  __typename?: 'StartSubscriptionPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of StartTrialPlanSubscription */
export type StartTrialPlanSubscriptionPayload = {
  __typename?: 'StartTrialPlanSubscriptionPayload';
  errors: Array<Error>;
  success: Scalars['Boolean'];
};

export enum State {
  Draft = 'draft',
  Finished = 'finished',
}

export type Statistics = {
  __typename?: 'Statistics';
  averageDuration: Maybe<Scalars['Int']>;
  favoriteStyle: Maybe<Term>;
  sessionsCompleted: Scalars['Int'];
  sessionsLastWeek: Scalars['Int'];
  totalTime: Scalars['Int'];
};

export type Stats = {
  __typename?: 'Stats';
  numberOfChallengesStarted: Scalars['Int'];
  numberOfCoursesStarted: Scalars['Int'];
  numberOfLiveEventsBooked: Scalars['Int'];
  numberOfProgramsStarted: Scalars['Int'];
  playlistsCreated: Scalars['Int'];
  sessionsCompleted: Scalars['Int'];
  sessionsFavorited: Maybe<Scalars['Int']>;
  threeMostWatchedInstructors: Array<Instructor>;
  threeMostWatchedStyles: Array<Term>;
  totalWatchTime: Maybe<Scalars['Int']>;
};

export enum StatsPeriodKind {
  LastYear = 'lastYear',
  NinetyDays = 'ninetyDays',
  OneDay = 'oneDay',
  SevenDays = 'sevenDays',
  ThirtyDays = 'thirtyDays',
  ThisYear = 'thisYear',
}

/** Autogenerated return type of StepMutation */
export type StepMutationPayload = {
  __typename?: 'StepMutationPayload';
  success: Scalars['Boolean'];
};

/** Autogenerated return type of StoreAdyenDropin */
export type StoreAdyenDropinPayload = {
  __typename?: 'StoreAdyenDropinPayload';
  errors: Maybe<Array<Error>>;
  orderId: Maybe<Scalars['String']>;
  recurringDate: Maybe<Scalars['String']>;
  recurringPaymentAmount: Maybe<Scalars['String']>;
  recurringPaymentCurrency: Maybe<Scalars['String']>;
  resultAction: Maybe<AdyenAction>;
  resultCode: Maybe<PaymentResultCodeKind>;
  transaction: Maybe<Scalars['String']>;
};

/** Autogenerated return type of StoreEpassi */
export type StoreEpassiPayload = {
  __typename?: 'StoreEpassiPayload';
  amount: Maybe<Scalars['String']>;
  cancelUrl: Maybe<Scalars['String']>;
  fee: Maybe<Scalars['String']>;
  mac: Maybe<Scalars['String']>;
  notifyUrl: Maybe<Scalars['String']>;
  rejectUrl: Maybe<Scalars['String']>;
  returnUrl: Maybe<Scalars['String']>;
  site: Maybe<Scalars['String']>;
  stamp: Maybe<Scalars['String']>;
  vatValue: Maybe<Scalars['String']>;
};

/** Autogenerated return type of StorePaymentDetails */
export type StorePaymentDetailsPayload = {
  __typename?: 'StorePaymentDetailsPayload';
  errors: Maybe<Array<Error>>;
  newRecord: Maybe<Scalars['Boolean']>;
  orderId: Maybe<Scalars['String']>;
  resultCode: Maybe<AdyenResultCodeKind>;
};

/** Autogenerated return type of StoreTransactionId */
export type StoreTransactionIdPayload = {
  __typename?: 'StoreTransactionIdPayload';
  /** Apple service error message */
  errors: Maybe<Array<Error>>;
  /** Apple service error status code */
  statusCode: Maybe<Scalars['Int']>;
  /** Yogobe backend receipt validation message */
  validationMessage: Maybe<Scalars['String']>;
};

/** Autogenerated return type of StoreTrustly */
export type StoreTrustlyPayload = {
  __typename?: 'StoreTrustlyPayload';
  errors: Maybe<Array<Error>>;
  orderId: Maybe<Scalars['String']>;
  recurringDate: Maybe<Scalars['String']>;
  recurringPaymentAmount: Maybe<Scalars['String']>;
  recurringPaymentCurrency: Maybe<Scalars['String']>;
  resultUrl: Maybe<Scalars['String']>;
  transaction: Maybe<Scalars['String']>;
};

/** Autogenerated return type of StoreUserDecision */
export type StoreUserDecisionPayload = {
  __typename?: 'StoreUserDecisionPayload';
  user: User;
};

export type Story = {
  __typename?: 'Story';
  createdAt: Scalars['ISO8601DateTime'];
  hasAccess: Scalars['Boolean'];
  id: Scalars['ID'];
  progress: Scalars['Int'];
  resourceClass: Maybe<Scalars['String']>;
  resourceId: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  userId: Scalars['ID'];
};

/** Autogenerated return type of SubmitEventApplication */
export type SubmitEventApplicationPayload = {
  __typename?: 'SubmitEventApplicationPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Autogenerated return type of SubmitInterestForm */
export type SubmitInterestFormPayload = {
  __typename?: 'SubmitInterestFormPayload';
  errors: Array<ProcessingError>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** The latest plan (1m, 3m, 12, preglife, ninetynine ...) that user subscribed with, in other words, its the current subscription plan of the user of this subscripltion */
  currentSubscriptionPlan: SubscriptionPlan;
  /** In trial period if current subscription is trial and is active */
  inTrialPeriod: Scalars['Boolean'];
  /** Provider of the subscripton based on plan platform */
  provider: Maybe<Scalars['String']>;
  status: SubscriptionStatus;
  trial: Maybe<Trial>;
  /** User access_until will be present only if user is on trial */
  trialExpiration: Maybe<Scalars['ISO8601Date']>;
  userSubscription: Maybe<UserSubscription>;
};

export type SubscriptionPayment = OrderPaymentInterface & {
  __typename?: 'SubscriptionPayment';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyKind;
  failedReason: Maybe<Scalars['String']>;
  months: Scalars['Int'];
  orderId: Scalars['String'];
  originalAmountCents: Maybe<Scalars['Int']>;
  paymentProvider: PaymentProviderKind;
  paymentProviderVariant: Maybe<PaymentProviderVariantKind>;
  recurringAmountCents: Maybe<Scalars['Int']>;
  recurringDate: Maybe<Scalars['ISO8601Date']>;
  sourcePage: Maybe<Scalars['String']>;
  state: PaymentStatusKind;
  status: Maybe<OrderStatus>;
  success: Scalars['Boolean'];
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  amountCents: Scalars['Int'];
  amountCurrency: Scalars['String'];
  months: Scalars['Int'];
  oldSubscriptionPrice: Scalars['Boolean'];
  planType: Scalars['String'];
  platform: Maybe<DevicePlatformKind>;
  /** Not relevant for web platform */
  productId: Maybe<Scalars['String']>;
};

export enum SubscriptionStatus {
  Active = 'active',
  Cancelled = 'cancelled',
  PastDue = 'past_due',
  Unpaid = 'unpaid',
}

export enum SubscriptionUpsellPlanTypeKind {
  FreePlan = 'free_plan',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly',
}

export type Survey = {
  __typename?: 'Survey';
  content: SurveyContent;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Maybe<Array<Maybe<SurveyQuestion>>>;
  slug: Maybe<Scalars['String']>;
};

export type SurveyAnswer = {
  __typename?: 'SurveyAnswer';
  content: Array<ContentAnswer>;
  contextableType: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  state: Maybe<State>;
  userId: Scalars['ID'];
};

export type SurveyContent = {
  __typename?: 'SurveyContent';
  page1Description: Maybe<Scalars['String']>;
  page1Title: Maybe<Scalars['String']>;
  page2Description: Maybe<Scalars['String']>;
  page2Title: Maybe<Scalars['String']>;
  page3ButtonText: Maybe<Scalars['String']>;
  page3ButtonUrl: Maybe<Scalars['String']>;
  page3Description: Maybe<Scalars['String']>;
  page3Title: Maybe<Scalars['String']>;
};

export enum SurveyKind {
  Post = 'post',
  Pre = 'pre',
}

export type SurveyOptions = {
  __typename?: 'SurveyOptions';
  default: Maybe<Scalars['String']>;
  hint: Maybe<Scalars['String']>;
  leftLabel: Maybe<Scalars['String']>;
  maxValue: Maybe<Scalars['Int']>;
  minValue: Maybe<Scalars['Int']>;
  required: Scalars['Boolean'];
  rightLabel: Maybe<Scalars['String']>;
  type: SurveyTypeKind;
  useForNps: Maybe<Scalars['Boolean']>;
};

export type SurveyQuestion = {
  __typename?: 'SurveyQuestion';
  answers: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  options: Maybe<SurveyOptions>;
  question: Maybe<Scalars['String']>;
};

export enum SurveyTypeKind {
  CheckBoxes = 'check_boxes',
  DropDown = 'drop_down',
  DropDownMulti = 'drop_down_multi',
  EmojiSlider = 'emoji_slider',
  Input = 'input',
  MoodSlider = 'mood_slider',
  NumericalSlider = 'numerical_slider',
  RadioButtons = 'radio_buttons',
  RadioSlider = 'radio_slider',
  RadioSlider2 = 'radio_slider2',
  StarRating = 'star_rating',
  TextArea = 'text_area',
}

export type Taxonomy = {
  __typename?: 'Taxonomy';
  bodyparts: Maybe<Array<Maybe<Term>>>;
  duration: Maybe<Array<Maybe<Term>>>;
  flavor: Maybe<Term>;
  genre: Maybe<Term>;
  instructors: Maybe<Array<Maybe<Instructor>>>;
  needs: Maybe<Array<Maybe<Term>>>;
  props: Maybe<Array<Maybe<Term>>>;
  styles: Maybe<Array<Maybe<Term>>>;
  tags: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TaxonomyForLandingPage = {
  __typename?: 'TaxonomyForLandingPage';
  duration: Maybe<Term>;
  genre: Maybe<Term>;
  instructors: Maybe<Array<Instructor>>;
};

export type TaxonomyInput = {
  taxonomy: InputMaybe<Scalars['String']>;
  values: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TaxonomyItem = {
  __typename?: 'TaxonomyItem';
  genres: Maybe<Array<Scalars['String']>>;
  hasPrio: Maybe<Scalars['Boolean']>;
  hierarchical: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  languages: Maybe<Array<Scalars['String']>>;
  name: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  subcategories: Maybe<Array<TaxonomySubcategory>>;
  terms: Maybe<Array<Term>>;
  types: Maybe<Array<Scalars['String']>>;
};

export type TaxonomySubcategory = {
  __typename?: 'TaxonomySubcategory';
  name: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  terms: Maybe<Array<Term>>;
};

export type TaxonomyV2 = {
  __typename?: 'TaxonomyV2';
  taxonomies: Maybe<Array<TermTypeV2>>;
  term: Maybe<Term>;
};

export type Team = {
  __typename?: 'Team';
  instructors: Array<Instructor>;
  name: Scalars['String'];
};

export type Term = {
  __typename?: 'Term';
  category: Maybe<Scalars['String']>;
  categoryV2: Maybe<Scalars['String']>;
  genres: Maybe<Array<Maybe<Scalars['String']>>>;
  genresV2: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  important: Maybe<Scalars['Boolean']>;
  languages: Maybe<Array<Maybe<Scalars['String']>>>;
  link: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  prio: Maybe<Scalars['Boolean']>;
  selected: Maybe<Scalars['Boolean']>;
  slug: Maybe<Scalars['String']>;
  titleImageUrl: Maybe<Scalars['String']>;
  types: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TermTypeV2 = {
  __typename?: 'TermTypeV2';
  category: Maybe<Scalars['String']>;
  categoryV2: Maybe<Scalars['String']>;
  genres: Maybe<Array<Maybe<Scalars['String']>>>;
  genresV2: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  important: Maybe<Scalars['Boolean']>;
  languages: Maybe<Array<Maybe<Scalars['String']>>>;
  link: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  prio: Maybe<Scalars['Boolean']>;
  selected: Maybe<Scalars['Boolean']>;
  slug: Maybe<Scalars['String']>;
  titleImageUrl: Maybe<Scalars['String']>;
  types: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Testimonial = {
  __typename?: 'Testimonial';
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  quote: Scalars['String'];
  title: Scalars['String'];
};

export type Theme = {
  __typename?: 'Theme';
  label: Scalars['String'];
  slug: Scalars['String'];
};

export type Topic = {
  __typename?: 'Topic';
  hasContent: Scalars['Boolean'];
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Trial = {
  __typename?: 'Trial';
  claimedAt: Maybe<Scalars['ISO8601DateTime']>;
  code: Maybe<Scalars['String']>;
  endDate: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  periodDays: Scalars['Int'];
  selectedSubscriptionPlan: Maybe<TrialsSelectedSubscriptionPlan>;
  subscriptionPlanType: Maybe<PlanTypeKind>;
  subscriptionPromotionId: Maybe<Scalars['String']>;
};

export type TrialsSelectedSubscriptionPlan = {
  __typename?: 'TrialsSelectedSubscriptionPlan';
  amountCents: Scalars['Int'];
  amountCurrency: Scalars['String'];
  name: Scalars['String'];
};

export type TwentyThree = {
  __typename?: 'TwentyThree';
  iframeUrl: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UnarchiveItem */
export type UnarchiveItemPayload = {
  __typename?: 'UnarchiveItemPayload';
  errors: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated return type of UpdateMeditationSettings */
export type UpdateMeditationSettingsPayload = {
  __typename?: 'UpdateMeditationSettingsPayload';
  /** List of errors */
  errors: Maybe<Array<Error>>;
};

/** Autogenerated return type of UpdatePaymentMethodSettings */
export type UpdatePaymentMethodSettingsPayload = {
  __typename?: 'UpdatePaymentMethodSettingsPayload';
  errors: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type Upsell = {
  __typename?: 'Upsell';
  amountCents: Maybe<Scalars['Int']>;
  amountCurrency: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  promotionName: Scalars['String'];
  subscriptionPlan: Maybe<SubscriptionPlan>;
  subscriptionPlanType: Maybe<SubscriptionUpsellPlanTypeKind>;
  title: Maybe<Scalars['String']>;
};

export type User = UserInterface & {
  __typename?: 'User';
  accessToken: Maybe<Scalars['String']>;
  accessUntil: Maybe<Scalars['String']>;
  accountActivatedAt: Maybe<Scalars['ISO8601DateTime']>;
  address: Maybe<Scalars['String']>;
  assets: Maybe<Assets>;
  availableForTrial: Scalars['Boolean'];
  birthday: Maybe<Scalars['ISO8601Date']>;
  canUseTrustly: Maybe<Scalars['Boolean']>;
  challengeAccesses: Array<Maybe<ProgramAccess>>;
  challengeParticipations: Array<Maybe<ChallengeParticipant>>;
  city: Maybe<Scalars['String']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']>;
  country: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['String']>;
  courseAccesses: Array<Maybe<CourseAccess>>;
  dateOfBirth: Maybe<Scalars['Int']>;
  eligibleForDelete: Maybe<Scalars['Boolean']>;
  eligibleForStartDiscount: Maybe<Scalars['Boolean']>;
  eligibleForTrial: Maybe<Scalars['Boolean']>;
  email: Maybe<Scalars['String']>;
  emailLanguage: Maybe<Scalars['String']>;
  extraPayments: Maybe<Array<Maybe<ExtraPayment>>>;
  favorites: Maybe<Favorites>;
  finalFunnel: Maybe<FunnelKind>;
  firstName: Maybe<Scalars['String']>;
  funnel: Maybe<FunnelKind>;
  gender: Maybe<Scalars['String']>;
  group: Scalars['String'];
  hasWebPayments: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  impersonated: Maybe<Scalars['Boolean']>;
  inGracePeriod: Maybe<Scalars['Boolean']>;
  interests: Maybe<Array<Interest>>;
  isEmailActivated: Maybe<Scalars['Boolean']>;
  isPublic: Maybe<Scalars['Boolean']>;
  kind: Maybe<Scalars['String']>;
  lastAccessPaymentProvider: Maybe<PaymentProviderKind>;
  lastName: Maybe<Scalars['String']>;
  lastWellnessAccessProvider: Maybe<Scalars['String']>;
  liveEventsAccess: Maybe<Array<Maybe<LiveEventAccess>>>;
  locale: Maybe<Scalars['String']>;
  meditationSettings: Maybe<MeditationSettings>;
  nextBillingCycle: Maybe<NextBillingCycle>;
  onboardingCompleted: Scalars['Boolean'];
  onboardingInterestsSkipped: Scalars['Boolean'];
  onboardingSkipped: Scalars['Boolean'];
  onboardingTopicsSkipped: Scalars['Boolean'];
  paymentFailedAt: Maybe<Scalars['String']>;
  paymentType: Maybe<Scalars['String']>;
  paymentTypeRenews: Maybe<Scalars['Boolean']>;
  personNumber: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  playlists: Array<Maybe<Playlist>>;
  preferences: Maybe<Preferences>;
  preferredCurrency: Maybe<CurrencyKind>;
  previousEmail: Maybe<Scalars['String']>;
  privacySettings: Maybe<PrivacySettings>;
  profession: Maybe<Scalars['String']>;
  profile: Maybe<Profile>;
  programAccess: Array<Maybe<ProgramAccess>>;
  programs: Maybe<Array<Maybe<Page>>>;
  provider: Provider;
  receipts: Array<Maybe<Payment>>;
  referralCode: Maybe<Scalars['String']>;
  registrationDetail: RegistrationDetail;
  registrationSource: Maybe<RegistrationSourceKind>;
  signUpPlatform: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  statistics: Maybe<Statistics>;
  stories: Array<Story>;
  subscription: Subscription;
  topics: Maybe<Array<Topic>>;
  trial: Maybe<Trial>;
  trialPeriod: Maybe<Scalars['Int']>;
  trialPlanSelected: Maybe<Scalars['Boolean']>;
  trialRequired: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  videoNotes: Maybe<VideoNote>;
  zipcode: Maybe<Scalars['String']>;
};

export type UserAccess = {
  __typename?: 'UserAccess';
  accessUntil: Maybe<Scalars['ISO8601DateTime']>;
  accessable: Maybe<Accessable>;
  active: Scalars['Boolean'];
  archivedAt: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  joinedAt: Maybe<Scalars['ISO8601DateTime']>;
  user: User;
};

export enum UserDecisionKind {
  ProvaYogobe14 = 'ProvaYogobe14',
}

export type UserInterface = {
  accessToken: Maybe<Scalars['String']>;
  accessUntil: Maybe<Scalars['String']>;
  accountActivatedAt: Maybe<Scalars['ISO8601DateTime']>;
  address: Maybe<Scalars['String']>;
  assets: Maybe<Assets>;
  availableForTrial: Scalars['Boolean'];
  birthday: Maybe<Scalars['ISO8601Date']>;
  canUseTrustly: Maybe<Scalars['Boolean']>;
  challengeAccesses: Array<Maybe<ProgramAccess>>;
  challengeParticipations: Array<Maybe<ChallengeParticipant>>;
  city: Maybe<Scalars['String']>;
  completedAt: Maybe<Scalars['ISO8601DateTime']>;
  country: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['String']>;
  courseAccesses: Array<Maybe<CourseAccess>>;
  dateOfBirth: Maybe<Scalars['Int']>;
  eligibleForDelete: Maybe<Scalars['Boolean']>;
  eligibleForStartDiscount: Maybe<Scalars['Boolean']>;
  eligibleForTrial: Maybe<Scalars['Boolean']>;
  email: Maybe<Scalars['String']>;
  emailLanguage: Maybe<Scalars['String']>;
  extraPayments: Maybe<Array<Maybe<ExtraPayment>>>;
  favorites: Maybe<Favorites>;
  finalFunnel: Maybe<FunnelKind>;
  firstName: Maybe<Scalars['String']>;
  funnel: Maybe<FunnelKind>;
  gender: Maybe<Scalars['String']>;
  group: Scalars['String'];
  hasWebPayments: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  impersonated: Maybe<Scalars['Boolean']>;
  inGracePeriod: Maybe<Scalars['Boolean']>;
  interests: Maybe<Array<Interest>>;
  isEmailActivated: Maybe<Scalars['Boolean']>;
  isPublic: Maybe<Scalars['Boolean']>;
  kind: Maybe<Scalars['String']>;
  lastAccessPaymentProvider: Maybe<PaymentProviderKind>;
  lastName: Maybe<Scalars['String']>;
  lastWellnessAccessProvider: Maybe<Scalars['String']>;
  liveEventsAccess: Maybe<Array<Maybe<LiveEventAccess>>>;
  locale: Maybe<Scalars['String']>;
  meditationSettings: Maybe<MeditationSettings>;
  nextBillingCycle: Maybe<NextBillingCycle>;
  onboardingCompleted: Scalars['Boolean'];
  onboardingInterestsSkipped: Scalars['Boolean'];
  onboardingSkipped: Scalars['Boolean'];
  onboardingTopicsSkipped: Scalars['Boolean'];
  paymentFailedAt: Maybe<Scalars['String']>;
  paymentType: Maybe<Scalars['String']>;
  paymentTypeRenews: Maybe<Scalars['Boolean']>;
  personNumber: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  playlists: Array<Maybe<Playlist>>;
  preferences: Maybe<Preferences>;
  preferredCurrency: Maybe<CurrencyKind>;
  previousEmail: Maybe<Scalars['String']>;
  privacySettings: Maybe<PrivacySettings>;
  profession: Maybe<Scalars['String']>;
  profile: Maybe<Profile>;
  programAccess: Array<Maybe<ProgramAccess>>;
  programs: Maybe<Array<Maybe<Page>>>;
  provider: Provider;
  receipts: Array<Maybe<Payment>>;
  referralCode: Maybe<Scalars['String']>;
  registrationDetail: RegistrationDetail;
  registrationSource: Maybe<RegistrationSourceKind>;
  signUpPlatform: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  statistics: Maybe<Statistics>;
  stories: Array<Story>;
  subscription: Subscription;
  topics: Maybe<Array<Topic>>;
  trial: Maybe<Trial>;
  trialPeriod: Maybe<Scalars['Int']>;
  trialPlanSelected: Maybe<Scalars['Boolean']>;
  trialRequired: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
  videoNotes: Maybe<VideoNote>;
  zipcode: Maybe<Scalars['String']>;
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  accessUntil: Maybe<Scalars['ISO8601Date']>;
  /** Regular price of a basic plan or promotion */
  amount: Maybe<Scalars['Float']>;
  code: Maybe<Scalars['String']>;
  currency: Maybe<Scalars['String']>;
  /** Days to extend the access */
  days: Maybe<Scalars['Int']>;
  description: Maybe<Scalars['String']>;
  /** Will be present only for basic plan on which global discount is applied */
  discountedAmount: Maybe<Scalars['Float']>;
  /** true when promotion is added next to the basic plans */
  extraPromotion: Scalars['Boolean'];
  /** Months to extend the access */
  months: Maybe<Scalars['Int']>;
  platform: Scalars['String'];
  /** Product id defined in external store, i.e. appstore(Apple), playstore(Google), etc. */
  productId: Maybe<Scalars['String']>;
  promotionId: Maybe<Scalars['String']>;
  promotionKind: Maybe<PromotionKind>;
  /** Only for promotions. Returns promotion name */
  promotionName: Maybe<Scalars['String']>;
  /** If set to true, next and every other payment cycle will charge the discounted amount */
  recurringPromotion: Maybe<Scalars['Boolean']>;
  shortDescription: Maybe<Scalars['String']>;
  /** Gives unique value by which payment could be made. For basic plans and promotions */
  subscriptionName: Scalars['String'];
  /** Basic plan name, may be one of trial, monthly, quarterly, yearly, free_plan, notrial_monthly, notrial_quarterly, notrial_yearly, trial_monthly, trial_quarterly, trial_yearly, free_trial */
  subscriptionPlan: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ValidateBundleCode */
export type ValidateBundleCodePayload = {
  __typename?: 'ValidateBundleCodePayload';
  bundle: Maybe<Bundle>;
  code: Scalars['String'];
  codeStatus: CodeStatusKind;
  codeType: Maybe<CodeTypeKind>;
  error: Maybe<Scalars['String']>;
  price: Maybe<Price>;
};

export type ValidateCode = {
  __typename?: 'ValidateCode';
  code: Maybe<Scalars['String']>;
  codeDetails: Maybe<CodeDetails>;
  message: Maybe<Scalars['String']>;
  status: Maybe<CodeStatusKind>;
};

/** Autogenerated return type of Validate */
export type ValidatePayload = {
  __typename?: 'ValidatePayload';
  errors: Array<ProcessingError>;
  program: Maybe<GenericProgram>;
  status: Scalars['String'];
  url: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ValidateProgramCode */
export type ValidateProgramCodePayload = {
  __typename?: 'ValidateProgramCodePayload';
  code: Maybe<Scalars['String']>;
  codeSource: Maybe<CodeSourceKind>;
  codeStatus: Maybe<CodeStatusKind>;
  codeType: Maybe<CodeTypeKind>;
  error: Maybe<Scalars['String']>;
  price: Maybe<Price>;
  program: Maybe<GenericProgram>;
};

/** Autogenerated return type of ValidateToken */
export type ValidateTokenPayload = {
  __typename?: 'ValidateTokenPayload';
  errors: Maybe<Array<ProcessingError>>;
  status: Scalars['String'];
};

export type ValidateTransfer = {
  __typename?: 'ValidateTransfer';
  errors: Maybe<Scalars['String']>;
  valid: Maybe<Scalars['Boolean']>;
};

export type Video = VideoInterface & {
  __typename?: 'Video';
  assets: Maybe<VideoAssets>;
  categories: Array<Scalars['String']>;
  content: Maybe<VideoContent>;
  crossPromotion: Maybe<Array<GenericProgram>>;
  crossPromotionCourse: Maybe<Array<Scalars['String']>>;
  crossPromotionModel: Maybe<Scalars['String']>;
  cursorAt: Maybe<Scalars['Int']>;
  customThumbnailUrl: Maybe<Scalars['String']>;
  durationInSeconds: Maybe<Scalars['Int']>;
  embedDescription: Maybe<Scalars['String']>;
  favorited: Scalars['Boolean'];
  fileAssets: Array<VideoFileAsset>;
  freeAccess: Scalars['Boolean'];
  hasAccess: Scalars['Boolean'];
  hls: Scalars['String'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  inPlaylists: Array<Scalars['String']>;
  kind: Scalars['String'];
  language: LanguageKind;
  liveSessionActive: Maybe<Scalars['Boolean']>;
  longDescription: Scalars['String'];
  meta: Maybe<VideoMeta>;
  minutes: Scalars['Int'];
  pinnedTo: Scalars['String'];
  player: Maybe<VideoInfo>;
  primaryStyle: Maybe<Term>;
  protectionContinueTitle: Maybe<Scalars['String']>;
  protectionMessage: Maybe<Scalars['String']>;
  protectionTitle: Maybe<Scalars['String']>;
  publishedAt: Scalars['Int'];
  quickstartCategory: Scalars['String'];
  recommended: Maybe<Scalars['Boolean']>;
  removedText: Maybe<Scalars['String']>;
  shortDescription: Scalars['String'];
  taxonomies: Taxonomy;
  taxonomiesV2: Array<TaxonomyV2>;
  thumbnailUrl: Maybe<Scalars['String']>;
  thumbnailUrlMedium: Maybe<Scalars['String']>;
  title: Scalars['String'];
  twentythreeIframeUrl: Scalars['String'];
  userPlaylists: Maybe<Array<Scalars['ID']>>;
};

export type VideoAssets = {
  __typename?: 'VideoAssets';
  thumbnailUrl: Maybe<Scalars['String']>;
  thumbnailUrlMedium: Maybe<Scalars['String']>;
};

export type VideoContent = {
  __typename?: 'VideoContent';
  longDescription: Maybe<Scalars['String']>;
  shortDescription: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type VideoFileAsset = {
  __typename?: 'VideoFileAsset';
  resolution: Maybe<Scalars['Int']>;
  size: Maybe<Scalars['Int']>;
  src: Maybe<Scalars['String']>;
};

export type VideoInfo = {
  __typename?: 'VideoInfo';
  assets: Maybe<Array<Maybe<VideoFileAsset>>>;
  hasAccess: Maybe<Scalars['Boolean']>;
  twentyThree: Maybe<TwentyThree>;
};

export type VideoInterface = {
  assets: Maybe<VideoAssets>;
  categories: Array<Scalars['String']>;
  content: Maybe<VideoContent>;
  crossPromotion: Maybe<Array<GenericProgram>>;
  crossPromotionCourse: Maybe<Array<Scalars['String']>>;
  crossPromotionModel: Maybe<Scalars['String']>;
  cursorAt: Maybe<Scalars['Int']>;
  customThumbnailUrl: Maybe<Scalars['String']>;
  durationInSeconds: Maybe<Scalars['Int']>;
  embedDescription: Maybe<Scalars['String']>;
  favorited: Scalars['Boolean'];
  fileAssets: Array<VideoFileAsset>;
  freeAccess: Scalars['Boolean'];
  hasAccess: Scalars['Boolean'];
  hls: Scalars['String'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  inPlaylists: Array<Scalars['String']>;
  kind: Scalars['String'];
  language: LanguageKind;
  liveSessionActive: Maybe<Scalars['Boolean']>;
  longDescription: Scalars['String'];
  meta: Maybe<VideoMeta>;
  minutes: Scalars['Int'];
  pinnedTo: Scalars['String'];
  player: Maybe<VideoInfo>;
  primaryStyle: Maybe<Term>;
  protectionContinueTitle: Maybe<Scalars['String']>;
  protectionMessage: Maybe<Scalars['String']>;
  protectionTitle: Maybe<Scalars['String']>;
  publishedAt: Scalars['Int'];
  quickstartCategory: Scalars['String'];
  recommended: Maybe<Scalars['Boolean']>;
  removedText: Maybe<Scalars['String']>;
  shortDescription: Scalars['String'];
  taxonomies: Taxonomy;
  taxonomiesV2: Array<TaxonomyV2>;
  thumbnailUrl: Maybe<Scalars['String']>;
  thumbnailUrlMedium: Maybe<Scalars['String']>;
  title: Scalars['String'];
  twentythreeIframeUrl: Scalars['String'];
  userPlaylists: Maybe<Array<Scalars['ID']>>;
};

export enum VideoKind {
  Bonus = 'bonus',
  Exercise = 'exercise',
  Introduction = 'introduction',
  Lecture = 'lecture',
  Question = 'question',
  Skills = 'skills',
  Talk = 'talk',
  Workshop = 'workshop',
}

export enum VideoKindInput {
  Audio = 'audio',
  Video = 'video',
}

export type VideoMeta = {
  __typename?: 'VideoMeta';
  freeAccess: Maybe<Scalars['Boolean']>;
  language: Maybe<Scalars['String']>;
  minutes: Maybe<Scalars['Int']>;
  publishedAt: Maybe<Scalars['Int']>;
};

export type VideoNote = {
  __typename?: 'VideoNote';
  note: Maybe<Scalars['String']>;
  videoId: Maybe<Scalars['ID']>;
};

export enum VideoOrderBy {
  Alphabetical = 'alphabetical',
  Duration = 'duration',
  DurationAscending = 'duration_ascending',
  DurationDescending = 'duration_descending',
  Instructor = 'instructor',
  MostWatched = 'most_watched',
  NewestFirst = 'newest_first',
  None = 'none',
  PublishedDescending = 'published_descending',
}

export enum VideoSortAttribute {
  Alphabetical = 'alphabetical',
  Duration = 'duration',
  Instructor = 'instructor',
  NewestFirst = 'newest_first',
}

export enum VideoStatsOriginInput {
  Embed = 'embed',
  Yogobe = 'yogobe',
}

export type VideosCountByGenre = {
  __typename?: 'VideosCountByGenre';
  channel: Maybe<Scalars['String']>;
  count: Maybe<Scalars['Int']>;
  slug: Maybe<Scalars['String']>;
};

export type VideosLibrarySection = {
  __typename?: 'VideosLibrarySection';
  description: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  videosLibrary: Maybe<Array<PageSection>>;
};

export type WaitingItemInfo = {
  __typename?: 'WaitingItemInfo';
  /** @deprecated Field has been changed to datetime, use `start_time` instead. */
  startDate: Maybe<Scalars['ISO8601Date']>;
  startTime: Maybe<Scalars['ISO8601DateTime']>;
  waitingListEnabled: Maybe<Scalars['Boolean']>;
  waitingListEndDate: Maybe<Scalars['ISO8601Date']>;
  waitingListJoined: Maybe<Scalars['Boolean']>;
  waitingListStartDate: Maybe<Scalars['ISO8601Date']>;
};

/** Autogenerated return type of WatchCompleted */
export type WatchCompletedPayload = {
  __typename?: 'WatchCompletedPayload';
  errors: Maybe<Array<Error>>;
};

/** Autogenerated return type of Watch */
export type WatchPayload = {
  __typename?: 'WatchPayload';
  errors: Maybe<Array<Error>>;
};

export type WeeklyProgram = {
  __typename?: 'WeeklyProgram';
  currentElement: Maybe<CurrentElement>;
  description: Maybe<Scalars['String']>;
  finished: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  instructors: Maybe<Array<Maybe<Instructor>>>;
  intro: Maybe<SpecialSection>;
  outro: Maybe<SpecialSection>;
  progress: Maybe<Scalars['Float']>;
  recomendation: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  tags: Array<Scalars['String']>;
  videosCount: Maybe<Scalars['Int']>;
  weeks: Maybe<Array<Maybe<ProgramWeek>>>;
};

export type Wellness = {
  __typename?: 'Wellness';
  courses: PagedWellnessPackage;
  customPackage: Maybe<WellnessPackage>;
  id: Scalars['ID'];
  instructions: Maybe<Scalars['String']>;
  liveEventPackages: PagedWellnessPackage;
  liveEvents: PagedLiveEvents;
  logo: Maybe<Scalars['String']>;
  name: Scalars['String'];
  platformUrl: Maybe<Scalars['String']>;
  platformUrl2: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: Scalars['String'];
  subscriptionPlans: Array<UserSubscription>;
  wellnessAccesses: PagedWellnessPackage;
};

export type WellnessCoursesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type WellnessLiveEventPackagesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type WellnessLiveEventsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type WellnessWellnessAccessesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type WellnessAccess = {
  __typename?: 'WellnessAccess';
  periodUnit: WellnessPeriodUnitKind;
  periodValue: Scalars['Int'];
};

export type WellnessPackagable =
  | GenericProgram
  | LiveEvent
  | Promotion
  | WellnessAccess;

export type WellnessPackage = {
  __typename?: 'WellnessPackage';
  amountCents: Maybe<Scalars['Int']>;
  amountCurrency: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  discountedAmount: Maybe<Scalars['Int']>;
  featured: Scalars['Boolean'];
  id: Scalars['ID'];
  instructions: Maybe<Scalars['String']>;
  name: Scalars['String'];
  packageable: Maybe<WellnessPackagable>;
  priceBreakdown: Maybe<PriceBreakdown>;
  thumbnail: Maybe<Scalars['String']>;
};

export type WellnessPackagePayment = OrderPaymentInterface & {
  __typename?: 'WellnessPackagePayment';
  amountCents: Scalars['Int'];
  amountCurrency: CurrencyKind;
  failedReason: Maybe<Scalars['String']>;
  orderId: Scalars['String'];
  originalAmountCents: Maybe<Scalars['Int']>;
  paymentProvider: PaymentProviderKind;
  paymentProviderVariant: Maybe<PaymentProviderVariantKind>;
  sourcePage: Maybe<Scalars['String']>;
  state: PaymentStatusKind;
  status: Maybe<OrderStatus>;
  success: Scalars['Boolean'];
  wellnessPackage: WellnessPackage;
};

export enum WellnessPeriodUnitKind {
  Days = 'days',
  Months = 'months',
}

export enum WellnessProviderKind {
  Actiway = 'Actiway',
  Benefits = 'Benefits',
  Benify = 'Benify',
  Epassi = 'Epassi',
  Wellnet = 'Wellnet',
}

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  password: Scalars['String'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword:
    | {
        __typename?: 'ChangePasswordPayload';
        user: { __typename?: 'User'; id: string };
      }
    | null
    | undefined;
};

export type ChangeEmailMutationVariables = Exact<{
  email: InputMaybe<Scalars['String']>;
}>;

export type ChangeEmailMutation = {
  __typename?: 'Mutation';
  changeProfileInfo:
    | {
        __typename?: 'ChangeProfileInfoPayload';
        user:
          | { __typename?: 'CurrentUser'; id: string }
          | { __typename?: 'User'; id: string };
      }
    | null
    | undefined;
};

export type ChangeProfileInfoMutationVariables = Exact<{
  firstName: InputMaybe<Scalars['String']>;
  lastName: InputMaybe<Scalars['String']>;
  dateOfBirth: InputMaybe<Scalars['String']>;
  personNumber: InputMaybe<Scalars['String']>;
  address: InputMaybe<Scalars['String']>;
  zipcode: InputMaybe<Scalars['String']>;
  city: InputMaybe<Scalars['String']>;
  phone: InputMaybe<Scalars['String']>;
  profession: InputMaybe<Scalars['String']>;
}>;

export type ChangeProfileInfoMutation = {
  __typename?: 'Mutation';
  changeProfileInfo:
    | {
        __typename?: 'ChangeProfileInfoPayload';
        user:
          | { __typename?: 'CurrentUser'; id: string }
          | { __typename?: 'User'; id: string };
      }
    | null
    | undefined;
};

export type AuthenticateUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type AuthenticateUserMutation = {
  __typename?: 'Mutation';
  authenticateUser:
    | {
        __typename?: 'AuthenticateUserPayload';
        user:
          | {
              __typename?: 'CurrentUser';
              id: string;
              firstName: string | null | undefined;
              lastName: string | null | undefined;
              email: string | null | undefined;
            }
          | {
              __typename?: 'User';
              id: string;
              firstName: string | null | undefined;
              lastName: string | null | undefined;
              email: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AvailableOneTimeCodeQueryVariables = Exact<{
  code: InputMaybe<Scalars['String']>;
}>;

export type AvailableOneTimeCodeQuery = {
  __typename?: 'Query';
  availableOneTimeCode: {
    __typename?: 'AvailableSubscriptionPlan';
    isCodeValid: boolean | null | undefined;
    code: string | null | undefined;
    codeType: Code | null | undefined;
    validationMessage: string | null | undefined;
    oneTimeCodes:
      | Array<{
          __typename?: 'UserSubscription';
          title: string | null | undefined;
          description: string | null | undefined;
          shortDescription: string | null | undefined;
          amount: number | null | undefined;
          discountedAmount: number | null | undefined;
          currency: string | null | undefined;
          months: number | null | undefined;
          days: number | null | undefined;
          accessUntil: any | null | undefined;
          promotionId: string | null | undefined;
          subscriptionName: string;
          subscriptionPlan: string | null | undefined;
          promotionKind: PromotionKind | null | undefined;
          promotionName: string | null | undefined;
          recurringPromotion: boolean | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type PricingSubscriptionFieldsFragment = {
  __typename?: 'UserSubscription';
  title: string | null | undefined;
  description: string | null | undefined;
  shortDescription: string | null | undefined;
  amount: number | null | undefined;
  discountedAmount: number | null | undefined;
  currency: string | null | undefined;
  months: number | null | undefined;
  days: number | null | undefined;
  accessUntil: any | null | undefined;
  promotionId: string | null | undefined;
  subscriptionName: string;
  subscriptionPlan: string | null | undefined;
  promotionKind: PromotionKind | null | undefined;
  promotionName: string | null | undefined;
  recurringPromotion: boolean | null | undefined;
};

export type PricingSubscriptionPlansQueryVariables = Exact<{
  code: InputMaybe<Scalars['String']>;
}>;

export type PricingSubscriptionPlansQuery = {
  __typename?: 'Query';
  availableSubscriptionPlans: {
    __typename?: 'AvailableSubscriptionPlan';
    code: string | null | undefined;
    isCodeValid: boolean | null | undefined;
    subscriptions: Array<{
      __typename?: 'UserSubscription';
      title: string | null | undefined;
      description: string | null | undefined;
      shortDescription: string | null | undefined;
      amount: number | null | undefined;
      discountedAmount: number | null | undefined;
      currency: string | null | undefined;
      months: number | null | undefined;
      days: number | null | undefined;
      accessUntil: any | null | undefined;
      promotionId: string | null | undefined;
      subscriptionName: string;
      subscriptionPlan: string | null | undefined;
      promotionKind: PromotionKind | null | undefined;
      promotionName: string | null | undefined;
      recurringPromotion: boolean | null | undefined;
    }>;
  };
};

export type PricingAvailableSubscriptionPlansQueryVariables = Exact<{
  code: InputMaybe<Scalars['String']>;
}>;

export type PricingAvailableSubscriptionPlansQuery = {
  __typename?: 'Query';
  availableAllCodes: {
    __typename?: 'AvailableSubscriptionPlan';
    code: string | null | undefined;
    isCodeValid: boolean | null | undefined;
    subscriptions: Array<{
      __typename?: 'UserSubscription';
      title: string | null | undefined;
      description: string | null | undefined;
      shortDescription: string | null | undefined;
      amount: number | null | undefined;
      discountedAmount: number | null | undefined;
      currency: string | null | undefined;
      months: number | null | undefined;
      days: number | null | undefined;
      accessUntil: any | null | undefined;
      promotionId: string | null | undefined;
      subscriptionName: string;
      subscriptionPlan: string | null | undefined;
      promotionKind: PromotionKind | null | undefined;
      promotionName: string | null | undefined;
      recurringPromotion: boolean | null | undefined;
    }>;
    trialReferralCodes:
      | Array<{
          __typename?: 'UserSubscription';
          title: string | null | undefined;
          description: string | null | undefined;
          shortDescription: string | null | undefined;
          amount: number | null | undefined;
          discountedAmount: number | null | undefined;
          currency: string | null | undefined;
          months: number | null | undefined;
          days: number | null | undefined;
          accessUntil: any | null | undefined;
          promotionId: string | null | undefined;
          subscriptionName: string;
          subscriptionPlan: string | null | undefined;
          promotionKind: PromotionKind | null | undefined;
          promotionName: string | null | undefined;
          recurringPromotion: boolean | null | undefined;
        }>
      | null
      | undefined;
    trials:
      | Array<{
          __typename?: 'UserSubscription';
          title: string | null | undefined;
          description: string | null | undefined;
          shortDescription: string | null | undefined;
          amount: number | null | undefined;
          discountedAmount: number | null | undefined;
          currency: string | null | undefined;
          months: number | null | undefined;
          days: number | null | undefined;
          subscriptionName: string;
          subscriptionPlan: string | null | undefined;
          promotionName: string | null | undefined;
          recurringPromotion: boolean | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type FullAvailableSubscriptionPlansQueryVariables = Exact<{
  code: InputMaybe<Scalars['String']>;
}>;

export type FullAvailableSubscriptionPlansQuery = {
  __typename?: 'Query';
  availableSubscriptionPlans: {
    __typename?: 'AvailableSubscriptionPlan';
    code: string | null | undefined;
    codeType: Code | null | undefined;
    isCodeValid: boolean | null | undefined;
    validationMessage: string | null | undefined;
    subscriptions: Array<{
      __typename?: 'UserSubscription';
      title: string | null | undefined;
      description: string | null | undefined;
      shortDescription: string | null | undefined;
      amount: number | null | undefined;
      discountedAmount: number | null | undefined;
      currency: string | null | undefined;
      months: number | null | undefined;
      days: number | null | undefined;
      accessUntil: any | null | undefined;
      promotionId: string | null | undefined;
      subscriptionName: string;
      subscriptionPlan: string | null | undefined;
      promotionKind: PromotionKind | null | undefined;
      promotionName: string | null | undefined;
      recurringPromotion: boolean | null | undefined;
    }>;
    fullAccesses:
      | Array<{
          __typename?: 'UserSubscription';
          title: string | null | undefined;
          description: string | null | undefined;
          shortDescription: string | null | undefined;
          amount: number | null | undefined;
          discountedAmount: number | null | undefined;
          currency: string | null | undefined;
          months: number | null | undefined;
          days: number | null | undefined;
          accessUntil: any | null | undefined;
          promotionId: string | null | undefined;
          subscriptionName: string;
          subscriptionPlan: string | null | undefined;
          promotionKind: PromotionKind | null | undefined;
          promotionName: string | null | undefined;
          recurringPromotion: boolean | null | undefined;
        }>
      | null
      | undefined;
    oneTimeCodes:
      | Array<{
          __typename?: 'UserSubscription';
          title: string | null | undefined;
          description: string | null | undefined;
          shortDescription: string | null | undefined;
          amount: number | null | undefined;
          discountedAmount: number | null | undefined;
          currency: string | null | undefined;
          months: number | null | undefined;
          days: number | null | undefined;
          accessUntil: any | null | undefined;
          promotionId: string | null | undefined;
          subscriptionName: string;
          subscriptionPlan: string | null | undefined;
          promotionKind: PromotionKind | null | undefined;
          promotionName: string | null | undefined;
          recurringPromotion: boolean | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type AvailableTrialExtensionsQueryVariables = Exact<{
  code: InputMaybe<Scalars['String']>;
}>;

export type AvailableTrialExtensionsQuery = {
  __typename?: 'Query';
  availableTrialExtensions: {
    __typename?: 'AvailableSubscriptionPlan';
    code: string | null | undefined;
    codeType: Code | null | undefined;
    isCodeValid: boolean | null | undefined;
    validationMessage: string | null | undefined;
    trials:
      | Array<{
          __typename?: 'UserSubscription';
          months: number | null | undefined;
          days: number | null | undefined;
          subscriptionPlan: string | null | undefined;
          promotionKind: PromotionKind | null | undefined;
          promotionName: string | null | undefined;
        }>
      | null
      | undefined;
    trialReferralCodes:
      | Array<{
          __typename?: 'UserSubscription';
          months: number | null | undefined;
          days: number | null | undefined;
          subscriptionPlan: string | null | undefined;
          promotionKind: PromotionKind | null | undefined;
          promotionName: string | null | undefined;
        }>
      | null
      | undefined;
    oneTimeCodes:
      | Array<{
          __typename?: 'UserSubscription';
          accessUntil: any | null | undefined;
          months: number | null | undefined;
          days: number | null | undefined;
          subscriptionPlan: string | null | undefined;
          promotionKind: PromotionKind | null | undefined;
          promotionName: string | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type ActivateTrialExtensionMutationVariables = Exact<{
  promotionName: Scalars['String'];
  code: Scalars['String'];
}>;

export type ActivateTrialExtensionMutation = {
  __typename?: 'Mutation';
  activateTrialExtension:
    | {
        __typename?: 'ActivateTrialExtensionPayload';
        success: boolean | null | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BlogPostCardFieldsFragment = {
  __typename?: 'BlogPost';
  id: string;
  slug: string;
  tags: Array<string | null | undefined>;
  title: string;
  body: string;
  author: string | null | undefined;
  categories: Array<string>;
  featuredImage: string;
  language: string;
  market: Array<string>;
  publishedAt: any;
};

export type BlogPostsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
}>;

export type BlogPostsQuery = {
  __typename?: 'Query';
  posts:
    | {
        __typename?: 'PagedBlogPosts';
        page: number;
        perPage: number;
        totalCount: number;
        data: Array<{
          __typename?: 'BlogPost';
          id: string;
          slug: string;
          tags: Array<string | null | undefined>;
          title: string;
          body: string;
          author: string | null | undefined;
          categories: Array<string>;
          featuredImage: string;
          language: string;
          market: Array<string>;
          publishedAt: any;
        }>;
      }
    | null
    | undefined;
};

export type BlogPostQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type BlogPostQuery = {
  __typename?: 'Query';
  blogPost:
    | {
        __typename?: 'BlogPost';
        author: string | null | undefined;
        authors: Array<string>;
        body: string;
        categories: Array<string>;
        collection: string | null | undefined;
        facebookImage: string | null | undefined;
        featuredImage: string;
        id: string;
        keywords2: Array<string> | null | undefined;
        language: string;
        market: Array<string>;
        metaDescription: string | null | undefined;
        metaTitle: string | null | undefined;
        processedBody: string | null | undefined;
        showPricingBanner: boolean;
        publishedAt: any;
        republishedAt: any | null | undefined;
        slug: string;
        tags: Array<string | null | undefined>;
        title: string;
        instructors: Array<{
          __typename?: 'Instructor';
          id: string;
          slug: string | null | undefined;
          name: string | null | undefined;
          description: string | null | undefined;
          blogDescription: string | null | undefined;
          assets:
            | {
                __typename?: 'InstructorAssets';
                squareImage: string | null | undefined;
              }
            | null
            | undefined;
          instructorPage: {
            __typename?: 'InstructorPage';
            description: string | null | undefined;
          };
        }>;
      }
    | null
    | undefined;
};

export type BlogInstructorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BlogInstructorQuery = {
  __typename?: 'Query';
  instructor:
    | {
        __typename?: 'Instructor';
        id: string;
        slug: string | null | undefined;
        name: string | null | undefined;
        speciality: string | null | undefined;
        location: string | null | undefined;
        webpage: string | null | undefined;
        blogDescription: string | null | undefined;
        shortDescription: string | null | undefined;
        assets:
          | {
              __typename?: 'InstructorAssets';
              squareImage: string | null | undefined;
            }
          | null
          | undefined;
        instructorPage: {
          __typename?: 'InstructorPage';
          description: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type BlogPostCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type BlogPostCategoriesQuery = {
  __typename?: 'Query';
  blogPostCategories:
    | Array<{ __typename?: 'BlogPostCategory'; name: string; slug: string }>
    | null
    | undefined;
};

export type BundleEssentialFieldsFragment = {
  __typename?: 'Bundle';
  id: string;
  title: string;
  slug: string;
  heroImage: string | null | undefined;
  cardImage: string | null | undefined;
  heroMobileImage: string | null | undefined;
  active: boolean;
  kind: BundleKind;
  accessStrategy: BundleAccessStrategyKind;
  isUserEligible: boolean;
  bundleCategory: BundleCategoryKind | null | undefined;
  specialOfferCode: string | null | undefined;
  accessPeriodUnit: string | null | undefined;
  accessPeriodValue: number | null | undefined;
  hiddenInListing: boolean;
  metaDescription: string;
  metaImage: string | null | undefined;
  publishedAt: any | null | undefined;
  shortDescription: string;
  promotionalImage: string | null | undefined;
  tags: Array<string | null | undefined>;
  price:
    | {
        __typename?: 'Price';
        currency: string | null | undefined;
        discountApplied: boolean;
        discountedPrice: number | null | undefined;
        originalPrice: number | null | undefined;
      }
    | null
    | undefined;
  subscriptionPromotion:
    | {
        __typename?: 'UserSubscription';
        code: string | null | undefined;
        title: string | null | undefined;
        description: string | null | undefined;
        shortDescription: string | null | undefined;
        amount: number | null | undefined;
        discountedAmount: number | null | undefined;
        currency: string | null | undefined;
        months: number | null | undefined;
        days: number | null | undefined;
        accessUntil: any | null | undefined;
        promotionId: string | null | undefined;
        subscriptionName: string;
        subscriptionPlan: string | null | undefined;
        promotionKind: PromotionKind | null | undefined;
        promotionName: string | null | undefined;
        recurringPromotion: boolean | null | undefined;
      }
    | null
    | undefined;
  userAccess:
    | {
        __typename?: 'UserAccess';
        active: boolean;
        joinedAt: any | null | undefined;
        accessUntil: any | null | undefined;
      }
    | null
    | undefined;
  waitingItemInfo: {
    __typename?: 'WaitingItemInfo';
    startDate: any | null | undefined;
    waitingListJoined: boolean | null | undefined;
    waitingListEnabled: boolean | null | undefined;
    waitingListEndDate: any | null | undefined;
    waitingListStartDate: any | null | undefined;
  };
  client:
    | {
        __typename?: 'Client';
        id: string;
        logo: string | null | undefined;
        name: string;
        slug: string;
        info: string | null | undefined;
      }
    | null
    | undefined;
};

export type BlogPostFieldsFragment = {
  __typename?: 'BlogPost';
  id: string;
  slug: string;
  tags: Array<string | null | undefined>;
  title: string;
  body: string;
  authors: Array<string>;
  categories: Array<string>;
  featuredImage: string;
  language: string;
  market: Array<string>;
  publishedAt: any;
};

export type BasicBundleQueryVariables = Exact<{
  slug: Scalars['String'];
  client: Scalars['String'];
}>;

export type BasicBundleQuery = {
  __typename?: 'Query';
  basicBundle:
    | {
        __typename?: 'Bundle';
        id: string;
        title: string;
        slug: string;
        heroImage: string | null | undefined;
        cardImage: string | null | undefined;
        heroMobileImage: string | null | undefined;
        active: boolean;
        kind: BundleKind;
        accessStrategy: BundleAccessStrategyKind;
        isUserEligible: boolean;
        bundleCategory: BundleCategoryKind | null | undefined;
        specialOfferCode: string | null | undefined;
        accessPeriodUnit: string | null | undefined;
        accessPeriodValue: number | null | undefined;
        hiddenInListing: boolean;
        metaDescription: string;
        metaImage: string | null | undefined;
        publishedAt: any | null | undefined;
        shortDescription: string;
        promotionalImage: string | null | undefined;
        tags: Array<string | null | undefined>;
        price:
          | {
              __typename?: 'Price';
              currency: string | null | undefined;
              discountApplied: boolean;
              discountedPrice: number | null | undefined;
              originalPrice: number | null | undefined;
            }
          | null
          | undefined;
        subscriptionPromotion:
          | {
              __typename?: 'UserSubscription';
              code: string | null | undefined;
              title: string | null | undefined;
              description: string | null | undefined;
              shortDescription: string | null | undefined;
              amount: number | null | undefined;
              discountedAmount: number | null | undefined;
              currency: string | null | undefined;
              months: number | null | undefined;
              days: number | null | undefined;
              accessUntil: any | null | undefined;
              promotionId: string | null | undefined;
              subscriptionName: string;
              subscriptionPlan: string | null | undefined;
              promotionKind: PromotionKind | null | undefined;
              promotionName: string | null | undefined;
              recurringPromotion: boolean | null | undefined;
            }
          | null
          | undefined;
        userAccess:
          | {
              __typename?: 'UserAccess';
              active: boolean;
              joinedAt: any | null | undefined;
              accessUntil: any | null | undefined;
            }
          | null
          | undefined;
        waitingItemInfo: {
          __typename?: 'WaitingItemInfo';
          startDate: any | null | undefined;
          waitingListJoined: boolean | null | undefined;
          waitingListEnabled: boolean | null | undefined;
          waitingListEndDate: any | null | undefined;
          waitingListStartDate: any | null | undefined;
        };
        client:
          | {
              __typename?: 'Client';
              id: string;
              logo: string | null | undefined;
              name: string;
              slug: string;
              info: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type WaitingListBundlesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type WaitingListBundlesQuery = {
  __typename?: 'Query';
  waitingListBundles:
    | {
        __typename?: 'PagedBundles';
        page: number;
        perPage: number;
        totalCount: number;
        data: Array<{
          __typename?: 'Bundle';
          id: string;
          title: string;
          slug: string;
          heroImage: string | null | undefined;
          cardImage: string | null | undefined;
          heroMobileImage: string | null | undefined;
          active: boolean;
          kind: BundleKind;
          accessStrategy: BundleAccessStrategyKind;
          isUserEligible: boolean;
          bundleCategory: BundleCategoryKind | null | undefined;
          specialOfferCode: string | null | undefined;
          accessPeriodUnit: string | null | undefined;
          accessPeriodValue: number | null | undefined;
          hiddenInListing: boolean;
          metaDescription: string;
          metaImage: string | null | undefined;
          publishedAt: any | null | undefined;
          shortDescription: string;
          promotionalImage: string | null | undefined;
          tags: Array<string | null | undefined>;
          price:
            | {
                __typename?: 'Price';
                currency: string | null | undefined;
                discountApplied: boolean;
                discountedPrice: number | null | undefined;
                originalPrice: number | null | undefined;
              }
            | null
            | undefined;
          subscriptionPromotion:
            | {
                __typename?: 'UserSubscription';
                code: string | null | undefined;
                title: string | null | undefined;
                description: string | null | undefined;
                shortDescription: string | null | undefined;
                amount: number | null | undefined;
                discountedAmount: number | null | undefined;
                currency: string | null | undefined;
                months: number | null | undefined;
                days: number | null | undefined;
                accessUntil: any | null | undefined;
                promotionId: string | null | undefined;
                subscriptionName: string;
                subscriptionPlan: string | null | undefined;
                promotionKind: PromotionKind | null | undefined;
                promotionName: string | null | undefined;
                recurringPromotion: boolean | null | undefined;
              }
            | null
            | undefined;
          userAccess:
            | {
                __typename?: 'UserAccess';
                active: boolean;
                joinedAt: any | null | undefined;
                accessUntil: any | null | undefined;
              }
            | null
            | undefined;
          waitingItemInfo: {
            __typename?: 'WaitingItemInfo';
            startDate: any | null | undefined;
            waitingListJoined: boolean | null | undefined;
            waitingListEnabled: boolean | null | undefined;
            waitingListEndDate: any | null | undefined;
            waitingListStartDate: any | null | undefined;
          };
          client:
            | {
                __typename?: 'Client';
                id: string;
                logo: string | null | undefined;
                name: string;
                slug: string;
                info: string | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type BundleQueryVariables = Exact<{
  slug: Scalars['String'];
  client: Scalars['String'];
  previewParameter?: InputMaybe<Scalars['String']>;
}>;

export type BundleQuery = {
  __typename?: 'Query';
  bundle:
    | {
        __typename?: 'Bundle';
        hideInfoText: boolean;
        sections: Array<BundleSectionKind>;
        id: string;
        title: string;
        slug: string;
        heroImage: string | null | undefined;
        cardImage: string | null | undefined;
        heroMobileImage: string | null | undefined;
        active: boolean;
        kind: BundleKind;
        accessStrategy: BundleAccessStrategyKind;
        isUserEligible: boolean;
        bundleCategory: BundleCategoryKind | null | undefined;
        specialOfferCode: string | null | undefined;
        accessPeriodUnit: string | null | undefined;
        accessPeriodValue: number | null | undefined;
        hiddenInListing: boolean;
        metaDescription: string;
        metaImage: string | null | undefined;
        publishedAt: any | null | undefined;
        shortDescription: string;
        promotionalImage: string | null | undefined;
        tags: Array<string | null | undefined>;
        preview:
          | {
              __typename?: 'BundlePreview';
              description: string | null | undefined;
              label: string | null | undefined;
              title: string | null | undefined;
              video:
                | {
                    __typename?: 'Video';
                    hls: string;
                    id: string;
                    identifier: string;
                    kind: string;
                    cursorAt: number | null | undefined;
                    removedText: string | null | undefined;
                    player:
                      | {
                          __typename?: 'VideoInfo';
                          assets:
                            | Array<
                                | {
                                    __typename?: 'VideoFileAsset';
                                    resolution: number | null | undefined;
                                    src: string | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    content:
                      | {
                          __typename?: 'VideoContent';
                          title: string | null | undefined;
                          shortDescription: string | null | undefined;
                          longDescription: string | null | undefined;
                        }
                      | null
                      | undefined;
                    meta:
                      | {
                          __typename?: 'VideoMeta';
                          minutes: number | null | undefined;
                          publishedAt: number | null | undefined;
                        }
                      | null
                      | undefined;
                    primaryStyle:
                      | {
                          __typename?: 'Term';
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined;
                    taxonomies: {
                      __typename?: 'Taxonomy';
                      flavor:
                        | {
                            __typename?: 'Term';
                            id: string;
                            name: string | null | undefined;
                          }
                        | null
                        | undefined;
                      needs:
                        | Array<
                            | {
                                __typename?: 'Term';
                                id: string;
                                name: string | null | undefined;
                                slug: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      instructors:
                        | Array<
                            | {
                                __typename?: 'Instructor';
                                id: string;
                                name: string | null | undefined;
                                slug: string | null | undefined;
                                assets:
                                  | {
                                      __typename?: 'InstructorAssets';
                                      externalImage: string | null | undefined;
                                      squareImage: string | null | undefined;
                                      titleImage: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      genre:
                        | {
                            __typename?: 'Term';
                            id: string;
                            name: string | null | undefined;
                            slug: string | null | undefined;
                          }
                        | null
                        | undefined;
                    };
                    assets:
                      | {
                          __typename?: 'VideoAssets';
                          thumbnailUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        trailer:
          | {
              __typename?: 'Video';
              identifier: string;
              minutes: number;
              thumbnailUrl: string | null | undefined;
              hls: string;
              player:
                | {
                    __typename?: 'VideoInfo';
                    assets:
                      | Array<
                          | {
                              __typename?: 'VideoFileAsset';
                              resolution: number | null | undefined;
                              src: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        infoPanel:
          | {
              __typename?: 'BundleInfoPanel';
              title: string | null | undefined;
              description: string | null | undefined;
              image: string | null | undefined;
              buttonContent: string | null | undefined;
              buttonLink: string | null | undefined;
            }
          | null
          | undefined;
        infoPanel2:
          | {
              __typename?: 'BundleInfoPanel2';
              title: string | null | undefined;
              image: string | null | undefined;
              longText: string | null | undefined;
              previewText: string | null | undefined;
              buttonContent: string | null | undefined;
              buttonLink: string | null | undefined;
            }
          | null
          | undefined;
        infoPanel3:
          | {
              __typename?: 'BundleInfoPanel';
              title: string | null | undefined;
              description: string | null | undefined;
              image: string | null | undefined;
              buttonContent: string | null | undefined;
              buttonLink: string | null | undefined;
            }
          | null
          | undefined;
        liveBannerSection:
          | {
              __typename?: 'BundleLiveBannerSection';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              image: string | null | undefined;
              buttonLink: string | null | undefined;
              buttonName: string | null | undefined;
            }
          | null
          | undefined;
        twoColumnsSection:
          | {
              __typename?: 'BundleTwoColumnsSection';
              title: string | null | undefined;
              subtitle: string | null | undefined;
              leftContent: string | null | undefined;
              rightContent: string | null | undefined;
            }
          | null
          | undefined;
        promotionSection:
          | {
              __typename?: 'BundlePromotionSection';
              title: string | null | undefined;
              content: string | null | undefined;
              buttonContent: string | null | undefined;
              buttonLink: string | null | undefined;
              image: string | null | undefined;
            }
          | null
          | undefined;
        footerCta: {
          __typename?: 'BundleFooterCta';
          description: string | null | undefined;
          image: string | null | undefined;
          title: string | null | undefined;
        };
        bundleItems: {
          __typename: 'BundleItem';
          sectionedVideos: Array<{
            __typename?: 'BundleSection';
            headline: string | null | undefined;
            viewAllUrl: string | null | undefined;
            items: Array<
              | { __typename?: 'BlogPost' }
              | { __typename?: 'Document' }
              | { __typename?: 'GenericProgram' }
              | { __typename?: 'LiveEvent' }
              | { __typename?: 'Playlist' }
              | {
                  __typename?: 'Video';
                  id: string;
                  identifier: string;
                  kind: string;
                  cursorAt: number | null | undefined;
                  removedText: string | null | undefined;
                  content:
                    | {
                        __typename?: 'VideoContent';
                        title: string | null | undefined;
                        shortDescription: string | null | undefined;
                        longDescription: string | null | undefined;
                      }
                    | null
                    | undefined;
                  meta:
                    | {
                        __typename?: 'VideoMeta';
                        minutes: number | null | undefined;
                        publishedAt: number | null | undefined;
                      }
                    | null
                    | undefined;
                  primaryStyle:
                    | {
                        __typename?: 'Term';
                        name: string | null | undefined;
                        slug: string | null | undefined;
                      }
                    | null
                    | undefined;
                  taxonomies: {
                    __typename?: 'Taxonomy';
                    flavor:
                      | {
                          __typename?: 'Term';
                          id: string;
                          name: string | null | undefined;
                        }
                      | null
                      | undefined;
                    needs:
                      | Array<
                          | {
                              __typename?: 'Term';
                              id: string;
                              name: string | null | undefined;
                              slug: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    instructors:
                      | Array<
                          | {
                              __typename?: 'Instructor';
                              id: string;
                              name: string | null | undefined;
                              slug: string | null | undefined;
                              assets:
                                | {
                                    __typename?: 'InstructorAssets';
                                    externalImage: string | null | undefined;
                                    squareImage: string | null | undefined;
                                    titleImage: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    genre:
                      | {
                          __typename?: 'Term';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined;
                  };
                  assets:
                    | {
                        __typename?: 'VideoAssets';
                        thumbnailUrl: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
            >;
          }>;
          challenges: {
            __typename?: 'BundleSection';
            headline: string | null | undefined;
            viewAllUrl: string | null | undefined;
            items: Array<
              | { __typename?: 'BlogPost' }
              | { __typename?: 'Document' }
              | {
                  __typename?: 'GenericProgram';
                  id: string;
                  title: string | null | undefined;
                  slug: string;
                  promotionalImageUrl: string | null | undefined;
                  category: ProgramCategory;
                  priceType: PriceTypeKind | null | undefined;
                  kind: ProgramKind;
                  language: LanguageKind | null | undefined;
                  titleImageUrl: string | null | undefined;
                  shortDescription: string | null | undefined;
                  description: string | null | undefined;
                  accessStatus: ProgramAccessStatus;
                  themesV2:
                    | Array<{
                        __typename?: 'Theme';
                        label: string;
                        slug: string;
                      }>
                    | null
                    | undefined;
                  price:
                    | {
                        __typename?: 'Price';
                        originalPrice: number | null | undefined;
                        discountedPrice: number | null | undefined;
                        discountApplied: boolean;
                        currency: string | null | undefined;
                      }
                    | null
                    | undefined;
                  accessPeriod: {
                    __typename?: 'AccessPeriod';
                    unit: AccessPeriodUnitKind | null | undefined;
                    value: number | null | undefined;
                  };
                  duration:
                    | {
                        __typename?: 'Duration';
                        unit: DurationUnitKind | null | undefined;
                        value: number | null | undefined;
                      }
                    | null
                    | undefined;
                  instructors:
                    | Array<
                        | {
                            __typename?: 'Instructor';
                            id: string;
                            name: string | null | undefined;
                            slug: string | null | undefined;
                            assets:
                              | {
                                  __typename?: 'InstructorAssets';
                                  squareImage: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                  programAccess:
                    | {
                        __typename?: 'ProgramAccess';
                        accessStatus: ProgramAccessStatus;
                        accessUntil: any | null | undefined;
                        joinedAt: any | null | undefined;
                        progressPercentage: number | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | { __typename?: 'LiveEvent' }
              | { __typename?: 'Playlist' }
              | { __typename?: 'Video' }
            >;
          };
          programs: {
            __typename?: 'BundleSection';
            headline: string | null | undefined;
            viewAllUrl: string | null | undefined;
            items: Array<
              | { __typename?: 'BlogPost' }
              | { __typename?: 'Document' }
              | {
                  __typename?: 'GenericProgram';
                  id: string;
                  title: string | null | undefined;
                  slug: string;
                  promotionalImageUrl: string | null | undefined;
                  category: ProgramCategory;
                  priceType: PriceTypeKind | null | undefined;
                  kind: ProgramKind;
                  language: LanguageKind | null | undefined;
                  titleImageUrl: string | null | undefined;
                  shortDescription: string | null | undefined;
                  description: string | null | undefined;
                  accessStatus: ProgramAccessStatus;
                  themesV2:
                    | Array<{
                        __typename?: 'Theme';
                        label: string;
                        slug: string;
                      }>
                    | null
                    | undefined;
                  price:
                    | {
                        __typename?: 'Price';
                        originalPrice: number | null | undefined;
                        discountedPrice: number | null | undefined;
                        discountApplied: boolean;
                        currency: string | null | undefined;
                      }
                    | null
                    | undefined;
                  accessPeriod: {
                    __typename?: 'AccessPeriod';
                    unit: AccessPeriodUnitKind | null | undefined;
                    value: number | null | undefined;
                  };
                  duration:
                    | {
                        __typename?: 'Duration';
                        unit: DurationUnitKind | null | undefined;
                        value: number | null | undefined;
                      }
                    | null
                    | undefined;
                  instructors:
                    | Array<
                        | {
                            __typename?: 'Instructor';
                            id: string;
                            name: string | null | undefined;
                            slug: string | null | undefined;
                            assets:
                              | {
                                  __typename?: 'InstructorAssets';
                                  squareImage: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                  programAccess:
                    | {
                        __typename?: 'ProgramAccess';
                        accessStatus: ProgramAccessStatus;
                        accessUntil: any | null | undefined;
                        joinedAt: any | null | undefined;
                        progressPercentage: number | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | { __typename?: 'LiveEvent' }
              | { __typename?: 'Playlist' }
              | { __typename?: 'Video' }
            >;
          };
          playlists: {
            __typename?: 'BundleSection';
            headline: string | null | undefined;
            viewAllUrl: string | null | undefined;
            items: Array<
              | { __typename?: 'BlogPost' }
              | { __typename?: 'Document' }
              | { __typename?: 'GenericProgram' }
              | { __typename?: 'LiveEvent' }
              | {
                  __typename?: 'Playlist';
                  id: string;
                  identifier: string | null | undefined;
                  visibility: string | null | undefined;
                  title: string;
                  description: string | null | undefined;
                  owner:
                    | {
                        __typename?: 'CurrentUser';
                        id: string;
                        firstName: string | null | undefined;
                      }
                    | {
                        __typename?: 'User';
                        id: string;
                        firstName: string | null | undefined;
                      }
                    | null
                    | undefined;
                  meta:
                    | {
                        __typename?: 'Meta';
                        videoCount: number | null | undefined;
                        minutes: number | null | undefined;
                      }
                    | null
                    | undefined;
                  assets:
                    | {
                        __typename?: 'PlaylistAssets';
                        thumbnailUrl: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | { __typename?: 'Video' }
            >;
          };
          liveEvents: {
            __typename?: 'BundleSection';
            headline: string | null | undefined;
            viewAllUrl: string | null | undefined;
            items: Array<
              | { __typename?: 'BlogPost' }
              | { __typename?: 'Document' }
              | { __typename?: 'GenericProgram' }
              | {
                  __typename?: 'LiveEvent';
                  id: string;
                  title: string;
                  slug: string;
                  thumbnail: string;
                  duration: number;
                  eventDates: Array<any>;
                  kind: LiveEventKind;
                  language: LanguageKind;
                  level: LiveEventLevel;
                  theme: string | null | undefined;
                  themeLabel: string | null | undefined;
                  availableSpots: number;
                  maximumParticipants: number;
                  bookingAvailable: boolean;
                  paymentKind: LiveEventPaymentKind;
                  flexibleMaxAmountCents: number | null | undefined;
                  flexibleMinAmountCents: number | null | undefined;
                  customDiscount: number | null | undefined;
                  isUserEligible: boolean | null | undefined;
                  descriptionLong: string;
                  registrationEndDate: any;
                  registrationStartDate: any;
                  isClosed: boolean | null | undefined;
                  isExpired: boolean | null | undefined;
                  isComingSoon: boolean | null | undefined;
                  price:
                    | {
                        __typename?: 'Price';
                        originalPrice: number | null | undefined;
                        discountApplied: boolean;
                        discountedPrice: number | null | undefined;
                        currency: string | null | undefined;
                      }
                    | null
                    | undefined;
                  primaryInstructor:
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  instructors:
                    | Array<
                        | {
                            __typename?: 'Instructor';
                            id: string;
                            name: string | null | undefined;
                            slug: string | null | undefined;
                            assets:
                              | {
                                  __typename?: 'InstructorAssets';
                                  squareImage: string | null | undefined;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                  userAccess:
                    | {
                        __typename?: 'LiveEventAccess';
                        zoomLink: string;
                        zoomPassword: string;
                      }
                    | null
                    | undefined;
                }
              | { __typename?: 'Playlist' }
              | { __typename?: 'Video' }
            >;
          };
          blogPosts: {
            __typename?: 'BundleSection';
            headline: string | null | undefined;
            viewAllUrl: string | null | undefined;
            items: Array<
              | {
                  __typename?: 'BlogPost';
                  id: string;
                  slug: string;
                  tags: Array<string | null | undefined>;
                  title: string;
                  body: string;
                  authors: Array<string>;
                  categories: Array<string>;
                  featuredImage: string;
                  language: string;
                  market: Array<string>;
                  publishedAt: any;
                }
              | { __typename?: 'Document' }
              | { __typename?: 'GenericProgram' }
              | { __typename?: 'LiveEvent' }
              | { __typename?: 'Playlist' }
              | { __typename?: 'Video' }
            >;
          };
          documents: {
            __typename?: 'BundleSection';
            headline: string | null | undefined;
            viewAllUrl: string | null | undefined;
            items: Array<
              | { __typename?: 'BlogPost' }
              | {
                  __typename?: 'Document';
                  contentType: string | null | undefined;
                  id: string;
                  name: string;
                  url: string | null | undefined;
                }
              | { __typename?: 'GenericProgram' }
              | { __typename?: 'LiveEvent' }
              | { __typename?: 'Playlist' }
              | { __typename?: 'Video' }
            >;
          };
        };
        relatedBundlesSection:
          | {
              __typename?: 'BundleRelatedBundlesSection';
              headline: string | null | undefined;
              bundles:
                | Array<{
                    __typename?: 'Bundle';
                    id: string;
                    title: string;
                    slug: string;
                    heroImage: string | null | undefined;
                    cardImage: string | null | undefined;
                    heroMobileImage: string | null | undefined;
                    active: boolean;
                    kind: BundleKind;
                    accessStrategy: BundleAccessStrategyKind;
                    isUserEligible: boolean;
                    bundleCategory: BundleCategoryKind | null | undefined;
                    specialOfferCode: string | null | undefined;
                    accessPeriodUnit: string | null | undefined;
                    accessPeriodValue: number | null | undefined;
                    hiddenInListing: boolean;
                    metaDescription: string;
                    metaImage: string | null | undefined;
                    publishedAt: any | null | undefined;
                    shortDescription: string;
                    promotionalImage: string | null | undefined;
                    tags: Array<string | null | undefined>;
                    price:
                      | {
                          __typename?: 'Price';
                          currency: string | null | undefined;
                          discountApplied: boolean;
                          discountedPrice: number | null | undefined;
                          originalPrice: number | null | undefined;
                        }
                      | null
                      | undefined;
                    subscriptionPromotion:
                      | {
                          __typename?: 'UserSubscription';
                          code: string | null | undefined;
                          title: string | null | undefined;
                          description: string | null | undefined;
                          shortDescription: string | null | undefined;
                          amount: number | null | undefined;
                          discountedAmount: number | null | undefined;
                          currency: string | null | undefined;
                          months: number | null | undefined;
                          days: number | null | undefined;
                          accessUntil: any | null | undefined;
                          promotionId: string | null | undefined;
                          subscriptionName: string;
                          subscriptionPlan: string | null | undefined;
                          promotionKind: PromotionKind | null | undefined;
                          promotionName: string | null | undefined;
                          recurringPromotion: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    userAccess:
                      | {
                          __typename?: 'UserAccess';
                          active: boolean;
                          joinedAt: any | null | undefined;
                          accessUntil: any | null | undefined;
                        }
                      | null
                      | undefined;
                    waitingItemInfo: {
                      __typename?: 'WaitingItemInfo';
                      startDate: any | null | undefined;
                      waitingListJoined: boolean | null | undefined;
                      waitingListEnabled: boolean | null | undefined;
                      waitingListEndDate: any | null | undefined;
                      waitingListStartDate: any | null | undefined;
                    };
                    client:
                      | {
                          __typename?: 'Client';
                          id: string;
                          logo: string | null | undefined;
                          name: string;
                          slug: string;
                          info: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        price:
          | {
              __typename?: 'Price';
              currency: string | null | undefined;
              discountApplied: boolean;
              discountedPrice: number | null | undefined;
              originalPrice: number | null | undefined;
            }
          | null
          | undefined;
        subscriptionPromotion:
          | {
              __typename?: 'UserSubscription';
              code: string | null | undefined;
              title: string | null | undefined;
              description: string | null | undefined;
              shortDescription: string | null | undefined;
              amount: number | null | undefined;
              discountedAmount: number | null | undefined;
              currency: string | null | undefined;
              months: number | null | undefined;
              days: number | null | undefined;
              accessUntil: any | null | undefined;
              promotionId: string | null | undefined;
              subscriptionName: string;
              subscriptionPlan: string | null | undefined;
              promotionKind: PromotionKind | null | undefined;
              promotionName: string | null | undefined;
              recurringPromotion: boolean | null | undefined;
            }
          | null
          | undefined;
        userAccess:
          | {
              __typename?: 'UserAccess';
              active: boolean;
              joinedAt: any | null | undefined;
              accessUntil: any | null | undefined;
            }
          | null
          | undefined;
        waitingItemInfo: {
          __typename?: 'WaitingItemInfo';
          startDate: any | null | undefined;
          waitingListJoined: boolean | null | undefined;
          waitingListEnabled: boolean | null | undefined;
          waitingListEndDate: any | null | undefined;
          waitingListStartDate: any | null | undefined;
        };
        client:
          | {
              __typename?: 'Client';
              id: string;
              logo: string | null | undefined;
              name: string;
              slug: string;
              info: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ActivateBundleMutationVariables = Exact<{
  slug: Scalars['String'];
  client: Scalars['String'];
  code: Scalars['String'];
}>;

export type ActivateBundleMutation = {
  __typename?: 'Mutation';
  activateBundle:
    | {
        __typename?: 'ActivateBundlePayload';
        success: boolean;
        errors: string | null | undefined;
      }
    | null
    | undefined;
};

export type AddWaitingItemMutationVariables = Exact<{
  itemId: Scalars['ID'];
  itemType: ItemTypeKind;
}>;

export type AddWaitingItemMutation = {
  __typename?: 'Mutation';
  addWaitingItem:
    | {
        __typename?: 'AddWaitingItemPayload';
        success: boolean;
        errors: Array<string> | null | undefined;
      }
    | null
    | undefined;
};

export type ValidateBundleCodeMutationVariables = Exact<{
  slug: Scalars['String'];
  code: Scalars['String'];
  client: Scalars['String'];
}>;

export type ValidateBundleCodeMutation = {
  __typename?: 'Mutation';
  validateBundleCode:
    | {
        __typename?: 'ValidateBundleCodePayload';
        code: string;
        codeStatus: CodeStatusKind;
        codeType: CodeTypeKind | null | undefined;
        error: string | null | undefined;
        bundle:
          | {
              __typename?: 'Bundle';
              id: string;
              title: string;
              slug: string;
              heroImage: string | null | undefined;
              cardImage: string | null | undefined;
              heroMobileImage: string | null | undefined;
              active: boolean;
              kind: BundleKind;
              accessStrategy: BundleAccessStrategyKind;
              isUserEligible: boolean;
              bundleCategory: BundleCategoryKind | null | undefined;
              specialOfferCode: string | null | undefined;
              accessPeriodUnit: string | null | undefined;
              accessPeriodValue: number | null | undefined;
              hiddenInListing: boolean;
              metaDescription: string;
              metaImage: string | null | undefined;
              publishedAt: any | null | undefined;
              shortDescription: string;
              promotionalImage: string | null | undefined;
              tags: Array<string | null | undefined>;
              price:
                | {
                    __typename?: 'Price';
                    currency: string | null | undefined;
                    discountApplied: boolean;
                    discountedPrice: number | null | undefined;
                    originalPrice: number | null | undefined;
                  }
                | null
                | undefined;
              subscriptionPromotion:
                | {
                    __typename?: 'UserSubscription';
                    code: string | null | undefined;
                    title: string | null | undefined;
                    description: string | null | undefined;
                    shortDescription: string | null | undefined;
                    amount: number | null | undefined;
                    discountedAmount: number | null | undefined;
                    currency: string | null | undefined;
                    months: number | null | undefined;
                    days: number | null | undefined;
                    accessUntil: any | null | undefined;
                    promotionId: string | null | undefined;
                    subscriptionName: string;
                    subscriptionPlan: string | null | undefined;
                    promotionKind: PromotionKind | null | undefined;
                    promotionName: string | null | undefined;
                    recurringPromotion: boolean | null | undefined;
                  }
                | null
                | undefined;
              userAccess:
                | {
                    __typename?: 'UserAccess';
                    active: boolean;
                    joinedAt: any | null | undefined;
                    accessUntil: any | null | undefined;
                  }
                | null
                | undefined;
              waitingItemInfo: {
                __typename?: 'WaitingItemInfo';
                startDate: any | null | undefined;
                waitingListJoined: boolean | null | undefined;
                waitingListEnabled: boolean | null | undefined;
                waitingListEndDate: any | null | undefined;
                waitingListStartDate: any | null | undefined;
              };
              client:
                | {
                    __typename?: 'Client';
                    id: string;
                    logo: string | null | undefined;
                    name: string;
                    slug: string;
                    info: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        price:
          | {
              __typename?: 'Price';
              currency: string | null | undefined;
              discountApplied: boolean;
              discountedPrice: number | null | undefined;
              originalPrice: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BundlesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<BundleCategoryInput>;
}>;

export type BundlesQuery = {
  __typename?: 'Query';
  bundles:
    | {
        __typename?: 'PagedBundles';
        page: number;
        perPage: number;
        totalCount: number;
        data: Array<{
          __typename?: 'Bundle';
          id: string;
          title: string;
          slug: string;
          heroImage: string | null | undefined;
          cardImage: string | null | undefined;
          heroMobileImage: string | null | undefined;
          active: boolean;
          kind: BundleKind;
          accessStrategy: BundleAccessStrategyKind;
          isUserEligible: boolean;
          bundleCategory: BundleCategoryKind | null | undefined;
          specialOfferCode: string | null | undefined;
          accessPeriodUnit: string | null | undefined;
          accessPeriodValue: number | null | undefined;
          hiddenInListing: boolean;
          metaDescription: string;
          metaImage: string | null | undefined;
          publishedAt: any | null | undefined;
          shortDescription: string;
          promotionalImage: string | null | undefined;
          tags: Array<string | null | undefined>;
          price:
            | {
                __typename?: 'Price';
                currency: string | null | undefined;
                discountApplied: boolean;
                discountedPrice: number | null | undefined;
                originalPrice: number | null | undefined;
              }
            | null
            | undefined;
          subscriptionPromotion:
            | {
                __typename?: 'UserSubscription';
                code: string | null | undefined;
                title: string | null | undefined;
                description: string | null | undefined;
                shortDescription: string | null | undefined;
                amount: number | null | undefined;
                discountedAmount: number | null | undefined;
                currency: string | null | undefined;
                months: number | null | undefined;
                days: number | null | undefined;
                accessUntil: any | null | undefined;
                promotionId: string | null | undefined;
                subscriptionName: string;
                subscriptionPlan: string | null | undefined;
                promotionKind: PromotionKind | null | undefined;
                promotionName: string | null | undefined;
                recurringPromotion: boolean | null | undefined;
              }
            | null
            | undefined;
          userAccess:
            | {
                __typename?: 'UserAccess';
                active: boolean;
                joinedAt: any | null | undefined;
                accessUntil: any | null | undefined;
              }
            | null
            | undefined;
          waitingItemInfo: {
            __typename?: 'WaitingItemInfo';
            startDate: any | null | undefined;
            waitingListJoined: boolean | null | undefined;
            waitingListEnabled: boolean | null | undefined;
            waitingListEndDate: any | null | undefined;
            waitingListStartDate: any | null | undefined;
          };
          client:
            | {
                __typename?: 'Client';
                id: string;
                logo: string | null | undefined;
                name: string;
                slug: string;
                info: string | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type MyBundlesQueryVariables = Exact<{
  page: InputMaybe<Scalars['Int']>;
  perPage: InputMaybe<Scalars['Int']>;
}>;

export type MyBundlesQuery = {
  __typename?: 'Query';
  myBundles:
    | {
        __typename?: 'PagedBundles';
        page: number;
        perPage: number;
        totalCount: number;
        data: Array<{
          __typename?: 'Bundle';
          id: string;
          title: string;
          slug: string;
          heroImage: string | null | undefined;
          cardImage: string | null | undefined;
          heroMobileImage: string | null | undefined;
          active: boolean;
          kind: BundleKind;
          accessStrategy: BundleAccessStrategyKind;
          isUserEligible: boolean;
          bundleCategory: BundleCategoryKind | null | undefined;
          specialOfferCode: string | null | undefined;
          accessPeriodUnit: string | null | undefined;
          accessPeriodValue: number | null | undefined;
          hiddenInListing: boolean;
          metaDescription: string;
          metaImage: string | null | undefined;
          publishedAt: any | null | undefined;
          shortDescription: string;
          promotionalImage: string | null | undefined;
          tags: Array<string | null | undefined>;
          price:
            | {
                __typename?: 'Price';
                currency: string | null | undefined;
                discountApplied: boolean;
                discountedPrice: number | null | undefined;
                originalPrice: number | null | undefined;
              }
            | null
            | undefined;
          subscriptionPromotion:
            | {
                __typename?: 'UserSubscription';
                code: string | null | undefined;
                title: string | null | undefined;
                description: string | null | undefined;
                shortDescription: string | null | undefined;
                amount: number | null | undefined;
                discountedAmount: number | null | undefined;
                currency: string | null | undefined;
                months: number | null | undefined;
                days: number | null | undefined;
                accessUntil: any | null | undefined;
                promotionId: string | null | undefined;
                subscriptionName: string;
                subscriptionPlan: string | null | undefined;
                promotionKind: PromotionKind | null | undefined;
                promotionName: string | null | undefined;
                recurringPromotion: boolean | null | undefined;
              }
            | null
            | undefined;
          userAccess:
            | {
                __typename?: 'UserAccess';
                active: boolean;
                joinedAt: any | null | undefined;
                accessUntil: any | null | undefined;
              }
            | null
            | undefined;
          waitingItemInfo: {
            __typename?: 'WaitingItemInfo';
            startDate: any | null | undefined;
            waitingListJoined: boolean | null | undefined;
            waitingListEnabled: boolean | null | undefined;
            waitingListEndDate: any | null | undefined;
            waitingListStartDate: any | null | undefined;
          };
          client:
            | {
                __typename?: 'Client';
                id: string;
                logo: string | null | undefined;
                name: string;
                slug: string;
                info: string | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type CancelSubscriptionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CancelSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription:
    | { __typename?: 'CancelSubscriptionPayload'; success: boolean }
    | null
    | undefined;
};

export type ChallengesQueryQueryVariables = Exact<{ [key: string]: never }>;

export type ChallengesQueryQuery = {
  __typename?: 'Query';
  genericPrograms:
    | {
        __typename?: 'PagedGenericPrograms';
        page: number;
        totalCount: number;
        data: Array<{
          __typename?: 'GenericProgram';
          tags: Array<string>;
          id: string;
          title: string | null | undefined;
          slug: string;
          promotionalImageUrl: string | null | undefined;
          category: ProgramCategory;
          priceType: PriceTypeKind | null | undefined;
          kind: ProgramKind;
          language: LanguageKind | null | undefined;
          titleImageUrl: string | null | undefined;
          shortDescription: string | null | undefined;
          description: string | null | undefined;
          accessStatus: ProgramAccessStatus;
          discount:
            | {
                __typename?: 'Discount';
                discountType: DiscountTypeKind;
                discountValue: number | null | undefined;
                discountedPrice: number | null | undefined;
                endDate: any | null | undefined;
                identifier: PriceTypeKind | null | undefined;
                startDate: any | null | undefined;
              }
            | null
            | undefined;
          themesV2:
            | Array<{ __typename?: 'Theme'; label: string; slug: string }>
            | null
            | undefined;
          price:
            | {
                __typename?: 'Price';
                originalPrice: number | null | undefined;
                discountedPrice: number | null | undefined;
                discountApplied: boolean;
                currency: string | null | undefined;
              }
            | null
            | undefined;
          accessPeriod: {
            __typename?: 'AccessPeriod';
            unit: AccessPeriodUnitKind | null | undefined;
            value: number | null | undefined;
          };
          duration:
            | {
                __typename?: 'Duration';
                unit: DurationUnitKind | null | undefined;
                value: number | null | undefined;
              }
            | null
            | undefined;
          instructors:
            | Array<
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          programAccess:
            | {
                __typename?: 'ProgramAccess';
                accessStatus: ProgramAccessStatus;
                accessUntil: any | null | undefined;
                joinedAt: any | null | undefined;
                progressPercentage: number | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type ValidateChallengeTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type ValidateChallengeTokenMutation = {
  __typename?: 'Mutation';
  validateChallengeToken:
    | {
        __typename?: 'ValidateTokenPayload';
        status: string;
        errors:
          | Array<{
              __typename?: 'ProcessingError';
              message: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ActivateChallengeMutationVariables = Exact<{
  token: InputMaybe<Scalars['String']>;
  challenge: InputMaybe<Scalars['String']>;
}>;

export type ActivateChallengeMutation = {
  __typename?: 'Mutation';
  activateChallenge:
    | {
        __typename?: 'ActivateChallengePayload';
        challengeParticipation:
          | { __typename?: 'ChallengeParticipant'; accessUntil: any }
          | null
          | undefined;
        challengePass:
          | {
              __typename?: 'ChallengePass';
              code: string | null | undefined;
              days: number | null | undefined;
              challenge: { __typename?: 'Challenge'; name: string };
            }
          | null
          | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ChangeProfileDataMutationVariables = Exact<{
  displayName: InputMaybe<Scalars['String']>;
  shortBio: InputMaybe<Scalars['String']>;
  instagramUrl: InputMaybe<Scalars['String']>;
  facebookUrl: InputMaybe<Scalars['String']>;
  linkedinUrl: InputMaybe<Scalars['String']>;
  timezone: InputMaybe<Scalars['String']>;
  avatar: InputMaybe<Scalars['Upload']>;
}>;

export type ChangeProfileDataMutation = {
  __typename?: 'Mutation';
  changeProfileData:
    | {
        __typename?: 'ChangeProfileDataPayload';
        user:
          | { __typename?: 'CurrentUser'; id: string }
          | { __typename?: 'User'; id: string };
      }
    | null
    | undefined;
};

export type ChurnUpsellOfferFragment = {
  __typename?: 'Upsell';
  title: string | null | undefined;
  description: string | null | undefined;
  image: string | null | undefined;
  amountCents: number | null | undefined;
  amountCurrency: string | null | undefined;
  promotionName: string;
  subscriptionPlan:
    | {
        __typename?: 'SubscriptionPlan';
        months: number;
        amountCents: number;
        amountCurrency: string;
        planType: string;
        platform: DevicePlatformKind | null | undefined;
        productId: string | null | undefined;
      }
    | null
    | undefined;
};

export type ChurnUpsellOffersQueryVariables = Exact<{ [key: string]: never }>;

export type ChurnUpsellOffersQuery = {
  __typename?: 'Query';
  churnUpsellOffers: Array<{
    __typename?: 'Upsell';
    title: string | null | undefined;
    description: string | null | undefined;
    image: string | null | undefined;
    amountCents: number | null | undefined;
    amountCurrency: string | null | undefined;
    promotionName: string;
    subscriptionPlan:
      | {
          __typename?: 'SubscriptionPlan';
          months: number;
          amountCents: number;
          amountCurrency: string;
          planType: string;
          platform: DevicePlatformKind | null | undefined;
          productId: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type ChurnStartMutationVariables = Exact<{ [key: string]: never }>;

export type ChurnStartMutation = {
  __typename?: 'Mutation';
  churnStart:
    | {
        __typename?: 'ChurnStartPayload';
        previousAnswer:
          | {
              __typename?: 'ChurnAnswer';
              id: string;
              cause: string | null | undefined;
              feedback: string | null | undefined;
              solution: string | null | undefined;
              state: string;
            }
          | null
          | undefined;
        answer:
          | {
              __typename?: 'ChurnAnswer';
              id: string;
              cause: string | null | undefined;
              feedback: string | null | undefined;
              solution: string | null | undefined;
              state: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ChurnStepMutationVariables = Exact<{
  answerId: Scalars['String'];
  action: ActionsKind;
  cause: InputMaybe<Scalars['String']>;
  feedback: InputMaybe<Scalars['String']>;
  solution: InputMaybe<Scalars['String']>;
}>;

export type ChurnStepMutation = {
  __typename?: 'Mutation';
  churnStep:
    | {
        __typename?: 'ChurnStepPayload';
        answer:
          | {
              __typename?: 'ChurnAnswer';
              cause: string | null | undefined;
              feedback: string | null | undefined;
              id: string;
              solution: string | null | undefined;
              state: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CompleteProfileMutationVariables = Exact<{
  email: InputMaybe<Scalars['String']>;
  lastName: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  gender: InputMaybe<GenderKind>;
  country: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  trialExtensionCode?: InputMaybe<Scalars['String']>;
  oneTimeCode?: InputMaybe<Scalars['String']>;
}>;

export type CompleteProfileMutation = {
  __typename?: 'Mutation';
  completeProfile:
    | {
        __typename?: 'CompleteProfilePayload';
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
        user: { __typename?: 'CurrentUser'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type SearchBundleFieldsFragment = {
  __typename?: 'SearchBundle';
  slug: string | null | undefined;
  title: string | null | undefined;
  shortDescription: string | null | undefined;
  cardImageUrl: string | null | undefined;
  heroImageUrl: string | null | undefined;
  bundleCategory: string | null | undefined;
  client: { __typename?: 'Client'; slug: string } | null | undefined;
};

export type FetchCrossPromotionsQueryVariables = Exact<{
  resourceId: Scalars['String'];
  resourceType: ResourceTypeKind;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type FetchCrossPromotionsQuery = {
  __typename?: 'Query';
  fetchCrossPromotions:
    | {
        __typename?: 'Search';
        bundles:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | {
                        __typename: 'SearchBundle';
                        slug: string | null | undefined;
                        title: string | null | undefined;
                        shortDescription: string | null | undefined;
                        cardImageUrl: string | null | undefined;
                        heroImageUrl: string | null | undefined;
                        bundleCategory: string | null | undefined;
                        client:
                          | { __typename?: 'Client'; slug: string }
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        blogPosts:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | {
                        __typename: 'SearchBlogPost';
                        featuredImageUrl: string | null | undefined;
                        id: string;
                        slug: string;
                        title: string;
                      }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        challenges:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | {
                        __typename: 'SearchGenericProgram';
                        category: string | null | undefined;
                        description: string;
                        durationUnit: string | null | undefined;
                        durationValue: string | null | undefined;
                        id: string;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        priceType: PriceTypeKind | null | undefined;
                        slug: string | null | undefined;
                        title: string;
                        titleImageUrl: string | null | undefined;
                        accessPeriod:
                          | {
                              __typename?: 'AccessPeriod';
                              unit: AccessPeriodUnitKind | null | undefined;
                              value: number | null | undefined;
                            }
                          | null
                          | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                        themesV2:
                          | Array<{
                              __typename?: 'Theme';
                              label: string;
                              slug: string;
                            }>
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        genericPrograms:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | {
                        __typename: 'SearchGenericProgram';
                        category: string | null | undefined;
                        description: string;
                        durationUnit: string | null | undefined;
                        durationValue: string | null | undefined;
                        id: string;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        priceType: PriceTypeKind | null | undefined;
                        slug: string | null | undefined;
                        title: string;
                        titleImageUrl: string | null | undefined;
                        accessPeriod:
                          | {
                              __typename?: 'AccessPeriod';
                              unit: AccessPeriodUnitKind | null | undefined;
                              value: number | null | undefined;
                            }
                          | null
                          | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                        themesV2:
                          | Array<{
                              __typename?: 'Theme';
                              label: string;
                              slug: string;
                            }>
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        courses:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | {
                        __typename: 'SearchGenericProgram';
                        category: string | null | undefined;
                        description: string;
                        durationUnit: string | null | undefined;
                        durationValue: string | null | undefined;
                        id: string;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        priceType: PriceTypeKind | null | undefined;
                        slug: string | null | undefined;
                        title: string;
                        titleImageUrl: string | null | undefined;
                        accessPeriod:
                          | {
                              __typename?: 'AccessPeriod';
                              unit: AccessPeriodUnitKind | null | undefined;
                              value: number | null | undefined;
                            }
                          | null
                          | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                        themesV2:
                          | Array<{
                              __typename?: 'Theme';
                              label: string;
                              slug: string;
                            }>
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        liveEvents:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | {
                        __typename: 'SearchLiveEvent';
                        id: string;
                        kind: LiveEventKind;
                        slug: string | null | undefined;
                        theme: string | null | undefined;
                        themeLabel: string | null | undefined;
                        thumbnailUrl: string | null | undefined;
                        title: string;
                        availableSpots: number | null | undefined;
                        bookingAvailable: boolean | null | undefined;
                        duration: number;
                        eventDates: Array<any>;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        maximumParticipants: number | null | undefined;
                        registrationEndDate: any | null | undefined;
                        registrationStartDate: any | null | undefined;
                        paymentKind: string | null | undefined;
                        isClosed: boolean | null | undefined;
                        isExpired: boolean | null | undefined;
                        isComingSoon: boolean | null | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type TrialFragmentFragment = {
  __typename?: 'Trial';
  claimedAt: any | null | undefined;
  endDate: any | null | undefined;
  id: string;
  periodDays: number;
  subscriptionPromotionId: string | null | undefined;
  subscriptionPlanType: PlanTypeKind | null | undefined;
};

export type ProgramAccessFieldsFragment = {
  __typename?: 'ProgramAccess';
  accessStatus: ProgramAccessStatus;
  accessUntil: any | null | undefined;
  joinedAt: any | null | undefined;
  program:
    | {
        __typename?: 'Program';
        id: string;
        name: string;
        slug: string | null | undefined;
        lede: string | null | undefined;
        shortDescription: string | null | undefined;
        themes: Array<string> | null | undefined;
        legacy: boolean;
        language: string | null | undefined;
        titleImageUrl: string | null | undefined;
        tags: Array<string>;
        title: string;
        duration: {
          __typename?: 'Duration';
          value: number | null | undefined;
          unit: DurationUnitKind | null | undefined;
        };
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ReceiptFieldsFragment = {
  __typename?: 'Payment';
  amount: string;
  currency: string;
  date: string;
  downloadUrl: string | null | undefined;
  mobilePayment: boolean;
  months: number;
  number: string;
  paymentProvider: string;
  paymentProviderVariant: PaymentProviderVariantKind | null | undefined;
  paymentType: string;
  transact: string | null | undefined;
  receiptTitle: string | null | undefined;
  target: {
    __typename?: 'PaymentTarget';
    targetType: PaymentTargetKind;
    title: string | null | undefined;
  };
};

export type ExtraReceiptFieldsFragment = {
  __typename?: 'ExtraPayment';
  amount: string;
  currency: string;
  date: string;
  downloadUrl: string | null | undefined;
  number: string | null | undefined;
  paymentType: ExtraPaymentTargetKind;
  paymentProvider: string | null | undefined;
  paymentProviderVariant: PaymentProviderVariantKind | null | undefined;
  transact: string | null | undefined;
  target:
    | {
        __typename?: 'ExtraPaymentTarget';
        targetType: ExtraPaymentTargetKind;
        title: string | null | undefined;
      }
    | null
    | undefined;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser:
    | {
        __typename?: 'CurrentUser';
        impersonated: boolean | null | undefined;
        id: string;
        firstName: string | null | undefined;
        lastName: string | null | undefined;
        email: string | null | undefined;
        accessToken: string | null | undefined;
        accessUntil: string | null | undefined;
        completedAt: any | null | undefined;
        preferredCurrency: CurrencyKind | null | undefined;
        isPremium: boolean | null | undefined;
        isNoneTrialPremium: boolean | null | undefined;
        hasWellness: boolean | null | undefined;
        group: string;
        isEmailActivated: boolean | null | undefined;
        accountActivatedAt: any | null | undefined;
        isPublic: boolean | null | undefined;
        kind: string | null | undefined;
        locale: string | null | undefined;
        slug: string | null | undefined;
        previousEmail: string | null | undefined;
        eligibleForDelete: boolean | null | undefined;
        canUseTrustly: boolean | null | undefined;
        lastAccessPaymentProvider: PaymentProviderKind | null | undefined;
        lastWellnessAccessProvider: string | null | undefined;
        trialRequired: boolean | null | undefined;
        eligibleForStartDiscount: boolean | null | undefined;
        registrationSource: RegistrationSourceKind | null | undefined;
        countryCode: string | null | undefined;
        emailLanguage: string | null | undefined;
        country: string | null | undefined;
        zipcode: string | null | undefined;
        city: string | null | undefined;
        phone: string | null | undefined;
        address: string | null | undefined;
        referralCode: string | null | undefined;
        paymentTypeRenews: boolean | null | undefined;
        onboardingCompleted: boolean;
        onboardingSkipped: boolean;
        onboardingInterestsSkipped: boolean;
        onboardingTopicsSkipped: boolean;
        funnel: FunnelKind | null | undefined;
        finalFunnel: FunnelKind | null | undefined;
        trial:
          | {
              __typename?: 'Trial';
              code: string | null | undefined;
              claimedAt: any | null | undefined;
              endDate: any | null | undefined;
              id: string;
              periodDays: number;
              subscriptionPromotionId: string | null | undefined;
              subscriptionPlanType: PlanTypeKind | null | undefined;
            }
          | null
          | undefined;
        profile:
          | {
              __typename?: 'Profile';
              displayName: string | null | undefined;
              shortBio: string | null | undefined;
              instagramURL: string | null | undefined;
              linkedinURL: string | null | undefined;
              facebookURL: string | null | undefined;
              timezone: string | null | undefined;
            }
          | null
          | undefined;
        assets:
          | { __typename?: 'Assets'; avatar: string | null | undefined }
          | null
          | undefined;
        preferences:
          | {
              __typename?: 'Preferences';
              contentLanguages:
                | Array<string | null | undefined>
                | null
                | undefined;
              preferTerms: Array<string | null | undefined> | null | undefined;
              excludeTerms: Array<string | null | undefined> | null | undefined;
              videoLevels: Array<string | null | undefined> | null | undefined;
            }
          | null
          | undefined;
        subscription: {
          __typename?: 'Subscription';
          provider: string | null | undefined;
          status: SubscriptionStatus;
          inTrialPeriod: boolean;
          trial:
            | {
                __typename?: 'Trial';
                claimedAt: any | null | undefined;
                endDate: any | null | undefined;
                id: string;
                periodDays: number;
                subscriptionPromotionId: string | null | undefined;
                subscriptionPlanType: PlanTypeKind | null | undefined;
              }
            | null
            | undefined;
          currentSubscriptionPlan: {
            __typename?: 'SubscriptionPlan';
            platform: DevicePlatformKind | null | undefined;
            planType: string;
            productId: string | null | undefined;
            months: number;
            oldSubscriptionPrice: boolean;
          };
          userSubscription:
            | {
                __typename?: 'UserSubscription';
                title: string | null | undefined;
                description: string | null | undefined;
                shortDescription: string | null | undefined;
                amount: number | null | undefined;
                discountedAmount: number | null | undefined;
                currency: string | null | undefined;
                months: number | null | undefined;
                days: number | null | undefined;
                accessUntil: any | null | undefined;
                promotionId: string | null | undefined;
                subscriptionName: string;
                subscriptionPlan: string | null | undefined;
                promotionKind: PromotionKind | null | undefined;
                promotionName: string | null | undefined;
                recurringPromotion: boolean | null | undefined;
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type CurrentUser2QueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUser2Query = {
  __typename?: 'Query';
  currentUser:
    | {
        __typename?: 'CurrentUser';
        id: string;
        accessUntil: string | null | undefined;
        paymentFailedAt: string | null | undefined;
        gender: string | null | undefined;
        birthday: any | null | undefined;
        profession: string | null | undefined;
        personNumber: string | null | undefined;
        nextBillingCycle:
          | {
              __typename?: 'NextBillingCycle';
              paymentStatus: PaymentStatusKind | null | undefined;
              amount: string | null | undefined;
              currentAmountCharged: string | null | undefined;
              billingDate: any | null | undefined;
              planName: string | null | undefined;
            }
          | null
          | undefined;
        topics:
          | Array<{ __typename?: 'Topic'; name: string; slug: string }>
          | null
          | undefined;
        interests:
          | Array<{ __typename?: 'Interest'; name: string; slug: string }>
          | null
          | undefined;
        favorites:
          | {
              __typename?: 'Favorites';
              videos: Array<string | null | undefined>;
              playlists: Array<string | null | undefined>;
            }
          | null
          | undefined;
        privacySettings:
          | {
              __typename?: 'PrivacySettings';
              isPrivateFavorites: boolean | null | undefined;
              isPrivateHistory: boolean | null | undefined;
              isPrivatePlaylists: boolean | null | undefined;
              isPrivateProfile: boolean | null | undefined;
              isPrivateStats: boolean | null | undefined;
            }
          | null
          | undefined;
        statistics:
          | {
              __typename?: 'Statistics';
              sessionsLastWeek: number;
              sessionsCompleted: number;
              totalTime: number;
              averageDuration: number | null | undefined;
            }
          | null
          | undefined;
        receipts: Array<
          | {
              __typename?: 'Payment';
              amount: string;
              currency: string;
              date: string;
              downloadUrl: string | null | undefined;
              mobilePayment: boolean;
              months: number;
              number: string;
              paymentProvider: string;
              paymentProviderVariant:
                | PaymentProviderVariantKind
                | null
                | undefined;
              paymentType: string;
              transact: string | null | undefined;
              receiptTitle: string | null | undefined;
              target: {
                __typename?: 'PaymentTarget';
                targetType: PaymentTargetKind;
                title: string | null | undefined;
              };
            }
          | null
          | undefined
        >;
        extraPayments:
          | Array<
              | {
                  __typename?: 'ExtraPayment';
                  amount: string;
                  currency: string;
                  date: string;
                  downloadUrl: string | null | undefined;
                  number: string | null | undefined;
                  paymentType: ExtraPaymentTargetKind;
                  paymentProvider: string | null | undefined;
                  paymentProviderVariant:
                    | PaymentProviderVariantKind
                    | null
                    | undefined;
                  transact: string | null | undefined;
                  target:
                    | {
                        __typename?: 'ExtraPaymentTarget';
                        targetType: ExtraPaymentTargetKind;
                        title: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        liveEventsAccess:
          | Array<
              | {
                  __typename?: 'LiveEventAccess';
                  zoomLink: string;
                  zoomPassword: string;
                  liveEvent:
                    | {
                        __typename?: 'LiveEvent';
                        id: string;
                        duration: number;
                        eventDates: Array<any>;
                        kind: LiveEventKind;
                        language: LanguageKind;
                        level: LiveEventLevel;
                        theme: string | null | undefined;
                        slug: string;
                        title: string;
                        thumbnail: string;
                        accessories: Array<string>;
                        registrationEndDate: any;
                        registrationStartDate: any;
                        descriptionLong: string;
                        availableSpots: number;
                        bookingAvailable: boolean;
                        price:
                          | {
                              __typename?: 'Price';
                              originalPrice: number | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              currency: string | null | undefined;
                            }
                          | null
                          | undefined;
                        linkToRecordings:
                          | Array<{
                              __typename?: 'LinkToRecording';
                              link: string | null | undefined;
                              id: string;
                              eventDate: any;
                              expiresAt: any | null | undefined;
                              video:
                                | { __typename?: 'Video'; id: string }
                                | null
                                | undefined;
                            }>
                          | null
                          | undefined;
                        primaryInstructor:
                          | {
                              __typename?: 'Instructor';
                              id: string;
                              name: string | null | undefined;
                              slug: string | null | undefined;
                              assets:
                                | {
                                    __typename?: 'InstructorAssets';
                                    squareImage: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        otherInstructors: Array<{
                          __typename?: 'Instructor';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                          assets:
                            | {
                                __typename?: 'InstructorAssets';
                                squareImage: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }>;
                        instructors:
                          | Array<
                              | {
                                  __typename?: 'Instructor';
                                  id: string;
                                  name: string | null | undefined;
                                  slug: string | null | undefined;
                                  assets:
                                    | {
                                        __typename?: 'InstructorAssets';
                                        squareImage: string | null | undefined;
                                      }
                                    | null
                                    | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        programAccess: Array<
          | {
              __typename?: 'ProgramAccess';
              accessStatus: ProgramAccessStatus;
              accessUntil: any | null | undefined;
              joinedAt: any | null | undefined;
              program:
                | {
                    __typename?: 'Program';
                    id: string;
                    name: string;
                    slug: string | null | undefined;
                    lede: string | null | undefined;
                    shortDescription: string | null | undefined;
                    themes: Array<string> | null | undefined;
                    legacy: boolean;
                    language: string | null | undefined;
                    titleImageUrl: string | null | undefined;
                    tags: Array<string>;
                    title: string;
                    duration: {
                      __typename?: 'Duration';
                      value: number | null | undefined;
                      unit: DurationUnitKind | null | undefined;
                    };
                    instructors:
                      | Array<
                          | {
                              __typename?: 'Instructor';
                              id: string;
                              name: string | null | undefined;
                              slug: string | null | undefined;
                              assets:
                                | {
                                    __typename?: 'InstructorAssets';
                                    squareImage: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
        challengeAccesses: Array<
          | {
              __typename?: 'ProgramAccess';
              accessStatus: ProgramAccessStatus;
              accessUntil: any | null | undefined;
              joinedAt: any | null | undefined;
              program:
                | {
                    __typename?: 'Program';
                    id: string;
                    name: string;
                    slug: string | null | undefined;
                    lede: string | null | undefined;
                    shortDescription: string | null | undefined;
                    themes: Array<string> | null | undefined;
                    legacy: boolean;
                    language: string | null | undefined;
                    titleImageUrl: string | null | undefined;
                    tags: Array<string>;
                    title: string;
                    duration: {
                      __typename?: 'Duration';
                      value: number | null | undefined;
                      unit: DurationUnitKind | null | undefined;
                    };
                    instructors:
                      | Array<
                          | {
                              __typename?: 'Instructor';
                              id: string;
                              name: string | null | undefined;
                              slug: string | null | undefined;
                              assets:
                                | {
                                    __typename?: 'InstructorAssets';
                                    squareImage: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
        courseAccesses: Array<
          | {
              __typename?: 'CourseAccess';
              accessUntil: any | null | undefined;
              accessStatus: ProgramAccessStatus;
              joinedAt: any | null | undefined;
              course:
                | {
                    __typename?: 'Course';
                    id: string;
                    name: string | null | undefined;
                    titleImageUrl: string | null | undefined;
                    slug: string | null | undefined;
                    path: string | null | undefined;
                    themes: Array<string> | null | undefined;
                    legacy: boolean;
                    content: {
                      __typename?: 'Content';
                      lede: string | null | undefined;
                    };
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >;
        challengeParticipations: Array<
          | {
              __typename?: 'ChallengeParticipant';
              accessUntil: any;
              challenge: { __typename?: 'Challenge'; name: string };
            }
          | null
          | undefined
        >;
      }
    | null
    | undefined;
};

export type ChangeUserPreferencesMutationVariables = Exact<{
  preferredCurrency: InputMaybe<CurrencyKind>;
  emailLanguage: InputMaybe<Scalars['String']>;
  country: InputMaybe<Scalars['String']>;
  countryCode: InputMaybe<Scalars['String']>;
}>;

export type ChangeUserPreferencesMutation = {
  __typename?: 'Mutation';
  changeUserPreferences:
    | {
        __typename?: 'ChangeUserPreferencesPayload';
        user:
          | { __typename?: 'CurrentUser'; id: string }
          | { __typename?: 'User'; id: string };
      }
    | null
    | undefined;
};

export type DashboardRecentVideosQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DashboardRecentVideosQuery = {
  __typename?: 'Query';
  recentVideos:
    | Array<
        | {
            __typename?: 'Video';
            id: string;
            identifier: string;
            content:
              | {
                  __typename?: 'VideoContent';
                  title: string | null | undefined;
                  shortDescription: string | null | undefined;
                }
              | null
              | undefined;
            meta:
              | {
                  __typename?: 'VideoMeta';
                  minutes: number | null | undefined;
                  publishedAt: number | null | undefined;
                }
              | null
              | undefined;
            taxonomies: {
              __typename?: 'Taxonomy';
              flavor:
                | { __typename?: 'Term'; name: string | null | undefined }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        name: string | null | undefined;
                        slug: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              genre:
                | {
                    __typename?: 'Term';
                    name: string | null | undefined;
                    slug: string | null | undefined;
                  }
                | null
                | undefined;
            };
            assets:
              | {
                  __typename?: 'VideoAssets';
                  thumbnailUrl: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type DashboardTaxonomiesQueryVariables = Exact<{
  filter: InputMaybe<Scalars['String']>;
}>;

export type DashboardTaxonomiesQuery = {
  __typename?: 'Query';
  playlists:
    | Array<
        | {
            __typename?: 'TaxonomyItem';
            terms:
              | Array<{
                  __typename?: 'Term';
                  id: string;
                  name: string | null | undefined;
                  titleImageUrl: string | null | undefined;
                  slug: string | null | undefined;
                }>
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type DeleteAccountMutationVariables = Exact<{
  deletionReason: Scalars['String'];
}>;

export type DeleteAccountMutation = {
  __typename?: 'Mutation';
  deleteAccount:
    | {
        __typename?: 'DeleteAccountPayload';
        user:
          | { __typename?: 'CurrentUser'; id: string }
          | { __typename?: 'User'; id: string }
          | null
          | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type EmailPreferencesQueryVariables = Exact<{
  email: InputMaybe<Scalars['String']>;
  token: InputMaybe<Scalars['String']>;
}>;

export type EmailPreferencesQuery = {
  __typename?: 'Query';
  emailPreferences:
    | Array<{
        __typename?: 'EmailPreference';
        id: string;
        isMuted: boolean;
        key: string;
        name: string;
        description: string;
        mutable: boolean;
      }>
    | null
    | undefined;
};

export type ChangeEmailPreferenceMutationVariables = Exact<{
  mute: Scalars['Boolean'];
  key: Scalars['String'];
  email: InputMaybe<Scalars['String']>;
  token: InputMaybe<Scalars['String']>;
}>;

export type ChangeEmailPreferenceMutation = {
  __typename?: 'Mutation';
  changeEmailPreference:
    | {
        __typename?: 'ChangeEmailPreferencePayload';
        errors: string | null | undefined;
        success: boolean;
      }
    | null
    | undefined;
};

export type StoreEpassiMutationVariables = Exact<{
  paymentType: Scalars['String'];
  redirectToSuccess: Scalars['String'];
  redirectToFailed: Scalars['String'];
  sourcePage: InputMaybe<Scalars['String']>;
}>;

export type StoreEpassiMutation = {
  __typename?: 'Mutation';
  storeEpassi:
    | {
        __typename?: 'StoreEpassiPayload';
        stamp: string | null | undefined;
        amount: string | null | undefined;
        fee: string | null | undefined;
        mac: string | null | undefined;
        site: string | null | undefined;
        vatValue: string | null | undefined;
        cancelUrl: string | null | undefined;
        rejectUrl: string | null | undefined;
        returnUrl: string | null | undefined;
        notifyUrl: string | null | undefined;
      }
    | null
    | undefined;
};

export type PayForWellnessItemMutationVariables = Exact<{
  slug: Scalars['String'];
  itemKind: ItemKind;
  wellnessProvider: PaymentWellnessProviderKind;
  redirectToSuccess: InputMaybe<Scalars['String']>;
  redirectToFailed: InputMaybe<Scalars['String']>;
  discountCode: InputMaybe<Scalars['String']>;
  amountCents: InputMaybe<Scalars['Int']>;
  sourcePage: InputMaybe<Scalars['String']>;
}>;

export type PayForWellnessItemMutation = {
  __typename?: 'Mutation';
  payForWellnessItem:
    | {
        __typename?: 'PayForWellnessItemPayload';
        amount: string | null | undefined;
        cancelUrl: string | null | undefined;
        fee: string | null | undefined;
        mac: string | null | undefined;
        notifyUrl: string | null | undefined;
        rejectUrl: string | null | undefined;
        returnUrl: string | null | undefined;
        site: string | null | undefined;
        stamp: string | null | undefined;
        vatValue: string | null | undefined;
      }
    | null
    | undefined;
};

export type EventProxyMutationVariables = Exact<{
  event: ProxyEventKind;
  properties: InputMaybe<Array<EventPropertyInput> | EventPropertyInput>;
}>;

export type EventProxyMutation = {
  __typename?: 'Mutation';
  eventProxy:
    | {
        __typename?: 'EventProxyPayload';
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type FlagsQueryVariables = Exact<{
  names: Array<Scalars['String']> | Scalars['String'];
}>;

export type FlagsQuery = {
  __typename?: 'Query';
  flipperFlags:
    | Array<{ __typename?: 'FlipperFlag'; name: string; value: boolean }>
    | null
    | undefined;
};

export type ProgramInstructorsFragment = {
  __typename?: 'Instructor';
  id: string;
  name: string | null | undefined;
  slug: string | null | undefined;
  assets:
    | {
        __typename?: 'InstructorAssets';
        squareImage: string | null | undefined;
      }
    | null
    | undefined;
};

export type EssentialGenericFieldsFragment = {
  __typename?: 'GenericProgram';
  id: string;
  title: string | null | undefined;
  slug: string;
  promotionalImageUrl: string | null | undefined;
  category: ProgramCategory;
  priceType: PriceTypeKind | null | undefined;
  kind: ProgramKind;
  language: LanguageKind | null | undefined;
  titleImageUrl: string | null | undefined;
  shortDescription: string | null | undefined;
  description: string | null | undefined;
  accessStatus: ProgramAccessStatus;
  themesV2:
    | Array<{ __typename?: 'Theme'; label: string; slug: string }>
    | null
    | undefined;
  price:
    | {
        __typename?: 'Price';
        originalPrice: number | null | undefined;
        discountedPrice: number | null | undefined;
        discountApplied: boolean;
        currency: string | null | undefined;
      }
    | null
    | undefined;
  accessPeriod: {
    __typename?: 'AccessPeriod';
    unit: AccessPeriodUnitKind | null | undefined;
    value: number | null | undefined;
  };
  duration:
    | {
        __typename?: 'Duration';
        unit: DurationUnitKind | null | undefined;
        value: number | null | undefined;
      }
    | null
    | undefined;
  instructors:
    | Array<
        | {
            __typename?: 'Instructor';
            id: string;
            name: string | null | undefined;
            slug: string | null | undefined;
            assets:
              | {
                  __typename?: 'InstructorAssets';
                  squareImage: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  programAccess:
    | {
        __typename?: 'ProgramAccess';
        accessStatus: ProgramAccessStatus;
        accessUntil: any | null | undefined;
        joinedAt: any | null | undefined;
        progressPercentage: number | null | undefined;
      }
    | null
    | undefined;
};

export type ProgramIntroFieldsFragment = {
  __typename?: 'SpecialSection';
  description: string | null | undefined;
  label: string;
  assetUrl: string | null | undefined;
  slug: string;
};

export type ProgramOutroFieldsFragment = {
  __typename?: 'SpecialSection';
  description: string | null | undefined;
  label: string;
  slug: string;
  videos:
    | Array<
        | {
            __typename?: 'ProgramVideo';
            id: string;
            slug: string;
            title: string | null | undefined;
            subheader: string | null | undefined;
            customTitle: string | null | undefined;
            locked: boolean;
            watched: boolean | null | undefined;
            finished: boolean | null | undefined;
            position: number;
            positionLabel: string;
            videoKind: VideoKind | null | undefined;
            progressIsCurrent: boolean;
            repeatRecomendation: number | null | undefined;
            customDescription: string | null | undefined;
            preMoodTracker:
              | {
                  __typename?: 'MoodTracker';
                  id: string;
                  finished: boolean | null | undefined;
                  kind: MoodTrackerKind;
                }
              | null
              | undefined;
            postMoodTracker:
              | {
                  __typename?: 'MoodTracker';
                  id: string;
                  finished: boolean | null | undefined;
                  kind: MoodTrackerKind;
                }
              | null
              | undefined;
            video: {
              __typename?: 'Video';
              id: string;
              title: string;
              kind: string;
              identifier: string;
              hasAccess: boolean;
              cursorAt: number | null | undefined;
              hls: string;
              content:
                | {
                    __typename?: 'VideoContent';
                    title: string | null | undefined;
                    shortDescription: string | null | undefined;
                    longDescription: string | null | undefined;
                  }
                | null
                | undefined;
              player:
                | {
                    __typename?: 'VideoInfo';
                    assets:
                      | Array<
                          | {
                              __typename?: 'VideoFileAsset';
                              src: string | null | undefined;
                              resolution: number | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              meta:
                | {
                    __typename?: 'VideoMeta';
                    publishedAt: number | null | undefined;
                    minutes: number | null | undefined;
                  }
                | null
                | undefined;
              taxonomies: {
                __typename?: 'Taxonomy';
                flavor:
                  | { __typename?: 'Term'; name: string | null | undefined }
                  | null
                  | undefined;
                instructors:
                  | Array<
                      | {
                          __typename?: 'Instructor';
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
                genre:
                  | {
                      __typename?: 'Term';
                      name: string | null | undefined;
                      slug: string | null | undefined;
                    }
                  | null
                  | undefined;
              };
              assets:
                | {
                    __typename?: 'VideoAssets';
                    thumbnailUrl: string | null | undefined;
                  }
                | null
                | undefined;
            };
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetBasicGenericProgramQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetBasicGenericProgramQuery = {
  __typename?: 'Query';
  genericProgram:
    | {
        __typename?: 'GenericProgram';
        id: string;
        title: string | null | undefined;
        slug: string;
        promotionalImageUrl: string | null | undefined;
        category: ProgramCategory;
        priceType: PriceTypeKind | null | undefined;
        kind: ProgramKind;
        language: LanguageKind | null | undefined;
        titleImageUrl: string | null | undefined;
        shortDescription: string | null | undefined;
        description: string | null | undefined;
        accessStatus: ProgramAccessStatus;
        themesV2:
          | Array<{ __typename?: 'Theme'; label: string; slug: string }>
          | null
          | undefined;
        price:
          | {
              __typename?: 'Price';
              originalPrice: number | null | undefined;
              discountedPrice: number | null | undefined;
              discountApplied: boolean;
              currency: string | null | undefined;
            }
          | null
          | undefined;
        accessPeriod: {
          __typename?: 'AccessPeriod';
          unit: AccessPeriodUnitKind | null | undefined;
          value: number | null | undefined;
        };
        duration:
          | {
              __typename?: 'Duration';
              unit: DurationUnitKind | null | undefined;
              value: number | null | undefined;
            }
          | null
          | undefined;
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        programAccess:
          | {
              __typename?: 'ProgramAccess';
              accessStatus: ProgramAccessStatus;
              accessUntil: any | null | undefined;
              joinedAt: any | null | undefined;
              progressPercentage: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type FullGenericProgramFieldsFragment = {
  __typename?: 'GenericProgram';
  id: string;
  progress: number | null | undefined;
  summaryAsset: string | null | undefined;
  title: string | null | undefined;
  slug: string;
  promotionalImageUrl: string | null | undefined;
  category: ProgramCategory;
  priceType: PriceTypeKind | null | undefined;
  kind: ProgramKind;
  language: LanguageKind | null | undefined;
  titleImageUrl: string | null | undefined;
  shortDescription: string | null | undefined;
  description: string | null | undefined;
  accessStatus: ProgramAccessStatus;
  meta:
    | {
        __typename?: 'ProgramMeta';
        title: string | null | undefined;
        description: string | null | undefined;
        imageUrl: string | null | undefined;
      }
    | null
    | undefined;
  discount:
    | {
        __typename?: 'Discount';
        discountType: DiscountTypeKind;
        discountValue: number | null | undefined;
        discountedPrice: number | null | undefined;
        endDate: any | null | undefined;
        identifier: PriceTypeKind | null | undefined;
        startDate: any | null | undefined;
      }
    | null
    | undefined;
  postSurvey:
    | {
        __typename?: 'ProgramSurvey';
        id: string;
        label: string;
        kind: SurveyKind;
        link: string;
        finished: boolean | null | undefined;
        locked: boolean;
      }
    | null
    | undefined;
  preSurvey:
    | {
        __typename?: 'ProgramSurvey';
        id: string;
        label: string;
        kind: SurveyKind;
        link: string;
        finished: boolean | null | undefined;
        locked: boolean;
      }
    | null
    | undefined;
  trailer:
    | {
        __typename?: 'Video';
        id: string;
        minutes: number;
        thumbnailUrl: string | null | undefined;
        hls: string;
        content:
          | { __typename?: 'VideoContent'; title: string | null | undefined }
          | null
          | undefined;
        player:
          | {
              __typename?: 'VideoInfo';
              assets:
                | Array<
                    | {
                        __typename?: 'VideoFileAsset';
                        resolution: number | null | undefined;
                        src: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  themesV2:
    | Array<{ __typename?: 'Theme'; label: string; slug: string }>
    | null
    | undefined;
  price:
    | {
        __typename?: 'Price';
        originalPrice: number | null | undefined;
        discountedPrice: number | null | undefined;
        discountApplied: boolean;
        currency: string | null | undefined;
      }
    | null
    | undefined;
  accessPeriod: {
    __typename?: 'AccessPeriod';
    unit: AccessPeriodUnitKind | null | undefined;
    value: number | null | undefined;
  };
  duration:
    | {
        __typename?: 'Duration';
        unit: DurationUnitKind | null | undefined;
        value: number | null | undefined;
      }
    | null
    | undefined;
  instructors:
    | Array<
        | {
            __typename?: 'Instructor';
            id: string;
            name: string | null | undefined;
            slug: string | null | undefined;
            assets:
              | {
                  __typename?: 'InstructorAssets';
                  squareImage: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  programAccess:
    | {
        __typename?: 'ProgramAccess';
        accessStatus: ProgramAccessStatus;
        accessUntil: any | null | undefined;
        joinedAt: any | null | undefined;
        progressPercentage: number | null | undefined;
      }
    | null
    | undefined;
};

export type GetFullGenericProgramQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetFullGenericProgramQuery = {
  __typename?: 'Query';
  genericProgram:
    | {
        __typename?: 'GenericProgram';
        id: string;
        progress: number | null | undefined;
        summaryAsset: string | null | undefined;
        title: string | null | undefined;
        slug: string;
        promotionalImageUrl: string | null | undefined;
        category: ProgramCategory;
        priceType: PriceTypeKind | null | undefined;
        kind: ProgramKind;
        language: LanguageKind | null | undefined;
        titleImageUrl: string | null | undefined;
        shortDescription: string | null | undefined;
        description: string | null | undefined;
        accessStatus: ProgramAccessStatus;
        meta:
          | {
              __typename?: 'ProgramMeta';
              title: string | null | undefined;
              description: string | null | undefined;
              imageUrl: string | null | undefined;
            }
          | null
          | undefined;
        discount:
          | {
              __typename?: 'Discount';
              discountType: DiscountTypeKind;
              discountValue: number | null | undefined;
              discountedPrice: number | null | undefined;
              endDate: any | null | undefined;
              identifier: PriceTypeKind | null | undefined;
              startDate: any | null | undefined;
            }
          | null
          | undefined;
        postSurvey:
          | {
              __typename?: 'ProgramSurvey';
              id: string;
              label: string;
              kind: SurveyKind;
              link: string;
              finished: boolean | null | undefined;
              locked: boolean;
            }
          | null
          | undefined;
        preSurvey:
          | {
              __typename?: 'ProgramSurvey';
              id: string;
              label: string;
              kind: SurveyKind;
              link: string;
              finished: boolean | null | undefined;
              locked: boolean;
            }
          | null
          | undefined;
        trailer:
          | {
              __typename?: 'Video';
              id: string;
              minutes: number;
              thumbnailUrl: string | null | undefined;
              hls: string;
              content:
                | {
                    __typename?: 'VideoContent';
                    title: string | null | undefined;
                  }
                | null
                | undefined;
              player:
                | {
                    __typename?: 'VideoInfo';
                    assets:
                      | Array<
                          | {
                              __typename?: 'VideoFileAsset';
                              resolution: number | null | undefined;
                              src: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        themesV2:
          | Array<{ __typename?: 'Theme'; label: string; slug: string }>
          | null
          | undefined;
        price:
          | {
              __typename?: 'Price';
              originalPrice: number | null | undefined;
              discountedPrice: number | null | undefined;
              discountApplied: boolean;
              currency: string | null | undefined;
            }
          | null
          | undefined;
        accessPeriod: {
          __typename?: 'AccessPeriod';
          unit: AccessPeriodUnitKind | null | undefined;
          value: number | null | undefined;
        };
        duration:
          | {
              __typename?: 'Duration';
              unit: DurationUnitKind | null | undefined;
              value: number | null | undefined;
            }
          | null
          | undefined;
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        programAccess:
          | {
              __typename?: 'ProgramAccess';
              accessStatus: ProgramAccessStatus;
              accessUntil: any | null | undefined;
              joinedAt: any | null | undefined;
              progressPercentage: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GenericProgramsQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  languages: InputMaybe<Array<LanguageKind> | LanguageKind>;
  instructor: InputMaybe<Scalars['String']>;
  sortBy: InputMaybe<GenericProgramSortAttribute>;
  theme: InputMaybe<Scalars['String']>;
  category: InputMaybe<ProgramCategory>;
  tags: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GenericProgramsQuery = {
  __typename?: 'Query';
  genericPrograms:
    | {
        __typename?: 'PagedGenericPrograms';
        page: number;
        totalCount: number;
        data: Array<{
          __typename?: 'GenericProgram';
          id: string;
          title: string | null | undefined;
          slug: string;
          promotionalImageUrl: string | null | undefined;
          category: ProgramCategory;
          priceType: PriceTypeKind | null | undefined;
          kind: ProgramKind;
          language: LanguageKind | null | undefined;
          titleImageUrl: string | null | undefined;
          shortDescription: string | null | undefined;
          description: string | null | undefined;
          accessStatus: ProgramAccessStatus;
          discount:
            | {
                __typename?: 'Discount';
                discountType: DiscountTypeKind;
                discountValue: number | null | undefined;
                discountedPrice: number | null | undefined;
                endDate: any | null | undefined;
                identifier: PriceTypeKind | null | undefined;
                startDate: any | null | undefined;
              }
            | null
            | undefined;
          themesV2:
            | Array<{ __typename?: 'Theme'; label: string; slug: string }>
            | null
            | undefined;
          price:
            | {
                __typename?: 'Price';
                originalPrice: number | null | undefined;
                discountedPrice: number | null | undefined;
                discountApplied: boolean;
                currency: string | null | undefined;
              }
            | null
            | undefined;
          accessPeriod: {
            __typename?: 'AccessPeriod';
            unit: AccessPeriodUnitKind | null | undefined;
            value: number | null | undefined;
          };
          duration:
            | {
                __typename?: 'Duration';
                unit: DurationUnitKind | null | undefined;
                value: number | null | undefined;
              }
            | null
            | undefined;
          instructors:
            | Array<
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          programAccess:
            | {
                __typename?: 'ProgramAccess';
                accessStatus: ProgramAccessStatus;
                accessUntil: any | null | undefined;
                joinedAt: any | null | undefined;
                progressPercentage: number | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type ProgramThemesQueryVariables = Exact<{
  category: InputMaybe<ProgramCategory>;
}>;

export type ProgramThemesQuery = {
  __typename?: 'Query';
  programThemesV2:
    | Array<{ __typename?: 'Theme'; label: string; slug: string }>
    | null
    | undefined;
};

export type SaveProgramProgressMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SaveProgramProgressMutation = {
  __typename?: 'Mutation';
  saveProgramProgress:
    | { __typename?: 'SaveProgramProgressPayload'; errors: Array<string> }
    | null
    | undefined;
};

export type SaveGenericProgramProgressMutationVariables = Exact<{
  videoPartId: InputMaybe<Scalars['ID']>;
  documentPartId: InputMaybe<Scalars['ID']>;
  programPartElementId: InputMaybe<Scalars['ID']>;
  liveEventPartId: InputMaybe<Scalars['ID']>;
}>;

export type SaveGenericProgramProgressMutation = {
  __typename?: 'Mutation';
  savePhasedProgramProgress:
    | { __typename?: 'SavePhasedProgramProgressPayload'; errors: Array<string> }
    | null
    | undefined;
};

export type ValidateProgramCodeMutationVariables = Exact<{
  code: Scalars['String'];
  slug: Scalars['String'];
}>;

export type ValidateProgramCodeMutation = {
  __typename?: 'Mutation';
  validateProgramCode:
    | {
        __typename?: 'ValidateProgramCodePayload';
        code: string | null | undefined;
        codeStatus: CodeStatusKind | null | undefined;
        codeType: CodeTypeKind | null | undefined;
        codeSource: CodeSourceKind | null | undefined;
        error: string | null | undefined;
        price:
          | {
              __typename?: 'Price';
              currency: string | null | undefined;
              discountApplied: boolean;
              discountedPrice: number | null | undefined;
              originalPrice: number | null | undefined;
            }
          | null
          | undefined;
        program:
          | { __typename?: 'GenericProgram'; id: string; slug: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ActivateGenericProgramMutationVariables = Exact<{
  slug: Scalars['String'];
  code: Scalars['String'];
}>;

export type ActivateGenericProgramMutation = {
  __typename?: 'Mutation';
  activateGenericProgram:
    | {
        __typename?: 'ActivateGenericProgramPayload';
        success: boolean;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GiftGenericProgramQueryVariables = Exact<{
  discountCode: Scalars['String'];
  slug: Scalars['String'];
}>;

export type GiftGenericProgramQuery = {
  __typename?: 'Query';
  giftGenericProgram:
    | {
        __typename?: 'GenericProgramWithCode';
        code: string | null | undefined;
        codeStatus: CodeStatusKind | null | undefined;
        price:
          | {
              __typename?: 'Price';
              currency: string | null | undefined;
              discountApplied: boolean;
              discountedPrice: number | null | undefined;
              originalPrice: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type BasicPlaylistFieldsFragment = {
  __typename?: 'Playlist';
  id: string;
  identifier: string | null | undefined;
  visibility: string | null | undefined;
  title: string;
  description: string | null | undefined;
  owner:
    | {
        __typename?: 'CurrentUser';
        id: string;
        firstName: string | null | undefined;
      }
    | { __typename?: 'User'; id: string; firstName: string | null | undefined }
    | null
    | undefined;
  meta:
    | {
        __typename?: 'Meta';
        videoCount: number | null | undefined;
        minutes: number | null | undefined;
      }
    | null
    | undefined;
  assets:
    | { __typename?: 'PlaylistAssets'; thumbnailUrl: string | null | undefined }
    | null
    | undefined;
};

export type PlaylistEssentialFieldsFragment = {
  __typename?: 'Playlist';
  id: string;
  identifier: string | null | undefined;
  visibility: string | null | undefined;
  title: string;
  description: string | null | undefined;
  removedText: string | null | undefined;
  owner:
    | {
        __typename?: 'CurrentUser';
        id: string;
        firstName: string | null | undefined;
      }
    | { __typename?: 'User'; id: string; firstName: string | null | undefined }
    | null
    | undefined;
  meta:
    | {
        __typename?: 'Meta';
        imageUrl: string | null | undefined;
        videoCount: number | null | undefined;
        minutes: number | null | undefined;
      }
    | null
    | undefined;
  assets:
    | { __typename?: 'PlaylistAssets'; thumbnailUrl: string | null | undefined }
    | null
    | undefined;
  videos:
    | Array<
        | {
            __typename?: 'Video';
            id: string;
            identifier: string;
            kind: string;
            hls: string;
            hasAccess: boolean;
            cursorAt: number | null | undefined;
            assets:
              | {
                  __typename?: 'VideoAssets';
                  thumbnailUrl: string | null | undefined;
                }
              | null
              | undefined;
            player:
              | {
                  __typename?: 'VideoInfo';
                  assets:
                    | Array<
                        | {
                            __typename?: 'VideoFileAsset';
                            src: string | null | undefined;
                            resolution: number | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined;
            meta:
              | { __typename?: 'VideoMeta'; minutes: number | null | undefined }
              | null
              | undefined;
            content:
              | {
                  __typename?: 'VideoContent';
                  title: string | null | undefined;
                  longDescription: string | null | undefined;
                  shortDescription: string | null | undefined;
                }
              | null
              | undefined;
            taxonomies: {
              __typename?: 'Taxonomy';
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              needs:
                | Array<
                    | {
                        __typename?: 'Term';
                        id: string;
                        name: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              bodyparts:
                | Array<
                    | {
                        __typename?: 'Term';
                        id: string;
                        name: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              styles:
                | Array<
                    | {
                        __typename?: 'Term';
                        id: string;
                        name: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              genre:
                | {
                    __typename?: 'Term';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                  }
                | null
                | undefined;
            };
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type PlaylistCardFieldsFragment = {
  __typename?: 'Playlist';
  id: string;
  identifier: string | null | undefined;
  visibility: string | null | undefined;
  title: string;
  description: string | null | undefined;
  owner:
    | {
        __typename?: 'CurrentUser';
        id: string;
        firstName: string | null | undefined;
      }
    | { __typename?: 'User'; id: string; firstName: string | null | undefined }
    | null
    | undefined;
  meta:
    | {
        __typename?: 'Meta';
        videoCount: number | null | undefined;
        minutes: number | null | undefined;
      }
    | null
    | undefined;
  assets:
    | { __typename?: 'PlaylistAssets'; thumbnailUrl: string | null | undefined }
    | null
    | undefined;
};

export type PlaylistQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PlaylistQuery = {
  __typename?: 'Query';
  playlist:
    | {
        __typename?: 'Playlist';
        id: string;
        identifier: string | null | undefined;
        visibility: string | null | undefined;
        title: string;
        description: string | null | undefined;
        removedText: string | null | undefined;
        owner:
          | {
              __typename?: 'CurrentUser';
              id: string;
              firstName: string | null | undefined;
            }
          | {
              __typename?: 'User';
              id: string;
              firstName: string | null | undefined;
            }
          | null
          | undefined;
        meta:
          | {
              __typename?: 'Meta';
              imageUrl: string | null | undefined;
              videoCount: number | null | undefined;
              minutes: number | null | undefined;
            }
          | null
          | undefined;
        assets:
          | {
              __typename?: 'PlaylistAssets';
              thumbnailUrl: string | null | undefined;
            }
          | null
          | undefined;
        videos:
          | Array<
              | {
                  __typename?: 'Video';
                  id: string;
                  identifier: string;
                  kind: string;
                  hls: string;
                  hasAccess: boolean;
                  cursorAt: number | null | undefined;
                  assets:
                    | {
                        __typename?: 'VideoAssets';
                        thumbnailUrl: string | null | undefined;
                      }
                    | null
                    | undefined;
                  player:
                    | {
                        __typename?: 'VideoInfo';
                        assets:
                          | Array<
                              | {
                                  __typename?: 'VideoFileAsset';
                                  src: string | null | undefined;
                                  resolution: number | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  meta:
                    | {
                        __typename?: 'VideoMeta';
                        minutes: number | null | undefined;
                      }
                    | null
                    | undefined;
                  content:
                    | {
                        __typename?: 'VideoContent';
                        title: string | null | undefined;
                        longDescription: string | null | undefined;
                        shortDescription: string | null | undefined;
                      }
                    | null
                    | undefined;
                  taxonomies: {
                    __typename?: 'Taxonomy';
                    instructors:
                      | Array<
                          | {
                              __typename?: 'Instructor';
                              id: string;
                              name: string | null | undefined;
                              slug: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    needs:
                      | Array<
                          | {
                              __typename?: 'Term';
                              id: string;
                              name: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    bodyparts:
                      | Array<
                          | {
                              __typename?: 'Term';
                              id: string;
                              name: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    styles:
                      | Array<
                          | {
                              __typename?: 'Term';
                              id: string;
                              name: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                    genre:
                      | {
                          __typename?: 'Term';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined;
                  };
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type VideoFieldsFragment = {
  __typename?: 'Video';
  id: string;
  identifier: string;
  kind: string;
  cursorAt: number | null | undefined;
  removedText: string | null | undefined;
  content:
    | {
        __typename?: 'VideoContent';
        title: string | null | undefined;
        shortDescription: string | null | undefined;
        longDescription: string | null | undefined;
      }
    | null
    | undefined;
  meta:
    | {
        __typename?: 'VideoMeta';
        minutes: number | null | undefined;
        publishedAt: number | null | undefined;
      }
    | null
    | undefined;
  primaryStyle:
    | {
        __typename?: 'Term';
        name: string | null | undefined;
        slug: string | null | undefined;
      }
    | null
    | undefined;
  taxonomies: {
    __typename?: 'Taxonomy';
    flavor:
      | { __typename?: 'Term'; id: string; name: string | null | undefined }
      | null
      | undefined;
    needs:
      | Array<
          | {
              __typename?: 'Term';
              id: string;
              name: string | null | undefined;
              slug: string | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    instructors:
      | Array<
          | {
              __typename?: 'Instructor';
              id: string;
              name: string | null | undefined;
              slug: string | null | undefined;
              assets:
                | {
                    __typename?: 'InstructorAssets';
                    externalImage: string | null | undefined;
                    squareImage: string | null | undefined;
                    titleImage: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    genre:
      | {
          __typename?: 'Term';
          id: string;
          name: string | null | undefined;
          slug: string | null | undefined;
        }
      | null
      | undefined;
  };
  assets:
    | { __typename?: 'VideoAssets'; thumbnailUrl: string | null | undefined }
    | null
    | undefined;
};

export type VideosQueryVariables = Exact<{
  taxonomies: InputMaybe<Array<TaxonomyInput> | TaxonomyInput>;
  page: InputMaybe<Scalars['Int']>;
  perPage: InputMaybe<Scalars['Int']>;
  genre: InputMaybe<Scalars['String']>;
  query: InputMaybe<Scalars['String']>;
  useLocale: InputMaybe<Scalars['Boolean']>;
  contentLanguages: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  excludeTerms: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  videoLevels: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  orderBy: InputMaybe<VideoOrderBy>;
}>;

export type VideosQuery = {
  __typename?: 'Query';
  videos: {
    __typename?: 'PagedVideos';
    page: number;
    perPage: number;
    totalCount: number;
    data: Array<{
      __typename?: 'Video';
      id: string;
      identifier: string;
      kind: string;
      cursorAt: number | null | undefined;
      removedText: string | null | undefined;
      content:
        | {
            __typename?: 'VideoContent';
            title: string | null | undefined;
            shortDescription: string | null | undefined;
            longDescription: string | null | undefined;
          }
        | null
        | undefined;
      meta:
        | {
            __typename?: 'VideoMeta';
            minutes: number | null | undefined;
            publishedAt: number | null | undefined;
          }
        | null
        | undefined;
      primaryStyle:
        | {
            __typename?: 'Term';
            name: string | null | undefined;
            slug: string | null | undefined;
          }
        | null
        | undefined;
      taxonomies: {
        __typename?: 'Taxonomy';
        flavor:
          | { __typename?: 'Term'; id: string; name: string | null | undefined }
          | null
          | undefined;
        needs:
          | Array<
              | {
                  __typename?: 'Term';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        externalImage: string | null | undefined;
                        squareImage: string | null | undefined;
                        titleImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        genre:
          | {
              __typename?: 'Term';
              id: string;
              name: string | null | undefined;
              slug: string | null | undefined;
            }
          | null
          | undefined;
      };
      assets:
        | {
            __typename?: 'VideoAssets';
            thumbnailUrl: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export type ModalInstructorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ModalInstructorQuery = {
  __typename?: 'Query';
  instructor:
    | {
        __typename?: 'Instructor';
        id: string;
        slug: string | null | undefined;
        name: string | null | undefined;
        speciality: string | null | undefined;
        location: string | null | undefined;
        webpage: string | null | undefined;
        assets:
          | {
              __typename?: 'InstructorAssets';
              externalImage: string | null | undefined;
            }
          | null
          | undefined;
        instructorPage: {
          __typename?: 'InstructorPage';
          description: string | null | undefined;
        };
      }
    | null
    | undefined;
};

export type InstructorListingFieldsFragment = {
  __typename?: 'Instructor';
  id: string;
  slug: string | null | undefined;
  name: string | null | undefined;
  speciality: string | null | undefined;
  location: string | null | undefined;
  webpage: string | null | undefined;
  team: string | null | undefined;
  assets:
    | {
        __typename?: 'InstructorAssets';
        externalImage: string | null | undefined;
      }
    | null
    | undefined;
  instructorPage: {
    __typename?: 'InstructorPage';
    description: string | null | undefined;
  };
};

export type InstructorsQueryVariables = Exact<{ [key: string]: never }>;

export type InstructorsQuery = {
  __typename?: 'Query';
  instructors:
    | {
        __typename?: 'PagedInstructors';
        data: Array<{
          __typename?: 'Instructor';
          id: string;
          slug: string | null | undefined;
          name: string | null | undefined;
          speciality: string | null | undefined;
          location: string | null | undefined;
          webpage: string | null | undefined;
          team: string | null | undefined;
          assets:
            | {
                __typename?: 'InstructorAssets';
                externalImage: string | null | undefined;
              }
            | null
            | undefined;
          instructorPage: {
            __typename?: 'InstructorPage';
            description: string | null | undefined;
          };
        }>;
      }
    | null
    | undefined;
};

export type InstructorQueryVariables = Exact<{
  id: Scalars['ID'];
  PageBlogPosts: InputMaybe<Scalars['Int']>;
  PageCourses: InputMaybe<Scalars['Int']>;
  PageLiveEvents: InputMaybe<Scalars['Int']>;
  PagePlaylists: InputMaybe<Scalars['Int']>;
  PagePrograms: InputMaybe<Scalars['Int']>;
  PageVideos: InputMaybe<Scalars['Int']>;
}>;

export type InstructorQuery = {
  __typename?: 'Query';
  instructor:
    | {
        __typename?: 'Instructor';
        id: string;
        slug: string | null | undefined;
        name: string | null | undefined;
        shortDescription: string | null | undefined;
        blogDescription: string | null | undefined;
        speciality: string | null | undefined;
        location: string | null | undefined;
        webpage: string | null | undefined;
        assets:
          | {
              __typename?: 'InstructorAssets';
              externalImage: string | null | undefined;
            }
          | null
          | undefined;
        instructorPage: {
          __typename?: 'InstructorPage';
          description: string | null | undefined;
          heroShortDescription: string | null | undefined;
          defaultCampaignCode: string | null | undefined;
          favoritedVideos: {
            __typename?: 'PagedVideos';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'Video';
              id: string;
              identifier: string;
              kind: string;
              cursorAt: number | null | undefined;
              removedText: string | null | undefined;
              content:
                | {
                    __typename?: 'VideoContent';
                    title: string | null | undefined;
                    shortDescription: string | null | undefined;
                    longDescription: string | null | undefined;
                  }
                | null
                | undefined;
              meta:
                | {
                    __typename?: 'VideoMeta';
                    minutes: number | null | undefined;
                    publishedAt: number | null | undefined;
                  }
                | null
                | undefined;
              primaryStyle:
                | {
                    __typename?: 'Term';
                    name: string | null | undefined;
                    slug: string | null | undefined;
                  }
                | null
                | undefined;
              taxonomies: {
                __typename?: 'Taxonomy';
                flavor:
                  | {
                      __typename?: 'Term';
                      id: string;
                      name: string | null | undefined;
                    }
                  | null
                  | undefined;
                needs:
                  | Array<
                      | {
                          __typename?: 'Term';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
                instructors:
                  | Array<
                      | {
                          __typename?: 'Instructor';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                          assets:
                            | {
                                __typename?: 'InstructorAssets';
                                externalImage: string | null | undefined;
                                squareImage: string | null | undefined;
                                titleImage: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
                genre:
                  | {
                      __typename?: 'Term';
                      id: string;
                      name: string | null | undefined;
                      slug: string | null | undefined;
                    }
                  | null
                  | undefined;
              };
              assets:
                | {
                    __typename?: 'VideoAssets';
                    thumbnailUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
          blogPosts: {
            __typename?: 'PagedBlogPosts';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'BlogPost';
              id: string;
              title: string;
              featuredImage: string;
              slug: string;
            }>;
          };
          courses: {
            __typename?: 'PagedGenericPrograms';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'GenericProgram';
              id: string;
              title: string | null | undefined;
              slug: string;
              promotionalImageUrl: string | null | undefined;
              category: ProgramCategory;
              priceType: PriceTypeKind | null | undefined;
              kind: ProgramKind;
              language: LanguageKind | null | undefined;
              titleImageUrl: string | null | undefined;
              shortDescription: string | null | undefined;
              description: string | null | undefined;
              accessStatus: ProgramAccessStatus;
              themesV2:
                | Array<{ __typename?: 'Theme'; label: string; slug: string }>
                | null
                | undefined;
              price:
                | {
                    __typename?: 'Price';
                    originalPrice: number | null | undefined;
                    discountedPrice: number | null | undefined;
                    discountApplied: boolean;
                    currency: string | null | undefined;
                  }
                | null
                | undefined;
              accessPeriod: {
                __typename?: 'AccessPeriod';
                unit: AccessPeriodUnitKind | null | undefined;
                value: number | null | undefined;
              };
              duration:
                | {
                    __typename?: 'Duration';
                    unit: DurationUnitKind | null | undefined;
                    value: number | null | undefined;
                  }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              programAccess:
                | {
                    __typename?: 'ProgramAccess';
                    accessStatus: ProgramAccessStatus;
                    accessUntil: any | null | undefined;
                    joinedAt: any | null | undefined;
                    progressPercentage: number | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
          liveEvents: {
            __typename?: 'PagedLiveEvents';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'LiveEvent';
              id: string;
              title: string;
              slug: string;
              thumbnail: string;
              duration: number;
              eventDates: Array<any>;
              kind: LiveEventKind;
              language: LanguageKind;
              level: LiveEventLevel;
              theme: string | null | undefined;
              themeLabel: string | null | undefined;
              availableSpots: number;
              maximumParticipants: number;
              bookingAvailable: boolean;
              paymentKind: LiveEventPaymentKind;
              flexibleMaxAmountCents: number | null | undefined;
              flexibleMinAmountCents: number | null | undefined;
              customDiscount: number | null | undefined;
              isUserEligible: boolean | null | undefined;
              descriptionLong: string;
              registrationEndDate: any;
              registrationStartDate: any;
              isClosed: boolean | null | undefined;
              isExpired: boolean | null | undefined;
              isComingSoon: boolean | null | undefined;
              price:
                | {
                    __typename?: 'Price';
                    originalPrice: number | null | undefined;
                    discountApplied: boolean;
                    discountedPrice: number | null | undefined;
                    currency: string | null | undefined;
                  }
                | null
                | undefined;
              primaryInstructor:
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              userAccess:
                | {
                    __typename?: 'LiveEventAccess';
                    zoomLink: string;
                    zoomPassword: string;
                  }
                | null
                | undefined;
            }>;
          };
          playlists: {
            __typename?: 'PagedPlaylists';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'Playlist';
              id: string;
              identifier: string | null | undefined;
              visibility: string | null | undefined;
              title: string;
              description: string | null | undefined;
              owner:
                | {
                    __typename?: 'CurrentUser';
                    id: string;
                    firstName: string | null | undefined;
                  }
                | {
                    __typename?: 'User';
                    id: string;
                    firstName: string | null | undefined;
                  }
                | null
                | undefined;
              meta:
                | {
                    __typename?: 'Meta';
                    videoCount: number | null | undefined;
                    minutes: number | null | undefined;
                  }
                | null
                | undefined;
              assets:
                | {
                    __typename?: 'PlaylistAssets';
                    thumbnailUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
          programs: {
            __typename?: 'PagedGenericPrograms';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'GenericProgram';
              id: string;
              title: string | null | undefined;
              slug: string;
              promotionalImageUrl: string | null | undefined;
              category: ProgramCategory;
              priceType: PriceTypeKind | null | undefined;
              kind: ProgramKind;
              language: LanguageKind | null | undefined;
              titleImageUrl: string | null | undefined;
              shortDescription: string | null | undefined;
              description: string | null | undefined;
              accessStatus: ProgramAccessStatus;
              themesV2:
                | Array<{ __typename?: 'Theme'; label: string; slug: string }>
                | null
                | undefined;
              price:
                | {
                    __typename?: 'Price';
                    originalPrice: number | null | undefined;
                    discountedPrice: number | null | undefined;
                    discountApplied: boolean;
                    currency: string | null | undefined;
                  }
                | null
                | undefined;
              accessPeriod: {
                __typename?: 'AccessPeriod';
                unit: AccessPeriodUnitKind | null | undefined;
                value: number | null | undefined;
              };
              duration:
                | {
                    __typename?: 'Duration';
                    unit: DurationUnitKind | null | undefined;
                    value: number | null | undefined;
                  }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              programAccess:
                | {
                    __typename?: 'ProgramAccess';
                    accessStatus: ProgramAccessStatus;
                    accessUntil: any | null | undefined;
                    joinedAt: any | null | undefined;
                    progressPercentage: number | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
          videos: {
            __typename?: 'PagedVideos';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'Video';
              id: string;
              identifier: string;
              kind: string;
              cursorAt: number | null | undefined;
              removedText: string | null | undefined;
              content:
                | {
                    __typename?: 'VideoContent';
                    title: string | null | undefined;
                    shortDescription: string | null | undefined;
                    longDescription: string | null | undefined;
                  }
                | null
                | undefined;
              meta:
                | {
                    __typename?: 'VideoMeta';
                    minutes: number | null | undefined;
                    publishedAt: number | null | undefined;
                  }
                | null
                | undefined;
              primaryStyle:
                | {
                    __typename?: 'Term';
                    name: string | null | undefined;
                    slug: string | null | undefined;
                  }
                | null
                | undefined;
              taxonomies: {
                __typename?: 'Taxonomy';
                flavor:
                  | {
                      __typename?: 'Term';
                      id: string;
                      name: string | null | undefined;
                    }
                  | null
                  | undefined;
                needs:
                  | Array<
                      | {
                          __typename?: 'Term';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
                instructors:
                  | Array<
                      | {
                          __typename?: 'Instructor';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                          assets:
                            | {
                                __typename?: 'InstructorAssets';
                                externalImage: string | null | undefined;
                                squareImage: string | null | undefined;
                                titleImage: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
                genre:
                  | {
                      __typename?: 'Term';
                      id: string;
                      name: string | null | undefined;
                      slug: string | null | undefined;
                    }
                  | null
                  | undefined;
              };
              assets:
                | {
                    __typename?: 'VideoAssets';
                    thumbnailUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
        };
      }
    | null
    | undefined;
};

export type InstructorDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
  videosPerPage?: InputMaybe<Scalars['Int']>;
  videosPage?: InputMaybe<Scalars['Int']>;
  favortieVideosPerPage?: InputMaybe<Scalars['Int']>;
  favortieVideosPage?: InputMaybe<Scalars['Int']>;
  playlistsPerPage?: InputMaybe<Scalars['Int']>;
  playlistsPage?: InputMaybe<Scalars['Int']>;
  liveEventsPerPage?: InputMaybe<Scalars['Int']>;
  liveEventsPage?: InputMaybe<Scalars['Int']>;
  programsPerPage?: InputMaybe<Scalars['Int']>;
  programsPage?: InputMaybe<Scalars['Int']>;
  coursesPerPage?: InputMaybe<Scalars['Int']>;
  coursesPage?: InputMaybe<Scalars['Int']>;
  blogPostsPerPage?: InputMaybe<Scalars['Int']>;
  blogPostsPage?: InputMaybe<Scalars['Int']>;
}>;

export type InstructorDetailsQuery = {
  __typename?: 'Query';
  instructor:
    | {
        __typename?: 'Instructor';
        id: string;
        slug: string | null | undefined;
        name: string | null | undefined;
        shortDescription: string | null | undefined;
        blogDescription: string | null | undefined;
        speciality: string | null | undefined;
        location: string | null | undefined;
        webpage: string | null | undefined;
        assets:
          | {
              __typename?: 'InstructorAssets';
              externalImage: string | null | undefined;
            }
          | null
          | undefined;
        instructorPage: {
          __typename?: 'InstructorPage';
          description: string | null | undefined;
          heroShortDescription: string | null | undefined;
          defaultCampaignCode: string | null | undefined;
          videos: {
            __typename?: 'PagedVideos';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'Video';
              id: string;
              identifier: string;
              kind: string;
              cursorAt: number | null | undefined;
              removedText: string | null | undefined;
              content:
                | {
                    __typename?: 'VideoContent';
                    title: string | null | undefined;
                    shortDescription: string | null | undefined;
                    longDescription: string | null | undefined;
                  }
                | null
                | undefined;
              meta:
                | {
                    __typename?: 'VideoMeta';
                    minutes: number | null | undefined;
                    publishedAt: number | null | undefined;
                  }
                | null
                | undefined;
              primaryStyle:
                | {
                    __typename?: 'Term';
                    name: string | null | undefined;
                    slug: string | null | undefined;
                  }
                | null
                | undefined;
              taxonomies: {
                __typename?: 'Taxonomy';
                flavor:
                  | {
                      __typename?: 'Term';
                      id: string;
                      name: string | null | undefined;
                    }
                  | null
                  | undefined;
                needs:
                  | Array<
                      | {
                          __typename?: 'Term';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
                instructors:
                  | Array<
                      | {
                          __typename?: 'Instructor';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                          assets:
                            | {
                                __typename?: 'InstructorAssets';
                                externalImage: string | null | undefined;
                                squareImage: string | null | undefined;
                                titleImage: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
                genre:
                  | {
                      __typename?: 'Term';
                      id: string;
                      name: string | null | undefined;
                      slug: string | null | undefined;
                    }
                  | null
                  | undefined;
              };
              assets:
                | {
                    __typename?: 'VideoAssets';
                    thumbnailUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
          favoritedVideos: {
            __typename?: 'PagedVideos';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'Video';
              id: string;
              identifier: string;
              kind: string;
              cursorAt: number | null | undefined;
              removedText: string | null | undefined;
              content:
                | {
                    __typename?: 'VideoContent';
                    title: string | null | undefined;
                    shortDescription: string | null | undefined;
                    longDescription: string | null | undefined;
                  }
                | null
                | undefined;
              meta:
                | {
                    __typename?: 'VideoMeta';
                    minutes: number | null | undefined;
                    publishedAt: number | null | undefined;
                  }
                | null
                | undefined;
              primaryStyle:
                | {
                    __typename?: 'Term';
                    name: string | null | undefined;
                    slug: string | null | undefined;
                  }
                | null
                | undefined;
              taxonomies: {
                __typename?: 'Taxonomy';
                flavor:
                  | {
                      __typename?: 'Term';
                      id: string;
                      name: string | null | undefined;
                    }
                  | null
                  | undefined;
                needs:
                  | Array<
                      | {
                          __typename?: 'Term';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
                instructors:
                  | Array<
                      | {
                          __typename?: 'Instructor';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                          assets:
                            | {
                                __typename?: 'InstructorAssets';
                                externalImage: string | null | undefined;
                                squareImage: string | null | undefined;
                                titleImage: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
                genre:
                  | {
                      __typename?: 'Term';
                      id: string;
                      name: string | null | undefined;
                      slug: string | null | undefined;
                    }
                  | null
                  | undefined;
              };
              assets:
                | {
                    __typename?: 'VideoAssets';
                    thumbnailUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
          playlists: {
            __typename?: 'PagedPlaylists';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'Playlist';
              id: string;
              identifier: string | null | undefined;
              visibility: string | null | undefined;
              title: string;
              description: string | null | undefined;
              owner:
                | {
                    __typename?: 'CurrentUser';
                    id: string;
                    firstName: string | null | undefined;
                  }
                | {
                    __typename?: 'User';
                    id: string;
                    firstName: string | null | undefined;
                  }
                | null
                | undefined;
              meta:
                | {
                    __typename?: 'Meta';
                    videoCount: number | null | undefined;
                    minutes: number | null | undefined;
                  }
                | null
                | undefined;
              assets:
                | {
                    __typename?: 'PlaylistAssets';
                    thumbnailUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
          liveEvents: {
            __typename?: 'PagedLiveEvents';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'LiveEvent';
              id: string;
              title: string;
              slug: string;
              thumbnail: string;
              duration: number;
              eventDates: Array<any>;
              kind: LiveEventKind;
              language: LanguageKind;
              level: LiveEventLevel;
              theme: string | null | undefined;
              themeLabel: string | null | undefined;
              availableSpots: number;
              maximumParticipants: number;
              bookingAvailable: boolean;
              paymentKind: LiveEventPaymentKind;
              flexibleMaxAmountCents: number | null | undefined;
              flexibleMinAmountCents: number | null | undefined;
              customDiscount: number | null | undefined;
              isUserEligible: boolean | null | undefined;
              descriptionLong: string;
              registrationEndDate: any;
              registrationStartDate: any;
              isClosed: boolean | null | undefined;
              isExpired: boolean | null | undefined;
              isComingSoon: boolean | null | undefined;
              price:
                | {
                    __typename?: 'Price';
                    originalPrice: number | null | undefined;
                    discountApplied: boolean;
                    discountedPrice: number | null | undefined;
                    currency: string | null | undefined;
                  }
                | null
                | undefined;
              primaryInstructor:
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              userAccess:
                | {
                    __typename?: 'LiveEventAccess';
                    zoomLink: string;
                    zoomPassword: string;
                  }
                | null
                | undefined;
            }>;
          };
          programs: {
            __typename?: 'PagedGenericPrograms';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'GenericProgram';
              id: string;
              title: string | null | undefined;
              slug: string;
              promotionalImageUrl: string | null | undefined;
              category: ProgramCategory;
              priceType: PriceTypeKind | null | undefined;
              kind: ProgramKind;
              language: LanguageKind | null | undefined;
              titleImageUrl: string | null | undefined;
              shortDescription: string | null | undefined;
              description: string | null | undefined;
              accessStatus: ProgramAccessStatus;
              themesV2:
                | Array<{ __typename?: 'Theme'; label: string; slug: string }>
                | null
                | undefined;
              price:
                | {
                    __typename?: 'Price';
                    originalPrice: number | null | undefined;
                    discountedPrice: number | null | undefined;
                    discountApplied: boolean;
                    currency: string | null | undefined;
                  }
                | null
                | undefined;
              accessPeriod: {
                __typename?: 'AccessPeriod';
                unit: AccessPeriodUnitKind | null | undefined;
                value: number | null | undefined;
              };
              duration:
                | {
                    __typename?: 'Duration';
                    unit: DurationUnitKind | null | undefined;
                    value: number | null | undefined;
                  }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              programAccess:
                | {
                    __typename?: 'ProgramAccess';
                    accessStatus: ProgramAccessStatus;
                    accessUntil: any | null | undefined;
                    joinedAt: any | null | undefined;
                    progressPercentage: number | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
          courses: {
            __typename?: 'PagedGenericPrograms';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'GenericProgram';
              id: string;
              title: string | null | undefined;
              slug: string;
              promotionalImageUrl: string | null | undefined;
              category: ProgramCategory;
              priceType: PriceTypeKind | null | undefined;
              kind: ProgramKind;
              language: LanguageKind | null | undefined;
              titleImageUrl: string | null | undefined;
              shortDescription: string | null | undefined;
              description: string | null | undefined;
              accessStatus: ProgramAccessStatus;
              themesV2:
                | Array<{ __typename?: 'Theme'; label: string; slug: string }>
                | null
                | undefined;
              price:
                | {
                    __typename?: 'Price';
                    originalPrice: number | null | undefined;
                    discountedPrice: number | null | undefined;
                    discountApplied: boolean;
                    currency: string | null | undefined;
                  }
                | null
                | undefined;
              accessPeriod: {
                __typename?: 'AccessPeriod';
                unit: AccessPeriodUnitKind | null | undefined;
                value: number | null | undefined;
              };
              duration:
                | {
                    __typename?: 'Duration';
                    unit: DurationUnitKind | null | undefined;
                    value: number | null | undefined;
                  }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              programAccess:
                | {
                    __typename?: 'ProgramAccess';
                    accessStatus: ProgramAccessStatus;
                    accessUntil: any | null | undefined;
                    joinedAt: any | null | undefined;
                    progressPercentage: number | null | undefined;
                  }
                | null
                | undefined;
            }>;
          };
          blogPosts: {
            __typename?: 'PagedBlogPosts';
            page: number;
            totalCount: number;
            data: Array<{
              __typename?: 'BlogPost';
              id: string;
              title: string;
              featuredImage: string;
              slug: string;
            }>;
          };
        };
      }
    | null
    | undefined;
};

export type LandingPageV2QueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type LandingPageV2Query = {
  __typename?: 'Query';
  landingPageV2:
    | {
        __typename?: 'CampaignPage';
        id: string;
        createdAt: any | null | undefined;
        description: string | null | undefined;
        metaDescription: string | null | undefined;
        metaTitle: string | null | undefined;
        name: string | null | undefined;
        path: string | null | undefined;
        slug: string | null | undefined;
        title: string | null | undefined;
        updatedAt: any | null | undefined;
        blogSection:
          | {
              __typename?: 'CampaignBlogSection';
              linkText: string | null | undefined;
              linkUrl: string | null | undefined;
              subtitle: string | null | undefined;
              title: string | null | undefined;
              blog1:
                | {
                    __typename?: 'BlogPost';
                    id: string;
                    slug: string;
                    title: string;
                    featuredImageUrl: string;
                  }
                | null
                | undefined;
              blog2:
                | {
                    __typename?: 'BlogPost';
                    id: string;
                    slug: string;
                    title: string;
                    featuredImageUrl: string;
                  }
                | null
                | undefined;
              blog3:
                | {
                    __typename?: 'BlogPost';
                    id: string;
                    slug: string;
                    title: string;
                    featuredImageUrl: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        bundleSection:
          | {
              __typename?: 'CampaignBundleSection';
              linkText: string | null | undefined;
              linkUrl: string | null | undefined;
              subtitle: string | null | undefined;
              title: string | null | undefined;
              bundle1:
                | {
                    __typename?: 'Bundle';
                    id: string;
                    title: string;
                    slug: string;
                    heroImage: string | null | undefined;
                    cardImage: string | null | undefined;
                    heroMobileImage: string | null | undefined;
                    active: boolean;
                    kind: BundleKind;
                    accessStrategy: BundleAccessStrategyKind;
                    isUserEligible: boolean;
                    bundleCategory: BundleCategoryKind | null | undefined;
                    specialOfferCode: string | null | undefined;
                    accessPeriodUnit: string | null | undefined;
                    accessPeriodValue: number | null | undefined;
                    hiddenInListing: boolean;
                    metaDescription: string;
                    metaImage: string | null | undefined;
                    publishedAt: any | null | undefined;
                    shortDescription: string;
                    promotionalImage: string | null | undefined;
                    tags: Array<string | null | undefined>;
                    price:
                      | {
                          __typename?: 'Price';
                          currency: string | null | undefined;
                          discountApplied: boolean;
                          discountedPrice: number | null | undefined;
                          originalPrice: number | null | undefined;
                        }
                      | null
                      | undefined;
                    subscriptionPromotion:
                      | {
                          __typename?: 'UserSubscription';
                          code: string | null | undefined;
                          title: string | null | undefined;
                          description: string | null | undefined;
                          shortDescription: string | null | undefined;
                          amount: number | null | undefined;
                          discountedAmount: number | null | undefined;
                          currency: string | null | undefined;
                          months: number | null | undefined;
                          days: number | null | undefined;
                          accessUntil: any | null | undefined;
                          promotionId: string | null | undefined;
                          subscriptionName: string;
                          subscriptionPlan: string | null | undefined;
                          promotionKind: PromotionKind | null | undefined;
                          promotionName: string | null | undefined;
                          recurringPromotion: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    userAccess:
                      | {
                          __typename?: 'UserAccess';
                          active: boolean;
                          joinedAt: any | null | undefined;
                          accessUntil: any | null | undefined;
                        }
                      | null
                      | undefined;
                    waitingItemInfo: {
                      __typename?: 'WaitingItemInfo';
                      startDate: any | null | undefined;
                      waitingListJoined: boolean | null | undefined;
                      waitingListEnabled: boolean | null | undefined;
                      waitingListEndDate: any | null | undefined;
                      waitingListStartDate: any | null | undefined;
                    };
                    client:
                      | {
                          __typename?: 'Client';
                          id: string;
                          logo: string | null | undefined;
                          name: string;
                          slug: string;
                          info: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              bundle2:
                | {
                    __typename?: 'Bundle';
                    id: string;
                    title: string;
                    slug: string;
                    heroImage: string | null | undefined;
                    cardImage: string | null | undefined;
                    heroMobileImage: string | null | undefined;
                    active: boolean;
                    kind: BundleKind;
                    accessStrategy: BundleAccessStrategyKind;
                    isUserEligible: boolean;
                    bundleCategory: BundleCategoryKind | null | undefined;
                    specialOfferCode: string | null | undefined;
                    accessPeriodUnit: string | null | undefined;
                    accessPeriodValue: number | null | undefined;
                    hiddenInListing: boolean;
                    metaDescription: string;
                    metaImage: string | null | undefined;
                    publishedAt: any | null | undefined;
                    shortDescription: string;
                    promotionalImage: string | null | undefined;
                    tags: Array<string | null | undefined>;
                    price:
                      | {
                          __typename?: 'Price';
                          currency: string | null | undefined;
                          discountApplied: boolean;
                          discountedPrice: number | null | undefined;
                          originalPrice: number | null | undefined;
                        }
                      | null
                      | undefined;
                    subscriptionPromotion:
                      | {
                          __typename?: 'UserSubscription';
                          code: string | null | undefined;
                          title: string | null | undefined;
                          description: string | null | undefined;
                          shortDescription: string | null | undefined;
                          amount: number | null | undefined;
                          discountedAmount: number | null | undefined;
                          currency: string | null | undefined;
                          months: number | null | undefined;
                          days: number | null | undefined;
                          accessUntil: any | null | undefined;
                          promotionId: string | null | undefined;
                          subscriptionName: string;
                          subscriptionPlan: string | null | undefined;
                          promotionKind: PromotionKind | null | undefined;
                          promotionName: string | null | undefined;
                          recurringPromotion: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    userAccess:
                      | {
                          __typename?: 'UserAccess';
                          active: boolean;
                          joinedAt: any | null | undefined;
                          accessUntil: any | null | undefined;
                        }
                      | null
                      | undefined;
                    waitingItemInfo: {
                      __typename?: 'WaitingItemInfo';
                      startDate: any | null | undefined;
                      waitingListJoined: boolean | null | undefined;
                      waitingListEnabled: boolean | null | undefined;
                      waitingListEndDate: any | null | undefined;
                      waitingListStartDate: any | null | undefined;
                    };
                    client:
                      | {
                          __typename?: 'Client';
                          id: string;
                          logo: string | null | undefined;
                          name: string;
                          slug: string;
                          info: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              bundle3:
                | {
                    __typename?: 'Bundle';
                    id: string;
                    title: string;
                    slug: string;
                    heroImage: string | null | undefined;
                    cardImage: string | null | undefined;
                    heroMobileImage: string | null | undefined;
                    active: boolean;
                    kind: BundleKind;
                    accessStrategy: BundleAccessStrategyKind;
                    isUserEligible: boolean;
                    bundleCategory: BundleCategoryKind | null | undefined;
                    specialOfferCode: string | null | undefined;
                    accessPeriodUnit: string | null | undefined;
                    accessPeriodValue: number | null | undefined;
                    hiddenInListing: boolean;
                    metaDescription: string;
                    metaImage: string | null | undefined;
                    publishedAt: any | null | undefined;
                    shortDescription: string;
                    promotionalImage: string | null | undefined;
                    tags: Array<string | null | undefined>;
                    price:
                      | {
                          __typename?: 'Price';
                          currency: string | null | undefined;
                          discountApplied: boolean;
                          discountedPrice: number | null | undefined;
                          originalPrice: number | null | undefined;
                        }
                      | null
                      | undefined;
                    subscriptionPromotion:
                      | {
                          __typename?: 'UserSubscription';
                          code: string | null | undefined;
                          title: string | null | undefined;
                          description: string | null | undefined;
                          shortDescription: string | null | undefined;
                          amount: number | null | undefined;
                          discountedAmount: number | null | undefined;
                          currency: string | null | undefined;
                          months: number | null | undefined;
                          days: number | null | undefined;
                          accessUntil: any | null | undefined;
                          promotionId: string | null | undefined;
                          subscriptionName: string;
                          subscriptionPlan: string | null | undefined;
                          promotionKind: PromotionKind | null | undefined;
                          promotionName: string | null | undefined;
                          recurringPromotion: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    userAccess:
                      | {
                          __typename?: 'UserAccess';
                          active: boolean;
                          joinedAt: any | null | undefined;
                          accessUntil: any | null | undefined;
                        }
                      | null
                      | undefined;
                    waitingItemInfo: {
                      __typename?: 'WaitingItemInfo';
                      startDate: any | null | undefined;
                      waitingListJoined: boolean | null | undefined;
                      waitingListEnabled: boolean | null | undefined;
                      waitingListEndDate: any | null | undefined;
                      waitingListStartDate: any | null | undefined;
                    };
                    client:
                      | {
                          __typename?: 'Client';
                          id: string;
                          logo: string | null | undefined;
                          name: string;
                          slug: string;
                          info: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        ctaSection1:
          | {
              __typename?: 'CampaignCtaSection';
              linkText: string | null | undefined;
              linkUrl: string | null | undefined;
              subtitle: string | null | undefined;
              title: string | null | undefined;
              image:
                | {
                    __typename?: 'Image';
                    altText: string | null | undefined;
                    largeImageUrl: string;
                    mainImageUrl: string;
                    mediumImageUrl: string;
                    originalImageUrl: string;
                    thumbnailImageUrl: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        ctaSection2:
          | {
              __typename?: 'CampaignCtaSection';
              linkText: string | null | undefined;
              linkUrl: string | null | undefined;
              subtitle: string | null | undefined;
              title: string | null | undefined;
              image:
                | {
                    __typename?: 'Image';
                    altText: string | null | undefined;
                    largeImageUrl: string;
                    mainImageUrl: string;
                    mediumImageUrl: string;
                    originalImageUrl: string;
                    thumbnailImageUrl: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        qASection:
          | Array<{
              __typename?: 'QAndA';
              answer: string | null | undefined;
              id: string;
              position: number | null | undefined;
              question: string | null | undefined;
            }>
          | null
          | undefined;
        textSection:
          | {
              __typename?: 'CampaignTextSection';
              subtitle: string | null | undefined;
              title: string | null | undefined;
              content1: string | null | undefined;
              content2: string | null | undefined;
              content3: string | null | undefined;
              content4: string | null | undefined;
              content5: string | null | undefined;
              image:
                | {
                    __typename?: 'Image';
                    altText: string | null | undefined;
                    largeImageUrl: string;
                    mainImageUrl: string;
                    mediumImageUrl: string;
                    originalImageUrl: string;
                    thumbnailImageUrl: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        metaImage:
          | {
              __typename?: 'Image';
              altText: string | null | undefined;
              largeImageUrl: string;
              mainImageUrl: string;
              mediumImageUrl: string;
              originalImageUrl: string;
              thumbnailImageUrl: string;
            }
          | null
          | undefined;
        trailerImage:
          | {
              __typename?: 'Image';
              altText: string | null | undefined;
              largeImageUrl: string;
              mainImageUrl: string;
              mediumImageUrl: string;
              originalImageUrl: string;
              thumbnailImageUrl: string;
            }
          | null
          | undefined;
        trailerVideo:
          | {
              __typename?: 'Video';
              identifier: string;
              minutes: number;
              thumbnailUrl: string | null | undefined;
              hls: string;
              assets:
                | {
                    __typename?: 'VideoAssets';
                    thumbnailUrl: string | null | undefined;
                  }
                | null
                | undefined;
              player:
                | {
                    __typename?: 'VideoInfo';
                    assets:
                      | Array<
                          | {
                              __typename?: 'VideoFileAsset';
                              resolution: number | null | undefined;
                              src: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        videoSection:
          | {
              __typename?: 'CampaignVideoSection';
              linkText: string | null | undefined;
              linkUrl: string | null | undefined;
              subtitle: string | null | undefined;
              title: string | null | undefined;
              video1:
                | {
                    __typename?: 'Video';
                    id: string;
                    identifier: string;
                    kind: string;
                    cursorAt: number | null | undefined;
                    removedText: string | null | undefined;
                    content:
                      | {
                          __typename?: 'VideoContent';
                          title: string | null | undefined;
                          shortDescription: string | null | undefined;
                          longDescription: string | null | undefined;
                        }
                      | null
                      | undefined;
                    meta:
                      | {
                          __typename?: 'VideoMeta';
                          minutes: number | null | undefined;
                          publishedAt: number | null | undefined;
                        }
                      | null
                      | undefined;
                    primaryStyle:
                      | {
                          __typename?: 'Term';
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined;
                    taxonomies: {
                      __typename?: 'Taxonomy';
                      flavor:
                        | {
                            __typename?: 'Term';
                            id: string;
                            name: string | null | undefined;
                          }
                        | null
                        | undefined;
                      needs:
                        | Array<
                            | {
                                __typename?: 'Term';
                                id: string;
                                name: string | null | undefined;
                                slug: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      instructors:
                        | Array<
                            | {
                                __typename?: 'Instructor';
                                id: string;
                                name: string | null | undefined;
                                slug: string | null | undefined;
                                assets:
                                  | {
                                      __typename?: 'InstructorAssets';
                                      externalImage: string | null | undefined;
                                      squareImage: string | null | undefined;
                                      titleImage: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      genre:
                        | {
                            __typename?: 'Term';
                            id: string;
                            name: string | null | undefined;
                            slug: string | null | undefined;
                          }
                        | null
                        | undefined;
                    };
                    assets:
                      | {
                          __typename?: 'VideoAssets';
                          thumbnailUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              video2:
                | {
                    __typename?: 'Video';
                    id: string;
                    identifier: string;
                    kind: string;
                    cursorAt: number | null | undefined;
                    removedText: string | null | undefined;
                    content:
                      | {
                          __typename?: 'VideoContent';
                          title: string | null | undefined;
                          shortDescription: string | null | undefined;
                          longDescription: string | null | undefined;
                        }
                      | null
                      | undefined;
                    meta:
                      | {
                          __typename?: 'VideoMeta';
                          minutes: number | null | undefined;
                          publishedAt: number | null | undefined;
                        }
                      | null
                      | undefined;
                    primaryStyle:
                      | {
                          __typename?: 'Term';
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined;
                    taxonomies: {
                      __typename?: 'Taxonomy';
                      flavor:
                        | {
                            __typename?: 'Term';
                            id: string;
                            name: string | null | undefined;
                          }
                        | null
                        | undefined;
                      needs:
                        | Array<
                            | {
                                __typename?: 'Term';
                                id: string;
                                name: string | null | undefined;
                                slug: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      instructors:
                        | Array<
                            | {
                                __typename?: 'Instructor';
                                id: string;
                                name: string | null | undefined;
                                slug: string | null | undefined;
                                assets:
                                  | {
                                      __typename?: 'InstructorAssets';
                                      externalImage: string | null | undefined;
                                      squareImage: string | null | undefined;
                                      titleImage: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      genre:
                        | {
                            __typename?: 'Term';
                            id: string;
                            name: string | null | undefined;
                            slug: string | null | undefined;
                          }
                        | null
                        | undefined;
                    };
                    assets:
                      | {
                          __typename?: 'VideoAssets';
                          thumbnailUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              video3:
                | {
                    __typename?: 'Video';
                    id: string;
                    identifier: string;
                    kind: string;
                    cursorAt: number | null | undefined;
                    removedText: string | null | undefined;
                    content:
                      | {
                          __typename?: 'VideoContent';
                          title: string | null | undefined;
                          shortDescription: string | null | undefined;
                          longDescription: string | null | undefined;
                        }
                      | null
                      | undefined;
                    meta:
                      | {
                          __typename?: 'VideoMeta';
                          minutes: number | null | undefined;
                          publishedAt: number | null | undefined;
                        }
                      | null
                      | undefined;
                    primaryStyle:
                      | {
                          __typename?: 'Term';
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined;
                    taxonomies: {
                      __typename?: 'Taxonomy';
                      flavor:
                        | {
                            __typename?: 'Term';
                            id: string;
                            name: string | null | undefined;
                          }
                        | null
                        | undefined;
                      needs:
                        | Array<
                            | {
                                __typename?: 'Term';
                                id: string;
                                name: string | null | undefined;
                                slug: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      instructors:
                        | Array<
                            | {
                                __typename?: 'Instructor';
                                id: string;
                                name: string | null | undefined;
                                slug: string | null | undefined;
                                assets:
                                  | {
                                      __typename?: 'InstructorAssets';
                                      externalImage: string | null | undefined;
                                      squareImage: string | null | undefined;
                                      titleImage: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      genre:
                        | {
                            __typename?: 'Term';
                            id: string;
                            name: string | null | undefined;
                            slug: string | null | undefined;
                          }
                        | null
                        | undefined;
                    };
                    assets:
                      | {
                          __typename?: 'VideoAssets';
                          thumbnailUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type LandingPagesQueryVariables = Exact<{
  path: Scalars['String'];
}>;

export type LandingPagesQuery = {
  __typename?: 'Query';
  pageByRoute:
    | {
        __typename?: 'Page';
        id: string;
        kind: string;
        name: string;
        internalTitle: string | null | undefined;
        internalLede: string | null | undefined;
        externalTitle: string | null | undefined;
        externalLede: string | null | undefined;
        testimonials:
          | Array<
              | {
                  __typename?: 'Testimonial';
                  name: string;
                  title: string;
                  quote: string;
                  imageUrl: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        assets:
          | {
              __typename?: 'PageAssets';
              titleImage: string;
              internalImage: string;
              externalImage: string;
              titleVideo:
                | {
                    __typename?: 'Video';
                    id: string;
                    kind: string;
                    hls: string;
                    content:
                      | {
                          __typename?: 'VideoContent';
                          title: string | null | undefined;
                          shortDescription: string | null | undefined;
                        }
                      | null
                      | undefined;
                    assets:
                      | {
                          __typename?: 'VideoAssets';
                          thumbnailUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                    player:
                      | {
                          __typename?: 'VideoInfo';
                          assets:
                            | Array<
                                | {
                                    __typename?: 'VideoFileAsset';
                                    src: string | null | undefined;
                                    resolution: number | null | undefined;
                                    size: number | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              ledeVideos:
                | Array<
                    | {
                        __typename?: 'Video';
                        id: string;
                        identifier: string;
                        assets:
                          | {
                              __typename?: 'VideoAssets';
                              thumbnailUrl: string | null | undefined;
                            }
                          | null
                          | undefined;
                        content:
                          | {
                              __typename?: 'VideoContent';
                              title: string | null | undefined;
                              shortDescription: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        programs:
          | Array<
              | {
                  __typename?: 'Page';
                  id: string;
                  path: string;
                  internalTitle: string | null | undefined;
                  internalLede: string | null | undefined;
                  name: string;
                  slug: string;
                  assets:
                    | { __typename?: 'PageAssets'; titleImage: string }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        playlists:
          | Array<
              | {
                  __typename?: 'Playlist';
                  id: string;
                  identifier: string | null | undefined;
                  languages: Array<string> | null | undefined;
                  title: string;
                  description: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'PlaylistAssets';
                        thumbnailUrl: string | null | undefined;
                      }
                    | null
                    | undefined;
                  meta:
                    | {
                        __typename?: 'Meta';
                        minutes: number | null | undefined;
                        videoCount: number | null | undefined;
                      }
                    | null
                    | undefined;
                  owner:
                    | {
                        __typename?: 'CurrentUser';
                        id: string;
                        firstName: string | null | undefined;
                      }
                    | {
                        __typename?: 'User';
                        id: string;
                        firstName: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        videos:
          | Array<
              | {
                  __typename?: 'Video';
                  id: string;
                  identifier: string;
                  kind: string;
                  hls: string;
                  player:
                    | {
                        __typename?: 'VideoInfo';
                        assets:
                          | Array<
                              | {
                                  __typename?: 'VideoFileAsset';
                                  src: string | null | undefined;
                                  resolution: number | null | undefined;
                                  size: number | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  content:
                    | {
                        __typename?: 'VideoContent';
                        title: string | null | undefined;
                        shortDescription: string | null | undefined;
                      }
                    | null
                    | undefined;
                  assets:
                    | {
                        __typename?: 'VideoAssets';
                        thumbnailUrl: string | null | undefined;
                      }
                    | null
                    | undefined;
                  taxonomies: {
                    __typename?: 'Taxonomy';
                    flavor:
                      | {
                          __typename?: 'Term';
                          id: string;
                          name: string | null | undefined;
                        }
                      | null
                      | undefined;
                    genre:
                      | {
                          __typename?: 'Term';
                          id: string;
                          name: string | null | undefined;
                          slug: string | null | undefined;
                        }
                      | null
                      | undefined;
                    instructors:
                      | Array<
                          | {
                              __typename?: 'Instructor';
                              id: string;
                              name: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  };
                  meta:
                    | {
                        __typename?: 'VideoMeta';
                        publishedAt: number | null | undefined;
                        minutes: number | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        content:
          | {
              __typename?: 'PageContent';
              lede: string | null | undefined;
              subtitle: string | null | undefined;
              body: string | null | undefined;
              body1: string | null | undefined;
              body2: string | null | undefined;
              body3: string | null | undefined;
              body4: string | null | undefined;
              body5: string | null | undefined;
              body6: string | null | undefined;
              englishTitle: string | null | undefined;
              sanskritTitle: string | null | undefined;
              localLanguageTitle: string | null | undefined;
              internalTitle: string | null | undefined;
              internalLede: string | null | undefined;
              externalTitle: string | null | undefined;
              extraImages: string | null | undefined;
            }
          | null
          | undefined;
        taxonomies:
          | {
              __typename?: 'TaxonomyForLandingPage';
              instructors:
                | Array<{
                    __typename?: 'Instructor';
                    slug: string | null | undefined;
                    name: string | null | undefined;
                    shortDescription: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type LiveCodeDataFieldsFragment = {
  __typename?: 'PromotionCode';
  code: string;
  expirationDate: any | null | undefined;
  usageLimit: number | null | undefined;
  creationDate: any;
  provider: string | null | undefined;
  matchingResources: Array<
    | { __typename?: 'Bundle' }
    | { __typename?: 'GenericProgram' }
    | {
        __typename?: 'LiveEvent';
        id: string;
        title: string;
        slug: string;
        thumbnail: string;
        duration: number;
        eventDates: Array<any>;
        kind: LiveEventKind;
        language: LanguageKind;
        level: LiveEventLevel;
        theme: string | null | undefined;
        themeLabel: string | null | undefined;
        availableSpots: number;
        maximumParticipants: number;
        bookingAvailable: boolean;
        paymentKind: LiveEventPaymentKind;
        flexibleMaxAmountCents: number | null | undefined;
        flexibleMinAmountCents: number | null | undefined;
        customDiscount: number | null | undefined;
        isUserEligible: boolean | null | undefined;
        descriptionLong: string;
        registrationEndDate: any;
        registrationStartDate: any;
        isClosed: boolean | null | undefined;
        isExpired: boolean | null | undefined;
        isComingSoon: boolean | null | undefined;
        price:
          | {
              __typename?: 'Price';
              originalPrice: number | null | undefined;
              discountApplied: boolean;
              discountedPrice: number | null | undefined;
              currency: string | null | undefined;
            }
          | null
          | undefined;
        primaryInstructor:
          | {
              __typename?: 'Instructor';
              id: string;
              name: string | null | undefined;
              slug: string | null | undefined;
              assets:
                | {
                    __typename?: 'InstructorAssets';
                    squareImage: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        userAccess:
          | {
              __typename?: 'LiveEventAccess';
              zoomLink: string;
              zoomPassword: string;
            }
          | null
          | undefined;
      }
  >;
  usedIn: Array<{
    __typename?: 'PromotionCodeUser';
    usageDate: any;
    live:
      | {
          __typename?: 'LiveEvent';
          id: string;
          title: string;
          slug: string;
          thumbnail: string;
          duration: number;
          eventDates: Array<any>;
          kind: LiveEventKind;
          language: LanguageKind;
          level: LiveEventLevel;
          theme: string | null | undefined;
          themeLabel: string | null | undefined;
          availableSpots: number;
          maximumParticipants: number;
          bookingAvailable: boolean;
          paymentKind: LiveEventPaymentKind;
          flexibleMaxAmountCents: number | null | undefined;
          flexibleMinAmountCents: number | null | undefined;
          customDiscount: number | null | undefined;
          isUserEligible: boolean | null | undefined;
          descriptionLong: string;
          registrationEndDate: any;
          registrationStartDate: any;
          isClosed: boolean | null | undefined;
          isExpired: boolean | null | undefined;
          isComingSoon: boolean | null | undefined;
          price:
            | {
                __typename?: 'Price';
                originalPrice: number | null | undefined;
                discountApplied: boolean;
                discountedPrice: number | null | undefined;
                currency: string | null | undefined;
              }
            | null
            | undefined;
          primaryInstructor:
            | {
                __typename?: 'Instructor';
                id: string;
                name: string | null | undefined;
                slug: string | null | undefined;
                assets:
                  | {
                      __typename?: 'InstructorAssets';
                      squareImage: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          instructors:
            | Array<
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          userAccess:
            | {
                __typename?: 'LiveEventAccess';
                zoomLink: string;
                zoomPassword: string;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
};

export type LiveEventCodesQueryVariables = Exact<{ [key: string]: never }>;

export type LiveEventCodesQuery = {
  __typename?: 'Query';
  liveEventCodes: {
    __typename?: 'LiveEventCodes';
    trial: Array<{
      __typename?: 'PromotionCode';
      code: string;
      expirationDate: any | null | undefined;
      usageLimit: number | null | undefined;
      creationDate: any;
      provider: string | null | undefined;
      matchingResources: Array<
        | { __typename?: 'Bundle' }
        | { __typename?: 'GenericProgram' }
        | {
            __typename?: 'LiveEvent';
            id: string;
            title: string;
            slug: string;
            thumbnail: string;
            duration: number;
            eventDates: Array<any>;
            kind: LiveEventKind;
            language: LanguageKind;
            level: LiveEventLevel;
            theme: string | null | undefined;
            themeLabel: string | null | undefined;
            availableSpots: number;
            maximumParticipants: number;
            bookingAvailable: boolean;
            paymentKind: LiveEventPaymentKind;
            flexibleMaxAmountCents: number | null | undefined;
            flexibleMinAmountCents: number | null | undefined;
            customDiscount: number | null | undefined;
            isUserEligible: boolean | null | undefined;
            descriptionLong: string;
            registrationEndDate: any;
            registrationStartDate: any;
            isClosed: boolean | null | undefined;
            isExpired: boolean | null | undefined;
            isComingSoon: boolean | null | undefined;
            price:
              | {
                  __typename?: 'Price';
                  originalPrice: number | null | undefined;
                  discountApplied: boolean;
                  discountedPrice: number | null | undefined;
                  currency: string | null | undefined;
                }
              | null
              | undefined;
            primaryInstructor:
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            instructors:
              | Array<
                  | {
                      __typename?: 'Instructor';
                      id: string;
                      name: string | null | undefined;
                      slug: string | null | undefined;
                      assets:
                        | {
                            __typename?: 'InstructorAssets';
                            squareImage: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            userAccess:
              | {
                  __typename?: 'LiveEventAccess';
                  zoomLink: string;
                  zoomPassword: string;
                }
              | null
              | undefined;
          }
      >;
      usedIn: Array<{
        __typename?: 'PromotionCodeUser';
        usageDate: any;
        live:
          | {
              __typename?: 'LiveEvent';
              id: string;
              title: string;
              slug: string;
              thumbnail: string;
              duration: number;
              eventDates: Array<any>;
              kind: LiveEventKind;
              language: LanguageKind;
              level: LiveEventLevel;
              theme: string | null | undefined;
              themeLabel: string | null | undefined;
              availableSpots: number;
              maximumParticipants: number;
              bookingAvailable: boolean;
              paymentKind: LiveEventPaymentKind;
              flexibleMaxAmountCents: number | null | undefined;
              flexibleMinAmountCents: number | null | undefined;
              customDiscount: number | null | undefined;
              isUserEligible: boolean | null | undefined;
              descriptionLong: string;
              registrationEndDate: any;
              registrationStartDate: any;
              isClosed: boolean | null | undefined;
              isExpired: boolean | null | undefined;
              isComingSoon: boolean | null | undefined;
              price:
                | {
                    __typename?: 'Price';
                    originalPrice: number | null | undefined;
                    discountApplied: boolean;
                    discountedPrice: number | null | undefined;
                    currency: string | null | undefined;
                  }
                | null
                | undefined;
              primaryInstructor:
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              userAccess:
                | {
                    __typename?: 'LiveEventAccess';
                    zoomLink: string;
                    zoomPassword: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }>;
    }>;
    wellness: Array<{
      __typename?: 'PromotionCode';
      code: string;
      expirationDate: any | null | undefined;
      usageLimit: number | null | undefined;
      creationDate: any;
      provider: string | null | undefined;
      matchingResources: Array<
        | { __typename?: 'Bundle' }
        | { __typename?: 'GenericProgram' }
        | {
            __typename?: 'LiveEvent';
            id: string;
            title: string;
            slug: string;
            thumbnail: string;
            duration: number;
            eventDates: Array<any>;
            kind: LiveEventKind;
            language: LanguageKind;
            level: LiveEventLevel;
            theme: string | null | undefined;
            themeLabel: string | null | undefined;
            availableSpots: number;
            maximumParticipants: number;
            bookingAvailable: boolean;
            paymentKind: LiveEventPaymentKind;
            flexibleMaxAmountCents: number | null | undefined;
            flexibleMinAmountCents: number | null | undefined;
            customDiscount: number | null | undefined;
            isUserEligible: boolean | null | undefined;
            descriptionLong: string;
            registrationEndDate: any;
            registrationStartDate: any;
            isClosed: boolean | null | undefined;
            isExpired: boolean | null | undefined;
            isComingSoon: boolean | null | undefined;
            price:
              | {
                  __typename?: 'Price';
                  originalPrice: number | null | undefined;
                  discountApplied: boolean;
                  discountedPrice: number | null | undefined;
                  currency: string | null | undefined;
                }
              | null
              | undefined;
            primaryInstructor:
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            instructors:
              | Array<
                  | {
                      __typename?: 'Instructor';
                      id: string;
                      name: string | null | undefined;
                      slug: string | null | undefined;
                      assets:
                        | {
                            __typename?: 'InstructorAssets';
                            squareImage: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            userAccess:
              | {
                  __typename?: 'LiveEventAccess';
                  zoomLink: string;
                  zoomPassword: string;
                }
              | null
              | undefined;
          }
      >;
      usedIn: Array<{
        __typename?: 'PromotionCodeUser';
        usageDate: any;
        live:
          | {
              __typename?: 'LiveEvent';
              id: string;
              title: string;
              slug: string;
              thumbnail: string;
              duration: number;
              eventDates: Array<any>;
              kind: LiveEventKind;
              language: LanguageKind;
              level: LiveEventLevel;
              theme: string | null | undefined;
              themeLabel: string | null | undefined;
              availableSpots: number;
              maximumParticipants: number;
              bookingAvailable: boolean;
              paymentKind: LiveEventPaymentKind;
              flexibleMaxAmountCents: number | null | undefined;
              flexibleMinAmountCents: number | null | undefined;
              customDiscount: number | null | undefined;
              isUserEligible: boolean | null | undefined;
              descriptionLong: string;
              registrationEndDate: any;
              registrationStartDate: any;
              isClosed: boolean | null | undefined;
              isExpired: boolean | null | undefined;
              isComingSoon: boolean | null | undefined;
              price:
                | {
                    __typename?: 'Price';
                    originalPrice: number | null | undefined;
                    discountApplied: boolean;
                    discountedPrice: number | null | undefined;
                    currency: string | null | undefined;
                  }
                | null
                | undefined;
              primaryInstructor:
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              userAccess:
                | {
                    __typename?: 'LiveEventAccess';
                    zoomLink: string;
                    zoomPassword: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }>;
    }>;
    campaign: Array<{
      __typename?: 'PromotionCode';
      code: string;
      expirationDate: any | null | undefined;
      usageLimit: number | null | undefined;
      creationDate: any;
      provider: string | null | undefined;
      matchingResources: Array<
        | { __typename?: 'Bundle' }
        | { __typename?: 'GenericProgram' }
        | {
            __typename?: 'LiveEvent';
            id: string;
            title: string;
            slug: string;
            thumbnail: string;
            duration: number;
            eventDates: Array<any>;
            kind: LiveEventKind;
            language: LanguageKind;
            level: LiveEventLevel;
            theme: string | null | undefined;
            themeLabel: string | null | undefined;
            availableSpots: number;
            maximumParticipants: number;
            bookingAvailable: boolean;
            paymentKind: LiveEventPaymentKind;
            flexibleMaxAmountCents: number | null | undefined;
            flexibleMinAmountCents: number | null | undefined;
            customDiscount: number | null | undefined;
            isUserEligible: boolean | null | undefined;
            descriptionLong: string;
            registrationEndDate: any;
            registrationStartDate: any;
            isClosed: boolean | null | undefined;
            isExpired: boolean | null | undefined;
            isComingSoon: boolean | null | undefined;
            price:
              | {
                  __typename?: 'Price';
                  originalPrice: number | null | undefined;
                  discountApplied: boolean;
                  discountedPrice: number | null | undefined;
                  currency: string | null | undefined;
                }
              | null
              | undefined;
            primaryInstructor:
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            instructors:
              | Array<
                  | {
                      __typename?: 'Instructor';
                      id: string;
                      name: string | null | undefined;
                      slug: string | null | undefined;
                      assets:
                        | {
                            __typename?: 'InstructorAssets';
                            squareImage: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            userAccess:
              | {
                  __typename?: 'LiveEventAccess';
                  zoomLink: string;
                  zoomPassword: string;
                }
              | null
              | undefined;
          }
      >;
      usedIn: Array<{
        __typename?: 'PromotionCodeUser';
        usageDate: any;
        live:
          | {
              __typename?: 'LiveEvent';
              id: string;
              title: string;
              slug: string;
              thumbnail: string;
              duration: number;
              eventDates: Array<any>;
              kind: LiveEventKind;
              language: LanguageKind;
              level: LiveEventLevel;
              theme: string | null | undefined;
              themeLabel: string | null | undefined;
              availableSpots: number;
              maximumParticipants: number;
              bookingAvailable: boolean;
              paymentKind: LiveEventPaymentKind;
              flexibleMaxAmountCents: number | null | undefined;
              flexibleMinAmountCents: number | null | undefined;
              customDiscount: number | null | undefined;
              isUserEligible: boolean | null | undefined;
              descriptionLong: string;
              registrationEndDate: any;
              registrationStartDate: any;
              isClosed: boolean | null | undefined;
              isExpired: boolean | null | undefined;
              isComingSoon: boolean | null | undefined;
              price:
                | {
                    __typename?: 'Price';
                    originalPrice: number | null | undefined;
                    discountApplied: boolean;
                    discountedPrice: number | null | undefined;
                    currency: string | null | undefined;
                  }
                | null
                | undefined;
              primaryInstructor:
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              userAccess:
                | {
                    __typename?: 'LiveEventAccess';
                    zoomLink: string;
                    zoomPassword: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }>;
    }>;
  };
};

export type ParticipateInAnEventMutationVariables = Exact<{
  slug: Scalars['String'];
}>;

export type ParticipateInAnEventMutation = {
  __typename?: 'Mutation';
  participateInAnEvent:
    | {
        __typename?: 'ParticipateInAnEventPayload';
        success: boolean;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ActivateLiveEventMutationVariables = Exact<{
  slug: Scalars['String'];
  code: Scalars['String'];
}>;

export type ActivateLiveEventMutation = {
  __typename?: 'Mutation';
  activateLiveEvent:
    | {
        __typename?: 'ActivateLiveEventPayload';
        success: boolean;
        errors: string | null | undefined;
      }
    | null
    | undefined;
};

export type LiveEventEssentialFieldsFragment = {
  __typename?: 'LiveEvent';
  id: string;
  title: string;
  slug: string;
  thumbnail: string;
  duration: number;
  eventDates: Array<any>;
  kind: LiveEventKind;
  language: LanguageKind;
  level: LiveEventLevel;
  theme: string | null | undefined;
  themeLabel: string | null | undefined;
  availableSpots: number;
  maximumParticipants: number;
  bookingAvailable: boolean;
  paymentKind: LiveEventPaymentKind;
  flexibleMaxAmountCents: number | null | undefined;
  flexibleMinAmountCents: number | null | undefined;
  customDiscount: number | null | undefined;
  isUserEligible: boolean | null | undefined;
  descriptionLong: string;
  registrationEndDate: any;
  registrationStartDate: any;
  isClosed: boolean | null | undefined;
  isExpired: boolean | null | undefined;
  isComingSoon: boolean | null | undefined;
  price:
    | {
        __typename?: 'Price';
        originalPrice: number | null | undefined;
        discountApplied: boolean;
        discountedPrice: number | null | undefined;
        currency: string | null | undefined;
      }
    | null
    | undefined;
  primaryInstructor:
    | {
        __typename?: 'Instructor';
        id: string;
        name: string | null | undefined;
        slug: string | null | undefined;
        assets:
          | {
              __typename?: 'InstructorAssets';
              squareImage: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  instructors:
    | Array<
        | {
            __typename?: 'Instructor';
            id: string;
            name: string | null | undefined;
            slug: string | null | undefined;
            assets:
              | {
                  __typename?: 'InstructorAssets';
                  squareImage: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  userAccess:
    | { __typename?: 'LiveEventAccess'; zoomLink: string; zoomPassword: string }
    | null
    | undefined;
};

export type LiveRecordingVideoFieldsFragment = {
  __typename?: 'Video';
  id: string;
  title: string;
  kind: string;
  identifier: string;
  hasAccess: boolean;
  cursorAt: number | null | undefined;
  hls: string;
  content:
    | {
        __typename?: 'VideoContent';
        title: string | null | undefined;
        shortDescription: string | null | undefined;
        longDescription: string | null | undefined;
      }
    | null
    | undefined;
  player:
    | {
        __typename?: 'VideoInfo';
        assets:
          | Array<
              | {
                  __typename?: 'VideoFileAsset';
                  src: string | null | undefined;
                  resolution: number | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  assets:
    | { __typename?: 'VideoAssets'; thumbnailUrl: string | null | undefined }
    | null
    | undefined;
};

export type FullLiveEventFieldsFragment = {
  __typename?: 'LiveEvent';
  isExternal: boolean;
  hostedBy: string | null | undefined;
  hostedByPageMessage: string | null | undefined;
  accessories: Array<string>;
  id: string;
  title: string;
  slug: string;
  thumbnail: string;
  duration: number;
  eventDates: Array<any>;
  kind: LiveEventKind;
  language: LanguageKind;
  level: LiveEventLevel;
  theme: string | null | undefined;
  themeLabel: string | null | undefined;
  availableSpots: number;
  maximumParticipants: number;
  bookingAvailable: boolean;
  paymentKind: LiveEventPaymentKind;
  flexibleMaxAmountCents: number | null | undefined;
  flexibleMinAmountCents: number | null | undefined;
  customDiscount: number | null | undefined;
  isUserEligible: boolean | null | undefined;
  descriptionLong: string;
  registrationEndDate: any;
  registrationStartDate: any;
  isClosed: boolean | null | undefined;
  isExpired: boolean | null | undefined;
  isComingSoon: boolean | null | undefined;
  otherInstructors: Array<{
    __typename?: 'Instructor';
    id: string;
    name: string | null | undefined;
    slug: string | null | undefined;
    assets:
      | {
          __typename?: 'InstructorAssets';
          squareImage: string | null | undefined;
        }
      | null
      | undefined;
  }>;
  linkToRecordings:
    | Array<{
        __typename?: 'LinkToRecording';
        link: string | null | undefined;
        id: string;
        eventDate: any;
        expiresAt: any | null | undefined;
        isExpired: boolean | null | undefined;
        video:
          | {
              __typename?: 'Video';
              id: string;
              title: string;
              kind: string;
              identifier: string;
              hasAccess: boolean;
              cursorAt: number | null | undefined;
              hls: string;
              content:
                | {
                    __typename?: 'VideoContent';
                    title: string | null | undefined;
                    shortDescription: string | null | undefined;
                    longDescription: string | null | undefined;
                  }
                | null
                | undefined;
              player:
                | {
                    __typename?: 'VideoInfo';
                    assets:
                      | Array<
                          | {
                              __typename?: 'VideoFileAsset';
                              src: string | null | undefined;
                              resolution: number | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              assets:
                | {
                    __typename?: 'VideoAssets';
                    thumbnailUrl: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  price:
    | {
        __typename?: 'Price';
        originalPrice: number | null | undefined;
        discountApplied: boolean;
        discountedPrice: number | null | undefined;
        currency: string | null | undefined;
      }
    | null
    | undefined;
  primaryInstructor:
    | {
        __typename?: 'Instructor';
        id: string;
        name: string | null | undefined;
        slug: string | null | undefined;
        assets:
          | {
              __typename?: 'InstructorAssets';
              squareImage: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  instructors:
    | Array<
        | {
            __typename?: 'Instructor';
            id: string;
            name: string | null | undefined;
            slug: string | null | undefined;
            assets:
              | {
                  __typename?: 'InstructorAssets';
                  squareImage: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  userAccess:
    | { __typename?: 'LiveEventAccess'; zoomLink: string; zoomPassword: string }
    | null
    | undefined;
};

export type LiveEventQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type LiveEventQuery = {
  __typename?: 'Query';
  liveEvent:
    | {
        __typename?: 'LiveEvent';
        isExternal: boolean;
        hostedBy: string | null | undefined;
        hostedByPageMessage: string | null | undefined;
        accessories: Array<string>;
        id: string;
        title: string;
        slug: string;
        thumbnail: string;
        duration: number;
        eventDates: Array<any>;
        kind: LiveEventKind;
        language: LanguageKind;
        level: LiveEventLevel;
        theme: string | null | undefined;
        themeLabel: string | null | undefined;
        availableSpots: number;
        maximumParticipants: number;
        bookingAvailable: boolean;
        paymentKind: LiveEventPaymentKind;
        flexibleMaxAmountCents: number | null | undefined;
        flexibleMinAmountCents: number | null | undefined;
        customDiscount: number | null | undefined;
        isUserEligible: boolean | null | undefined;
        descriptionLong: string;
        registrationEndDate: any;
        registrationStartDate: any;
        isClosed: boolean | null | undefined;
        isExpired: boolean | null | undefined;
        isComingSoon: boolean | null | undefined;
        otherInstructors: Array<{
          __typename?: 'Instructor';
          id: string;
          name: string | null | undefined;
          slug: string | null | undefined;
          assets:
            | {
                __typename?: 'InstructorAssets';
                squareImage: string | null | undefined;
              }
            | null
            | undefined;
        }>;
        linkToRecordings:
          | Array<{
              __typename?: 'LinkToRecording';
              link: string | null | undefined;
              id: string;
              eventDate: any;
              expiresAt: any | null | undefined;
              isExpired: boolean | null | undefined;
              video:
                | {
                    __typename?: 'Video';
                    id: string;
                    title: string;
                    kind: string;
                    identifier: string;
                    hasAccess: boolean;
                    cursorAt: number | null | undefined;
                    hls: string;
                    content:
                      | {
                          __typename?: 'VideoContent';
                          title: string | null | undefined;
                          shortDescription: string | null | undefined;
                          longDescription: string | null | undefined;
                        }
                      | null
                      | undefined;
                    player:
                      | {
                          __typename?: 'VideoInfo';
                          assets:
                            | Array<
                                | {
                                    __typename?: 'VideoFileAsset';
                                    src: string | null | undefined;
                                    resolution: number | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    assets:
                      | {
                          __typename?: 'VideoAssets';
                          thumbnailUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        price:
          | {
              __typename?: 'Price';
              originalPrice: number | null | undefined;
              discountApplied: boolean;
              discountedPrice: number | null | undefined;
              currency: string | null | undefined;
            }
          | null
          | undefined;
        primaryInstructor:
          | {
              __typename?: 'Instructor';
              id: string;
              name: string | null | undefined;
              slug: string | null | undefined;
              assets:
                | {
                    __typename?: 'InstructorAssets';
                    squareImage: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        userAccess:
          | {
              __typename?: 'LiveEventAccess';
              zoomLink: string;
              zoomPassword: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ValidateLiveEventCodeQueryVariables = Exact<{
  slug: Scalars['String'];
  code: Scalars['String'];
}>;

export type ValidateLiveEventCodeQuery = {
  __typename?: 'Query';
  validateLiveEventCode: {
    __typename?: 'ValidateCode';
    code: string | null | undefined;
    message: string | null | undefined;
    status: CodeStatusKind | null | undefined;
  };
};

export type LiveEventsQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars['Int']>;
  page: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  languages: InputMaybe<Array<LanguageKind> | LanguageKind>;
  instructor: InputMaybe<Scalars['String']>;
  sortBy: InputMaybe<LiveEventSortAttribute>;
  kind: InputMaybe<LiveEventKind>;
}>;

export type LiveEventsQuery = {
  __typename?: 'Query';
  liveEvents:
    | {
        __typename?: 'PagedLiveEvents';
        page: number;
        totalCount: number;
        availableKinds: Array<LiveEventKind>;
        data: Array<{
          __typename?: 'LiveEvent';
          id: string;
          title: string;
          slug: string;
          thumbnail: string;
          duration: number;
          eventDates: Array<any>;
          kind: LiveEventKind;
          language: LanguageKind;
          level: LiveEventLevel;
          theme: string | null | undefined;
          themeLabel: string | null | undefined;
          availableSpots: number;
          maximumParticipants: number;
          bookingAvailable: boolean;
          paymentKind: LiveEventPaymentKind;
          flexibleMaxAmountCents: number | null | undefined;
          flexibleMinAmountCents: number | null | undefined;
          customDiscount: number | null | undefined;
          isUserEligible: boolean | null | undefined;
          descriptionLong: string;
          registrationEndDate: any;
          registrationStartDate: any;
          isClosed: boolean | null | undefined;
          isExpired: boolean | null | undefined;
          isComingSoon: boolean | null | undefined;
          price:
            | {
                __typename?: 'Price';
                originalPrice: number | null | undefined;
                discountApplied: boolean;
                discountedPrice: number | null | undefined;
                currency: string | null | undefined;
              }
            | null
            | undefined;
          primaryInstructor:
            | {
                __typename?: 'Instructor';
                id: string;
                name: string | null | undefined;
                slug: string | null | undefined;
                assets:
                  | {
                      __typename?: 'InstructorAssets';
                      squareImage: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          instructors:
            | Array<
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          userAccess:
            | {
                __typename?: 'LiveEventAccess';
                zoomLink: string;
                zoomPassword: string;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type MarketplaceEventFieldsFragment = {
  __typename?: 'Event';
  id: string;
  instructor: string;
  name: string;
  slug: string;
  description: string | null | undefined;
  body1: string | null | undefined;
  body2: string | null | undefined;
  externalImageUrl: string | null | undefined;
  location: string | null | undefined;
  publishedAt: any | null | undefined;
  startsAt: any | null | undefined;
  endsAt: any | null | undefined;
  titleImageUrl: string | null | undefined;
};

export type MarketplaceEventsQueryVariables = Exact<{
  category: InputMaybe<Scalars['String']>;
  archive: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type MarketplaceEventsQuery = {
  __typename?: 'Query';
  marketplaceEvents: {
    __typename?: 'PagedEvents';
    page: number;
    perPage: number;
    totalCount: number;
    data: Array<{
      __typename?: 'Event';
      id: string;
      instructor: string;
      name: string;
      slug: string;
      description: string | null | undefined;
      body1: string | null | undefined;
      body2: string | null | undefined;
      externalImageUrl: string | null | undefined;
      location: string | null | undefined;
      publishedAt: any | null | undefined;
      startsAt: any | null | undefined;
      endsAt: any | null | undefined;
      titleImageUrl: string | null | undefined;
    }>;
  };
};

export type MarketplaceEventQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type MarketplaceEventQuery = {
  __typename?: 'Query';
  marketplaceEvent: {
    __typename?: 'Event';
    id: string;
    instructor: string;
    name: string;
    slug: string;
    description: string | null | undefined;
    body1: string | null | undefined;
    body2: string | null | undefined;
    externalImageUrl: string | null | undefined;
    location: string | null | undefined;
    publishedAt: any | null | undefined;
    startsAt: any | null | undefined;
    endsAt: any | null | undefined;
    titleImageUrl: string | null | undefined;
  };
};

export type SubmitEventApplicationMutationVariables = Exact<{
  slug: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
}>;

export type SubmitEventApplicationMutation = {
  __typename?: 'Mutation';
  submitEventApplication:
    | {
        __typename?: 'SubmitEventApplicationPayload';
        message: string;
        success: boolean;
      }
    | null
    | undefined;
};

export type WellnessPackagePaymentFieldsFragment = {
  __typename?: 'WellnessPackagePayment';
  orderId: string;
  state: PaymentStatusKind;
  status: OrderStatus | null | undefined;
  sourcePage: string | null | undefined;
  amountCents: number;
  originalAmountCents: number | null | undefined;
  paymentProvider: PaymentProviderKind;
  paymentProviderVariant: PaymentProviderVariantKind | null | undefined;
  amountCurrency: CurrencyKind;
  wellnessPackage: {
    __typename?: 'WellnessPackage';
    name: string;
    priceBreakdown:
      | {
          __typename?: 'PriceBreakdown';
          amountPerDay: number | null | undefined;
          currency: string | null | undefined;
          extraDays: number | null | undefined;
          extraDaysAmount: number | null | undefined;
          planCount: number | null | undefined;
          planType: string | null | undefined;
          originalPlanAmount: number | null | undefined;
          totalAmount: number | null | undefined;
          accessDays: number | null | undefined;
        }
      | null
      | undefined;
    packageable:
      | {
          __typename?: 'GenericProgram';
          id: string;
          title: string | null | undefined;
          slug: string;
          category: ProgramCategory;
        }
      | { __typename?: 'LiveEvent' }
      | { __typename?: 'Promotion'; name: string }
      | {
          __typename?: 'WellnessAccess';
          periodUnit: WellnessPeriodUnitKind;
          periodValue: number;
        }
      | null
      | undefined;
  };
};

export type OrderQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type OrderQuery = {
  __typename?: 'Query';
  order:
    | {
        __typename?: 'AddPayment';
        orderId: string;
        state: PaymentStatusKind;
        status: OrderStatus | null | undefined;
        sourcePage: string | null | undefined;
        amountCents: number;
        amountCurrency: CurrencyKind;
        paymentProvider: PaymentProviderKind;
        failedReason: string | null | undefined;
      }
    | {
        __typename?: 'BundlePayment';
        orderId: string;
        state: PaymentStatusKind;
        status: OrderStatus | null | undefined;
        sourcePage: string | null | undefined;
        amountCents: number;
        amountCurrency: CurrencyKind;
        originalAmountCents: number | null | undefined;
        paymentProvider: PaymentProviderKind;
        paymentProviderVariant: PaymentProviderVariantKind | null | undefined;
        success: boolean;
        bundle: { __typename?: 'Bundle'; id: string; title: string };
      }
    | {
        __typename?: 'GenericProgramPayment';
        orderId: string;
        state: PaymentStatusKind;
        status: OrderStatus | null | undefined;
        sourcePage: string | null | undefined;
        amountCents: number;
        amountCurrency: CurrencyKind;
        originalAmountCents: number | null | undefined;
        paymentProvider: PaymentProviderKind;
        paymentProviderVariant: PaymentProviderVariantKind | null | undefined;
        voucher: boolean | null | undefined;
        program: {
          __typename?: 'GenericProgram';
          id: string;
          slug: string;
          title: string | null | undefined;
          category: ProgramCategory;
        };
      }
    | {
        __typename?: 'LiveEventPayment';
        orderId: string;
        state: PaymentStatusKind;
        status: OrderStatus | null | undefined;
        sourcePage: string | null | undefined;
        amountCents: number;
        amountCurrency: CurrencyKind;
        originalAmountCents: number | null | undefined;
        paymentProvider: PaymentProviderKind;
        paymentProviderVariant: PaymentProviderVariantKind | null | undefined;
        voucher: boolean | null | undefined;
        live: {
          __typename?: 'LiveEvent';
          id: string;
          slug: string;
          title: string;
        };
      }
    | {
        __typename?: 'SubscriptionPayment';
        orderId: string;
        state: PaymentStatusKind;
        status: OrderStatus | null | undefined;
        sourcePage: string | null | undefined;
        amountCents: number;
        amountCurrency: CurrencyKind;
        months: number;
        originalAmountCents: number | null | undefined;
        recurringAmountCents: number | null | undefined;
        paymentProvider: PaymentProviderKind;
        paymentProviderVariant: PaymentProviderVariantKind | null | undefined;
        recurringDate: any | null | undefined;
      }
    | {
        __typename?: 'WellnessPackagePayment';
        orderId: string;
        state: PaymentStatusKind;
        status: OrderStatus | null | undefined;
        sourcePage: string | null | undefined;
        amountCents: number;
        originalAmountCents: number | null | undefined;
        paymentProvider: PaymentProviderKind;
        paymentProviderVariant: PaymentProviderVariantKind | null | undefined;
        amountCurrency: CurrencyKind;
        wellnessPackage: {
          __typename?: 'WellnessPackage';
          name: string;
          priceBreakdown:
            | {
                __typename?: 'PriceBreakdown';
                amountPerDay: number | null | undefined;
                currency: string | null | undefined;
                extraDays: number | null | undefined;
                extraDaysAmount: number | null | undefined;
                planCount: number | null | undefined;
                planType: string | null | undefined;
                originalPlanAmount: number | null | undefined;
                totalAmount: number | null | undefined;
                accessDays: number | null | undefined;
              }
            | null
            | undefined;
          packageable:
            | {
                __typename?: 'GenericProgram';
                id: string;
                title: string | null | undefined;
                slug: string;
                category: ProgramCategory;
              }
            | { __typename?: 'LiveEvent' }
            | { __typename?: 'Promotion'; name: string }
            | {
                __typename?: 'WellnessAccess';
                periodUnit: WellnessPeriodUnitKind;
                periodValue: number;
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type PlayOverviewQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
}>;

export type PlayOverviewQuery = {
  __typename?: 'Query';
  playOverview: {
    __typename?: 'PlayOverview';
    videos:
      | {
          __typename?: 'PagedVideos';
          page: number;
          perPage: number;
          totalCount: number;
          data: Array<{
            __typename?: 'Video';
            id: string;
            identifier: string;
            kind: string;
            cursorAt: number | null | undefined;
            removedText: string | null | undefined;
            content:
              | {
                  __typename?: 'VideoContent';
                  title: string | null | undefined;
                  shortDescription: string | null | undefined;
                  longDescription: string | null | undefined;
                }
              | null
              | undefined;
            meta:
              | {
                  __typename?: 'VideoMeta';
                  minutes: number | null | undefined;
                  publishedAt: number | null | undefined;
                }
              | null
              | undefined;
            primaryStyle:
              | {
                  __typename?: 'Term';
                  name: string | null | undefined;
                  slug: string | null | undefined;
                }
              | null
              | undefined;
            taxonomies: {
              __typename?: 'Taxonomy';
              flavor:
                | {
                    __typename?: 'Term';
                    id: string;
                    name: string | null | undefined;
                  }
                | null
                | undefined;
              needs:
                | Array<
                    | {
                        __typename?: 'Term';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              externalImage: string | null | undefined;
                              squareImage: string | null | undefined;
                              titleImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              genre:
                | {
                    __typename?: 'Term';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                  }
                | null
                | undefined;
            };
            assets:
              | {
                  __typename?: 'VideoAssets';
                  thumbnailUrl: string | null | undefined;
                }
              | null
              | undefined;
          }>;
        }
      | null
      | undefined;
    playlists:
      | Array<{
          __typename?: 'Playlist';
          id: string;
          identifier: string | null | undefined;
          visibility: string | null | undefined;
          title: string;
          description: string | null | undefined;
          owner:
            | {
                __typename?: 'CurrentUser';
                id: string;
                firstName: string | null | undefined;
              }
            | {
                __typename?: 'User';
                id: string;
                firstName: string | null | undefined;
              }
            | null
            | undefined;
          meta:
            | {
                __typename?: 'Meta';
                videoCount: number | null | undefined;
                minutes: number | null | undefined;
              }
            | null
            | undefined;
          assets:
            | {
                __typename?: 'PlaylistAssets';
                thumbnailUrl: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    challenges:
      | Array<{
          __typename?: 'GenericProgram';
          id: string;
          title: string | null | undefined;
          slug: string;
          promotionalImageUrl: string | null | undefined;
          category: ProgramCategory;
          priceType: PriceTypeKind | null | undefined;
          kind: ProgramKind;
          language: LanguageKind | null | undefined;
          titleImageUrl: string | null | undefined;
          shortDescription: string | null | undefined;
          description: string | null | undefined;
          accessStatus: ProgramAccessStatus;
          themesV2:
            | Array<{ __typename?: 'Theme'; label: string; slug: string }>
            | null
            | undefined;
          price:
            | {
                __typename?: 'Price';
                originalPrice: number | null | undefined;
                discountedPrice: number | null | undefined;
                discountApplied: boolean;
                currency: string | null | undefined;
              }
            | null
            | undefined;
          accessPeriod: {
            __typename?: 'AccessPeriod';
            unit: AccessPeriodUnitKind | null | undefined;
            value: number | null | undefined;
          };
          duration:
            | {
                __typename?: 'Duration';
                unit: DurationUnitKind | null | undefined;
                value: number | null | undefined;
              }
            | null
            | undefined;
          instructors:
            | Array<
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          programAccess:
            | {
                __typename?: 'ProgramAccess';
                accessStatus: ProgramAccessStatus;
                accessUntil: any | null | undefined;
                joinedAt: any | null | undefined;
                progressPercentage: number | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    programs:
      | Array<{
          __typename?: 'GenericProgram';
          id: string;
          title: string | null | undefined;
          slug: string;
          promotionalImageUrl: string | null | undefined;
          category: ProgramCategory;
          priceType: PriceTypeKind | null | undefined;
          kind: ProgramKind;
          language: LanguageKind | null | undefined;
          titleImageUrl: string | null | undefined;
          shortDescription: string | null | undefined;
          description: string | null | undefined;
          accessStatus: ProgramAccessStatus;
          themesV2:
            | Array<{ __typename?: 'Theme'; label: string; slug: string }>
            | null
            | undefined;
          price:
            | {
                __typename?: 'Price';
                originalPrice: number | null | undefined;
                discountedPrice: number | null | undefined;
                discountApplied: boolean;
                currency: string | null | undefined;
              }
            | null
            | undefined;
          accessPeriod: {
            __typename?: 'AccessPeriod';
            unit: AccessPeriodUnitKind | null | undefined;
            value: number | null | undefined;
          };
          duration:
            | {
                __typename?: 'Duration';
                unit: DurationUnitKind | null | undefined;
                value: number | null | undefined;
              }
            | null
            | undefined;
          instructors:
            | Array<
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          programAccess:
            | {
                __typename?: 'ProgramAccess';
                accessStatus: ProgramAccessStatus;
                accessUntil: any | null | undefined;
                joinedAt: any | null | undefined;
                progressPercentage: number | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    liveEvents:
      | Array<{
          __typename?: 'LiveEvent';
          id: string;
          title: string;
          slug: string;
          thumbnail: string;
          duration: number;
          eventDates: Array<any>;
          kind: LiveEventKind;
          language: LanguageKind;
          level: LiveEventLevel;
          theme: string | null | undefined;
          themeLabel: string | null | undefined;
          availableSpots: number;
          maximumParticipants: number;
          bookingAvailable: boolean;
          paymentKind: LiveEventPaymentKind;
          flexibleMaxAmountCents: number | null | undefined;
          flexibleMinAmountCents: number | null | undefined;
          customDiscount: number | null | undefined;
          isUserEligible: boolean | null | undefined;
          descriptionLong: string;
          registrationEndDate: any;
          registrationStartDate: any;
          isClosed: boolean | null | undefined;
          isExpired: boolean | null | undefined;
          isComingSoon: boolean | null | undefined;
          price:
            | {
                __typename?: 'Price';
                originalPrice: number | null | undefined;
                discountApplied: boolean;
                discountedPrice: number | null | undefined;
                currency: string | null | undefined;
              }
            | null
            | undefined;
          primaryInstructor:
            | {
                __typename?: 'Instructor';
                id: string;
                name: string | null | undefined;
                slug: string | null | undefined;
                assets:
                  | {
                      __typename?: 'InstructorAssets';
                      squareImage: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          instructors:
            | Array<
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          userAccess:
            | {
                __typename?: 'LiveEventAccess';
                zoomLink: string;
                zoomPassword: string;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    needs:
      | Array<{
          __typename?: 'Need';
          imageUrl: string;
          index: number;
          name: string;
          settings: { [key: string]: any };
          settingsV2: { [key: string]: any };
          slug: string;
        }>
      | null
      | undefined;
  };
  startOverview: {
    __typename?: 'StartOverview';
    genresSection:
      | {
          __typename?: 'GenresSection';
          genre:
            | Array<{
                __typename?: 'PageSection';
                title: string | null | undefined;
                slug: SlugTypeKind | null | undefined;
                name: string | null | undefined;
                description: string | null | undefined;
                imageUrl: string | null | undefined;
                otherType: OtherTypeKind | null | undefined;
                sectionType: string | null | undefined;
              }>
            | null
            | undefined;
        }
      | null
      | undefined;
    mobileAppSection:
      | {
          __typename?: 'MobileAppSection';
          description: string | null | undefined;
          imageUrl: string | null | undefined;
          title: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type PayForItemMutationVariables = Exact<{
  slug: Scalars['String'];
  itemKind: ItemKind;
  paymentMethodId: InputMaybe<Scalars['String']>;
  paymentMethod: PaymentMethodInput;
  riskData: InputMaybe<RiskDataInput>;
  redirectToSuccess: InputMaybe<Scalars['String']>;
  redirectToFailed: InputMaybe<Scalars['String']>;
  browserInfo: InputMaybe<BrowserInfoInput>;
  discountCode: InputMaybe<Scalars['String']>;
  amountCents: InputMaybe<Scalars['Int']>;
  sourcePage: InputMaybe<Scalars['String']>;
}>;

export type PayForItemMutation = {
  __typename?: 'Mutation';
  payForItem:
    | {
        __typename?: 'PayForItemPayload';
        orderId: string | null | undefined;
        resultCode: PaymentResultCodeKind | null | undefined;
        transaction: string | null | undefined;
        resultAction:
          | {
              __typename?: 'AdyenAction';
              authorisationToken: string | null | undefined;
              httpMethod: string | null | undefined;
              paymentData: string | null | undefined;
              paymentMethodType: string;
              redirectData: string | null | undefined;
              qrCodeData: string | null | undefined;
              subtype: string | null | undefined;
              token: string | null | undefined;
              type: AdyenActionTypeKind | null | undefined;
              url: string | null | undefined;
            }
          | null
          | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PayForVoucherMutationVariables = Exact<{
  slug: Scalars['String'];
  paymentMethod: PaymentMethodInput;
  paymentMethodId: InputMaybe<Scalars['String']>;
  itemKind: ItemKind;
  redirectToSuccess: InputMaybe<Scalars['String']>;
  redirectToFailed: InputMaybe<Scalars['String']>;
  riskData: InputMaybe<RiskDataInput>;
  browserInfo: InputMaybe<BrowserInfoInput>;
  discountCode: InputMaybe<Scalars['String']>;
  senderName: Scalars['String'];
  recipientName: Scalars['String'];
  recipientEmail: Scalars['String'];
  sendGiftEmail: Scalars['Boolean'];
  customMessage: InputMaybe<Scalars['String']>;
  amountCents: InputMaybe<Scalars['Int']>;
}>;

export type PayForVoucherMutation = {
  __typename?: 'Mutation';
  payForVoucher:
    | {
        __typename?: 'PayForVoucherPayload';
        orderId: string | null | undefined;
        resultCode: PaymentResultCodeKind | null | undefined;
        transaction: string | null | undefined;
        resultAction:
          | {
              __typename?: 'AdyenAction';
              url: string | null | undefined;
              httpMethod: string | null | undefined;
              paymentMethodType: string;
              paymentData: string | null | undefined;
              qrCodeData: string | null | undefined;
              redirectData: string | null | undefined;
              subtype: string | null | undefined;
              token: string | null | undefined;
              type: AdyenActionTypeKind | null | undefined;
              authorisationToken: string | null | undefined;
            }
          | null
          | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MyPaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type MyPaymentMethodsQuery = {
  __typename?: 'Query';
  myPaymentMethods:
    | Array<{
        __typename?: 'PaymentMethod';
        id: string;
        name: string;
        expiryMonth: string | null | undefined;
        expiryYear: string | null | undefined;
        isDefault: boolean | null | undefined;
        lastFour: string;
        variant: string | null | undefined;
        provider: ProviderKind | null | undefined;
        status: PaymentMethodStatusKind | null | undefined;
        fallback: boolean | null | undefined;
      }>
    | null
    | undefined;
};

export type AddTrustlyPaymentMethodMutationVariables = Exact<{
  redirectTo: InputMaybe<Scalars['String']>;
}>;

export type AddTrustlyPaymentMethodMutation = {
  __typename?: 'Mutation';
  addTrustlyPaymentMethod:
    | {
        __typename?: 'AddTrustlyPaymentMethodPayload';
        orderId: string | null | undefined;
        resultUrl: string | null | undefined;
        transaction: string | null | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AddAdyenPaymentMethodMutationVariables = Exact<{
  paymentMethod: PaymentMethodInput;
  redirectToSuccess: InputMaybe<Scalars['String']>;
  redirectToFailed: InputMaybe<Scalars['String']>;
  riskData: InputMaybe<RiskDataInput>;
  browserInfo: InputMaybe<BrowserInfoInput>;
  recurringModel?: InputMaybe<RecurringModelInputKind>;
}>;

export type AddAdyenPaymentMethodMutation = {
  __typename?: 'Mutation';
  addAdyenPaymentMethod:
    | {
        __typename?: 'AddAdyenPaymentMethodPayload';
        orderId: string | null | undefined;
        newRecord: boolean | null | undefined;
        resultCode: PaymentResultCodeKind | null | undefined;
        transaction: string | null | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
        resultAction:
          | {
              __typename?: 'AdyenAction';
              url: string | null | undefined;
              httpMethod: string | null | undefined;
              authorisationToken: string | null | undefined;
              paymentMethodType: string;
              paymentData: string | null | undefined;
              redirectData: string | null | undefined;
              subtype: string | null | undefined;
              token: string | null | undefined;
              type: AdyenActionTypeKind | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeletePaymentMethodMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePaymentMethodMutation = {
  __typename?: 'Mutation';
  deletePaymentMethod:
    | {
        __typename?: 'DeletePaymentMethodPayload';
        errors: Array<string> | null | undefined;
        success: boolean;
      }
    | null
    | undefined;
};

export type UpdatePaymentMethodSettingsMutationVariables = Exact<{
  id: Scalars['ID'];
  setAsDefault: InputMaybe<Scalars['Boolean']>;
  setAsFallback: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdatePaymentMethodSettingsMutation = {
  __typename?: 'Mutation';
  updatePaymentMethodSettings:
    | {
        __typename?: 'UpdatePaymentMethodSettingsPayload';
        success: boolean;
        errors: Array<string> | null | undefined;
      }
    | null
    | undefined;
};

export type PhasedProgramFieldsFragment = {
  __typename?: 'PhasedProgram';
  id: string;
  slug: string;
  title: string | null | undefined;
  progress: number | null | undefined;
  language: string | null | undefined;
  weeksCount: number | null | undefined;
  phasesCount: number | null | undefined;
  videosCount: number | null | undefined;
  currentElement:
    | {
        __typename?: 'CurrentElement';
        path: string | null | undefined;
        title: string | null | undefined;
      }
    | null
    | undefined;
  instructors:
    | Array<
        | {
            __typename?: 'Instructor';
            id: string;
            name: string | null | undefined;
            assets:
              | {
                  __typename?: 'InstructorAssets';
                  squareImage: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  intro:
    | {
        __typename?: 'SpecialSection';
        description: string | null | undefined;
        label: string;
        assetUrl: string | null | undefined;
        slug: string;
      }
    | null
    | undefined;
  outro:
    | {
        __typename?: 'SpecialSection';
        description: string | null | undefined;
        label: string;
        slug: string;
        videos:
          | Array<
              | {
                  __typename?: 'ProgramVideo';
                  id: string;
                  slug: string;
                  title: string | null | undefined;
                  subheader: string | null | undefined;
                  customTitle: string | null | undefined;
                  locked: boolean;
                  watched: boolean | null | undefined;
                  finished: boolean | null | undefined;
                  position: number;
                  positionLabel: string;
                  videoKind: VideoKind | null | undefined;
                  progressIsCurrent: boolean;
                  repeatRecomendation: number | null | undefined;
                  customDescription: string | null | undefined;
                  preMoodTracker:
                    | {
                        __typename?: 'MoodTracker';
                        id: string;
                        finished: boolean | null | undefined;
                        kind: MoodTrackerKind;
                      }
                    | null
                    | undefined;
                  postMoodTracker:
                    | {
                        __typename?: 'MoodTracker';
                        id: string;
                        finished: boolean | null | undefined;
                        kind: MoodTrackerKind;
                      }
                    | null
                    | undefined;
                  video: {
                    __typename?: 'Video';
                    id: string;
                    title: string;
                    kind: string;
                    identifier: string;
                    hasAccess: boolean;
                    cursorAt: number | null | undefined;
                    hls: string;
                    content:
                      | {
                          __typename?: 'VideoContent';
                          title: string | null | undefined;
                          shortDescription: string | null | undefined;
                          longDescription: string | null | undefined;
                        }
                      | null
                      | undefined;
                    player:
                      | {
                          __typename?: 'VideoInfo';
                          assets:
                            | Array<
                                | {
                                    __typename?: 'VideoFileAsset';
                                    src: string | null | undefined;
                                    resolution: number | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    meta:
                      | {
                          __typename?: 'VideoMeta';
                          publishedAt: number | null | undefined;
                          minutes: number | null | undefined;
                        }
                      | null
                      | undefined;
                    taxonomies: {
                      __typename?: 'Taxonomy';
                      flavor:
                        | {
                            __typename?: 'Term';
                            name: string | null | undefined;
                          }
                        | null
                        | undefined;
                      instructors:
                        | Array<
                            | {
                                __typename?: 'Instructor';
                                name: string | null | undefined;
                                slug: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      genre:
                        | {
                            __typename?: 'Term';
                            name: string | null | undefined;
                            slug: string | null | undefined;
                          }
                        | null
                        | undefined;
                    };
                    assets:
                      | {
                          __typename?: 'VideoAssets';
                          thumbnailUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  };
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  phases:
    | Array<
        | {
            __typename?: 'ProgramPhase';
            id: string;
            description: string | null | undefined;
            label: string | null | undefined;
            progress: number | null | undefined;
            slug: string;
            finished: boolean | null | undefined;
            locked: boolean;
            videosCount: number | null | undefined;
            videosDuration: string | null | undefined;
            weeksRange: string | null | undefined;
            weeks:
              | Array<
                  | {
                      __typename?: 'ProgramWeek';
                      id: string;
                      label: string;
                      slug: string;
                      locked: boolean;
                      finished: boolean | null | undefined;
                      indexInProgram: number | null | undefined;
                      videos: Array<{
                        __typename?: 'ProgramVideo';
                        id: string;
                        slug: string;
                        title: string | null | undefined;
                        subheader: string | null | undefined;
                        customTitle: string | null | undefined;
                        locked: boolean;
                        watched: boolean | null | undefined;
                        finished: boolean | null | undefined;
                        position: number;
                        positionLabel: string;
                        videoKind: VideoKind | null | undefined;
                        progressIsCurrent: boolean;
                        repeatRecomendation: number | null | undefined;
                        customDescription: string | null | undefined;
                        preMoodTracker:
                          | {
                              __typename?: 'MoodTracker';
                              id: string;
                              finished: boolean | null | undefined;
                              kind: MoodTrackerKind;
                            }
                          | null
                          | undefined;
                        postMoodTracker:
                          | {
                              __typename?: 'MoodTracker';
                              id: string;
                              finished: boolean | null | undefined;
                              kind: MoodTrackerKind;
                            }
                          | null
                          | undefined;
                        video: {
                          __typename?: 'Video';
                          id: string;
                          title: string;
                          kind: string;
                          identifier: string;
                          hasAccess: boolean;
                          cursorAt: number | null | undefined;
                          hls: string;
                          content:
                            | {
                                __typename?: 'VideoContent';
                                title: string | null | undefined;
                                shortDescription: string | null | undefined;
                                longDescription: string | null | undefined;
                              }
                            | null
                            | undefined;
                          player:
                            | {
                                __typename?: 'VideoInfo';
                                assets:
                                  | Array<
                                      | {
                                          __typename?: 'VideoFileAsset';
                                          src: string | null | undefined;
                                          resolution: number | null | undefined;
                                        }
                                      | null
                                      | undefined
                                    >
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          meta:
                            | {
                                __typename?: 'VideoMeta';
                                publishedAt: number | null | undefined;
                                minutes: number | null | undefined;
                              }
                            | null
                            | undefined;
                          taxonomies: {
                            __typename?: 'Taxonomy';
                            flavor:
                              | {
                                  __typename?: 'Term';
                                  name: string | null | undefined;
                                }
                              | null
                              | undefined;
                            instructors:
                              | Array<
                                  | {
                                      __typename?: 'Instructor';
                                      name: string | null | undefined;
                                      slug: string | null | undefined;
                                    }
                                  | null
                                  | undefined
                                >
                              | null
                              | undefined;
                            genre:
                              | {
                                  __typename?: 'Term';
                                  name: string | null | undefined;
                                  slug: string | null | undefined;
                                }
                              | null
                              | undefined;
                          };
                          assets:
                            | {
                                __typename?: 'VideoAssets';
                                thumbnailUrl: string | null | undefined;
                              }
                            | null
                            | undefined;
                        };
                      }>;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type PhasedProgramQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type PhasedProgramQuery = {
  __typename?: 'Query';
  phasedProgram:
    | {
        __typename?: 'PhasedProgram';
        id: string;
        slug: string;
        title: string | null | undefined;
        progress: number | null | undefined;
        language: string | null | undefined;
        weeksCount: number | null | undefined;
        phasesCount: number | null | undefined;
        videosCount: number | null | undefined;
        currentElement:
          | {
              __typename?: 'CurrentElement';
              path: string | null | undefined;
              title: string | null | undefined;
            }
          | null
          | undefined;
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        intro:
          | {
              __typename?: 'SpecialSection';
              description: string | null | undefined;
              label: string;
              assetUrl: string | null | undefined;
              slug: string;
            }
          | null
          | undefined;
        outro:
          | {
              __typename?: 'SpecialSection';
              description: string | null | undefined;
              label: string;
              slug: string;
              videos:
                | Array<
                    | {
                        __typename?: 'ProgramVideo';
                        id: string;
                        slug: string;
                        title: string | null | undefined;
                        subheader: string | null | undefined;
                        customTitle: string | null | undefined;
                        locked: boolean;
                        watched: boolean | null | undefined;
                        finished: boolean | null | undefined;
                        position: number;
                        positionLabel: string;
                        videoKind: VideoKind | null | undefined;
                        progressIsCurrent: boolean;
                        repeatRecomendation: number | null | undefined;
                        customDescription: string | null | undefined;
                        preMoodTracker:
                          | {
                              __typename?: 'MoodTracker';
                              id: string;
                              finished: boolean | null | undefined;
                              kind: MoodTrackerKind;
                            }
                          | null
                          | undefined;
                        postMoodTracker:
                          | {
                              __typename?: 'MoodTracker';
                              id: string;
                              finished: boolean | null | undefined;
                              kind: MoodTrackerKind;
                            }
                          | null
                          | undefined;
                        video: {
                          __typename?: 'Video';
                          id: string;
                          title: string;
                          kind: string;
                          identifier: string;
                          hasAccess: boolean;
                          cursorAt: number | null | undefined;
                          hls: string;
                          content:
                            | {
                                __typename?: 'VideoContent';
                                title: string | null | undefined;
                                shortDescription: string | null | undefined;
                                longDescription: string | null | undefined;
                              }
                            | null
                            | undefined;
                          player:
                            | {
                                __typename?: 'VideoInfo';
                                assets:
                                  | Array<
                                      | {
                                          __typename?: 'VideoFileAsset';
                                          src: string | null | undefined;
                                          resolution: number | null | undefined;
                                        }
                                      | null
                                      | undefined
                                    >
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          meta:
                            | {
                                __typename?: 'VideoMeta';
                                publishedAt: number | null | undefined;
                                minutes: number | null | undefined;
                              }
                            | null
                            | undefined;
                          taxonomies: {
                            __typename?: 'Taxonomy';
                            flavor:
                              | {
                                  __typename?: 'Term';
                                  name: string | null | undefined;
                                }
                              | null
                              | undefined;
                            instructors:
                              | Array<
                                  | {
                                      __typename?: 'Instructor';
                                      name: string | null | undefined;
                                      slug: string | null | undefined;
                                    }
                                  | null
                                  | undefined
                                >
                              | null
                              | undefined;
                            genre:
                              | {
                                  __typename?: 'Term';
                                  name: string | null | undefined;
                                  slug: string | null | undefined;
                                }
                              | null
                              | undefined;
                          };
                          assets:
                            | {
                                __typename?: 'VideoAssets';
                                thumbnailUrl: string | null | undefined;
                              }
                            | null
                            | undefined;
                        };
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        phases:
          | Array<
              | {
                  __typename?: 'ProgramPhase';
                  id: string;
                  description: string | null | undefined;
                  label: string | null | undefined;
                  progress: number | null | undefined;
                  slug: string;
                  finished: boolean | null | undefined;
                  locked: boolean;
                  videosCount: number | null | undefined;
                  videosDuration: string | null | undefined;
                  weeksRange: string | null | undefined;
                  weeks:
                    | Array<
                        | {
                            __typename?: 'ProgramWeek';
                            id: string;
                            label: string;
                            slug: string;
                            locked: boolean;
                            finished: boolean | null | undefined;
                            indexInProgram: number | null | undefined;
                            videos: Array<{
                              __typename?: 'ProgramVideo';
                              id: string;
                              slug: string;
                              title: string | null | undefined;
                              subheader: string | null | undefined;
                              customTitle: string | null | undefined;
                              locked: boolean;
                              watched: boolean | null | undefined;
                              finished: boolean | null | undefined;
                              position: number;
                              positionLabel: string;
                              videoKind: VideoKind | null | undefined;
                              progressIsCurrent: boolean;
                              repeatRecomendation: number | null | undefined;
                              customDescription: string | null | undefined;
                              preMoodTracker:
                                | {
                                    __typename?: 'MoodTracker';
                                    id: string;
                                    finished: boolean | null | undefined;
                                    kind: MoodTrackerKind;
                                  }
                                | null
                                | undefined;
                              postMoodTracker:
                                | {
                                    __typename?: 'MoodTracker';
                                    id: string;
                                    finished: boolean | null | undefined;
                                    kind: MoodTrackerKind;
                                  }
                                | null
                                | undefined;
                              video: {
                                __typename?: 'Video';
                                id: string;
                                title: string;
                                kind: string;
                                identifier: string;
                                hasAccess: boolean;
                                cursorAt: number | null | undefined;
                                hls: string;
                                content:
                                  | {
                                      __typename?: 'VideoContent';
                                      title: string | null | undefined;
                                      shortDescription:
                                        | string
                                        | null
                                        | undefined;
                                      longDescription:
                                        | string
                                        | null
                                        | undefined;
                                    }
                                  | null
                                  | undefined;
                                player:
                                  | {
                                      __typename?: 'VideoInfo';
                                      assets:
                                        | Array<
                                            | {
                                                __typename?: 'VideoFileAsset';
                                                src: string | null | undefined;
                                                resolution:
                                                  | number
                                                  | null
                                                  | undefined;
                                              }
                                            | null
                                            | undefined
                                          >
                                        | null
                                        | undefined;
                                    }
                                  | null
                                  | undefined;
                                meta:
                                  | {
                                      __typename?: 'VideoMeta';
                                      publishedAt: number | null | undefined;
                                      minutes: number | null | undefined;
                                    }
                                  | null
                                  | undefined;
                                taxonomies: {
                                  __typename?: 'Taxonomy';
                                  flavor:
                                    | {
                                        __typename?: 'Term';
                                        name: string | null | undefined;
                                      }
                                    | null
                                    | undefined;
                                  instructors:
                                    | Array<
                                        | {
                                            __typename?: 'Instructor';
                                            name: string | null | undefined;
                                            slug: string | null | undefined;
                                          }
                                        | null
                                        | undefined
                                      >
                                    | null
                                    | undefined;
                                  genre:
                                    | {
                                        __typename?: 'Term';
                                        name: string | null | undefined;
                                        slug: string | null | undefined;
                                      }
                                    | null
                                    | undefined;
                                };
                                assets:
                                  | {
                                      __typename?: 'VideoAssets';
                                      thumbnailUrl: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              };
                            }>;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AddFavoritePlaylistMutationVariables = Exact<{
  playlistId: Scalars['ID'];
}>;

export type AddFavoritePlaylistMutation = {
  __typename?: 'Mutation';
  addFavoritePlaylist:
    | {
        __typename?: 'AddFavoritePlaylistPayload';
        playlist:
          | { __typename?: 'Playlist'; favorited: boolean | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteFavoritePlaylistMutationVariables = Exact<{
  playlistId: Scalars['ID'];
}>;

export type DeleteFavoritePlaylistMutation = {
  __typename?: 'Mutation';
  deleteFavoritePlaylist:
    | {
        __typename?: 'DeleteFavoritePlaylistPayload';
        playlist:
          | { __typename?: 'Playlist'; favorited: boolean | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PricingPageQueryVariables = Exact<{ [key: string]: never }>;

export type PricingPageQuery = {
  __typename?: 'Query';
  pricingPage:
    | {
        __typename?: 'PricingPage';
        title: string | null | undefined;
        description: string | null | undefined;
        buttonLink: string | null | undefined;
        buttonName: string | null | undefined;
        image: string | null | undefined;
      }
    | null
    | undefined;
};

export type RelatedVideosQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RelatedVideosQuery = {
  __typename?: 'Query';
  relatedVideos:
    | Array<
        | {
            __typename?: 'Video';
            id: string;
            identifier: string;
            recommended: boolean | null | undefined;
            cursorAt: number | null | undefined;
            content:
              | {
                  __typename?: 'VideoContent';
                  title: string | null | undefined;
                  shortDescription: string | null | undefined;
                }
              | null
              | undefined;
            assets:
              | {
                  __typename?: 'VideoAssets';
                  thumbnailUrlMedium: string | null | undefined;
                }
              | null
              | undefined;
            meta:
              | { __typename?: 'VideoMeta'; minutes: number | null | undefined }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type SearchVideoFieldsFragment = {
  __typename?: 'SearchVideo';
  id: string;
  identifier: string;
  title: string;
  thumbnailUrl: string | null | undefined;
  instructorName: string | null | undefined;
  instructorSlug: string | null | undefined;
  longDescription: string;
  kind: string;
  minutes: string;
  primaryStyleName: string | null | undefined;
  primaryStyleSlug: string | null | undefined;
  shortDescription: string;
};

export type SearchPlaylistFieldsFragment = {
  __typename?: 'SearchPlaylist';
  id: string;
  identifier: string;
  title: string;
  description: string;
  minutes: string | null | undefined;
  thumbnailUrl: string | null | undefined;
  videoCount: string | null | undefined;
  ownerId: string | null | undefined;
  visibility: string | null | undefined;
};

export type SearchGenericProgramFieldsFragment = {
  __typename?: 'SearchGenericProgram';
  category: string | null | undefined;
  description: string;
  durationUnit: string | null | undefined;
  durationValue: string | null | undefined;
  id: string;
  instructorName: string | null | undefined;
  instructorSlug: string | null | undefined;
  priceType: PriceTypeKind | null | undefined;
  slug: string | null | undefined;
  title: string;
  titleImageUrl: string | null | undefined;
  accessPeriod:
    | {
        __typename?: 'AccessPeriod';
        unit: AccessPeriodUnitKind | null | undefined;
        value: number | null | undefined;
      }
    | null
    | undefined;
  price:
    | {
        __typename?: 'Price';
        currency: string | null | undefined;
        discountApplied: boolean;
        discountedPrice: number | null | undefined;
        originalPrice: number | null | undefined;
      }
    | null
    | undefined;
  themesV2:
    | Array<{ __typename?: 'Theme'; label: string; slug: string }>
    | null
    | undefined;
};

export type SearchInstructorFieldsFragment = {
  __typename?: 'SearchInstructor';
  description: string | null | undefined;
  id: string;
  name: string | null | undefined;
  slug: string | null | undefined;
  squareImageUrl: string | null | undefined;
};

export type SearchEventFieldsFragment = {
  __typename?: 'SearchEvent';
  id: string;
  name: string;
  slug: string | null | undefined;
  description: string | null | undefined;
  endsAt: any | null | undefined;
  instructor: string | null | undefined;
  startsAt: any | null | undefined;
  titleImageUrl: string | null | undefined;
  location: string | null | undefined;
};

export type SearchLiveEventFieldsFragment = {
  __typename?: 'SearchLiveEvent';
  id: string;
  kind: LiveEventKind;
  slug: string | null | undefined;
  theme: string | null | undefined;
  themeLabel: string | null | undefined;
  thumbnailUrl: string | null | undefined;
  title: string;
  availableSpots: number | null | undefined;
  bookingAvailable: boolean | null | undefined;
  duration: number;
  eventDates: Array<any>;
  instructorName: string | null | undefined;
  instructorSlug: string | null | undefined;
  maximumParticipants: number | null | undefined;
  registrationEndDate: any | null | undefined;
  registrationStartDate: any | null | undefined;
  paymentKind: string | null | undefined;
  isClosed: boolean | null | undefined;
  isExpired: boolean | null | undefined;
  isComingSoon: boolean | null | undefined;
  price:
    | {
        __typename?: 'Price';
        currency: string | null | undefined;
        discountApplied: boolean;
        discountedPrice: number | null | undefined;
        originalPrice: number | null | undefined;
      }
    | null
    | undefined;
};

export type SearchLandingPageFieldsFragment = {
  __typename?: 'SearchLandingPage';
  id: string;
  lede: string | null | undefined;
  locale: string | null | undefined;
  title: string | null | undefined;
  slug: string | null | undefined;
};

export type SearchBlogPostFieldsFragment = {
  __typename?: 'SearchBlogPost';
  featuredImageUrl: string | null | undefined;
  id: string;
  slug: string;
  title: string;
};

export type AutocompleteQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type AutocompleteQuery = {
  __typename?: 'Query';
  autocomplete: {
    __typename?: 'Autocomplete';
    query: string | null | undefined;
    error: string | null | undefined;
    results: Array<string>;
  };
};

export type SpecificSearchQueryVariables = Exact<{
  query: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  modelType: ModelNameKind;
  languages: Array<LanguageKind> | LanguageKind;
}>;

export type SpecificSearchQuery = {
  __typename?: 'Query';
  specificSearch:
    | {
        __typename?: 'SpecificSearch';
        query: string | null | undefined;
        blogPosts:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | {
                        __typename: 'SearchBlogPost';
                        featuredImageUrl: string | null | undefined;
                        id: string;
                        slug: string;
                        title: string;
                      }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        challenges:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | {
                        __typename: 'SearchGenericProgram';
                        category: string | null | undefined;
                        description: string;
                        durationUnit: string | null | undefined;
                        durationValue: string | null | undefined;
                        id: string;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        priceType: PriceTypeKind | null | undefined;
                        slug: string | null | undefined;
                        title: string;
                        titleImageUrl: string | null | undefined;
                        accessPeriod:
                          | {
                              __typename?: 'AccessPeriod';
                              unit: AccessPeriodUnitKind | null | undefined;
                              value: number | null | undefined;
                            }
                          | null
                          | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                        themesV2:
                          | Array<{
                              __typename?: 'Theme';
                              label: string;
                              slug: string;
                            }>
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        events:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | {
                        __typename: 'SearchEvent';
                        id: string;
                        name: string;
                        slug: string | null | undefined;
                        description: string | null | undefined;
                        endsAt: any | null | undefined;
                        instructor: string | null | undefined;
                        startsAt: any | null | undefined;
                        titleImageUrl: string | null | undefined;
                        location: string | null | undefined;
                      }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        genericPrograms:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | {
                        __typename: 'SearchGenericProgram';
                        category: string | null | undefined;
                        description: string;
                        durationUnit: string | null | undefined;
                        durationValue: string | null | undefined;
                        id: string;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        priceType: PriceTypeKind | null | undefined;
                        slug: string | null | undefined;
                        title: string;
                        titleImageUrl: string | null | undefined;
                        accessPeriod:
                          | {
                              __typename?: 'AccessPeriod';
                              unit: AccessPeriodUnitKind | null | undefined;
                              value: number | null | undefined;
                            }
                          | null
                          | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                        themesV2:
                          | Array<{
                              __typename?: 'Theme';
                              label: string;
                              slug: string;
                            }>
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        courses:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | {
                        __typename: 'SearchGenericProgram';
                        category: string | null | undefined;
                        description: string;
                        durationUnit: string | null | undefined;
                        durationValue: string | null | undefined;
                        id: string;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        priceType: PriceTypeKind | null | undefined;
                        slug: string | null | undefined;
                        title: string;
                        titleImageUrl: string | null | undefined;
                        accessPeriod:
                          | {
                              __typename?: 'AccessPeriod';
                              unit: AccessPeriodUnitKind | null | undefined;
                              value: number | null | undefined;
                            }
                          | null
                          | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                        themesV2:
                          | Array<{
                              __typename?: 'Theme';
                              label: string;
                              slug: string;
                            }>
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        instructors:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | {
                        __typename: 'SearchInstructor';
                        description: string | null | undefined;
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        squareImageUrl: string | null | undefined;
                      }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        landingPages:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | {
                        __typename: 'SearchLandingPage';
                        id: string;
                        lede: string | null | undefined;
                        locale: string | null | undefined;
                        title: string | null | undefined;
                        slug: string | null | undefined;
                      }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        liveEvents:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | {
                        __typename: 'SearchLiveEvent';
                        id: string;
                        kind: LiveEventKind;
                        slug: string | null | undefined;
                        theme: string | null | undefined;
                        themeLabel: string | null | undefined;
                        thumbnailUrl: string | null | undefined;
                        title: string;
                        availableSpots: number | null | undefined;
                        bookingAvailable: boolean | null | undefined;
                        duration: number;
                        eventDates: Array<any>;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        maximumParticipants: number | null | undefined;
                        registrationEndDate: any | null | undefined;
                        registrationStartDate: any | null | undefined;
                        paymentKind: string | null | undefined;
                        isClosed: boolean | null | undefined;
                        isExpired: boolean | null | undefined;
                        isComingSoon: boolean | null | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        playlists:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | {
                        __typename: 'SearchPlaylist';
                        id: string;
                        identifier: string;
                        title: string;
                        description: string;
                        minutes: string | null | undefined;
                        thumbnailUrl: string | null | undefined;
                        videoCount: string | null | undefined;
                        ownerId: string | null | undefined;
                        visibility: string | null | undefined;
                      }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        videos:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | {
                        __typename: 'SearchVideo';
                        id: string;
                        identifier: string;
                        title: string;
                        thumbnailUrl: string | null | undefined;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        longDescription: string;
                        kind: string;
                        minutes: string;
                        primaryStyleName: string | null | undefined;
                        primaryStyleSlug: string | null | undefined;
                        shortDescription: string;
                      }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SearchQueryVariables = Exact<{
  query: Scalars['String'];
  searchType: GlobalSearch;
  languages: Array<LanguageKind> | LanguageKind;
}>;

export type SearchQuery = {
  __typename?: 'Query';
  search:
    | {
        __typename?: 'Search';
        query: string | null | undefined;
        blogPosts:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | {
                        __typename: 'SearchBlogPost';
                        featuredImageUrl: string | null | undefined;
                        id: string;
                        slug: string;
                        title: string;
                      }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        challenges:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | {
                        __typename: 'SearchGenericProgram';
                        category: string | null | undefined;
                        description: string;
                        durationUnit: string | null | undefined;
                        durationValue: string | null | undefined;
                        id: string;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        priceType: PriceTypeKind | null | undefined;
                        slug: string | null | undefined;
                        title: string;
                        titleImageUrl: string | null | undefined;
                        accessPeriod:
                          | {
                              __typename?: 'AccessPeriod';
                              unit: AccessPeriodUnitKind | null | undefined;
                              value: number | null | undefined;
                            }
                          | null
                          | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                        themesV2:
                          | Array<{
                              __typename?: 'Theme';
                              label: string;
                              slug: string;
                            }>
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        events:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | {
                        __typename: 'SearchEvent';
                        id: string;
                        name: string;
                        slug: string | null | undefined;
                        description: string | null | undefined;
                        endsAt: any | null | undefined;
                        instructor: string | null | undefined;
                        startsAt: any | null | undefined;
                        titleImageUrl: string | null | undefined;
                        location: string | null | undefined;
                      }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        genericPrograms:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | {
                        __typename: 'SearchGenericProgram';
                        category: string | null | undefined;
                        description: string;
                        durationUnit: string | null | undefined;
                        durationValue: string | null | undefined;
                        id: string;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        priceType: PriceTypeKind | null | undefined;
                        slug: string | null | undefined;
                        title: string;
                        titleImageUrl: string | null | undefined;
                        accessPeriod:
                          | {
                              __typename?: 'AccessPeriod';
                              unit: AccessPeriodUnitKind | null | undefined;
                              value: number | null | undefined;
                            }
                          | null
                          | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                        themesV2:
                          | Array<{
                              __typename?: 'Theme';
                              label: string;
                              slug: string;
                            }>
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        courses:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | {
                        __typename: 'SearchGenericProgram';
                        category: string | null | undefined;
                        description: string;
                        durationUnit: string | null | undefined;
                        durationValue: string | null | undefined;
                        id: string;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        priceType: PriceTypeKind | null | undefined;
                        slug: string | null | undefined;
                        title: string;
                        titleImageUrl: string | null | undefined;
                        accessPeriod:
                          | {
                              __typename?: 'AccessPeriod';
                              unit: AccessPeriodUnitKind | null | undefined;
                              value: number | null | undefined;
                            }
                          | null
                          | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                        themesV2:
                          | Array<{
                              __typename?: 'Theme';
                              label: string;
                              slug: string;
                            }>
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        instructors:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | {
                        __typename: 'SearchInstructor';
                        description: string | null | undefined;
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        squareImageUrl: string | null | undefined;
                      }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        landingPages:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | {
                        __typename: 'SearchLandingPage';
                        id: string;
                        lede: string | null | undefined;
                        locale: string | null | undefined;
                        title: string | null | undefined;
                        slug: string | null | undefined;
                      }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        liveEvents:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | {
                        __typename: 'SearchLiveEvent';
                        id: string;
                        kind: LiveEventKind;
                        slug: string | null | undefined;
                        theme: string | null | undefined;
                        themeLabel: string | null | undefined;
                        thumbnailUrl: string | null | undefined;
                        title: string;
                        availableSpots: number | null | undefined;
                        bookingAvailable: boolean | null | undefined;
                        duration: number;
                        eventDates: Array<any>;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        maximumParticipants: number | null | undefined;
                        registrationEndDate: any | null | undefined;
                        registrationStartDate: any | null | undefined;
                        paymentKind: string | null | undefined;
                        isClosed: boolean | null | undefined;
                        isExpired: boolean | null | undefined;
                        isComingSoon: boolean | null | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              currency: string | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              originalPrice: number | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | { __typename: 'SearchPlaylist' }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        playlists:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | {
                        __typename: 'SearchPlaylist';
                        id: string;
                        identifier: string;
                        title: string;
                        description: string;
                        minutes: string | null | undefined;
                        thumbnailUrl: string | null | undefined;
                        videoCount: string | null | undefined;
                        ownerId: string | null | undefined;
                        visibility: string | null | undefined;
                      }
                    | { __typename: 'SearchVideo' }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        videos:
          | {
              __typename?: 'SearchResults';
              page: number;
              perPage: number;
              totalCount: number;
              results:
                | Array<
                    | { __typename: 'SearchBlogPost' }
                    | { __typename: 'SearchBundle' }
                    | { __typename: 'SearchEvent' }
                    | { __typename: 'SearchGenericProgram' }
                    | { __typename: 'SearchInstructor' }
                    | { __typename: 'SearchLandingPage' }
                    | { __typename: 'SearchLiveEvent' }
                    | { __typename: 'SearchPlaylist' }
                    | {
                        __typename: 'SearchVideo';
                        id: string;
                        identifier: string;
                        title: string;
                        thumbnailUrl: string | null | undefined;
                        instructorName: string | null | undefined;
                        instructorSlug: string | null | undefined;
                        longDescription: string;
                        kind: string;
                        minutes: string;
                        primaryStyleName: string | null | undefined;
                        primaryStyleSlug: string | null | undefined;
                        shortDescription: string;
                      }
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AddSearchResultMutationVariables = Exact<{
  modelType: ModelNameKind;
  documentId: Scalars['String'];
  query: Scalars['String'];
}>;

export type AddSearchResultMutation = {
  __typename?: 'Mutation';
  addSearchResult:
    | {
        __typename?: 'AddSearchResultPayload';
        documentId: string | null | undefined;
      }
    | null
    | undefined;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  utmSource: InputMaybe<Scalars['String']>;
  utmMedium: InputMaybe<Scalars['String']>;
  utmCampaign: InputMaybe<Scalars['String']>;
  paymentType: InputMaybe<Scalars['String']>;
  campaignCode: InputMaybe<Scalars['String']>;
  registrationSource: InputMaybe<RegistrationSourceKind>;
  registrationSourceId: InputMaybe<Scalars['String']>;
  funnel: InputMaybe<FunnelKind>;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUpV2:
    | {
        __typename?: 'RegistrationV2Payload';
        user:
          | {
              __typename?: 'CurrentUser';
              id: string;
              email: string | null | undefined;
              gender: string | null | undefined;
              country: string | null | undefined;
              accessUntil: string | null | undefined;
              funnel: FunnelKind | null | undefined;
              trialRequired: boolean | null | undefined;
            }
          | null
          | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SocialSignUpMutationVariables = Exact<{
  provider: ProviderTypes;
  method: MethodTypes;
  code: InputMaybe<Scalars['String']>;
  accessToken: InputMaybe<Scalars['String']>;
  identityToken: InputMaybe<Scalars['String']>;
  firstName: InputMaybe<Scalars['String']>;
  lastName: InputMaybe<Scalars['String']>;
  avatarFromUrl: InputMaybe<Scalars['String']>;
  paymentType: InputMaybe<Scalars['String']>;
  campaignCode: InputMaybe<Scalars['String']>;
  utmSource: InputMaybe<Scalars['String']>;
  utmMedium: InputMaybe<Scalars['String']>;
  utmCampaign: InputMaybe<Scalars['String']>;
  registrationSource: InputMaybe<RegistrationSourceKind>;
  registrationSourceId: InputMaybe<Scalars['String']>;
}>;

export type SocialSignUpMutation = {
  __typename?: 'Mutation';
  socialSignUp:
    | {
        __typename?: 'SignUpPayload';
        success: boolean | null | undefined;
        user:
          | { __typename?: 'CurrentUser'; id: string }
          | { __typename?: 'User'; id: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SocialSignInMutationVariables = Exact<{
  provider: ProviderTypes;
  method: MethodTypes;
  code: InputMaybe<Scalars['String']>;
  accessToken: InputMaybe<Scalars['String']>;
  identityToken: InputMaybe<Scalars['String']>;
}>;

export type SocialSignInMutation = {
  __typename?: 'Mutation';
  socialSignIn:
    | {
        __typename?: 'SignInPayload';
        success: boolean | null | undefined;
        user:
          | { __typename?: 'CurrentUser'; id: string }
          | { __typename?: 'User'; id: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RegisterSourceLiveQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type RegisterSourceLiveQuery = {
  __typename?: 'Query';
  liveEvent:
    | {
        __typename?: 'LiveEvent';
        id: string;
        slug: string;
        paymentKind: LiveEventPaymentKind;
        price:
          | {
              __typename?: 'Price';
              discountedPrice: number | null | undefined;
              originalPrice: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RegisterSourceGenericProgramQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type RegisterSourceGenericProgramQuery = {
  __typename?: 'Query';
  genericProgram:
    | {
        __typename?: 'GenericProgram';
        id: string;
        slug: string;
        category: ProgramCategory;
      }
    | null
    | undefined;
};

export type RegisterSourceInstructorQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;

export type RegisterSourceInstructorQuery = {
  __typename?: 'Query';
  instructor:
    | { __typename?: 'Instructor'; id: string; slug: string | null | undefined }
    | null
    | undefined;
};

export type RegisterSourceBundleQueryVariables = Exact<{
  slug: Scalars['String'];
  client: Scalars['String'];
}>;

export type RegisterSourceBundleQuery = {
  __typename?: 'Query';
  bundle:
    | {
        __typename?: 'Bundle';
        id: string;
        bundleCategory: BundleCategoryKind | null | undefined;
      }
    | null
    | undefined;
};

export type StartOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type StartOverviewQuery = {
  __typename?: 'Query';
  startOverview: {
    __typename?: 'StartOverview';
    metaSection:
      | {
          __typename?: 'MetaSection';
          description: string | null | undefined;
          imageUrl: string | null | undefined;
          title: string | null | undefined;
        }
      | null
      | undefined;
    heroSection:
      | {
          __typename?: 'HeroSection';
          title: string | null | undefined;
          description: string | null | undefined;
          smallNote: string | null | undefined;
          imageUrl: string | null | undefined;
          mobileImageUrl: string | null | undefined;
        }
      | null
      | undefined;
    coursesSection:
      | {
          __typename?: 'CoursesSection';
          title: string | null | undefined;
          description: string | null | undefined;
          group1Title: string | null | undefined;
          group1Description: string | null | undefined;
          group1ButtonLink: string | null | undefined;
          group1ButtonText: string | null | undefined;
          group1ImageUrl: string | null | undefined;
          group2Title: string | null | undefined;
          group2Description: string | null | undefined;
          group2ButtonLink: string | null | undefined;
          group2ButtonText: string | null | undefined;
          group2ImageUrl: string | null | undefined;
        }
      | null
      | undefined;
    campaignSection:
      | {
          __typename?: 'CampaignSection';
          title: string | null | undefined;
          description: string | null | undefined;
          buttonLink: string | null | undefined;
          buttonText: string | null | undefined;
          imageUrl: string | null | undefined;
          tag: string | null | undefined;
        }
      | null
      | undefined;
    campaignSection2:
      | {
          __typename?: 'CampaignSection';
          title: string | null | undefined;
          description: string | null | undefined;
          buttonLink: string | null | undefined;
          buttonText: string | null | undefined;
          imageUrl: string | null | undefined;
          tag: string | null | undefined;
        }
      | null
      | undefined;
    campaignSection3:
      | {
          __typename?: 'CampaignSection';
          title: string | null | undefined;
          description: string | null | undefined;
          buttonLink: string | null | undefined;
          buttonText: string | null | undefined;
          imageUrl: string | null | undefined;
          tag: string | null | undefined;
        }
      | null
      | undefined;
    featuresSection:
      | {
          __typename?: 'FeaturesSection';
          description: string | null | undefined;
          featureBullets: Array<string> | null | undefined;
          imageUrl: string | null | undefined;
          title: string | null | undefined;
        }
      | null
      | undefined;
    genresSection:
      | {
          __typename?: 'GenresSection';
          title: string | null | undefined;
          description: string | null | undefined;
          genre:
            | Array<{
                __typename?: 'PageSection';
                title: string | null | undefined;
                slug: SlugTypeKind | null | undefined;
                name: string | null | undefined;
                description: string | null | undefined;
                imageUrl: string | null | undefined;
                otherType: OtherTypeKind | null | undefined;
                sectionType: string | null | undefined;
              }>
            | null
            | undefined;
        }
      | null
      | undefined;
    mobileAppSection:
      | {
          __typename?: 'MobileAppSection';
          description: string | null | undefined;
          imageUrl: string | null | undefined;
          title: string | null | undefined;
        }
      | null
      | undefined;
    otherFeaturesSection:
      | {
          __typename?: 'OtherFeaturesSection';
          description: string | null | undefined;
          title: string | null | undefined;
          otherFeatures:
            | Array<{
                __typename?: 'PageSection';
                description: string | null | undefined;
                imageUrl: string | null | undefined;
                name: string | null | undefined;
                url: string | null | undefined;
                otherType: OtherTypeKind | null | undefined;
                sectionType: string | null | undefined;
                slug: SlugTypeKind | null | undefined;
                title: string | null | undefined;
              }>
            | null
            | undefined;
        }
      | null
      | undefined;
    videoLibrarySection:
      | {
          __typename?: 'VideosLibrarySection';
          description: string | null | undefined;
          title: string | null | undefined;
          videosLibrary:
            | Array<{
                __typename?: 'PageSection';
                slug: SlugTypeKind | null | undefined;
                title: string | null | undefined;
                name: string | null | undefined;
                description: string | null | undefined;
                url: string | null | undefined;
                imageUrl: string | null | undefined;
                otherType: OtherTypeKind | null | undefined;
                sectionType: string | null | undefined;
              }>
            | null
            | undefined;
        }
      | null
      | undefined;
    highlightedBundles: Array<{
      __typename?: 'Bundle';
      id: string;
      title: string;
      slug: string;
      heroImage: string | null | undefined;
      cardImage: string | null | undefined;
      heroMobileImage: string | null | undefined;
      active: boolean;
      kind: BundleKind;
      accessStrategy: BundleAccessStrategyKind;
      isUserEligible: boolean;
      bundleCategory: BundleCategoryKind | null | undefined;
      specialOfferCode: string | null | undefined;
      accessPeriodUnit: string | null | undefined;
      accessPeriodValue: number | null | undefined;
      hiddenInListing: boolean;
      metaDescription: string;
      metaImage: string | null | undefined;
      publishedAt: any | null | undefined;
      shortDescription: string;
      promotionalImage: string | null | undefined;
      tags: Array<string | null | undefined>;
      price:
        | {
            __typename?: 'Price';
            currency: string | null | undefined;
            discountApplied: boolean;
            discountedPrice: number | null | undefined;
            originalPrice: number | null | undefined;
          }
        | null
        | undefined;
      subscriptionPromotion:
        | {
            __typename?: 'UserSubscription';
            code: string | null | undefined;
            title: string | null | undefined;
            description: string | null | undefined;
            shortDescription: string | null | undefined;
            amount: number | null | undefined;
            discountedAmount: number | null | undefined;
            currency: string | null | undefined;
            months: number | null | undefined;
            days: number | null | undefined;
            accessUntil: any | null | undefined;
            promotionId: string | null | undefined;
            subscriptionName: string;
            subscriptionPlan: string | null | undefined;
            promotionKind: PromotionKind | null | undefined;
            promotionName: string | null | undefined;
            recurringPromotion: boolean | null | undefined;
          }
        | null
        | undefined;
      userAccess:
        | {
            __typename?: 'UserAccess';
            active: boolean;
            joinedAt: any | null | undefined;
            accessUntil: any | null | undefined;
          }
        | null
        | undefined;
      waitingItemInfo: {
        __typename?: 'WaitingItemInfo';
        startDate: any | null | undefined;
        waitingListJoined: boolean | null | undefined;
        waitingListEnabled: boolean | null | undefined;
        waitingListEndDate: any | null | undefined;
        waitingListStartDate: any | null | undefined;
      };
      client:
        | {
            __typename?: 'Client';
            id: string;
            logo: string | null | undefined;
            name: string;
            slug: string;
            info: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export type VideoWatchMutationVariables = Exact<{
  videoId: Scalars['String'];
  seconds: Scalars['Int'];
  cursorAt: Scalars['Int'];
}>;

export type VideoWatchMutation = {
  __typename?: 'Mutation';
  videoWatch:
    | {
        __typename?: 'WatchPayload';
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type VideoWatchCompletedMutationVariables = Exact<{
  videoId: Scalars['String'];
  seconds: Scalars['Int'];
  cursorAt: Scalars['Int'];
}>;

export type VideoWatchCompletedMutation = {
  __typename?: 'Mutation';
  videoWatchCompleted:
    | {
        __typename?: 'WatchCompletedPayload';
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserStatsQueryVariables = Exact<{
  period: InputMaybe<StatsPeriodKind>;
}>;

export type UserStatsQuery = {
  __typename?: 'Query';
  userStats: {
    __typename?: 'Stats';
    numberOfChallengesStarted: number;
    numberOfCoursesStarted: number;
    numberOfLiveEventsBooked: number;
    numberOfProgramsStarted: number;
    playlistsCreated: number;
    sessionsCompleted: number;
    sessionsFavorited: number | null | undefined;
    totalWatchTime: number | null | undefined;
    threeMostWatchedInstructors: Array<{
      __typename?: 'Instructor';
      id: string;
      name: string | null | undefined;
      assets:
        | {
            __typename?: 'InstructorAssets';
            squareImage: string | null | undefined;
          }
        | null
        | undefined;
    }>;
    threeMostWatchedStyles: Array<{
      __typename?: 'Term';
      slug: string | null | undefined;
      name: string | null | undefined;
    }>;
  };
};

export type ActivateFullAccessCodeMutationVariables = Exact<{
  promotionName: Scalars['String'];
  code: Scalars['String'];
}>;

export type ActivateFullAccessCodeMutation = {
  __typename?: 'Mutation';
  activateFullAccessCode:
    | {
        __typename?: 'ActivateFullAccessCodePayload';
        success: boolean | null | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ActivateOneTimeCodeMutationVariables = Exact<{
  promotionName: Scalars['String'];
  code: Scalars['String'];
}>;

export type ActivateOneTimeCodeMutation = {
  __typename?: 'Mutation';
  activateOneTimeCode:
    | {
        __typename?: 'ActivateOneTimeCodePayload';
        success: boolean | null | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PaymentsStoreAdyenDropinMutationVariables = Exact<{
  redirectTo: InputMaybe<Scalars['String']>;
  paymentType: Scalars['String'];
  riskData: InputMaybe<RiskDataInput>;
  paymentMethod: PaymentMethodInput;
  paymentMethodId: InputMaybe<Scalars['String']>;
  browserInfo: InputMaybe<BrowserInfoInput>;
  code: InputMaybe<Scalars['String']>;
  sourcePage: InputMaybe<Scalars['String']>;
}>;

export type PaymentsStoreAdyenDropinMutation = {
  __typename?: 'Mutation';
  paymentsStoreAdyenDropin:
    | {
        __typename?: 'StoreAdyenDropinPayload';
        orderId: string | null | undefined;
        transaction: string | null | undefined;
        recurringDate: string | null | undefined;
        recurringPaymentAmount: string | null | undefined;
        resultCode: PaymentResultCodeKind | null | undefined;
        resultAction:
          | {
              __typename?: 'AdyenAction';
              url: string | null | undefined;
              httpMethod: string | null | undefined;
              paymentMethodType: string;
              paymentData: string | null | undefined;
              redirectData: string | null | undefined;
              subtype: string | null | undefined;
              token: string | null | undefined;
              type: AdyenActionTypeKind | null | undefined;
              authorisationToken: string | null | undefined;
            }
          | null
          | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PaymentsStoreTrustlyMutationVariables = Exact<{
  paymentMethodId: InputMaybe<Scalars['String']>;
  paymentType: Scalars['String'];
  redirectTo: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
  sourcePage: InputMaybe<Scalars['String']>;
}>;

export type PaymentsStoreTrustlyMutation = {
  __typename?: 'Mutation';
  paymentsStoreTrustly:
    | {
        __typename?: 'StoreTrustlyPayload';
        resultUrl: string | null | undefined;
        recurringPaymentAmount: string | null | undefined;
        recurringPaymentCurrency: string | null | undefined;
        recurringDate: string | null | undefined;
        transaction: string | null | undefined;
        orderId: string | null | undefined;
        errors:
          | Array<{
              __typename?: 'Error';
              path: Array<string> | null | undefined;
              message: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type PaymentsStorePaymentDetailsMutationVariables = Exact<{
  threedsResult: InputMaybe<Scalars['String']>;
  paymentData: InputMaybe<Scalars['String']>;
  payload: InputMaybe<Scalars['String']>;
  orderId: Scalars['String'];
}>;

export type PaymentsStorePaymentDetailsMutation = {
  __typename?: 'Mutation';
  paymentsStorePaymentDetails:
    | {
        __typename?: 'StorePaymentDetailsPayload';
        orderId: string | null | undefined;
        resultCode: AdyenResultCodeKind | null | undefined;
        newRecord: boolean | null | undefined;
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SurveyQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type SurveyQuery = {
  __typename?: 'Query';
  survey:
    | {
        __typename?: 'Survey';
        id: string;
        name: string;
        description: string | null | undefined;
        content: {
          __typename?: 'SurveyContent';
          page1Description: string | null | undefined;
          page1Title: string | null | undefined;
          page2Description: string | null | undefined;
          page2Title: string | null | undefined;
          page3ButtonUrl: string | null | undefined;
          page3ButtonText: string | null | undefined;
          page3Description: string | null | undefined;
          page3Title: string | null | undefined;
        };
        questions:
          | Array<
              | {
                  __typename?: 'SurveyQuestion';
                  id: string;
                  question: string | null | undefined;
                  answers: Array<string> | null | undefined;
                  options:
                    | {
                        __typename?: 'SurveyOptions';
                        default: string | null | undefined;
                        hint: string | null | undefined;
                        leftLabel: string | null | undefined;
                        maxValue: number | null | undefined;
                        minValue: number | null | undefined;
                        required: boolean;
                        rightLabel: string | null | undefined;
                        useForNps: boolean | null | undefined;
                        type: SurveyTypeKind;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetSurveyQueryVariables = Exact<{
  surveyPartId: Scalars['Int'];
}>;

export type GetSurveyQuery = {
  __typename?: 'Query';
  surveyPart:
    | {
        __typename?: 'ProgramSurveyElement';
        id: string;
        slug: string;
        subheader: string | null | undefined;
        title: string | null | undefined;
        description: string | null | undefined;
        locked: boolean;
        finished: boolean | null | undefined;
        position: number;
        positionLabel: string;
        progressIsCurrent: boolean;
        lastSubmittedAt: any | null | undefined;
        surveysAnswer:
          | {
              __typename?: 'SurveyAnswer';
              id: string;
              state: State | null | undefined;
              contextableType: string | null | undefined;
              content: Array<{
                __typename?: 'ContentAnswer';
                key: string;
                value: Array<string>;
              }>;
            }
          | null
          | undefined;
        survey: {
          __typename?: 'Survey';
          id: string;
          name: string;
          description: string | null | undefined;
          content: {
            __typename?: 'SurveyContent';
            page1Description: string | null | undefined;
            page1Title: string | null | undefined;
            page2Description: string | null | undefined;
            page2Title: string | null | undefined;
            page3ButtonUrl: string | null | undefined;
            page3ButtonText: string | null | undefined;
            page3Description: string | null | undefined;
            page3Title: string | null | undefined;
          };
          questions:
            | Array<
                | {
                    __typename?: 'SurveyQuestion';
                    id: string;
                    question: string | null | undefined;
                    answers: Array<string> | null | undefined;
                    options:
                      | {
                          __typename?: 'SurveyOptions';
                          default: string | null | undefined;
                          hint: string | null | undefined;
                          leftLabel: string | null | undefined;
                          maxValue: number | null | undefined;
                          minValue: number | null | undefined;
                          required: boolean;
                          rightLabel: string | null | undefined;
                          useForNps: boolean | null | undefined;
                          type: SurveyTypeKind;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type StartTrialPlanSubscriptionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type StartTrialPlanSubscriptionMutation = {
  __typename?: 'Mutation';
  startTrialPlanSubscription:
    | {
        __typename?: 'StartTrialPlanSubscriptionPayload';
        success: boolean;
        errors: Array<{
          __typename?: 'Error';
          message: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type AssignPlanToTrialMutationVariables = Exact<{
  planType: PlanTypeKind;
  subscriptionPromotionId: InputMaybe<Scalars['String']>;
  code: InputMaybe<Scalars['String']>;
}>;

export type AssignPlanToTrialMutation = {
  __typename?: 'Mutation';
  assignPlanToTrial:
    | {
        __typename?: 'AssignPlanToTrialPayload';
        success: boolean;
        errors: Array<string> | null | undefined;
      }
    | null
    | undefined;
};

export type ProcessTrialPlanMutationVariables = Exact<{ [key: string]: never }>;

export type ProcessTrialPlanMutation = {
  __typename?: 'Mutation';
  processTrialPlan:
    | {
        __typename?: 'ProcessTrialPlanPayload';
        errors: Array<string> | null | undefined;
        success: boolean;
      }
    | null
    | undefined;
};

export type UpsellOffersQueryVariables = Exact<{ [key: string]: never }>;

export type UpsellOffersQuery = {
  __typename?: 'Query';
  upsellOffers:
    | Array<{
        __typename?: 'Upsell';
        title: string | null | undefined;
        description: string | null | undefined;
        image: string | null | undefined;
        amountCents: number | null | undefined;
        amountCurrency: string | null | undefined;
        promotionName: string;
        subscriptionPlanType: SubscriptionUpsellPlanTypeKind | null | undefined;
        subscriptionPlan:
          | {
              __typename?: 'SubscriptionPlan';
              months: number;
              amountCents: number;
              amountCurrency: string;
              planType: string;
              platform: DevicePlatformKind | null | undefined;
              productId: string | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type AvailableAllCodesQueryVariables = Exact<{
  code: InputMaybe<Scalars['String']>;
  registration?: InputMaybe<Scalars['Boolean']>;
}>;

export type AvailableAllCodesQuery = {
  __typename?: 'Query';
  availableAllCodes: {
    __typename?: 'AvailableSubscriptionPlan';
    code: string | null | undefined;
    codeType: Code | null | undefined;
    isCodeValid: boolean | null | undefined;
    validationMessage: string | null | undefined;
    subscriptions: Array<{
      __typename?: 'UserSubscription';
      title: string | null | undefined;
      description: string | null | undefined;
      shortDescription: string | null | undefined;
      amount: number | null | undefined;
      discountedAmount: number | null | undefined;
      currency: string | null | undefined;
      months: number | null | undefined;
      days: number | null | undefined;
      accessUntil: any | null | undefined;
      promotionId: string | null | undefined;
      subscriptionName: string;
      subscriptionPlan: string | null | undefined;
      promotionKind: PromotionKind | null | undefined;
      promotionName: string | null | undefined;
      recurringPromotion: boolean | null | undefined;
    }>;
    fullAccesses:
      | Array<{
          __typename?: 'UserSubscription';
          title: string | null | undefined;
          description: string | null | undefined;
          shortDescription: string | null | undefined;
          amount: number | null | undefined;
          discountedAmount: number | null | undefined;
          currency: string | null | undefined;
          months: number | null | undefined;
          days: number | null | undefined;
          accessUntil: any | null | undefined;
          promotionId: string | null | undefined;
          subscriptionName: string;
          subscriptionPlan: string | null | undefined;
          promotionKind: PromotionKind | null | undefined;
          promotionName: string | null | undefined;
          recurringPromotion: boolean | null | undefined;
        }>
      | null
      | undefined;
    oneTimeCodes:
      | Array<{
          __typename?: 'UserSubscription';
          title: string | null | undefined;
          description: string | null | undefined;
          shortDescription: string | null | undefined;
          amount: number | null | undefined;
          discountedAmount: number | null | undefined;
          currency: string | null | undefined;
          months: number | null | undefined;
          days: number | null | undefined;
          accessUntil: any | null | undefined;
          promotionId: string | null | undefined;
          subscriptionName: string;
          subscriptionPlan: string | null | undefined;
          promotionKind: PromotionKind | null | undefined;
          promotionName: string | null | undefined;
          recurringPromotion: boolean | null | undefined;
        }>
      | null
      | undefined;
    trialReferralCodes:
      | Array<{
          __typename?: 'UserSubscription';
          title: string | null | undefined;
          description: string | null | undefined;
          shortDescription: string | null | undefined;
          amount: number | null | undefined;
          discountedAmount: number | null | undefined;
          currency: string | null | undefined;
          months: number | null | undefined;
          days: number | null | undefined;
          accessUntil: any | null | undefined;
          promotionId: string | null | undefined;
          subscriptionName: string;
          subscriptionPlan: string | null | undefined;
          promotionKind: PromotionKind | null | undefined;
          promotionName: string | null | undefined;
          recurringPromotion: boolean | null | undefined;
        }>
      | null
      | undefined;
    trials:
      | Array<{
          __typename?: 'UserSubscription';
          title: string | null | undefined;
          description: string | null | undefined;
          shortDescription: string | null | undefined;
          amount: number | null | undefined;
          discountedAmount: number | null | undefined;
          currency: string | null | undefined;
          months: number | null | undefined;
          days: number | null | undefined;
          subscriptionName: string;
          subscriptionPlan: string | null | undefined;
          promotionName: string | null | undefined;
          recurringPromotion: boolean | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type RegistrationPageQueryVariables = Exact<{ [key: string]: never }>;

export type RegistrationPageQuery = {
  __typename?: 'Query';
  registrationPage: {
    __typename?: 'RegistrationPlayPage';
    contentHubImageUrl: string | null | undefined;
    courseImageUrl: string | null | undefined;
    healthcareImageUrl: string | null | undefined;
    liveImageUrl: string | null | undefined;
    playImageUrl: string | null | undefined;
    subscriptionPlansImageUrl: string | null | undefined;
    trialImageUrl: string | null | undefined;
  };
};

export type AssignFinalFunnelMutationVariables = Exact<{
  funnel: FunnelKind;
}>;

export type AssignFinalFunnelMutation = {
  __typename?: 'Mutation';
  assignFinalFunnel:
    | { __typename?: 'AssignFinalFunnelPayload'; success: boolean }
    | null
    | undefined;
};

export type RegistrationStepMutationVariables = Exact<{
  step: RegistrationStepEnum;
  funnel: FunnelKind;
}>;

export type RegistrationStepMutation = {
  __typename?: 'Mutation';
  registrationStep:
    | { __typename?: 'StepMutationPayload'; success: boolean }
    | null
    | undefined;
};

export type PatchUserMutationVariables = Exact<{
  contentLanguages: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  excludeTerms: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  videoLevels: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type PatchUserMutation = {
  __typename?: 'Mutation';
  patchUser:
    | {
        __typename?: 'ChangePreferencesPayload';
        user:
          | { __typename?: 'CurrentUser'; id: string }
          | { __typename?: 'User'; id: string };
      }
    | null
    | undefined;
};

export type UserPlaylistsQueryVariables = Exact<{
  id: InputMaybe<Scalars['ID']>;
  page: InputMaybe<Scalars['Int']>;
  perPage: InputMaybe<Scalars['Int']>;
}>;

export type UserPlaylistsQuery = {
  __typename?: 'Query';
  userPlaylists:
    | {
        __typename?: 'PagedPlaylists';
        page: number;
        perPage: number;
        totalCount: number;
        data: Array<{
          __typename?: 'Playlist';
          id: string;
          identifier: string | null | undefined;
          title: string;
          visibility: string | null | undefined;
          description: string | null | undefined;
          owner:
            | {
                __typename?: 'CurrentUser';
                id: string;
                firstName: string | null | undefined;
              }
            | {
                __typename?: 'User';
                id: string;
                firstName: string | null | undefined;
              }
            | null
            | undefined;
          meta:
            | {
                __typename?: 'Meta';
                videoCount: number | null | undefined;
                minutes: number | null | undefined;
              }
            | null
            | undefined;
          assets:
            | {
                __typename?: 'PlaylistAssets';
                thumbnailUrl: string | null | undefined;
              }
            | null
            | undefined;
          videos:
            | Array<{ __typename?: 'Video'; id: string } | null | undefined>
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type RemoveVideoFromPlaylistMutationVariables = Exact<{
  playlistId: Scalars['ID'];
  videoId: Scalars['ID'];
}>;

export type RemoveVideoFromPlaylistMutation = {
  __typename?: 'Mutation';
  removeVideoFromPlaylist:
    | {
        __typename?: 'RemoveVideoFromPlaylistPayload';
        playlist: { __typename?: 'Playlist'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type AddVideoToPlaylistMutationVariables = Exact<{
  playlistId: Scalars['ID'];
  videoId: Scalars['ID'];
}>;

export type AddVideoToPlaylistMutation = {
  __typename?: 'Mutation';
  addVideoToPlaylist:
    | {
        __typename?: 'AddVideoToPlaylistPayload';
        playlist: { __typename?: 'Playlist'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type DeleteFavoriteVideoMutationVariables = Exact<{
  videoId: Scalars['ID'];
}>;

export type DeleteFavoriteVideoMutation = {
  __typename?: 'Mutation';
  deleteFavoriteVideo:
    | {
        __typename?: 'DeleteFavoriteVideoPayload';
        video: { __typename?: 'Video'; favorited: boolean } | null | undefined;
      }
    | null
    | undefined;
};

export type AddFavoriteVideoMutationVariables = Exact<{
  videoId: Scalars['ID'];
}>;

export type AddFavoriteVideoMutation = {
  __typename?: 'Mutation';
  addFavoriteVideo:
    | {
        __typename?: 'AddFavoriteVideoPayload';
        video: { __typename?: 'Video'; favorited: boolean } | null | undefined;
      }
    | null
    | undefined;
};

export type TaxonomiesQueryVariables = Exact<{
  genre: InputMaybe<Scalars['String']>;
  languages: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type TaxonomiesQuery = {
  __typename?: 'Query';
  taxonomies:
    | Array<
        | {
            __typename?: 'TaxonomyItem';
            id: string;
            name: string | null | undefined;
            slug: string | null | undefined;
            terms:
              | Array<{
                  __typename?: 'Term';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  important: boolean | null | undefined;
                }>
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type TaxonomyTermFragment = {
  __typename?: 'Term';
  slug: string | null | undefined;
  name: string | null | undefined;
};

export type TaxonomyTermV2Fragment = {
  __typename?: 'TermTypeV2';
  slug: string | null | undefined;
  name: string | null | undefined;
};

export type NewTaxonomyItemFieldsFragment = {
  __typename?: 'NewTaxonomyItem';
  name: string | null | undefined;
  slug: string | null | undefined;
  subcategories:
    | Array<
        | {
            __typename?: 'TaxonomySubcategory';
            name: string | null | undefined;
            slug: string | null | undefined;
            terms:
              | Array<{
                  __typename?: 'Term';
                  slug: string | null | undefined;
                  name: string | null | undefined;
                }>
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  nonSubcategorized:
    | Array<
        | {
            __typename?: 'TermTypeV2';
            slug: string | null | undefined;
            name: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type TaxonomiesV2QueryVariables = Exact<{
  genre: InputMaybe<Scalars['String']>;
  languages: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type TaxonomiesV2Query = {
  __typename?: 'Query';
  taxonomiesV2: Array<
    | {
        __typename?: 'NewTaxonomyItem';
        name: string | null | undefined;
        slug: string | null | undefined;
        subcategories:
          | Array<
              | {
                  __typename?: 'TaxonomySubcategory';
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  terms:
                    | Array<{
                        __typename?: 'Term';
                        slug: string | null | undefined;
                        name: string | null | undefined;
                      }>
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        nonSubcategorized:
          | Array<
              | {
                  __typename?: 'TermTypeV2';
                  slug: string | null | undefined;
                  name: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type CrossPromotionalBannersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CrossPromotionalBannersQuery = {
  __typename?: 'Query';
  crossPromotionalBanners: Array<{
    __typename?: 'CrossPromotionalBanner';
    link: string | null | undefined;
    imageUrl: string | null | undefined;
    name: string;
  }>;
};

export type TaxonomyV2FieldsFragment = {
  __typename?: 'TaxonomyV2';
  term:
    | {
        __typename?: 'Term';
        name: string | null | undefined;
        slug: string | null | undefined;
      }
    | null
    | undefined;
  taxonomies:
    | Array<{
        __typename?: 'TermTypeV2';
        slug: string | null | undefined;
        name: string | null | undefined;
        link: string | null | undefined;
      }>
    | null
    | undefined;
};

export type VideoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type VideoQuery = {
  __typename?: 'Query';
  video:
    | {
        __typename?: 'Video';
        id: string;
        kind: string;
        hls: string;
        identifier: string;
        hasAccess: boolean;
        cursorAt: number | null | undefined;
        categories: Array<string>;
        removedText: string | null | undefined;
        publishedAt: number;
        player:
          | {
              __typename?: 'VideoInfo';
              assets:
                | Array<
                    | {
                        __typename?: 'VideoFileAsset';
                        src: string | null | undefined;
                        resolution: number | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        assets:
          | {
              __typename?: 'VideoAssets';
              thumbnailUrl: string | null | undefined;
              thumbnailUrlMedium: string | null | undefined;
            }
          | null
          | undefined;
        primaryStyle:
          | {
              __typename?: 'Term';
              name: string | null | undefined;
              slug: string | null | undefined;
            }
          | null
          | undefined;
        taxonomiesV2: Array<{
          __typename?: 'TaxonomyV2';
          term:
            | {
                __typename?: 'Term';
                name: string | null | undefined;
                slug: string | null | undefined;
              }
            | null
            | undefined;
          taxonomies:
            | Array<{
                __typename?: 'TermTypeV2';
                slug: string | null | undefined;
                name: string | null | undefined;
                link: string | null | undefined;
              }>
            | null
            | undefined;
        }>;
        taxonomies: {
          __typename?: 'Taxonomy';
          flavor:
            | {
                __typename?: 'Term';
                id: string;
                name: string | null | undefined;
                slug: string | null | undefined;
              }
            | null
            | undefined;
          needs:
            | Array<
                | {
                    __typename?: 'Term';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          instructors:
            | Array<
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    shortDescription: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          genre:
            | {
                __typename?: 'Term';
                id: string;
                name: string | null | undefined;
                slug: string | null | undefined;
              }
            | null
            | undefined;
          props:
            | Array<
                | {
                    __typename?: 'Term';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    link: string | null | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        };
        meta:
          | { __typename?: 'VideoMeta'; minutes: number | null | undefined }
          | null
          | undefined;
        content:
          | {
              __typename?: 'VideoContent';
              title: string | null | undefined;
              shortDescription: string | null | undefined;
              longDescription: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type InstructorByGenreQueryVariables = Exact<{
  genre: InputMaybe<Scalars['String']>;
  instructor: Scalars['String'];
  languages: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
}>;

export type InstructorByGenreQuery = {
  __typename?: 'Query';
  instructorByGenre: {
    __typename?: 'InstructorInGenre';
    instructor:
      | {
          __typename?: 'Instructor';
          id: string;
          name: string | null | undefined;
          assets:
            | {
                __typename?: 'InstructorAssets';
                squareImage: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    videosCountInGenres:
      | Array<
          | {
              __typename?: 'VideosCountByGenre';
              channel: string | null | undefined;
              slug: string | null | undefined;
              count: number | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type LatestViewedVideosQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type LatestViewedVideosQuery = {
  __typename?: 'Query';
  latestViewedVideos: {
    __typename?: 'PagedVideos';
    page: number;
    perPage: number;
    totalCount: number;
    data: Array<{
      __typename?: 'Video';
      id: string;
      identifier: string;
      kind: string;
      cursorAt: number | null | undefined;
      removedText: string | null | undefined;
      content:
        | {
            __typename?: 'VideoContent';
            title: string | null | undefined;
            shortDescription: string | null | undefined;
            longDescription: string | null | undefined;
          }
        | null
        | undefined;
      meta:
        | {
            __typename?: 'VideoMeta';
            minutes: number | null | undefined;
            publishedAt: number | null | undefined;
          }
        | null
        | undefined;
      primaryStyle:
        | {
            __typename?: 'Term';
            name: string | null | undefined;
            slug: string | null | undefined;
          }
        | null
        | undefined;
      taxonomies: {
        __typename?: 'Taxonomy';
        flavor:
          | { __typename?: 'Term'; id: string; name: string | null | undefined }
          | null
          | undefined;
        needs:
          | Array<
              | {
                  __typename?: 'Term';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        externalImage: string | null | undefined;
                        squareImage: string | null | undefined;
                        titleImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        genre:
          | {
              __typename?: 'Term';
              id: string;
              name: string | null | undefined;
              slug: string | null | undefined;
            }
          | null
          | undefined;
      };
      assets:
        | {
            __typename?: 'VideoAssets';
            thumbnailUrl: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export type PopularVideosQueryVariables = Exact<{ [key: string]: never }>;

export type PopularVideosQuery = {
  __typename?: 'Query';
  popularVideos:
    | Array<
        | {
            __typename?: 'Video';
            id: string;
            identifier: string;
            kind: string;
            cursorAt: number | null | undefined;
            removedText: string | null | undefined;
            content:
              | {
                  __typename?: 'VideoContent';
                  title: string | null | undefined;
                  shortDescription: string | null | undefined;
                  longDescription: string | null | undefined;
                }
              | null
              | undefined;
            meta:
              | {
                  __typename?: 'VideoMeta';
                  minutes: number | null | undefined;
                  publishedAt: number | null | undefined;
                }
              | null
              | undefined;
            primaryStyle:
              | {
                  __typename?: 'Term';
                  name: string | null | undefined;
                  slug: string | null | undefined;
                }
              | null
              | undefined;
            taxonomies: {
              __typename?: 'Taxonomy';
              flavor:
                | {
                    __typename?: 'Term';
                    id: string;
                    name: string | null | undefined;
                  }
                | null
                | undefined;
              needs:
                | Array<
                    | {
                        __typename?: 'Term';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              externalImage: string | null | undefined;
                              squareImage: string | null | undefined;
                              titleImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              genre:
                | {
                    __typename?: 'Term';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                  }
                | null
                | undefined;
            };
            assets:
              | {
                  __typename?: 'VideoAssets';
                  thumbnailUrl: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type WeeklyProgramVideoFieldsFragment = {
  __typename?: 'Video';
  id: string;
  title: string;
  kind: string;
  identifier: string;
  hasAccess: boolean;
  cursorAt: number | null | undefined;
  hls: string;
  content:
    | {
        __typename?: 'VideoContent';
        title: string | null | undefined;
        shortDescription: string | null | undefined;
        longDescription: string | null | undefined;
      }
    | null
    | undefined;
  player:
    | {
        __typename?: 'VideoInfo';
        assets:
          | Array<
              | {
                  __typename?: 'VideoFileAsset';
                  src: string | null | undefined;
                  resolution: number | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  meta:
    | {
        __typename?: 'VideoMeta';
        publishedAt: number | null | undefined;
        minutes: number | null | undefined;
      }
    | null
    | undefined;
  taxonomies: {
    __typename?: 'Taxonomy';
    flavor:
      | { __typename?: 'Term'; name: string | null | undefined }
      | null
      | undefined;
    instructors:
      | Array<
          | {
              __typename?: 'Instructor';
              name: string | null | undefined;
              slug: string | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    genre:
      | {
          __typename?: 'Term';
          name: string | null | undefined;
          slug: string | null | undefined;
        }
      | null
      | undefined;
  };
  assets:
    | { __typename?: 'VideoAssets'; thumbnailUrl: string | null | undefined }
    | null
    | undefined;
};

export type ProgramDocumentFieldsFragment = {
  __typename?: 'ProgramDocument';
  id: string;
  slug: string;
  title: string | null | undefined;
  subheader: string | null | undefined;
  description: string | null | undefined;
  finished: boolean | null | undefined;
  kind: string | null | undefined;
  locked: boolean;
  position: number;
  positionLabel: string;
  progressIsCurrent: boolean;
  customPdfThumbnail: string | null | undefined;
  document: {
    __typename?: 'Document';
    url: string | null | undefined;
    name: string;
  };
};

export type ProgramVideoFieldsFragment = {
  __typename?: 'ProgramVideo';
  id: string;
  slug: string;
  title: string | null | undefined;
  subheader: string | null | undefined;
  customTitle: string | null | undefined;
  locked: boolean;
  watched: boolean | null | undefined;
  finished: boolean | null | undefined;
  position: number;
  positionLabel: string;
  videoKind: VideoKind | null | undefined;
  progressIsCurrent: boolean;
  repeatRecomendation: number | null | undefined;
  customDescription: string | null | undefined;
  preMoodTracker:
    | {
        __typename?: 'MoodTracker';
        id: string;
        finished: boolean | null | undefined;
        kind: MoodTrackerKind;
      }
    | null
    | undefined;
  postMoodTracker:
    | {
        __typename?: 'MoodTracker';
        id: string;
        finished: boolean | null | undefined;
        kind: MoodTrackerKind;
      }
    | null
    | undefined;
  video: {
    __typename?: 'Video';
    id: string;
    title: string;
    kind: string;
    identifier: string;
    hasAccess: boolean;
    cursorAt: number | null | undefined;
    hls: string;
    content:
      | {
          __typename?: 'VideoContent';
          title: string | null | undefined;
          shortDescription: string | null | undefined;
          longDescription: string | null | undefined;
        }
      | null
      | undefined;
    player:
      | {
          __typename?: 'VideoInfo';
          assets:
            | Array<
                | {
                    __typename?: 'VideoFileAsset';
                    src: string | null | undefined;
                    resolution: number | null | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        }
      | null
      | undefined;
    meta:
      | {
          __typename?: 'VideoMeta';
          publishedAt: number | null | undefined;
          minutes: number | null | undefined;
        }
      | null
      | undefined;
    taxonomies: {
      __typename?: 'Taxonomy';
      flavor:
        | { __typename?: 'Term'; name: string | null | undefined }
        | null
        | undefined;
      instructors:
        | Array<
            | {
                __typename?: 'Instructor';
                name: string | null | undefined;
                slug: string | null | undefined;
              }
            | null
            | undefined
          >
        | null
        | undefined;
      genre:
        | {
            __typename?: 'Term';
            name: string | null | undefined;
            slug: string | null | undefined;
          }
        | null
        | undefined;
    };
    assets:
      | { __typename?: 'VideoAssets'; thumbnailUrl: string | null | undefined }
      | null
      | undefined;
  };
};

export type ProgramSurveyFieldsFragment = {
  __typename?: 'Survey';
  id: string;
  name: string;
  description: string | null | undefined;
  content: {
    __typename?: 'SurveyContent';
    page1Description: string | null | undefined;
    page1Title: string | null | undefined;
    page2Description: string | null | undefined;
    page2Title: string | null | undefined;
    page3ButtonUrl: string | null | undefined;
    page3ButtonText: string | null | undefined;
    page3Description: string | null | undefined;
    page3Title: string | null | undefined;
  };
  questions:
    | Array<
        | {
            __typename?: 'SurveyQuestion';
            id: string;
            question: string | null | undefined;
            answers: Array<string> | null | undefined;
            options:
              | {
                  __typename?: 'SurveyOptions';
                  default: string | null | undefined;
                  hint: string | null | undefined;
                  leftLabel: string | null | undefined;
                  maxValue: number | null | undefined;
                  minValue: number | null | undefined;
                  required: boolean;
                  rightLabel: string | null | undefined;
                  useForNps: boolean | null | undefined;
                  type: SurveyTypeKind;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ProgramLiveEventElementFieldsFragment = {
  __typename?: 'ProgramLiveEvent';
  description: string | null | undefined;
  finished: boolean | null | undefined;
  id: string;
  kind: string | null | undefined;
  locked: boolean;
  position: number;
  positionLabel: string;
  progressIsCurrent: boolean;
  slug: string;
  subheader: string | null | undefined;
  title: string | null | undefined;
  isExpired: boolean | null | undefined;
  liveEvent:
    | {
        __typename?: 'LiveEvent';
        id: string;
        title: string;
        slug: string;
        thumbnail: string;
        duration: number;
        eventDates: Array<any>;
        kind: LiveEventKind;
        language: LanguageKind;
        level: LiveEventLevel;
        theme: string | null | undefined;
        themeLabel: string | null | undefined;
        availableSpots: number;
        maximumParticipants: number;
        bookingAvailable: boolean;
        paymentKind: LiveEventPaymentKind;
        flexibleMaxAmountCents: number | null | undefined;
        flexibleMinAmountCents: number | null | undefined;
        customDiscount: number | null | undefined;
        isUserEligible: boolean | null | undefined;
        descriptionLong: string;
        registrationEndDate: any;
        registrationStartDate: any;
        isClosed: boolean | null | undefined;
        isExpired: boolean | null | undefined;
        isComingSoon: boolean | null | undefined;
        linkToRecordings:
          | Array<{
              __typename?: 'LinkToRecording';
              isExpired: boolean | null | undefined;
              link: string | null | undefined;
              id: string;
              eventDate: any;
              video:
                | {
                    __typename?: 'Video';
                    id: string;
                    title: string;
                    kind: string;
                    identifier: string;
                    hasAccess: boolean;
                    cursorAt: number | null | undefined;
                    hls: string;
                    content:
                      | {
                          __typename?: 'VideoContent';
                          title: string | null | undefined;
                          shortDescription: string | null | undefined;
                          longDescription: string | null | undefined;
                        }
                      | null
                      | undefined;
                    player:
                      | {
                          __typename?: 'VideoInfo';
                          assets:
                            | Array<
                                | {
                                    __typename?: 'VideoFileAsset';
                                    src: string | null | undefined;
                                    resolution: number | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    meta:
                      | {
                          __typename?: 'VideoMeta';
                          publishedAt: number | null | undefined;
                          minutes: number | null | undefined;
                        }
                      | null
                      | undefined;
                    taxonomies: {
                      __typename?: 'Taxonomy';
                      flavor:
                        | {
                            __typename?: 'Term';
                            name: string | null | undefined;
                          }
                        | null
                        | undefined;
                      instructors:
                        | Array<
                            | {
                                __typename?: 'Instructor';
                                name: string | null | undefined;
                                slug: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      genre:
                        | {
                            __typename?: 'Term';
                            name: string | null | undefined;
                            slug: string | null | undefined;
                          }
                        | null
                        | undefined;
                    };
                    assets:
                      | {
                          __typename?: 'VideoAssets';
                          thumbnailUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        price:
          | {
              __typename?: 'Price';
              originalPrice: number | null | undefined;
              discountApplied: boolean;
              discountedPrice: number | null | undefined;
              currency: string | null | undefined;
            }
          | null
          | undefined;
        primaryInstructor:
          | {
              __typename?: 'Instructor';
              id: string;
              name: string | null | undefined;
              slug: string | null | undefined;
              assets:
                | {
                    __typename?: 'InstructorAssets';
                    squareImage: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        userAccess:
          | {
              __typename?: 'LiveEventAccess';
              zoomLink: string;
              zoomPassword: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ProgramTextElementFieldsFragment = {
  __typename?: 'ProgramPartElement';
  id: string;
  slug: string;
  subheader: string | null | undefined;
  title: string | null | undefined;
  description: string | null | undefined;
  locked: boolean;
  finished: boolean | null | undefined;
  position: number;
  positionLabel: string;
  progressIsCurrent: boolean;
};

export type ProgramSurveyElementFieldsFragment = {
  __typename?: 'ProgramSurveyElement';
  id: string;
  slug: string;
  subheader: string | null | undefined;
  title: string | null | undefined;
  description: string | null | undefined;
  locked: boolean;
  finished: boolean | null | undefined;
  position: number;
  positionLabel: string;
  progressIsCurrent: boolean;
  lastSubmittedAt: any | null | undefined;
  surveysAnswer:
    | {
        __typename?: 'SurveyAnswer';
        id: string;
        state: State | null | undefined;
        contextableType: string | null | undefined;
        content: Array<{
          __typename?: 'ContentAnswer';
          key: string;
          value: Array<string>;
        }>;
      }
    | null
    | undefined;
  survey: {
    __typename?: 'Survey';
    id: string;
    name: string;
    description: string | null | undefined;
    content: {
      __typename?: 'SurveyContent';
      page1Description: string | null | undefined;
      page1Title: string | null | undefined;
      page2Description: string | null | undefined;
      page2Title: string | null | undefined;
      page3ButtonUrl: string | null | undefined;
      page3ButtonText: string | null | undefined;
      page3Description: string | null | undefined;
      page3Title: string | null | undefined;
    };
    questions:
      | Array<
          | {
              __typename?: 'SurveyQuestion';
              id: string;
              question: string | null | undefined;
              answers: Array<string> | null | undefined;
              options:
                | {
                    __typename?: 'SurveyOptions';
                    default: string | null | undefined;
                    hint: string | null | undefined;
                    leftLabel: string | null | undefined;
                    maxValue: number | null | undefined;
                    minValue: number | null | undefined;
                    required: boolean;
                    rightLabel: string | null | undefined;
                    useForNps: boolean | null | undefined;
                    type: SurveyTypeKind;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type WeeklyProgramWeekFragmentFragment = {
  __typename?: 'ProgramWeek';
  id: string;
  label: string;
  slug: string;
  locked: boolean;
  finished: boolean | null | undefined;
  progress: number | null | undefined;
  description: string | null | undefined;
  duration: string | null | undefined;
  videosCount: number | null | undefined;
  elements: Array<
    | {
        __typename: 'ProgramDocument';
        id: string;
        slug: string;
        title: string | null | undefined;
        subheader: string | null | undefined;
        description: string | null | undefined;
        finished: boolean | null | undefined;
        kind: string | null | undefined;
        locked: boolean;
        position: number;
        positionLabel: string;
        progressIsCurrent: boolean;
        customPdfThumbnail: string | null | undefined;
        document: {
          __typename?: 'Document';
          url: string | null | undefined;
          name: string;
        };
      }
    | {
        __typename: 'ProgramLiveEvent';
        description: string | null | undefined;
        finished: boolean | null | undefined;
        id: string;
        kind: string | null | undefined;
        locked: boolean;
        position: number;
        positionLabel: string;
        progressIsCurrent: boolean;
        slug: string;
        subheader: string | null | undefined;
        title: string | null | undefined;
        isExpired: boolean | null | undefined;
        liveEvent:
          | {
              __typename?: 'LiveEvent';
              id: string;
              title: string;
              slug: string;
              thumbnail: string;
              duration: number;
              eventDates: Array<any>;
              kind: LiveEventKind;
              language: LanguageKind;
              level: LiveEventLevel;
              theme: string | null | undefined;
              themeLabel: string | null | undefined;
              availableSpots: number;
              maximumParticipants: number;
              bookingAvailable: boolean;
              paymentKind: LiveEventPaymentKind;
              flexibleMaxAmountCents: number | null | undefined;
              flexibleMinAmountCents: number | null | undefined;
              customDiscount: number | null | undefined;
              isUserEligible: boolean | null | undefined;
              descriptionLong: string;
              registrationEndDate: any;
              registrationStartDate: any;
              isClosed: boolean | null | undefined;
              isExpired: boolean | null | undefined;
              isComingSoon: boolean | null | undefined;
              linkToRecordings:
                | Array<{
                    __typename?: 'LinkToRecording';
                    isExpired: boolean | null | undefined;
                    link: string | null | undefined;
                    id: string;
                    eventDate: any;
                    video:
                      | {
                          __typename?: 'Video';
                          id: string;
                          title: string;
                          kind: string;
                          identifier: string;
                          hasAccess: boolean;
                          cursorAt: number | null | undefined;
                          hls: string;
                          content:
                            | {
                                __typename?: 'VideoContent';
                                title: string | null | undefined;
                                shortDescription: string | null | undefined;
                                longDescription: string | null | undefined;
                              }
                            | null
                            | undefined;
                          player:
                            | {
                                __typename?: 'VideoInfo';
                                assets:
                                  | Array<
                                      | {
                                          __typename?: 'VideoFileAsset';
                                          src: string | null | undefined;
                                          resolution: number | null | undefined;
                                        }
                                      | null
                                      | undefined
                                    >
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          meta:
                            | {
                                __typename?: 'VideoMeta';
                                publishedAt: number | null | undefined;
                                minutes: number | null | undefined;
                              }
                            | null
                            | undefined;
                          taxonomies: {
                            __typename?: 'Taxonomy';
                            flavor:
                              | {
                                  __typename?: 'Term';
                                  name: string | null | undefined;
                                }
                              | null
                              | undefined;
                            instructors:
                              | Array<
                                  | {
                                      __typename?: 'Instructor';
                                      name: string | null | undefined;
                                      slug: string | null | undefined;
                                    }
                                  | null
                                  | undefined
                                >
                              | null
                              | undefined;
                            genre:
                              | {
                                  __typename?: 'Term';
                                  name: string | null | undefined;
                                  slug: string | null | undefined;
                                }
                              | null
                              | undefined;
                          };
                          assets:
                            | {
                                __typename?: 'VideoAssets';
                                thumbnailUrl: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
              price:
                | {
                    __typename?: 'Price';
                    originalPrice: number | null | undefined;
                    discountApplied: boolean;
                    discountedPrice: number | null | undefined;
                    currency: string | null | undefined;
                  }
                | null
                | undefined;
              primaryInstructor:
                | {
                    __typename?: 'Instructor';
                    id: string;
                    name: string | null | undefined;
                    slug: string | null | undefined;
                    assets:
                      | {
                          __typename?: 'InstructorAssets';
                          squareImage: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              instructors:
                | Array<
                    | {
                        __typename?: 'Instructor';
                        id: string;
                        name: string | null | undefined;
                        slug: string | null | undefined;
                        assets:
                          | {
                              __typename?: 'InstructorAssets';
                              squareImage: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              userAccess:
                | {
                    __typename?: 'LiveEventAccess';
                    zoomLink: string;
                    zoomPassword: string;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | {
        __typename: 'ProgramPartElement';
        id: string;
        slug: string;
        subheader: string | null | undefined;
        title: string | null | undefined;
        description: string | null | undefined;
        locked: boolean;
        finished: boolean | null | undefined;
        position: number;
        positionLabel: string;
        progressIsCurrent: boolean;
      }
    | {
        __typename: 'ProgramSurveyElement';
        id: string;
        slug: string;
        subheader: string | null | undefined;
        title: string | null | undefined;
        description: string | null | undefined;
        locked: boolean;
        finished: boolean | null | undefined;
        position: number;
        positionLabel: string;
        progressIsCurrent: boolean;
        lastSubmittedAt: any | null | undefined;
        surveysAnswer:
          | {
              __typename?: 'SurveyAnswer';
              id: string;
              state: State | null | undefined;
              contextableType: string | null | undefined;
              content: Array<{
                __typename?: 'ContentAnswer';
                key: string;
                value: Array<string>;
              }>;
            }
          | null
          | undefined;
        survey: {
          __typename?: 'Survey';
          id: string;
          name: string;
          description: string | null | undefined;
          content: {
            __typename?: 'SurveyContent';
            page1Description: string | null | undefined;
            page1Title: string | null | undefined;
            page2Description: string | null | undefined;
            page2Title: string | null | undefined;
            page3ButtonUrl: string | null | undefined;
            page3ButtonText: string | null | undefined;
            page3Description: string | null | undefined;
            page3Title: string | null | undefined;
          };
          questions:
            | Array<
                | {
                    __typename?: 'SurveyQuestion';
                    id: string;
                    question: string | null | undefined;
                    answers: Array<string> | null | undefined;
                    options:
                      | {
                          __typename?: 'SurveyOptions';
                          default: string | null | undefined;
                          hint: string | null | undefined;
                          leftLabel: string | null | undefined;
                          maxValue: number | null | undefined;
                          minValue: number | null | undefined;
                          required: boolean;
                          rightLabel: string | null | undefined;
                          useForNps: boolean | null | undefined;
                          type: SurveyTypeKind;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
        };
      }
    | {
        __typename: 'ProgramVideo';
        id: string;
        slug: string;
        title: string | null | undefined;
        subheader: string | null | undefined;
        customTitle: string | null | undefined;
        locked: boolean;
        watched: boolean | null | undefined;
        finished: boolean | null | undefined;
        position: number;
        positionLabel: string;
        videoKind: VideoKind | null | undefined;
        progressIsCurrent: boolean;
        repeatRecomendation: number | null | undefined;
        customDescription: string | null | undefined;
        preMoodTracker:
          | {
              __typename?: 'MoodTracker';
              id: string;
              finished: boolean | null | undefined;
              kind: MoodTrackerKind;
            }
          | null
          | undefined;
        postMoodTracker:
          | {
              __typename?: 'MoodTracker';
              id: string;
              finished: boolean | null | undefined;
              kind: MoodTrackerKind;
            }
          | null
          | undefined;
        video: {
          __typename?: 'Video';
          id: string;
          title: string;
          kind: string;
          identifier: string;
          hasAccess: boolean;
          cursorAt: number | null | undefined;
          hls: string;
          content:
            | {
                __typename?: 'VideoContent';
                title: string | null | undefined;
                shortDescription: string | null | undefined;
                longDescription: string | null | undefined;
              }
            | null
            | undefined;
          player:
            | {
                __typename?: 'VideoInfo';
                assets:
                  | Array<
                      | {
                          __typename?: 'VideoFileAsset';
                          src: string | null | undefined;
                          resolution: number | null | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
              }
            | null
            | undefined;
          meta:
            | {
                __typename?: 'VideoMeta';
                publishedAt: number | null | undefined;
                minutes: number | null | undefined;
              }
            | null
            | undefined;
          taxonomies: {
            __typename?: 'Taxonomy';
            flavor:
              | { __typename?: 'Term'; name: string | null | undefined }
              | null
              | undefined;
            instructors:
              | Array<
                  | {
                      __typename?: 'Instructor';
                      name: string | null | undefined;
                      slug: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            genre:
              | {
                  __typename?: 'Term';
                  name: string | null | undefined;
                  slug: string | null | undefined;
                }
              | null
              | undefined;
          };
          assets:
            | {
                __typename?: 'VideoAssets';
                thumbnailUrl: string | null | undefined;
              }
            | null
            | undefined;
        };
      }
  >;
};

export type WeeklyProgramFieldsFragment = {
  __typename?: 'WeeklyProgram';
  id: string;
  slug: string;
  recomendation: string | null | undefined;
  progress: number | null | undefined;
  videosCount: number | null | undefined;
  currentElement:
    | {
        __typename?: 'CurrentElement';
        path: string | null | undefined;
        title: string | null | undefined;
      }
    | null
    | undefined;
  instructors:
    | Array<
        | {
            __typename?: 'Instructor';
            id: string;
            name: string | null | undefined;
            slug: string | null | undefined;
            assets:
              | {
                  __typename?: 'InstructorAssets';
                  squareImage: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
  intro:
    | {
        __typename?: 'SpecialSection';
        description: string | null | undefined;
        label: string;
        assetUrl: string | null | undefined;
        slug: string;
      }
    | null
    | undefined;
  outro:
    | {
        __typename?: 'SpecialSection';
        description: string | null | undefined;
        label: string;
        slug: string;
        videos:
          | Array<
              | {
                  __typename?: 'ProgramVideo';
                  id: string;
                  slug: string;
                  title: string | null | undefined;
                  subheader: string | null | undefined;
                  customTitle: string | null | undefined;
                  locked: boolean;
                  watched: boolean | null | undefined;
                  finished: boolean | null | undefined;
                  position: number;
                  positionLabel: string;
                  videoKind: VideoKind | null | undefined;
                  progressIsCurrent: boolean;
                  repeatRecomendation: number | null | undefined;
                  customDescription: string | null | undefined;
                  preMoodTracker:
                    | {
                        __typename?: 'MoodTracker';
                        id: string;
                        finished: boolean | null | undefined;
                        kind: MoodTrackerKind;
                      }
                    | null
                    | undefined;
                  postMoodTracker:
                    | {
                        __typename?: 'MoodTracker';
                        id: string;
                        finished: boolean | null | undefined;
                        kind: MoodTrackerKind;
                      }
                    | null
                    | undefined;
                  video: {
                    __typename?: 'Video';
                    id: string;
                    title: string;
                    kind: string;
                    identifier: string;
                    hasAccess: boolean;
                    cursorAt: number | null | undefined;
                    hls: string;
                    content:
                      | {
                          __typename?: 'VideoContent';
                          title: string | null | undefined;
                          shortDescription: string | null | undefined;
                          longDescription: string | null | undefined;
                        }
                      | null
                      | undefined;
                    player:
                      | {
                          __typename?: 'VideoInfo';
                          assets:
                            | Array<
                                | {
                                    __typename?: 'VideoFileAsset';
                                    src: string | null | undefined;
                                    resolution: number | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    meta:
                      | {
                          __typename?: 'VideoMeta';
                          publishedAt: number | null | undefined;
                          minutes: number | null | undefined;
                        }
                      | null
                      | undefined;
                    taxonomies: {
                      __typename?: 'Taxonomy';
                      flavor:
                        | {
                            __typename?: 'Term';
                            name: string | null | undefined;
                          }
                        | null
                        | undefined;
                      instructors:
                        | Array<
                            | {
                                __typename?: 'Instructor';
                                name: string | null | undefined;
                                slug: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      genre:
                        | {
                            __typename?: 'Term';
                            name: string | null | undefined;
                            slug: string | null | undefined;
                          }
                        | null
                        | undefined;
                    };
                    assets:
                      | {
                          __typename?: 'VideoAssets';
                          thumbnailUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  };
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  weeks:
    | Array<
        | {
            __typename?: 'ProgramWeek';
            id: string;
            label: string;
            slug: string;
            locked: boolean;
            finished: boolean | null | undefined;
            progress: number | null | undefined;
            description: string | null | undefined;
            duration: string | null | undefined;
            videosCount: number | null | undefined;
            elements: Array<
              | {
                  __typename: 'ProgramDocument';
                  id: string;
                  slug: string;
                  title: string | null | undefined;
                  subheader: string | null | undefined;
                  description: string | null | undefined;
                  finished: boolean | null | undefined;
                  kind: string | null | undefined;
                  locked: boolean;
                  position: number;
                  positionLabel: string;
                  progressIsCurrent: boolean;
                  customPdfThumbnail: string | null | undefined;
                  document: {
                    __typename?: 'Document';
                    url: string | null | undefined;
                    name: string;
                  };
                }
              | {
                  __typename: 'ProgramLiveEvent';
                  description: string | null | undefined;
                  finished: boolean | null | undefined;
                  id: string;
                  kind: string | null | undefined;
                  locked: boolean;
                  position: number;
                  positionLabel: string;
                  progressIsCurrent: boolean;
                  slug: string;
                  subheader: string | null | undefined;
                  title: string | null | undefined;
                  isExpired: boolean | null | undefined;
                  liveEvent:
                    | {
                        __typename?: 'LiveEvent';
                        id: string;
                        title: string;
                        slug: string;
                        thumbnail: string;
                        duration: number;
                        eventDates: Array<any>;
                        kind: LiveEventKind;
                        language: LanguageKind;
                        level: LiveEventLevel;
                        theme: string | null | undefined;
                        themeLabel: string | null | undefined;
                        availableSpots: number;
                        maximumParticipants: number;
                        bookingAvailable: boolean;
                        paymentKind: LiveEventPaymentKind;
                        flexibleMaxAmountCents: number | null | undefined;
                        flexibleMinAmountCents: number | null | undefined;
                        customDiscount: number | null | undefined;
                        isUserEligible: boolean | null | undefined;
                        descriptionLong: string;
                        registrationEndDate: any;
                        registrationStartDate: any;
                        isClosed: boolean | null | undefined;
                        isExpired: boolean | null | undefined;
                        isComingSoon: boolean | null | undefined;
                        linkToRecordings:
                          | Array<{
                              __typename?: 'LinkToRecording';
                              isExpired: boolean | null | undefined;
                              link: string | null | undefined;
                              id: string;
                              eventDate: any;
                              video:
                                | {
                                    __typename?: 'Video';
                                    id: string;
                                    title: string;
                                    kind: string;
                                    identifier: string;
                                    hasAccess: boolean;
                                    cursorAt: number | null | undefined;
                                    hls: string;
                                    content:
                                      | {
                                          __typename?: 'VideoContent';
                                          title: string | null | undefined;
                                          shortDescription:
                                            | string
                                            | null
                                            | undefined;
                                          longDescription:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    player:
                                      | {
                                          __typename?: 'VideoInfo';
                                          assets:
                                            | Array<
                                                | {
                                                    __typename?: 'VideoFileAsset';
                                                    src:
                                                      | string
                                                      | null
                                                      | undefined;
                                                    resolution:
                                                      | number
                                                      | null
                                                      | undefined;
                                                  }
                                                | null
                                                | undefined
                                              >
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    meta:
                                      | {
                                          __typename?: 'VideoMeta';
                                          publishedAt:
                                            | number
                                            | null
                                            | undefined;
                                          minutes: number | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                    taxonomies: {
                                      __typename?: 'Taxonomy';
                                      flavor:
                                        | {
                                            __typename?: 'Term';
                                            name: string | null | undefined;
                                          }
                                        | null
                                        | undefined;
                                      instructors:
                                        | Array<
                                            | {
                                                __typename?: 'Instructor';
                                                name: string | null | undefined;
                                                slug: string | null | undefined;
                                              }
                                            | null
                                            | undefined
                                          >
                                        | null
                                        | undefined;
                                      genre:
                                        | {
                                            __typename?: 'Term';
                                            name: string | null | undefined;
                                            slug: string | null | undefined;
                                          }
                                        | null
                                        | undefined;
                                    };
                                    assets:
                                      | {
                                          __typename?: 'VideoAssets';
                                          thumbnailUrl:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }>
                          | null
                          | undefined;
                        price:
                          | {
                              __typename?: 'Price';
                              originalPrice: number | null | undefined;
                              discountApplied: boolean;
                              discountedPrice: number | null | undefined;
                              currency: string | null | undefined;
                            }
                          | null
                          | undefined;
                        primaryInstructor:
                          | {
                              __typename?: 'Instructor';
                              id: string;
                              name: string | null | undefined;
                              slug: string | null | undefined;
                              assets:
                                | {
                                    __typename?: 'InstructorAssets';
                                    squareImage: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                        instructors:
                          | Array<
                              | {
                                  __typename?: 'Instructor';
                                  id: string;
                                  name: string | null | undefined;
                                  slug: string | null | undefined;
                                  assets:
                                    | {
                                        __typename?: 'InstructorAssets';
                                        squareImage: string | null | undefined;
                                      }
                                    | null
                                    | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                        userAccess:
                          | {
                              __typename?: 'LiveEventAccess';
                              zoomLink: string;
                              zoomPassword: string;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | {
                  __typename: 'ProgramPartElement';
                  id: string;
                  slug: string;
                  subheader: string | null | undefined;
                  title: string | null | undefined;
                  description: string | null | undefined;
                  locked: boolean;
                  finished: boolean | null | undefined;
                  position: number;
                  positionLabel: string;
                  progressIsCurrent: boolean;
                }
              | {
                  __typename: 'ProgramSurveyElement';
                  id: string;
                  slug: string;
                  subheader: string | null | undefined;
                  title: string | null | undefined;
                  description: string | null | undefined;
                  locked: boolean;
                  finished: boolean | null | undefined;
                  position: number;
                  positionLabel: string;
                  progressIsCurrent: boolean;
                  lastSubmittedAt: any | null | undefined;
                  surveysAnswer:
                    | {
                        __typename?: 'SurveyAnswer';
                        id: string;
                        state: State | null | undefined;
                        contextableType: string | null | undefined;
                        content: Array<{
                          __typename?: 'ContentAnswer';
                          key: string;
                          value: Array<string>;
                        }>;
                      }
                    | null
                    | undefined;
                  survey: {
                    __typename?: 'Survey';
                    id: string;
                    name: string;
                    description: string | null | undefined;
                    content: {
                      __typename?: 'SurveyContent';
                      page1Description: string | null | undefined;
                      page1Title: string | null | undefined;
                      page2Description: string | null | undefined;
                      page2Title: string | null | undefined;
                      page3ButtonUrl: string | null | undefined;
                      page3ButtonText: string | null | undefined;
                      page3Description: string | null | undefined;
                      page3Title: string | null | undefined;
                    };
                    questions:
                      | Array<
                          | {
                              __typename?: 'SurveyQuestion';
                              id: string;
                              question: string | null | undefined;
                              answers: Array<string> | null | undefined;
                              options:
                                | {
                                    __typename?: 'SurveyOptions';
                                    default: string | null | undefined;
                                    hint: string | null | undefined;
                                    leftLabel: string | null | undefined;
                                    maxValue: number | null | undefined;
                                    minValue: number | null | undefined;
                                    required: boolean;
                                    rightLabel: string | null | undefined;
                                    useForNps: boolean | null | undefined;
                                    type: SurveyTypeKind;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  };
                }
              | {
                  __typename: 'ProgramVideo';
                  id: string;
                  slug: string;
                  title: string | null | undefined;
                  subheader: string | null | undefined;
                  customTitle: string | null | undefined;
                  locked: boolean;
                  watched: boolean | null | undefined;
                  finished: boolean | null | undefined;
                  position: number;
                  positionLabel: string;
                  videoKind: VideoKind | null | undefined;
                  progressIsCurrent: boolean;
                  repeatRecomendation: number | null | undefined;
                  customDescription: string | null | undefined;
                  preMoodTracker:
                    | {
                        __typename?: 'MoodTracker';
                        id: string;
                        finished: boolean | null | undefined;
                        kind: MoodTrackerKind;
                      }
                    | null
                    | undefined;
                  postMoodTracker:
                    | {
                        __typename?: 'MoodTracker';
                        id: string;
                        finished: boolean | null | undefined;
                        kind: MoodTrackerKind;
                      }
                    | null
                    | undefined;
                  video: {
                    __typename?: 'Video';
                    id: string;
                    title: string;
                    kind: string;
                    identifier: string;
                    hasAccess: boolean;
                    cursorAt: number | null | undefined;
                    hls: string;
                    content:
                      | {
                          __typename?: 'VideoContent';
                          title: string | null | undefined;
                          shortDescription: string | null | undefined;
                          longDescription: string | null | undefined;
                        }
                      | null
                      | undefined;
                    player:
                      | {
                          __typename?: 'VideoInfo';
                          assets:
                            | Array<
                                | {
                                    __typename?: 'VideoFileAsset';
                                    src: string | null | undefined;
                                    resolution: number | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                    meta:
                      | {
                          __typename?: 'VideoMeta';
                          publishedAt: number | null | undefined;
                          minutes: number | null | undefined;
                        }
                      | null
                      | undefined;
                    taxonomies: {
                      __typename?: 'Taxonomy';
                      flavor:
                        | {
                            __typename?: 'Term';
                            name: string | null | undefined;
                          }
                        | null
                        | undefined;
                      instructors:
                        | Array<
                            | {
                                __typename?: 'Instructor';
                                name: string | null | undefined;
                                slug: string | null | undefined;
                              }
                            | null
                            | undefined
                          >
                        | null
                        | undefined;
                      genre:
                        | {
                            __typename?: 'Term';
                            name: string | null | undefined;
                            slug: string | null | undefined;
                          }
                        | null
                        | undefined;
                    };
                    assets:
                      | {
                          __typename?: 'VideoAssets';
                          thumbnailUrl: string | null | undefined;
                        }
                      | null
                      | undefined;
                  };
                }
            >;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type WeeklyProgramQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type WeeklyProgramQuery = {
  __typename?: 'Query';
  weeklyProgram:
    | {
        __typename?: 'WeeklyProgram';
        id: string;
        slug: string;
        recomendation: string | null | undefined;
        progress: number | null | undefined;
        videosCount: number | null | undefined;
        currentElement:
          | {
              __typename?: 'CurrentElement';
              path: string | null | undefined;
              title: string | null | undefined;
            }
          | null
          | undefined;
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        intro:
          | {
              __typename?: 'SpecialSection';
              description: string | null | undefined;
              label: string;
              assetUrl: string | null | undefined;
              slug: string;
            }
          | null
          | undefined;
        outro:
          | {
              __typename?: 'SpecialSection';
              description: string | null | undefined;
              label: string;
              slug: string;
              videos:
                | Array<
                    | {
                        __typename?: 'ProgramVideo';
                        id: string;
                        slug: string;
                        title: string | null | undefined;
                        subheader: string | null | undefined;
                        customTitle: string | null | undefined;
                        locked: boolean;
                        watched: boolean | null | undefined;
                        finished: boolean | null | undefined;
                        position: number;
                        positionLabel: string;
                        videoKind: VideoKind | null | undefined;
                        progressIsCurrent: boolean;
                        repeatRecomendation: number | null | undefined;
                        customDescription: string | null | undefined;
                        preMoodTracker:
                          | {
                              __typename?: 'MoodTracker';
                              id: string;
                              finished: boolean | null | undefined;
                              kind: MoodTrackerKind;
                            }
                          | null
                          | undefined;
                        postMoodTracker:
                          | {
                              __typename?: 'MoodTracker';
                              id: string;
                              finished: boolean | null | undefined;
                              kind: MoodTrackerKind;
                            }
                          | null
                          | undefined;
                        video: {
                          __typename?: 'Video';
                          id: string;
                          title: string;
                          kind: string;
                          identifier: string;
                          hasAccess: boolean;
                          cursorAt: number | null | undefined;
                          hls: string;
                          content:
                            | {
                                __typename?: 'VideoContent';
                                title: string | null | undefined;
                                shortDescription: string | null | undefined;
                                longDescription: string | null | undefined;
                              }
                            | null
                            | undefined;
                          player:
                            | {
                                __typename?: 'VideoInfo';
                                assets:
                                  | Array<
                                      | {
                                          __typename?: 'VideoFileAsset';
                                          src: string | null | undefined;
                                          resolution: number | null | undefined;
                                        }
                                      | null
                                      | undefined
                                    >
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          meta:
                            | {
                                __typename?: 'VideoMeta';
                                publishedAt: number | null | undefined;
                                minutes: number | null | undefined;
                              }
                            | null
                            | undefined;
                          taxonomies: {
                            __typename?: 'Taxonomy';
                            flavor:
                              | {
                                  __typename?: 'Term';
                                  name: string | null | undefined;
                                }
                              | null
                              | undefined;
                            instructors:
                              | Array<
                                  | {
                                      __typename?: 'Instructor';
                                      name: string | null | undefined;
                                      slug: string | null | undefined;
                                    }
                                  | null
                                  | undefined
                                >
                              | null
                              | undefined;
                            genre:
                              | {
                                  __typename?: 'Term';
                                  name: string | null | undefined;
                                  slug: string | null | undefined;
                                }
                              | null
                              | undefined;
                          };
                          assets:
                            | {
                                __typename?: 'VideoAssets';
                                thumbnailUrl: string | null | undefined;
                              }
                            | null
                            | undefined;
                        };
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        weeks:
          | Array<
              | {
                  __typename?: 'ProgramWeek';
                  id: string;
                  label: string;
                  slug: string;
                  locked: boolean;
                  finished: boolean | null | undefined;
                  progress: number | null | undefined;
                  description: string | null | undefined;
                  duration: string | null | undefined;
                  videosCount: number | null | undefined;
                  elements: Array<
                    | {
                        __typename: 'ProgramDocument';
                        id: string;
                        slug: string;
                        title: string | null | undefined;
                        subheader: string | null | undefined;
                        description: string | null | undefined;
                        finished: boolean | null | undefined;
                        kind: string | null | undefined;
                        locked: boolean;
                        position: number;
                        positionLabel: string;
                        progressIsCurrent: boolean;
                        customPdfThumbnail: string | null | undefined;
                        document: {
                          __typename?: 'Document';
                          url: string | null | undefined;
                          name: string;
                        };
                      }
                    | {
                        __typename: 'ProgramLiveEvent';
                        description: string | null | undefined;
                        finished: boolean | null | undefined;
                        id: string;
                        kind: string | null | undefined;
                        locked: boolean;
                        position: number;
                        positionLabel: string;
                        progressIsCurrent: boolean;
                        slug: string;
                        subheader: string | null | undefined;
                        title: string | null | undefined;
                        isExpired: boolean | null | undefined;
                        liveEvent:
                          | {
                              __typename?: 'LiveEvent';
                              id: string;
                              title: string;
                              slug: string;
                              thumbnail: string;
                              duration: number;
                              eventDates: Array<any>;
                              kind: LiveEventKind;
                              language: LanguageKind;
                              level: LiveEventLevel;
                              theme: string | null | undefined;
                              themeLabel: string | null | undefined;
                              availableSpots: number;
                              maximumParticipants: number;
                              bookingAvailable: boolean;
                              paymentKind: LiveEventPaymentKind;
                              flexibleMaxAmountCents: number | null | undefined;
                              flexibleMinAmountCents: number | null | undefined;
                              customDiscount: number | null | undefined;
                              isUserEligible: boolean | null | undefined;
                              descriptionLong: string;
                              registrationEndDate: any;
                              registrationStartDate: any;
                              isClosed: boolean | null | undefined;
                              isExpired: boolean | null | undefined;
                              isComingSoon: boolean | null | undefined;
                              linkToRecordings:
                                | Array<{
                                    __typename?: 'LinkToRecording';
                                    isExpired: boolean | null | undefined;
                                    link: string | null | undefined;
                                    id: string;
                                    eventDate: any;
                                    video:
                                      | {
                                          __typename?: 'Video';
                                          id: string;
                                          title: string;
                                          kind: string;
                                          identifier: string;
                                          hasAccess: boolean;
                                          cursorAt: number | null | undefined;
                                          hls: string;
                                          content:
                                            | {
                                                __typename?: 'VideoContent';
                                                title:
                                                  | string
                                                  | null
                                                  | undefined;
                                                shortDescription:
                                                  | string
                                                  | null
                                                  | undefined;
                                                longDescription:
                                                  | string
                                                  | null
                                                  | undefined;
                                              }
                                            | null
                                            | undefined;
                                          player:
                                            | {
                                                __typename?: 'VideoInfo';
                                                assets:
                                                  | Array<
                                                      | {
                                                          __typename?: 'VideoFileAsset';
                                                          src:
                                                            | string
                                                            | null
                                                            | undefined;
                                                          resolution:
                                                            | number
                                                            | null
                                                            | undefined;
                                                        }
                                                      | null
                                                      | undefined
                                                    >
                                                  | null
                                                  | undefined;
                                              }
                                            | null
                                            | undefined;
                                          meta:
                                            | {
                                                __typename?: 'VideoMeta';
                                                publishedAt:
                                                  | number
                                                  | null
                                                  | undefined;
                                                minutes:
                                                  | number
                                                  | null
                                                  | undefined;
                                              }
                                            | null
                                            | undefined;
                                          taxonomies: {
                                            __typename?: 'Taxonomy';
                                            flavor:
                                              | {
                                                  __typename?: 'Term';
                                                  name:
                                                    | string
                                                    | null
                                                    | undefined;
                                                }
                                              | null
                                              | undefined;
                                            instructors:
                                              | Array<
                                                  | {
                                                      __typename?: 'Instructor';
                                                      name:
                                                        | string
                                                        | null
                                                        | undefined;
                                                      slug:
                                                        | string
                                                        | null
                                                        | undefined;
                                                    }
                                                  | null
                                                  | undefined
                                                >
                                              | null
                                              | undefined;
                                            genre:
                                              | {
                                                  __typename?: 'Term';
                                                  name:
                                                    | string
                                                    | null
                                                    | undefined;
                                                  slug:
                                                    | string
                                                    | null
                                                    | undefined;
                                                }
                                              | null
                                              | undefined;
                                          };
                                          assets:
                                            | {
                                                __typename?: 'VideoAssets';
                                                thumbnailUrl:
                                                  | string
                                                  | null
                                                  | undefined;
                                              }
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }>
                                | null
                                | undefined;
                              price:
                                | {
                                    __typename?: 'Price';
                                    originalPrice: number | null | undefined;
                                    discountApplied: boolean;
                                    discountedPrice: number | null | undefined;
                                    currency: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              primaryInstructor:
                                | {
                                    __typename?: 'Instructor';
                                    id: string;
                                    name: string | null | undefined;
                                    slug: string | null | undefined;
                                    assets:
                                      | {
                                          __typename?: 'InstructorAssets';
                                          squareImage:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                              instructors:
                                | Array<
                                    | {
                                        __typename?: 'Instructor';
                                        id: string;
                                        name: string | null | undefined;
                                        slug: string | null | undefined;
                                        assets:
                                          | {
                                              __typename?: 'InstructorAssets';
                                              squareImage:
                                                | string
                                                | null
                                                | undefined;
                                            }
                                          | null
                                          | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                              userAccess:
                                | {
                                    __typename?: 'LiveEventAccess';
                                    zoomLink: string;
                                    zoomPassword: string;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | {
                        __typename: 'ProgramPartElement';
                        id: string;
                        slug: string;
                        subheader: string | null | undefined;
                        title: string | null | undefined;
                        description: string | null | undefined;
                        locked: boolean;
                        finished: boolean | null | undefined;
                        position: number;
                        positionLabel: string;
                        progressIsCurrent: boolean;
                      }
                    | {
                        __typename: 'ProgramSurveyElement';
                        id: string;
                        slug: string;
                        subheader: string | null | undefined;
                        title: string | null | undefined;
                        description: string | null | undefined;
                        locked: boolean;
                        finished: boolean | null | undefined;
                        position: number;
                        positionLabel: string;
                        progressIsCurrent: boolean;
                        lastSubmittedAt: any | null | undefined;
                        surveysAnswer:
                          | {
                              __typename?: 'SurveyAnswer';
                              id: string;
                              state: State | null | undefined;
                              contextableType: string | null | undefined;
                              content: Array<{
                                __typename?: 'ContentAnswer';
                                key: string;
                                value: Array<string>;
                              }>;
                            }
                          | null
                          | undefined;
                        survey: {
                          __typename?: 'Survey';
                          id: string;
                          name: string;
                          description: string | null | undefined;
                          content: {
                            __typename?: 'SurveyContent';
                            page1Description: string | null | undefined;
                            page1Title: string | null | undefined;
                            page2Description: string | null | undefined;
                            page2Title: string | null | undefined;
                            page3ButtonUrl: string | null | undefined;
                            page3ButtonText: string | null | undefined;
                            page3Description: string | null | undefined;
                            page3Title: string | null | undefined;
                          };
                          questions:
                            | Array<
                                | {
                                    __typename?: 'SurveyQuestion';
                                    id: string;
                                    question: string | null | undefined;
                                    answers: Array<string> | null | undefined;
                                    options:
                                      | {
                                          __typename?: 'SurveyOptions';
                                          default: string | null | undefined;
                                          hint: string | null | undefined;
                                          leftLabel: string | null | undefined;
                                          maxValue: number | null | undefined;
                                          minValue: number | null | undefined;
                                          required: boolean;
                                          rightLabel: string | null | undefined;
                                          useForNps: boolean | null | undefined;
                                          type: SurveyTypeKind;
                                        }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        };
                      }
                    | {
                        __typename: 'ProgramVideo';
                        id: string;
                        slug: string;
                        title: string | null | undefined;
                        subheader: string | null | undefined;
                        customTitle: string | null | undefined;
                        locked: boolean;
                        watched: boolean | null | undefined;
                        finished: boolean | null | undefined;
                        position: number;
                        positionLabel: string;
                        videoKind: VideoKind | null | undefined;
                        progressIsCurrent: boolean;
                        repeatRecomendation: number | null | undefined;
                        customDescription: string | null | undefined;
                        preMoodTracker:
                          | {
                              __typename?: 'MoodTracker';
                              id: string;
                              finished: boolean | null | undefined;
                              kind: MoodTrackerKind;
                            }
                          | null
                          | undefined;
                        postMoodTracker:
                          | {
                              __typename?: 'MoodTracker';
                              id: string;
                              finished: boolean | null | undefined;
                              kind: MoodTrackerKind;
                            }
                          | null
                          | undefined;
                        video: {
                          __typename?: 'Video';
                          id: string;
                          title: string;
                          kind: string;
                          identifier: string;
                          hasAccess: boolean;
                          cursorAt: number | null | undefined;
                          hls: string;
                          content:
                            | {
                                __typename?: 'VideoContent';
                                title: string | null | undefined;
                                shortDescription: string | null | undefined;
                                longDescription: string | null | undefined;
                              }
                            | null
                            | undefined;
                          player:
                            | {
                                __typename?: 'VideoInfo';
                                assets:
                                  | Array<
                                      | {
                                          __typename?: 'VideoFileAsset';
                                          src: string | null | undefined;
                                          resolution: number | null | undefined;
                                        }
                                      | null
                                      | undefined
                                    >
                                  | null
                                  | undefined;
                              }
                            | null
                            | undefined;
                          meta:
                            | {
                                __typename?: 'VideoMeta';
                                publishedAt: number | null | undefined;
                                minutes: number | null | undefined;
                              }
                            | null
                            | undefined;
                          taxonomies: {
                            __typename?: 'Taxonomy';
                            flavor:
                              | {
                                  __typename?: 'Term';
                                  name: string | null | undefined;
                                }
                              | null
                              | undefined;
                            instructors:
                              | Array<
                                  | {
                                      __typename?: 'Instructor';
                                      name: string | null | undefined;
                                      slug: string | null | undefined;
                                    }
                                  | null
                                  | undefined
                                >
                              | null
                              | undefined;
                            genre:
                              | {
                                  __typename?: 'Term';
                                  name: string | null | undefined;
                                  slug: string | null | undefined;
                                }
                              | null
                              | undefined;
                          };
                          assets:
                            | {
                                __typename?: 'VideoAssets';
                                thumbnailUrl: string | null | undefined;
                              }
                            | null
                            | undefined;
                        };
                      }
                  >;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type WellnessAccessFieldsFragment = {
  __typename?: 'WellnessAccess';
  periodUnit: WellnessPeriodUnitKind;
  periodValue: number;
};

export type GenericProgramFieldsFragment = {
  __typename?: 'GenericProgram';
  slug: string;
};

export type PromotionFieldsFragment = {
  __typename?: 'Promotion';
  name: string;
};

export type WellnessesQueryVariables = Exact<{ [key: string]: never }>;

export type WellnessesQuery = {
  __typename?: 'Query';
  wellnesses: Array<{
    __typename?: 'Wellness';
    id: string;
    logo: string | null | undefined;
    name: string;
    slug: string;
    status: string;
    instructions: string | null | undefined;
  }>;
};

export type WellnessCustomBreakdownQueryVariables = Exact<{
  slug: Scalars['String'];
  allowance: InputMaybe<Scalars['Float']>;
}>;

export type WellnessCustomBreakdownQuery = {
  __typename?: 'Query';
  wellness: {
    __typename?: 'Wellness';
    customPackage:
      | {
          __typename?: 'WellnessPackage';
          id: string;
          name: string;
          thumbnail: string | null | undefined;
          instructions: string | null | undefined;
          description: string | null | undefined;
          amountCents: number | null | undefined;
          amountCurrency: string | null | undefined;
          packageable:
            | { __typename?: 'GenericProgram' }
            | { __typename?: 'LiveEvent' }
            | { __typename?: 'Promotion' }
            | {
                __typename?: 'WellnessAccess';
                periodUnit: WellnessPeriodUnitKind;
                periodValue: number;
              }
            | null
            | undefined;
          priceBreakdown:
            | {
                __typename?: 'PriceBreakdown';
                amountPerDay: number | null | undefined;
                currency: string | null | undefined;
                extraDays: number | null | undefined;
                extraDaysAmount: number | null | undefined;
                planCount: number | null | undefined;
                planType: string | null | undefined;
                originalPlanAmount: number | null | undefined;
                totalAmount: number | null | undefined;
                accessDays: number | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type WellnessQueryVariables = Exact<{
  slug: Scalars['String'];
  allowance: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Int']>;
  livePage: InputMaybe<Scalars['Int']>;
  accessPage: InputMaybe<Scalars['Int']>;
  coursePage: InputMaybe<Scalars['Int']>;
}>;

export type WellnessQuery = {
  __typename?: 'Query';
  wellness: {
    __typename?: 'Wellness';
    id: string;
    logo: string | null | undefined;
    name: string;
    slug: string;
    status: string;
    instructions: string | null | undefined;
    platformUrl: string | null | undefined;
    platformUrl2: string | null | undefined;
    subscriptionPlans: Array<{
      __typename?: 'UserSubscription';
      title: string | null | undefined;
      description: string | null | undefined;
      shortDescription: string | null | undefined;
      amount: number | null | undefined;
      discountedAmount: number | null | undefined;
      currency: string | null | undefined;
      months: number | null | undefined;
      days: number | null | undefined;
      accessUntil: any | null | undefined;
      promotionId: string | null | undefined;
      subscriptionName: string;
      subscriptionPlan: string | null | undefined;
      promotionKind: PromotionKind | null | undefined;
      promotionName: string | null | undefined;
      recurringPromotion: boolean | null | undefined;
    }>;
    customPackage:
      | {
          __typename?: 'WellnessPackage';
          id: string;
          name: string;
          thumbnail: string | null | undefined;
          instructions: string | null | undefined;
          description: string | null | undefined;
          amountCents: number | null | undefined;
          amountCurrency: string | null | undefined;
          packageable:
            | { __typename?: 'GenericProgram' }
            | { __typename?: 'LiveEvent' }
            | { __typename?: 'Promotion' }
            | {
                __typename?: 'WellnessAccess';
                periodUnit: WellnessPeriodUnitKind;
                periodValue: number;
              }
            | null
            | undefined;
          priceBreakdown:
            | {
                __typename?: 'PriceBreakdown';
                amountPerDay: number | null | undefined;
                currency: string | null | undefined;
                extraDays: number | null | undefined;
                extraDaysAmount: number | null | undefined;
                planCount: number | null | undefined;
                planType: string | null | undefined;
                originalPlanAmount: number | null | undefined;
                totalAmount: number | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    liveEventPackages: {
      __typename?: 'PagedWellnessPackage';
      page: number;
      totalCount: number;
      data: Array<{
        __typename?: 'WellnessPackage';
        id: string;
        name: string;
        thumbnail: string | null | undefined;
        amountCents: number | null | undefined;
        discountedAmount: number | null | undefined;
        amountCurrency: string | null | undefined;
        instructions: string | null | undefined;
        packageable:
          | { __typename?: 'GenericProgram' }
          | { __typename?: 'LiveEvent' }
          | { __typename?: 'Promotion'; name: string }
          | { __typename?: 'WellnessAccess' }
          | null
          | undefined;
      }>;
    };
    liveEvents: {
      __typename?: 'PagedLiveEvents';
      page: number;
      totalCount: number;
      data: Array<{
        __typename?: 'LiveEvent';
        id: string;
        title: string;
        slug: string;
        thumbnail: string;
        duration: number;
        eventDates: Array<any>;
        kind: LiveEventKind;
        language: LanguageKind;
        level: LiveEventLevel;
        theme: string | null | undefined;
        themeLabel: string | null | undefined;
        availableSpots: number;
        maximumParticipants: number;
        bookingAvailable: boolean;
        paymentKind: LiveEventPaymentKind;
        flexibleMaxAmountCents: number | null | undefined;
        flexibleMinAmountCents: number | null | undefined;
        customDiscount: number | null | undefined;
        isUserEligible: boolean | null | undefined;
        descriptionLong: string;
        registrationEndDate: any;
        registrationStartDate: any;
        isClosed: boolean | null | undefined;
        isExpired: boolean | null | undefined;
        isComingSoon: boolean | null | undefined;
        price:
          | {
              __typename?: 'Price';
              originalPrice: number | null | undefined;
              discountApplied: boolean;
              discountedPrice: number | null | undefined;
              currency: string | null | undefined;
            }
          | null
          | undefined;
        primaryInstructor:
          | {
              __typename?: 'Instructor';
              id: string;
              name: string | null | undefined;
              slug: string | null | undefined;
              assets:
                | {
                    __typename?: 'InstructorAssets';
                    squareImage: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        instructors:
          | Array<
              | {
                  __typename?: 'Instructor';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                  assets:
                    | {
                        __typename?: 'InstructorAssets';
                        squareImage: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        userAccess:
          | {
              __typename?: 'LiveEventAccess';
              zoomLink: string;
              zoomPassword: string;
            }
          | null
          | undefined;
      }>;
    };
    wellnessAccesses: {
      __typename?: 'PagedWellnessPackage';
      page: number;
      totalCount: number;
      data: Array<{
        __typename?: 'WellnessPackage';
        id: string;
        name: string;
        thumbnail: string | null | undefined;
        description: string | null | undefined;
        amountCents: number | null | undefined;
        discountedAmount: number | null | undefined;
        amountCurrency: string | null | undefined;
        instructions: string | null | undefined;
        packageable:
          | { __typename?: 'GenericProgram' }
          | { __typename?: 'LiveEvent' }
          | { __typename?: 'Promotion' }
          | {
              __typename?: 'WellnessAccess';
              periodUnit: WellnessPeriodUnitKind;
              periodValue: number;
            }
          | null
          | undefined;
      }>;
    };
    courses: {
      __typename?: 'PagedWellnessPackage';
      page: number;
      totalCount: number;
      data: Array<{
        __typename?: 'WellnessPackage';
        id: string;
        name: string;
        thumbnail: string | null | undefined;
        amountCents: number | null | undefined;
        discountedAmount: number | null | undefined;
        amountCurrency: string | null | undefined;
        description: string | null | undefined;
        instructions: string | null | undefined;
        packageable:
          | { __typename?: 'GenericProgram'; slug: string }
          | { __typename?: 'LiveEvent' }
          | { __typename?: 'Promotion' }
          | { __typename?: 'WellnessAccess' }
          | null
          | undefined;
      }>;
    };
  };
};

export type WellnessCoursesQueryVariables = Exact<{
  slug: Scalars['String'];
  allowance: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Int']>;
  coursePage: InputMaybe<Scalars['Int']>;
}>;

export type WellnessCoursesQuery = {
  __typename?: 'Query';
  wellness: {
    __typename?: 'Wellness';
    id: string;
    name: string;
    slug: string;
    platformUrl: string | null | undefined;
    platformUrl2: string | null | undefined;
    courses: {
      __typename?: 'PagedWellnessPackage';
      page: number;
      totalCount: number;
      data: Array<{
        __typename?: 'WellnessPackage';
        id: string;
        name: string;
        thumbnail: string | null | undefined;
        amountCents: number | null | undefined;
        discountedAmount: number | null | undefined;
        amountCurrency: string | null | undefined;
        description: string | null | undefined;
        instructions: string | null | undefined;
        packageable:
          | { __typename?: 'GenericProgram'; slug: string }
          | { __typename?: 'LiveEvent' }
          | { __typename?: 'Promotion' }
          | { __typename?: 'WellnessAccess' }
          | null
          | undefined;
      }>;
    };
  };
};

export type WellnessLivePackagesQueryVariables = Exact<{
  slug: Scalars['String'];
  allowance: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Int']>;
  livePage: InputMaybe<Scalars['Int']>;
}>;

export type WellnessLivePackagesQuery = {
  __typename?: 'Query';
  wellness: {
    __typename?: 'Wellness';
    id: string;
    name: string;
    slug: string;
    platformUrl: string | null | undefined;
    platformUrl2: string | null | undefined;
    liveEventPackages: {
      __typename?: 'PagedWellnessPackage';
      page: number;
      totalCount: number;
      data: Array<{
        __typename?: 'WellnessPackage';
        id: string;
        name: string;
        thumbnail: string | null | undefined;
        amountCents: number | null | undefined;
        discountedAmount: number | null | undefined;
        amountCurrency: string | null | undefined;
        instructions: string | null | undefined;
        packageable:
          | { __typename?: 'GenericProgram' }
          | { __typename?: 'LiveEvent' }
          | { __typename?: 'Promotion'; name: string }
          | { __typename?: 'WellnessAccess' }
          | null
          | undefined;
      }>;
    };
  };
};

export type WellnessCustomPackagesQueryVariables = Exact<{
  slug: Scalars['String'];
  allowance: InputMaybe<Scalars['Float']>;
  perPage?: InputMaybe<Scalars['Int']>;
  accessPage: InputMaybe<Scalars['Int']>;
}>;

export type WellnessCustomPackagesQuery = {
  __typename?: 'Query';
  wellness: {
    __typename?: 'Wellness';
    id: string;
    name: string;
    slug: string;
    platformUrl: string | null | undefined;
    platformUrl2: string | null | undefined;
    customPackage:
      | {
          __typename?: 'WellnessPackage';
          id: string;
          name: string;
          thumbnail: string | null | undefined;
          instructions: string | null | undefined;
          description: string | null | undefined;
          amountCents: number | null | undefined;
          amountCurrency: string | null | undefined;
          packageable:
            | { __typename?: 'GenericProgram' }
            | { __typename?: 'LiveEvent' }
            | { __typename?: 'Promotion' }
            | {
                __typename?: 'WellnessAccess';
                periodUnit: WellnessPeriodUnitKind;
                periodValue: number;
              }
            | null
            | undefined;
          priceBreakdown:
            | {
                __typename?: 'PriceBreakdown';
                amountPerDay: number | null | undefined;
                currency: string | null | undefined;
                extraDays: number | null | undefined;
                extraDaysAmount: number | null | undefined;
                planCount: number | null | undefined;
                planType: string | null | undefined;
                originalPlanAmount: number | null | undefined;
                totalAmount: number | null | undefined;
                accessDays: number | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    wellnessAccesses: {
      __typename?: 'PagedWellnessPackage';
      page: number;
      totalCount: number;
      data: Array<{
        __typename?: 'WellnessPackage';
        id: string;
        name: string;
        thumbnail: string | null | undefined;
        description: string | null | undefined;
        amountCents: number | null | undefined;
        discountedAmount: number | null | undefined;
        amountCurrency: string | null | undefined;
        instructions: string | null | undefined;
        packageable:
          | { __typename?: 'GenericProgram' }
          | { __typename?: 'LiveEvent' }
          | { __typename?: 'Promotion' }
          | {
              __typename?: 'WellnessAccess';
              periodUnit: WellnessPeriodUnitKind;
              periodValue: number;
            }
          | null
          | undefined;
      }>;
    };
  };
};

export type StartSubscriptionMutationVariables = Exact<{
  planType: PaidPlanTypeKind;
}>;

export type StartSubscriptionMutation = {
  __typename?: 'Mutation';
  startSubscription:
    | {
        __typename?: 'StartSubscriptionPayload';
        success: boolean;
        errors: Array<{
          __typename?: 'Error';
          message: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type YearlyPageQueryVariables = Exact<{ [key: string]: never }>;

export type YearlyPageQuery = {
  __typename?: 'Query';
  startOverview: {
    __typename?: 'StartOverview';
    featuresSection:
      | {
          __typename?: 'FeaturesSection';
          description: string | null | undefined;
          featureBullets: Array<string> | null | undefined;
          imageUrl: string | null | undefined;
          title: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type AnswerSurveyMutationVariables = Exact<{
  surveyPartId: Scalars['ID'];
  surveyAnswerId: InputMaybe<Scalars['ID']>;
  answers: InputMaybe<Array<AnswerInput> | AnswerInput>;
  surveyAction: ActionInput;
}>;

export type AnswerSurveyMutation = {
  __typename?: 'Mutation';
  answerSurvey:
    | {
        __typename?: 'AnswerSurveyPayload';
        errors: Array<{
          __typename?: 'ProcessingError';
          message: string | null | undefined;
          status: ErrorValue | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetFavoritePlaylistsQueryVariables = Exact<{
  id: Scalars['ID'];
  page: InputMaybe<Scalars['Int']>;
  perPage: InputMaybe<Scalars['Int']>;
}>;

export type GetFavoritePlaylistsQuery = {
  __typename?: 'Query';
  getFavoritePlaylists:
    | {
        __typename?: 'PagedPlaylists';
        totalCount: number;
        perPage: number;
        page: number;
        data: Array<{
          __typename?: 'Playlist';
          id: string;
          identifier: string | null | undefined;
          title: string;
          description: string | null | undefined;
          owner:
            | {
                __typename?: 'CurrentUser';
                id: string;
                firstName: string | null | undefined;
              }
            | {
                __typename?: 'User';
                id: string;
                firstName: string | null | undefined;
              }
            | null
            | undefined;
          meta:
            | {
                __typename?: 'Meta';
                videoCount: number | null | undefined;
                minutes: number | null | undefined;
              }
            | null
            | undefined;
          assets:
            | {
                __typename?: 'PlaylistAssets';
                thumbnailUrl: string | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetFavoriteVideosQueryVariables = Exact<{
  id: Scalars['ID'];
  page: InputMaybe<Scalars['Int']>;
  perPage: InputMaybe<Scalars['Int']>;
}>;

export type GetFavoriteVideosQuery = {
  __typename?: 'Query';
  getFavoriteVideos:
    | {
        __typename?: 'PagedVideos';
        totalCount: number;
        perPage: number;
        page: number;
        data: Array<{
          __typename?: 'Video';
          id: string;
          identifier: string;
          cursorAt: number | null | undefined;
          content:
            | {
                __typename?: 'VideoContent';
                title: string | null | undefined;
                shortDescription: string | null | undefined;
              }
            | null
            | undefined;
          meta:
            | {
                __typename?: 'VideoMeta';
                minutes: number | null | undefined;
                publishedAt: number | null | undefined;
              }
            | null
            | undefined;
          taxonomies: {
            __typename?: 'Taxonomy';
            flavor:
              | { __typename?: 'Term'; name: string | null | undefined }
              | null
              | undefined;
            instructors:
              | Array<
                  | {
                      __typename?: 'Instructor';
                      id: string;
                      name: string | null | undefined;
                      slug: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            genre:
              | {
                  __typename?: 'Term';
                  id: string;
                  name: string | null | undefined;
                  slug: string | null | undefined;
                }
              | null
              | undefined;
          };
          assets:
            | {
                __typename?: 'VideoAssets';
                thumbnailUrl: string | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type VideoClickMutationVariables = Exact<{
  videoId: Scalars['String'];
  sourceId: InputMaybe<Scalars['String']>;
  sourceType: InputMaybe<Scalars['String']>;
  recommended: InputMaybe<Scalars['Boolean']>;
}>;

export type VideoClickMutation = {
  __typename?: 'Mutation';
  videoClick:
    | {
        __typename?: 'ClickPayload';
        errors:
          | Array<{ __typename?: 'Error'; message: string | null | undefined }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const BlogPostCardFieldsFragmentDoc = gql`
  fragment BlogPostCardFields on BlogPost {
    id
    slug
    tags
    title
    body
    author
    categories
    featuredImage
    language
    market
    publishedAt
  }
`;
export const PricingSubscriptionFieldsFragmentDoc = gql`
  fragment PricingSubscriptionFields on UserSubscription {
    title
    description
    shortDescription
    amount
    discountedAmount
    currency
    months
    days
    accessUntil
    promotionId
    subscriptionName
    subscriptionPlan
    promotionKind
    promotionName
    recurringPromotion
  }
`;
export const BundleEssentialFieldsFragmentDoc = gql`
  fragment BundleEssentialFields on Bundle {
    id
    title
    slug
    heroImage
    cardImage
    heroMobileImage
    active
    kind
    accessStrategy
    isUserEligible
    bundleCategory
    specialOfferCode
    price {
      currency
      discountApplied
      discountedPrice
      originalPrice
    }
    subscriptionPromotion {
      ...PricingSubscriptionFields
      code
    }
    userAccess {
      active
      joinedAt
      accessUntil
    }
    waitingItemInfo {
      startDate
      waitingListJoined
      waitingListEnabled
      waitingListEndDate
      waitingListStartDate
    }
    accessPeriodUnit
    accessPeriodValue
    client {
      id
      logo
      name
      slug
      info
    }
    hiddenInListing
    metaDescription
    metaImage
    publishedAt
    shortDescription
    promotionalImage
    tags
  }
  ${PricingSubscriptionFieldsFragmentDoc}
`;
export const BlogPostFieldsFragmentDoc = gql`
  fragment BlogPostFields on BlogPost {
    id
    slug
    tags
    title
    body
    authors
    categories
    featuredImage
    language
    market
    publishedAt
  }
`;
export const ChurnUpsellOfferFragmentDoc = gql`
  fragment ChurnUpsellOffer on Upsell {
    title
    description
    image
    amountCents
    amountCurrency
    promotionName
    subscriptionPlan {
      months
      amountCents
      amountCurrency
      planType
      platform
      productId
    }
  }
`;
export const SearchBundleFieldsFragmentDoc = gql`
  fragment SearchBundleFields on SearchBundle {
    slug
    title
    shortDescription
    cardImageUrl
    heroImageUrl
    bundleCategory
    client {
      slug
    }
  }
`;
export const TrialFragmentFragmentDoc = gql`
  fragment trialFragment on Trial {
    claimedAt
    endDate
    id
    periodDays
    subscriptionPromotionId
    subscriptionPlanType
  }
`;
export const ProgramAccessFieldsFragmentDoc = gql`
  fragment ProgramAccessFields on ProgramAccess {
    accessStatus
    accessUntil
    joinedAt
    program {
      id
      name
      title: name
      slug
      lede
      shortDescription
      themes
      legacy
      language
      titleImageUrl
      tags
      duration {
        value
        unit
      }
      instructors {
        id
        name
        slug
        assets {
          squareImage
        }
      }
    }
  }
`;
export const ReceiptFieldsFragmentDoc = gql`
  fragment ReceiptFields on Payment {
    amount
    currency
    date
    downloadUrl
    mobilePayment
    months
    number
    paymentProvider
    paymentProviderVariant
    paymentType
    transact
    receiptTitle
    target {
      targetType
      title
    }
  }
`;
export const ExtraReceiptFieldsFragmentDoc = gql`
  fragment ExtraReceiptFields on ExtraPayment {
    amount
    currency
    date
    downloadUrl
    number
    paymentType
    paymentProvider
    paymentProviderVariant
    transact
    target {
      targetType
      title
    }
  }
`;
export const ProgramInstructorsFragmentDoc = gql`
  fragment ProgramInstructors on Instructor {
    id
    name
    slug
    assets {
      squareImage
    }
  }
`;
export const EssentialGenericFieldsFragmentDoc = gql`
  fragment EssentialGenericFields on GenericProgram {
    id
    title
    slug
    promotionalImageUrl
    category
    priceType
    kind
    language
    titleImageUrl
    shortDescription
    description
    themesV2 {
      label
      slug
    }
    accessStatus
    price {
      originalPrice
      discountedPrice
      discountApplied
      currency
    }
    accessPeriod {
      unit
      value
    }
    duration {
      unit
      value
    }
    instructors {
      ...ProgramInstructors
    }
    programAccess {
      accessStatus
      accessUntil
      joinedAt
      progressPercentage
    }
  }
  ${ProgramInstructorsFragmentDoc}
`;
export const FullGenericProgramFieldsFragmentDoc = gql`
  fragment FullGenericProgramFields on GenericProgram {
    id
    ...EssentialGenericFields
    progress
    summaryAsset
    meta {
      title
      description
      imageUrl
    }
    discount {
      discountType
      discountValue
      discountedPrice
      endDate
      identifier
      startDate
    }
    postSurvey {
      id
      label
      kind
      link
      finished
      locked
    }
    preSurvey {
      id
      label
      kind
      link
      finished
      locked
    }
    trailer {
      id
      content {
        title
      }
      minutes
      thumbnailUrl
      hls
      player {
        assets {
          resolution
          src
        }
      }
    }
  }
  ${EssentialGenericFieldsFragmentDoc}
`;
export const BasicPlaylistFieldsFragmentDoc = gql`
  fragment BasicPlaylistFields on Playlist {
    id
    identifier
    visibility
    title
    description
    owner {
      id
      firstName
    }
    meta {
      videoCount
      minutes
    }
    assets {
      thumbnailUrl
    }
  }
`;
export const PlaylistEssentialFieldsFragmentDoc = gql`
  fragment PlaylistEssentialFields on Playlist {
    id
    identifier
    visibility
    title
    description
    removedText
    owner {
      id
      firstName
    }
    meta {
      imageUrl
      videoCount
      minutes
    }
    assets {
      thumbnailUrl
    }
    videos {
      id
      identifier
      kind
      assets {
        thumbnailUrl
      }
      hls
      hasAccess
      player {
        assets {
          src
          resolution
        }
      }
      cursorAt
      meta {
        minutes
      }
      content {
        title
        longDescription
        shortDescription
      }
      taxonomies {
        instructors {
          id
          name
          slug
        }
        needs {
          id
          name
        }
        bodyparts {
          id
          name
        }
        styles {
          id
          name
        }
        genre {
          id
          name
          slug
        }
      }
    }
  }
`;
export const PlaylistCardFieldsFragmentDoc = gql`
  fragment PlaylistCardFields on Playlist {
    id
    identifier
    visibility
    title
    description
    owner {
      id
      firstName
    }
    meta {
      videoCount
      minutes
    }
    assets {
      thumbnailUrl
    }
  }
`;
export const VideoFieldsFragmentDoc = gql`
  fragment VideoFields on Video {
    id
    identifier
    kind
    cursorAt
    removedText
    content {
      title
      shortDescription
      longDescription
    }
    meta {
      minutes
      publishedAt
    }
    primaryStyle {
      name
      slug
    }
    taxonomies {
      flavor {
        id
        name
      }
      needs {
        id
        name
        slug
      }
      instructors {
        id
        name
        slug
        assets {
          externalImage
          squareImage
          titleImage
        }
      }
      genre {
        id
        name
        slug
      }
    }
    assets {
      thumbnailUrl
    }
  }
`;
export const InstructorListingFieldsFragmentDoc = gql`
  fragment instructorListingFields on Instructor {
    id
    slug
    name
    slug
    speciality
    location
    webpage
    team
    assets {
      externalImage
    }
    instructorPage {
      description
    }
  }
`;
export const LiveEventEssentialFieldsFragmentDoc = gql`
  fragment LiveEventEssentialFields on LiveEvent {
    id
    title
    slug
    thumbnail
    duration
    eventDates
    kind
    language
    level
    theme
    themeLabel
    availableSpots
    maximumParticipants
    bookingAvailable
    paymentKind
    flexibleMaxAmountCents
    flexibleMinAmountCents
    customDiscount
    isUserEligible
    price {
      originalPrice
      discountApplied
      discountedPrice
      currency
    }
    descriptionLong
    registrationEndDate
    registrationStartDate
    isClosed @client
    isExpired @client
    isComingSoon @client
    primaryInstructor {
      id
      name
      slug
      assets {
        squareImage
      }
    }
    instructors @client {
      id
      name
      slug
      assets {
        squareImage
      }
    }
    userAccess {
      zoomLink
      zoomPassword
    }
  }
`;
export const LiveCodeDataFieldsFragmentDoc = gql`
  fragment LiveCodeDataFields on PromotionCode {
    code
    expirationDate
    usageLimit
    creationDate
    provider
    matchingResources {
      ... on LiveEvent {
        ...LiveEventEssentialFields
      }
    }
    usedIn {
      usageDate
      live {
        ...LiveEventEssentialFields
      }
    }
  }
  ${LiveEventEssentialFieldsFragmentDoc}
`;
export const LiveRecordingVideoFieldsFragmentDoc = gql`
  fragment LiveRecordingVideoFields on Video {
    id
    title
    kind
    identifier
    hasAccess
    cursorAt
    content {
      title
      shortDescription
      longDescription
    }
    hls
    player {
      assets {
        src
        resolution
      }
    }
    assets {
      thumbnailUrl
    }
  }
`;
export const FullLiveEventFieldsFragmentDoc = gql`
  fragment FullLiveEventFields on LiveEvent {
    ...LiveEventEssentialFields
    isExternal
    hostedBy
    hostedByPageMessage
    otherInstructors {
      id
      name
      slug
      assets {
        squareImage
      }
    }
    accessories
    linkToRecordings {
      link
      id
      eventDate
      expiresAt
      isExpired @client
      video {
        ...LiveRecordingVideoFields
      }
    }
  }
  ${LiveEventEssentialFieldsFragmentDoc}
  ${LiveRecordingVideoFieldsFragmentDoc}
`;
export const MarketplaceEventFieldsFragmentDoc = gql`
  fragment marketplaceEventFields on Event {
    id
    instructor
    name
    slug
    description
    body1
    body2
    externalImageUrl
    location
    publishedAt
    startsAt
    endsAt
    titleImageUrl
  }
`;
export const WellnessPackagePaymentFieldsFragmentDoc = gql`
  fragment WellnessPackagePaymentFields on WellnessPackagePayment {
    orderId
    state
    status @client
    sourcePage
    amountCents
    originalAmountCents
    paymentProvider
    paymentProviderVariant
    amountCurrency
    wellnessPackage {
      name
      priceBreakdown {
        amountPerDay
        currency
        extraDays
        extraDaysAmount
        planCount
        planType
        originalPlanAmount
        totalAmount
        accessDays @client
      }
      packageable {
        ... on WellnessAccess {
          periodUnit
          periodValue
        }
        ... on GenericProgram {
          id
          title
          slug
          category
        }
        ... on Promotion {
          name
        }
      }
    }
  }
`;
export const ProgramIntroFieldsFragmentDoc = gql`
  fragment ProgramIntroFields on SpecialSection {
    description
    label
    assetUrl
    slug
  }
`;
export const WeeklyProgramVideoFieldsFragmentDoc = gql`
  fragment WeeklyProgramVideoFields on Video {
    id
    title
    kind
    identifier
    hasAccess
    cursorAt
    content {
      title
      shortDescription
      longDescription
    }
    hls
    player {
      assets {
        src
        resolution
      }
    }
    meta {
      publishedAt
      minutes
    }
    taxonomies {
      flavor {
        name
      }
      instructors {
        name
        slug
      }
      genre {
        name
        slug
      }
    }
    assets {
      thumbnailUrl
    }
  }
`;
export const ProgramVideoFieldsFragmentDoc = gql`
  fragment ProgramVideoFields on ProgramVideo {
    id
    slug
    title
    subheader
    customTitle
    locked
    watched
    finished
    position
    positionLabel
    videoKind
    progressIsCurrent
    repeatRecomendation
    customDescription
    preMoodTracker {
      id
      finished
      kind
    }
    postMoodTracker {
      id
      finished
      kind
    }
    video {
      ...WeeklyProgramVideoFields
    }
  }
  ${WeeklyProgramVideoFieldsFragmentDoc}
`;
export const ProgramOutroFieldsFragmentDoc = gql`
  fragment ProgramOutroFields on SpecialSection {
    description
    label
    slug
    videos {
      ...ProgramVideoFields
    }
  }
  ${ProgramVideoFieldsFragmentDoc}
`;
export const PhasedProgramFieldsFragmentDoc = gql`
  fragment PhasedProgramFields on PhasedProgram {
    id
    slug
    title @client
    progress @client
    language @client
    weeksCount @client
    phasesCount @client
    currentElement @client {
      path
      title
    }
    videosCount @client
    instructors @client {
      id
      name
      assets {
        squareImage
      }
    }
    intro {
      ...ProgramIntroFields
    }
    outro {
      ...ProgramOutroFields
    }
    phases {
      id
      description
      label
      progress
      slug
      finished
      locked
      videosCount @client
      videosDuration @client
      weeksRange @client
      weeks {
        id
        label
        slug
        locked
        finished
        indexInProgram @client
        videos {
          ...ProgramVideoFields
        }
      }
    }
  }
  ${ProgramIntroFieldsFragmentDoc}
  ${ProgramOutroFieldsFragmentDoc}
  ${ProgramVideoFieldsFragmentDoc}
`;
export const SearchVideoFieldsFragmentDoc = gql`
  fragment SearchVideoFields on SearchVideo {
    id
    identifier
    title
    thumbnailUrl
    instructorName
    instructorSlug
    longDescription
    kind
    minutes
    primaryStyleName
    primaryStyleSlug
    shortDescription
  }
`;
export const SearchPlaylistFieldsFragmentDoc = gql`
  fragment SearchPlaylistFields on SearchPlaylist {
    id
    identifier
    title
    description
    minutes
    thumbnailUrl
    videoCount
    ownerId
    visibility
  }
`;
export const SearchGenericProgramFieldsFragmentDoc = gql`
  fragment SearchGenericProgramFields on SearchGenericProgram {
    accessPeriod {
      unit
      value
    }
    category
    description
    durationUnit
    durationValue
    id
    instructorName
    instructorSlug
    price {
      currency
      discountApplied
      discountedPrice
      originalPrice
    }
    priceType
    slug
    themesV2 {
      label
      slug
    }
    title
    titleImageUrl
  }
`;
export const SearchInstructorFieldsFragmentDoc = gql`
  fragment SearchInstructorFields on SearchInstructor {
    description
    id
    name
    slug
    squareImageUrl
  }
`;
export const SearchEventFieldsFragmentDoc = gql`
  fragment SearchEventFields on SearchEvent {
    id
    name
    slug
    description
    endsAt
    instructor
    startsAt
    titleImageUrl
    location
  }
`;
export const SearchLiveEventFieldsFragmentDoc = gql`
  fragment SearchLiveEventFields on SearchLiveEvent {
    id
    kind
    slug
    theme
    themeLabel
    thumbnailUrl
    title
    availableSpots
    bookingAvailable
    duration
    eventDates
    instructorName
    instructorSlug
    maximumParticipants
    registrationEndDate
    registrationStartDate
    paymentKind
    isClosed @client
    isExpired @client
    isComingSoon @client
    price @client {
      currency
      discountApplied
      discountedPrice
      originalPrice
    }
  }
`;
export const SearchLandingPageFieldsFragmentDoc = gql`
  fragment SearchLandingPageFields on SearchLandingPage {
    id
    lede
    locale
    title
    slug
  }
`;
export const SearchBlogPostFieldsFragmentDoc = gql`
  fragment SearchBlogPostFields on SearchBlogPost {
    featuredImageUrl
    id
    slug
    title
  }
`;
export const TaxonomyTermFragmentDoc = gql`
  fragment TaxonomyTerm on Term {
    slug
    name
  }
`;
export const TaxonomyTermV2FragmentDoc = gql`
  fragment TaxonomyTermV2 on TermTypeV2 {
    slug
    name
  }
`;
export const NewTaxonomyItemFieldsFragmentDoc = gql`
  fragment NewTaxonomyItemFields on NewTaxonomyItem {
    name
    slug
    subcategories {
      name
      slug
      terms {
        ...TaxonomyTerm
      }
    }
    nonSubcategorized {
      ...TaxonomyTermV2
    }
  }
  ${TaxonomyTermFragmentDoc}
  ${TaxonomyTermV2FragmentDoc}
`;
export const TaxonomyV2FieldsFragmentDoc = gql`
  fragment taxonomyV2Fields on TaxonomyV2 {
    term {
      name
      slug
    }
    taxonomies {
      slug
      name
      link
    }
  }
`;
export const ProgramTextElementFieldsFragmentDoc = gql`
  fragment ProgramTextElementFields on ProgramPartElement {
    id
    slug
    subheader
    title
    description
    locked
    finished
    position
    positionLabel
    progressIsCurrent
  }
`;
export const ProgramSurveyFieldsFragmentDoc = gql`
  fragment ProgramSurveyFields on Survey {
    id
    name
    description
    content {
      page1Description
      page1Title
      page2Description
      page2Title
      page3ButtonUrl
      page3ButtonText
      page3Description
      page3Title
    }
    questions {
      id
      question
      answers
      options {
        default
        hint
        leftLabel
        maxValue
        minValue
        required
        rightLabel
        useForNps
        type
      }
    }
  }
`;
export const ProgramSurveyElementFieldsFragmentDoc = gql`
  fragment ProgramSurveyElementFields on ProgramSurveyElement {
    id
    slug
    subheader
    title
    description
    locked
    finished
    position
    positionLabel
    progressIsCurrent
    lastSubmittedAt
    surveysAnswer {
      id
      state
      contextableType
      content {
        key
        value
      }
    }
    survey {
      ...ProgramSurveyFields
    }
  }
  ${ProgramSurveyFieldsFragmentDoc}
`;
export const ProgramDocumentFieldsFragmentDoc = gql`
  fragment ProgramDocumentFields on ProgramDocument {
    id
    slug
    title
    subheader
    description
    finished
    kind
    locked
    position
    positionLabel
    progressIsCurrent
    customPdfThumbnail
    document {
      url
      name
    }
  }
`;
export const ProgramLiveEventElementFieldsFragmentDoc = gql`
  fragment ProgramLiveEventElementFields on ProgramLiveEvent {
    description
    finished
    id
    kind
    locked
    position
    positionLabel
    progressIsCurrent
    slug
    subheader
    title
    isExpired @client
    liveEvent {
      ...LiveEventEssentialFields
      linkToRecordings {
        isExpired @client
        link
        id
        eventDate
        video {
          ...WeeklyProgramVideoFields
        }
      }
    }
  }
  ${LiveEventEssentialFieldsFragmentDoc}
  ${WeeklyProgramVideoFieldsFragmentDoc}
`;
export const WeeklyProgramWeekFragmentFragmentDoc = gql`
  fragment WeeklyProgramWeekFragment on ProgramWeek {
    id
    label
    slug
    locked
    finished
    progress
    description
    duration @client
    videosCount @client
    elements {
      __typename
      ... on ProgramPartElement {
        ...ProgramTextElementFields
      }
      ... on ProgramSurveyElement {
        ...ProgramSurveyElementFields
      }
      ... on ProgramDocument {
        ...ProgramDocumentFields
      }
      ... on ProgramVideo {
        ...ProgramVideoFields
      }
      ... on ProgramLiveEvent {
        ...ProgramLiveEventElementFields
      }
    }
  }
  ${ProgramTextElementFieldsFragmentDoc}
  ${ProgramSurveyElementFieldsFragmentDoc}
  ${ProgramDocumentFieldsFragmentDoc}
  ${ProgramVideoFieldsFragmentDoc}
  ${ProgramLiveEventElementFieldsFragmentDoc}
`;
export const WeeklyProgramFieldsFragmentDoc = gql`
  fragment WeeklyProgramFields on WeeklyProgram {
    id
    slug
    recomendation
    progress @client
    videosCount @client
    currentElement @client {
      path
      title
    }
    instructors @client {
      ...ProgramInstructors
    }
    intro {
      ...ProgramIntroFields
    }
    outro {
      ...ProgramOutroFields
    }
    weeks {
      ...WeeklyProgramWeekFragment
    }
  }
  ${ProgramInstructorsFragmentDoc}
  ${ProgramIntroFieldsFragmentDoc}
  ${ProgramOutroFieldsFragmentDoc}
  ${WeeklyProgramWeekFragmentFragmentDoc}
`;
export const WellnessAccessFieldsFragmentDoc = gql`
  fragment WellnessAccessFields on WellnessAccess {
    periodUnit
    periodValue
  }
`;
export const GenericProgramFieldsFragmentDoc = gql`
  fragment GenericProgramFields on GenericProgram {
    slug
  }
`;
export const PromotionFieldsFragmentDoc = gql`
  fragment PromotionFields on Promotion {
    name
  }
`;
export const ChangePasswordDocument = gql`
  mutation changePassword($oldPassword: String!, $password: String!) {
    changePassword(password: $password, oldPassword: $oldPassword) {
      user {
        id
      }
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<
  typeof useChangePasswordMutation
>;
export type ChangePasswordMutationResult =
  Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const ChangeEmailDocument = gql`
  mutation changeEmail($email: String) {
    changeProfileInfo(email: $email) {
      user {
        id
      }
    }
  }
`;
export type ChangeEmailMutationFn = Apollo.MutationFunction<
  ChangeEmailMutation,
  ChangeEmailMutationVariables
>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useChangeEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeEmailMutation,
    ChangeEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(
    ChangeEmailDocument,
    options
  );
}
export type ChangeEmailMutationHookResult = ReturnType<
  typeof useChangeEmailMutation
>;
export type ChangeEmailMutationResult =
  Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<
  ChangeEmailMutation,
  ChangeEmailMutationVariables
>;
export const ChangeProfileInfoDocument = gql`
  mutation changeProfileInfo(
    $firstName: String
    $lastName: String
    $dateOfBirth: String
    $personNumber: String
    $address: String
    $zipcode: String
    $city: String
    $phone: String
    $profession: String
  ) {
    changeProfileInfo(
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      personNumber: $personNumber
      address: $address
      zipcode: $zipcode
      city: $city
      phone: $phone
      profession: $profession
    ) {
      user {
        id
      }
    }
  }
`;
export type ChangeProfileInfoMutationFn = Apollo.MutationFunction<
  ChangeProfileInfoMutation,
  ChangeProfileInfoMutationVariables
>;

/**
 * __useChangeProfileInfoMutation__
 *
 * To run a mutation, you first call `useChangeProfileInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProfileInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProfileInfoMutation, { data, loading, error }] = useChangeProfileInfoMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      personNumber: // value for 'personNumber'
 *      address: // value for 'address'
 *      zipcode: // value for 'zipcode'
 *      city: // value for 'city'
 *      phone: // value for 'phone'
 *      profession: // value for 'profession'
 *   },
 * });
 */
export function useChangeProfileInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeProfileInfoMutation,
    ChangeProfileInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeProfileInfoMutation,
    ChangeProfileInfoMutationVariables
  >(ChangeProfileInfoDocument, options);
}
export type ChangeProfileInfoMutationHookResult = ReturnType<
  typeof useChangeProfileInfoMutation
>;
export type ChangeProfileInfoMutationResult =
  Apollo.MutationResult<ChangeProfileInfoMutation>;
export type ChangeProfileInfoMutationOptions = Apollo.BaseMutationOptions<
  ChangeProfileInfoMutation,
  ChangeProfileInfoMutationVariables
>;
export const AuthenticateUserDocument = gql`
  mutation authenticateUser($email: String!, $password: String!) {
    authenticateUser(email: $email, password: $password) {
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<
  AuthenticateUserMutation,
  AuthenticateUserMutationVariables
>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateUserMutation,
    AuthenticateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthenticateUserMutation,
    AuthenticateUserMutationVariables
  >(AuthenticateUserDocument, options);
}
export type AuthenticateUserMutationHookResult = ReturnType<
  typeof useAuthenticateUserMutation
>;
export type AuthenticateUserMutationResult =
  Apollo.MutationResult<AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateUserMutation,
  AuthenticateUserMutationVariables
>;
export const AvailableOneTimeCodeDocument = gql`
  query availableOneTimeCode($code: String) {
    availableOneTimeCode(code: $code, platform: web) {
      isCodeValid
      code
      codeType
      validationMessage
      oneTimeCodes {
        ...PricingSubscriptionFields
      }
    }
  }
  ${PricingSubscriptionFieldsFragmentDoc}
`;

/**
 * __useAvailableOneTimeCodeQuery__
 *
 * To run a query within a React component, call `useAvailableOneTimeCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableOneTimeCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableOneTimeCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAvailableOneTimeCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableOneTimeCodeQuery,
    AvailableOneTimeCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableOneTimeCodeQuery,
    AvailableOneTimeCodeQueryVariables
  >(AvailableOneTimeCodeDocument, options);
}
export function useAvailableOneTimeCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableOneTimeCodeQuery,
    AvailableOneTimeCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableOneTimeCodeQuery,
    AvailableOneTimeCodeQueryVariables
  >(AvailableOneTimeCodeDocument, options);
}
export type AvailableOneTimeCodeQueryHookResult = ReturnType<
  typeof useAvailableOneTimeCodeQuery
>;
export type AvailableOneTimeCodeLazyQueryHookResult = ReturnType<
  typeof useAvailableOneTimeCodeLazyQuery
>;
export type AvailableOneTimeCodeQueryResult = Apollo.QueryResult<
  AvailableOneTimeCodeQuery,
  AvailableOneTimeCodeQueryVariables
>;
export function refetchAvailableOneTimeCodeQuery(
  variables?: AvailableOneTimeCodeQueryVariables
) {
  return { query: AvailableOneTimeCodeDocument, variables: variables };
}
export const PricingSubscriptionPlansDocument = gql`
  query PricingSubscriptionPlans($code: String) {
    availableSubscriptionPlans(code: $code, platform: web) {
      code
      isCodeValid
      subscriptions {
        ...PricingSubscriptionFields
      }
    }
  }
  ${PricingSubscriptionFieldsFragmentDoc}
`;

/**
 * __usePricingSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `usePricingSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingSubscriptionPlansQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePricingSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PricingSubscriptionPlansQuery,
    PricingSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PricingSubscriptionPlansQuery,
    PricingSubscriptionPlansQueryVariables
  >(PricingSubscriptionPlansDocument, options);
}
export function usePricingSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PricingSubscriptionPlansQuery,
    PricingSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PricingSubscriptionPlansQuery,
    PricingSubscriptionPlansQueryVariables
  >(PricingSubscriptionPlansDocument, options);
}
export type PricingSubscriptionPlansQueryHookResult = ReturnType<
  typeof usePricingSubscriptionPlansQuery
>;
export type PricingSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof usePricingSubscriptionPlansLazyQuery
>;
export type PricingSubscriptionPlansQueryResult = Apollo.QueryResult<
  PricingSubscriptionPlansQuery,
  PricingSubscriptionPlansQueryVariables
>;
export function refetchPricingSubscriptionPlansQuery(
  variables?: PricingSubscriptionPlansQueryVariables
) {
  return { query: PricingSubscriptionPlansDocument, variables: variables };
}
export const PricingAvailableSubscriptionPlansDocument = gql`
  query PricingAvailableSubscriptionPlans($code: String) {
    availableAllCodes(code: $code, platform: web) {
      code
      isCodeValid
      subscriptions {
        ...PricingSubscriptionFields
      }
      trialReferralCodes {
        ...PricingSubscriptionFields
      }
      trials {
        title
        description
        shortDescription
        amount
        discountedAmount
        currency
        months
        days
        subscriptionName
        subscriptionPlan
        promotionName
        recurringPromotion
      }
    }
  }
  ${PricingSubscriptionFieldsFragmentDoc}
`;

/**
 * __usePricingAvailableSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `usePricingAvailableSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingAvailableSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingAvailableSubscriptionPlansQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function usePricingAvailableSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PricingAvailableSubscriptionPlansQuery,
    PricingAvailableSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PricingAvailableSubscriptionPlansQuery,
    PricingAvailableSubscriptionPlansQueryVariables
  >(PricingAvailableSubscriptionPlansDocument, options);
}
export function usePricingAvailableSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PricingAvailableSubscriptionPlansQuery,
    PricingAvailableSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PricingAvailableSubscriptionPlansQuery,
    PricingAvailableSubscriptionPlansQueryVariables
  >(PricingAvailableSubscriptionPlansDocument, options);
}
export type PricingAvailableSubscriptionPlansQueryHookResult = ReturnType<
  typeof usePricingAvailableSubscriptionPlansQuery
>;
export type PricingAvailableSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof usePricingAvailableSubscriptionPlansLazyQuery
>;
export type PricingAvailableSubscriptionPlansQueryResult = Apollo.QueryResult<
  PricingAvailableSubscriptionPlansQuery,
  PricingAvailableSubscriptionPlansQueryVariables
>;
export function refetchPricingAvailableSubscriptionPlansQuery(
  variables?: PricingAvailableSubscriptionPlansQueryVariables
) {
  return {
    query: PricingAvailableSubscriptionPlansDocument,
    variables: variables,
  };
}
export const FullAvailableSubscriptionPlansDocument = gql`
  query FullAvailableSubscriptionPlans($code: String) {
    availableSubscriptionPlans(code: $code, platform: web) {
      code
      codeType
      isCodeValid
      validationMessage
      subscriptions {
        ...PricingSubscriptionFields
      }
      fullAccesses {
        ...PricingSubscriptionFields
      }
      oneTimeCodes {
        ...PricingSubscriptionFields
      }
    }
  }
  ${PricingSubscriptionFieldsFragmentDoc}
`;

/**
 * __useFullAvailableSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useFullAvailableSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullAvailableSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullAvailableSubscriptionPlansQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useFullAvailableSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FullAvailableSubscriptionPlansQuery,
    FullAvailableSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FullAvailableSubscriptionPlansQuery,
    FullAvailableSubscriptionPlansQueryVariables
  >(FullAvailableSubscriptionPlansDocument, options);
}
export function useFullAvailableSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FullAvailableSubscriptionPlansQuery,
    FullAvailableSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FullAvailableSubscriptionPlansQuery,
    FullAvailableSubscriptionPlansQueryVariables
  >(FullAvailableSubscriptionPlansDocument, options);
}
export type FullAvailableSubscriptionPlansQueryHookResult = ReturnType<
  typeof useFullAvailableSubscriptionPlansQuery
>;
export type FullAvailableSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof useFullAvailableSubscriptionPlansLazyQuery
>;
export type FullAvailableSubscriptionPlansQueryResult = Apollo.QueryResult<
  FullAvailableSubscriptionPlansQuery,
  FullAvailableSubscriptionPlansQueryVariables
>;
export function refetchFullAvailableSubscriptionPlansQuery(
  variables?: FullAvailableSubscriptionPlansQueryVariables
) {
  return {
    query: FullAvailableSubscriptionPlansDocument,
    variables: variables,
  };
}
export const AvailableTrialExtensionsDocument = gql`
  query availableTrialExtensions($code: String) {
    availableTrialExtensions(code: $code, platform: web) {
      code
      codeType
      isCodeValid
      validationMessage
      trials {
        months
        days
        subscriptionPlan
        promotionKind
        promotionName
      }
      trialReferralCodes {
        months
        days
        subscriptionPlan
        promotionKind
        promotionName
      }
      oneTimeCodes {
        accessUntil
        months
        days
        subscriptionPlan
        promotionKind
        promotionName
      }
    }
  }
`;

/**
 * __useAvailableTrialExtensionsQuery__
 *
 * To run a query within a React component, call `useAvailableTrialExtensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTrialExtensionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTrialExtensionsQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAvailableTrialExtensionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableTrialExtensionsQuery,
    AvailableTrialExtensionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableTrialExtensionsQuery,
    AvailableTrialExtensionsQueryVariables
  >(AvailableTrialExtensionsDocument, options);
}
export function useAvailableTrialExtensionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableTrialExtensionsQuery,
    AvailableTrialExtensionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableTrialExtensionsQuery,
    AvailableTrialExtensionsQueryVariables
  >(AvailableTrialExtensionsDocument, options);
}
export type AvailableTrialExtensionsQueryHookResult = ReturnType<
  typeof useAvailableTrialExtensionsQuery
>;
export type AvailableTrialExtensionsLazyQueryHookResult = ReturnType<
  typeof useAvailableTrialExtensionsLazyQuery
>;
export type AvailableTrialExtensionsQueryResult = Apollo.QueryResult<
  AvailableTrialExtensionsQuery,
  AvailableTrialExtensionsQueryVariables
>;
export function refetchAvailableTrialExtensionsQuery(
  variables?: AvailableTrialExtensionsQueryVariables
) {
  return { query: AvailableTrialExtensionsDocument, variables: variables };
}
export const ActivateTrialExtensionDocument = gql`
  mutation activateTrialExtension($promotionName: String!, $code: String!) {
    activateTrialExtension(
      promotionName: $promotionName
      code: $code
      platform: web
    ) {
      success
      errors {
        message
      }
    }
  }
`;
export type ActivateTrialExtensionMutationFn = Apollo.MutationFunction<
  ActivateTrialExtensionMutation,
  ActivateTrialExtensionMutationVariables
>;

/**
 * __useActivateTrialExtensionMutation__
 *
 * To run a mutation, you first call `useActivateTrialExtensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateTrialExtensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateTrialExtensionMutation, { data, loading, error }] = useActivateTrialExtensionMutation({
 *   variables: {
 *      promotionName: // value for 'promotionName'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useActivateTrialExtensionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateTrialExtensionMutation,
    ActivateTrialExtensionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateTrialExtensionMutation,
    ActivateTrialExtensionMutationVariables
  >(ActivateTrialExtensionDocument, options);
}
export type ActivateTrialExtensionMutationHookResult = ReturnType<
  typeof useActivateTrialExtensionMutation
>;
export type ActivateTrialExtensionMutationResult =
  Apollo.MutationResult<ActivateTrialExtensionMutation>;
export type ActivateTrialExtensionMutationOptions = Apollo.BaseMutationOptions<
  ActivateTrialExtensionMutation,
  ActivateTrialExtensionMutationVariables
>;
export const BlogPostsDocument = gql`
  query blogPosts(
    $page: Int = 1
    $perPage: Int = 8
    $category: String = ""
    $query: String = ""
  ) {
    posts(page: $page, perPage: $perPage, category: $category, query: $query) {
      page
      perPage
      totalCount
      data {
        ...BlogPostCardFields
      }
    }
  }
  ${BlogPostCardFieldsFragmentDoc}
`;

/**
 * __useBlogPostsQuery__
 *
 * To run a query within a React component, call `useBlogPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      category: // value for 'category'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBlogPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<BlogPostsQuery, BlogPostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlogPostsQuery, BlogPostsQueryVariables>(
    BlogPostsDocument,
    options
  );
}
export function useBlogPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlogPostsQuery,
    BlogPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlogPostsQuery, BlogPostsQueryVariables>(
    BlogPostsDocument,
    options
  );
}
export type BlogPostsQueryHookResult = ReturnType<typeof useBlogPostsQuery>;
export type BlogPostsLazyQueryHookResult = ReturnType<
  typeof useBlogPostsLazyQuery
>;
export type BlogPostsQueryResult = Apollo.QueryResult<
  BlogPostsQuery,
  BlogPostsQueryVariables
>;
export function refetchBlogPostsQuery(variables?: BlogPostsQueryVariables) {
  return { query: BlogPostsDocument, variables: variables };
}
export const BlogPostDocument = gql`
  query BlogPost($slug: String!) {
    blogPost: fetchBlogPost(slug: $slug) {
      author
      authors
      body
      categories
      collection
      facebookImage
      featuredImage
      id
      keywords2
      language
      market
      metaDescription
      metaTitle
      processedBody
      showPricingBanner
      instructors {
        id
        slug
        name
        description
        blogDescription
        assets {
          squareImage
        }
        instructorPage {
          description
        }
      }
      publishedAt
      republishedAt
      showPricingBanner
      slug
      tags
      title
    }
  }
`;

/**
 * __useBlogPostQuery__
 *
 * To run a query within a React component, call `useBlogPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBlogPostQuery(
  baseOptions: Apollo.QueryHookOptions<BlogPostQuery, BlogPostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlogPostQuery, BlogPostQueryVariables>(
    BlogPostDocument,
    options
  );
}
export function useBlogPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlogPostQuery,
    BlogPostQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlogPostQuery, BlogPostQueryVariables>(
    BlogPostDocument,
    options
  );
}
export type BlogPostQueryHookResult = ReturnType<typeof useBlogPostQuery>;
export type BlogPostLazyQueryHookResult = ReturnType<
  typeof useBlogPostLazyQuery
>;
export type BlogPostQueryResult = Apollo.QueryResult<
  BlogPostQuery,
  BlogPostQueryVariables
>;
export function refetchBlogPostQuery(variables: BlogPostQueryVariables) {
  return { query: BlogPostDocument, variables: variables };
}
export const BlogInstructorDocument = gql`
  query blogInstructor($id: ID!) {
    instructor(id: $id) {
      id
      slug
      name
      slug
      assets {
        squareImage
      }
      speciality
      location
      webpage
      instructorPage {
        description
      }
      blogDescription
      shortDescription
    }
  }
`;

/**
 * __useBlogInstructorQuery__
 *
 * To run a query within a React component, call `useBlogInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogInstructorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlogInstructorQuery(
  baseOptions: Apollo.QueryHookOptions<
    BlogInstructorQuery,
    BlogInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlogInstructorQuery, BlogInstructorQueryVariables>(
    BlogInstructorDocument,
    options
  );
}
export function useBlogInstructorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlogInstructorQuery,
    BlogInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlogInstructorQuery, BlogInstructorQueryVariables>(
    BlogInstructorDocument,
    options
  );
}
export type BlogInstructorQueryHookResult = ReturnType<
  typeof useBlogInstructorQuery
>;
export type BlogInstructorLazyQueryHookResult = ReturnType<
  typeof useBlogInstructorLazyQuery
>;
export type BlogInstructorQueryResult = Apollo.QueryResult<
  BlogInstructorQuery,
  BlogInstructorQueryVariables
>;
export function refetchBlogInstructorQuery(
  variables: BlogInstructorQueryVariables
) {
  return { query: BlogInstructorDocument, variables: variables };
}
export const BlogPostCategoriesDocument = gql`
  query blogPostCategories {
    blogPostCategories {
      name
      slug
    }
  }
`;

/**
 * __useBlogPostCategoriesQuery__
 *
 * To run a query within a React component, call `useBlogPostCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogPostCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogPostCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlogPostCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BlogPostCategoriesQuery,
    BlogPostCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BlogPostCategoriesQuery,
    BlogPostCategoriesQueryVariables
  >(BlogPostCategoriesDocument, options);
}
export function useBlogPostCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlogPostCategoriesQuery,
    BlogPostCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BlogPostCategoriesQuery,
    BlogPostCategoriesQueryVariables
  >(BlogPostCategoriesDocument, options);
}
export type BlogPostCategoriesQueryHookResult = ReturnType<
  typeof useBlogPostCategoriesQuery
>;
export type BlogPostCategoriesLazyQueryHookResult = ReturnType<
  typeof useBlogPostCategoriesLazyQuery
>;
export type BlogPostCategoriesQueryResult = Apollo.QueryResult<
  BlogPostCategoriesQuery,
  BlogPostCategoriesQueryVariables
>;
export function refetchBlogPostCategoriesQuery(
  variables?: BlogPostCategoriesQueryVariables
) {
  return { query: BlogPostCategoriesDocument, variables: variables };
}
export const BasicBundleDocument = gql`
  query basicBundle($slug: String!, $client: String!) {
    basicBundle: bundle(slug: $slug, client: $client) {
      ...BundleEssentialFields
    }
  }
  ${BundleEssentialFieldsFragmentDoc}
`;

/**
 * __useBasicBundleQuery__
 *
 * To run a query within a React component, call `useBasicBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicBundleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      client: // value for 'client'
 *   },
 * });
 */
export function useBasicBundleQuery(
  baseOptions: Apollo.QueryHookOptions<
    BasicBundleQuery,
    BasicBundleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BasicBundleQuery, BasicBundleQueryVariables>(
    BasicBundleDocument,
    options
  );
}
export function useBasicBundleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BasicBundleQuery,
    BasicBundleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BasicBundleQuery, BasicBundleQueryVariables>(
    BasicBundleDocument,
    options
  );
}
export type BasicBundleQueryHookResult = ReturnType<typeof useBasicBundleQuery>;
export type BasicBundleLazyQueryHookResult = ReturnType<
  typeof useBasicBundleLazyQuery
>;
export type BasicBundleQueryResult = Apollo.QueryResult<
  BasicBundleQuery,
  BasicBundleQueryVariables
>;
export function refetchBasicBundleQuery(variables: BasicBundleQueryVariables) {
  return { query: BasicBundleDocument, variables: variables };
}
export const WaitingListBundlesDocument = gql`
  query waitingListBundles($page: Int = 1, $perPage: Int = 24) {
    waitingListBundles(page: $page, perPage: $perPage) {
      page
      perPage
      totalCount
      data {
        ...BundleEssentialFields
      }
    }
  }
  ${BundleEssentialFieldsFragmentDoc}
`;

/**
 * __useWaitingListBundlesQuery__
 *
 * To run a query within a React component, call `useWaitingListBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitingListBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitingListBundlesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useWaitingListBundlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WaitingListBundlesQuery,
    WaitingListBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WaitingListBundlesQuery,
    WaitingListBundlesQueryVariables
  >(WaitingListBundlesDocument, options);
}
export function useWaitingListBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WaitingListBundlesQuery,
    WaitingListBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WaitingListBundlesQuery,
    WaitingListBundlesQueryVariables
  >(WaitingListBundlesDocument, options);
}
export type WaitingListBundlesQueryHookResult = ReturnType<
  typeof useWaitingListBundlesQuery
>;
export type WaitingListBundlesLazyQueryHookResult = ReturnType<
  typeof useWaitingListBundlesLazyQuery
>;
export type WaitingListBundlesQueryResult = Apollo.QueryResult<
  WaitingListBundlesQuery,
  WaitingListBundlesQueryVariables
>;
export function refetchWaitingListBundlesQuery(
  variables?: WaitingListBundlesQueryVariables
) {
  return { query: WaitingListBundlesDocument, variables: variables };
}
export const BundleDocument = gql`
  query bundle(
    $slug: String!
    $client: String!
    $previewParameter: String = ""
  ) {
    bundle(slug: $slug, client: $client, previewParameter: $previewParameter) {
      ...BundleEssentialFields
      hideInfoText
      preview {
        description
        label
        title
        video {
          ...VideoFields
          hls
          player {
            assets {
              resolution
              src
            }
          }
        }
      }
      trailer {
        identifier
        minutes
        thumbnailUrl
        hls
        player {
          assets {
            resolution
            src
          }
        }
      }
      sections
      infoPanel {
        title
        description
        image
        buttonContent
        buttonLink
      }
      infoPanel2 {
        title
        image
        longText
        previewText
        buttonContent
        buttonLink
      }
      infoPanel3 {
        title
        description
        image
        buttonContent
        buttonLink
      }
      liveBannerSection {
        title
        subtitle
        image
        buttonLink
        buttonName
      }
      twoColumnsSection {
        title
        subtitle
        leftContent
        rightContent
      }
      promotionSection {
        title
        content
        buttonContent
        buttonLink
        image
      }
      footerCta {
        description
        image
        title
      }
      bundleItems {
        __typename
        sectionedVideos {
          headline
          viewAllUrl
          items {
            ... on Video {
              ...VideoFields
            }
          }
        }
        challenges {
          headline
          viewAllUrl
          items {
            ... on GenericProgram {
              ...EssentialGenericFields
            }
          }
        }
        programs {
          headline
          viewAllUrl
          items {
            ... on GenericProgram {
              ...EssentialGenericFields
            }
          }
        }
        playlists {
          headline
          viewAllUrl
          items {
            ... on Playlist {
              ...PlaylistCardFields
            }
          }
        }
        liveEvents {
          headline
          viewAllUrl
          items {
            ... on LiveEvent {
              ...LiveEventEssentialFields
            }
          }
        }
        blogPosts {
          headline
          viewAllUrl
          items {
            ... on BlogPost {
              ...BlogPostFields
            }
          }
        }
        documents {
          headline
          viewAllUrl
          items {
            ... on Document {
              contentType
              id
              name
              url
            }
          }
        }
      }
      relatedBundlesSection {
        headline
        bundles {
          ...BundleEssentialFields
        }
      }
    }
  }
  ${BundleEssentialFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
  ${EssentialGenericFieldsFragmentDoc}
  ${PlaylistCardFieldsFragmentDoc}
  ${LiveEventEssentialFieldsFragmentDoc}
  ${BlogPostFieldsFragmentDoc}
`;

/**
 * __useBundleQuery__
 *
 * To run a query within a React component, call `useBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      client: // value for 'client'
 *      previewParameter: // value for 'previewParameter'
 *   },
 * });
 */
export function useBundleQuery(
  baseOptions: Apollo.QueryHookOptions<BundleQuery, BundleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BundleQuery, BundleQueryVariables>(
    BundleDocument,
    options
  );
}
export function useBundleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BundleQuery, BundleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BundleQuery, BundleQueryVariables>(
    BundleDocument,
    options
  );
}
export type BundleQueryHookResult = ReturnType<typeof useBundleQuery>;
export type BundleLazyQueryHookResult = ReturnType<typeof useBundleLazyQuery>;
export type BundleQueryResult = Apollo.QueryResult<
  BundleQuery,
  BundleQueryVariables
>;
export function refetchBundleQuery(variables: BundleQueryVariables) {
  return { query: BundleDocument, variables: variables };
}
export const ActivateBundleDocument = gql`
  mutation activateBundle($slug: String!, $client: String!, $code: String!) {
    activateBundle(slug: $slug, code: $code, client: $client) {
      success
      errors
    }
  }
`;
export type ActivateBundleMutationFn = Apollo.MutationFunction<
  ActivateBundleMutation,
  ActivateBundleMutationVariables
>;

/**
 * __useActivateBundleMutation__
 *
 * To run a mutation, you first call `useActivateBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateBundleMutation, { data, loading, error }] = useActivateBundleMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      client: // value for 'client'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useActivateBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateBundleMutation,
    ActivateBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateBundleMutation,
    ActivateBundleMutationVariables
  >(ActivateBundleDocument, options);
}
export type ActivateBundleMutationHookResult = ReturnType<
  typeof useActivateBundleMutation
>;
export type ActivateBundleMutationResult =
  Apollo.MutationResult<ActivateBundleMutation>;
export type ActivateBundleMutationOptions = Apollo.BaseMutationOptions<
  ActivateBundleMutation,
  ActivateBundleMutationVariables
>;
export const AddWaitingItemDocument = gql`
  mutation addWaitingItem($itemId: ID!, $itemType: ItemTypeKind!) {
    addWaitingItem(itemId: $itemId, itemType: $itemType) {
      success
      errors
    }
  }
`;
export type AddWaitingItemMutationFn = Apollo.MutationFunction<
  AddWaitingItemMutation,
  AddWaitingItemMutationVariables
>;

/**
 * __useAddWaitingItemMutation__
 *
 * To run a mutation, you first call `useAddWaitingItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWaitingItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWaitingItemMutation, { data, loading, error }] = useAddWaitingItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      itemType: // value for 'itemType'
 *   },
 * });
 */
export function useAddWaitingItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddWaitingItemMutation,
    AddWaitingItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddWaitingItemMutation,
    AddWaitingItemMutationVariables
  >(AddWaitingItemDocument, options);
}
export type AddWaitingItemMutationHookResult = ReturnType<
  typeof useAddWaitingItemMutation
>;
export type AddWaitingItemMutationResult =
  Apollo.MutationResult<AddWaitingItemMutation>;
export type AddWaitingItemMutationOptions = Apollo.BaseMutationOptions<
  AddWaitingItemMutation,
  AddWaitingItemMutationVariables
>;
export const ValidateBundleCodeDocument = gql`
  mutation validateBundleCode(
    $slug: String!
    $code: String!
    $client: String!
  ) {
    validateBundleCode(slug: $slug, code: $code, client: $client) {
      bundle {
        ...BundleEssentialFields
      }
      code
      codeStatus
      codeType
      error
      price {
        currency
        discountApplied
        discountedPrice
        originalPrice
      }
    }
  }
  ${BundleEssentialFieldsFragmentDoc}
`;
export type ValidateBundleCodeMutationFn = Apollo.MutationFunction<
  ValidateBundleCodeMutation,
  ValidateBundleCodeMutationVariables
>;

/**
 * __useValidateBundleCodeMutation__
 *
 * To run a mutation, you first call `useValidateBundleCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateBundleCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateBundleCodeMutation, { data, loading, error }] = useValidateBundleCodeMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      code: // value for 'code'
 *      client: // value for 'client'
 *   },
 * });
 */
export function useValidateBundleCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateBundleCodeMutation,
    ValidateBundleCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateBundleCodeMutation,
    ValidateBundleCodeMutationVariables
  >(ValidateBundleCodeDocument, options);
}
export type ValidateBundleCodeMutationHookResult = ReturnType<
  typeof useValidateBundleCodeMutation
>;
export type ValidateBundleCodeMutationResult =
  Apollo.MutationResult<ValidateBundleCodeMutation>;
export type ValidateBundleCodeMutationOptions = Apollo.BaseMutationOptions<
  ValidateBundleCodeMutation,
  ValidateBundleCodeMutationVariables
>;
export const BundlesDocument = gql`
  query bundles(
    $page: Int = 1
    $perPage: Int = 12
    $query: String = "*"
    $category: BundleCategoryInput = content_hub
  ) {
    bundles(
      page: $page
      perPage: $perPage
      query: $query
      category: $category
    ) {
      page
      perPage
      totalCount
      data {
        ...BundleEssentialFields
      }
    }
  }
  ${BundleEssentialFieldsFragmentDoc}
`;

/**
 * __useBundlesQuery__
 *
 * To run a query within a React component, call `useBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBundlesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      query: // value for 'query'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useBundlesQuery(
  baseOptions?: Apollo.QueryHookOptions<BundlesQuery, BundlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BundlesQuery, BundlesQueryVariables>(
    BundlesDocument,
    options
  );
}
export function useBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BundlesQuery, BundlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BundlesQuery, BundlesQueryVariables>(
    BundlesDocument,
    options
  );
}
export type BundlesQueryHookResult = ReturnType<typeof useBundlesQuery>;
export type BundlesLazyQueryHookResult = ReturnType<typeof useBundlesLazyQuery>;
export type BundlesQueryResult = Apollo.QueryResult<
  BundlesQuery,
  BundlesQueryVariables
>;
export function refetchBundlesQuery(variables?: BundlesQueryVariables) {
  return { query: BundlesDocument, variables: variables };
}
export const MyBundlesDocument = gql`
  query myBundles($page: Int, $perPage: Int) {
    myBundles(page: $page, perPage: $perPage) {
      page
      perPage
      totalCount
      data {
        ...BundleEssentialFields
      }
    }
  }
  ${BundleEssentialFieldsFragmentDoc}
`;

/**
 * __useMyBundlesQuery__
 *
 * To run a query within a React component, call `useMyBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBundlesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useMyBundlesQuery(
  baseOptions?: Apollo.QueryHookOptions<MyBundlesQuery, MyBundlesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyBundlesQuery, MyBundlesQueryVariables>(
    MyBundlesDocument,
    options
  );
}
export function useMyBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyBundlesQuery,
    MyBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyBundlesQuery, MyBundlesQueryVariables>(
    MyBundlesDocument,
    options
  );
}
export type MyBundlesQueryHookResult = ReturnType<typeof useMyBundlesQuery>;
export type MyBundlesLazyQueryHookResult = ReturnType<
  typeof useMyBundlesLazyQuery
>;
export type MyBundlesQueryResult = Apollo.QueryResult<
  MyBundlesQuery,
  MyBundlesQueryVariables
>;
export function refetchMyBundlesQuery(variables?: MyBundlesQueryVariables) {
  return { query: MyBundlesDocument, variables: variables };
}
export const CancelSubscriptionDocument = gql`
  mutation cancelSubscription {
    cancelSubscription {
      success
    }
  }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, options);
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>;
export type CancelSubscriptionMutationResult =
  Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const ChallengesQueryDocument = gql`
  query challengesQuery {
    genericPrograms(
      perPage: 20
      page: 1
      query: "*"
      languages: [en, se, no, fi]
      instructor: null
      theme: null
      category: challenges
      sort: newest_first
      tags: null
    ) {
      page
      totalCount
      data {
        ...EssentialGenericFields
        tags
        discount {
          discountType
          discountValue
          discountedPrice
          endDate
          identifier
          startDate
        }
      }
    }
  }
  ${EssentialGenericFieldsFragmentDoc}
`;

/**
 * __useChallengesQueryQuery__
 *
 * To run a query within a React component, call `useChallengesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useChallengesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChallengesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useChallengesQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChallengesQueryQuery,
    ChallengesQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChallengesQueryQuery, ChallengesQueryQueryVariables>(
    ChallengesQueryDocument,
    options
  );
}
export function useChallengesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChallengesQueryQuery,
    ChallengesQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChallengesQueryQuery,
    ChallengesQueryQueryVariables
  >(ChallengesQueryDocument, options);
}
export type ChallengesQueryQueryHookResult = ReturnType<
  typeof useChallengesQueryQuery
>;
export type ChallengesQueryLazyQueryHookResult = ReturnType<
  typeof useChallengesQueryLazyQuery
>;
export type ChallengesQueryQueryResult = Apollo.QueryResult<
  ChallengesQueryQuery,
  ChallengesQueryQueryVariables
>;
export function refetchChallengesQueryQuery(
  variables?: ChallengesQueryQueryVariables
) {
  return { query: ChallengesQueryDocument, variables: variables };
}
export const ValidateChallengeTokenDocument = gql`
  mutation validateChallengeToken($token: String!) {
    validateChallengeToken(accessToken: $token) {
      status
      errors {
        message
      }
    }
  }
`;
export type ValidateChallengeTokenMutationFn = Apollo.MutationFunction<
  ValidateChallengeTokenMutation,
  ValidateChallengeTokenMutationVariables
>;

/**
 * __useValidateChallengeTokenMutation__
 *
 * To run a mutation, you first call `useValidateChallengeTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateChallengeTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateChallengeTokenMutation, { data, loading, error }] = useValidateChallengeTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateChallengeTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateChallengeTokenMutation,
    ValidateChallengeTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateChallengeTokenMutation,
    ValidateChallengeTokenMutationVariables
  >(ValidateChallengeTokenDocument, options);
}
export type ValidateChallengeTokenMutationHookResult = ReturnType<
  typeof useValidateChallengeTokenMutation
>;
export type ValidateChallengeTokenMutationResult =
  Apollo.MutationResult<ValidateChallengeTokenMutation>;
export type ValidateChallengeTokenMutationOptions = Apollo.BaseMutationOptions<
  ValidateChallengeTokenMutation,
  ValidateChallengeTokenMutationVariables
>;
export const ActivateChallengeDocument = gql`
  mutation activateChallenge($token: String, $challenge: String) {
    activateChallenge(accessCode: $token, challenge: $challenge) {
      challengeParticipation {
        accessUntil
      }
      challengePass {
        challenge {
          name
        }
        code
        days
      }
      errors {
        message
      }
    }
  }
`;
export type ActivateChallengeMutationFn = Apollo.MutationFunction<
  ActivateChallengeMutation,
  ActivateChallengeMutationVariables
>;

/**
 * __useActivateChallengeMutation__
 *
 * To run a mutation, you first call `useActivateChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateChallengeMutation, { data, loading, error }] = useActivateChallengeMutation({
 *   variables: {
 *      token: // value for 'token'
 *      challenge: // value for 'challenge'
 *   },
 * });
 */
export function useActivateChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateChallengeMutation,
    ActivateChallengeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateChallengeMutation,
    ActivateChallengeMutationVariables
  >(ActivateChallengeDocument, options);
}
export type ActivateChallengeMutationHookResult = ReturnType<
  typeof useActivateChallengeMutation
>;
export type ActivateChallengeMutationResult =
  Apollo.MutationResult<ActivateChallengeMutation>;
export type ActivateChallengeMutationOptions = Apollo.BaseMutationOptions<
  ActivateChallengeMutation,
  ActivateChallengeMutationVariables
>;
export const ChangeProfileDataDocument = gql`
  mutation changeProfileData(
    $displayName: String
    $shortBio: String
    $instagramUrl: String
    $facebookUrl: String
    $linkedinUrl: String
    $timezone: String
    $avatar: Upload
  ) {
    changeProfileData(
      displayName: $displayName
      shortBio: $shortBio
      instagramUrl: $instagramUrl
      facebookUrl: $facebookUrl
      linkedinUrl: $linkedinUrl
      timezone: $timezone
      avatar: $avatar
    ) {
      user {
        id
      }
    }
  }
`;
export type ChangeProfileDataMutationFn = Apollo.MutationFunction<
  ChangeProfileDataMutation,
  ChangeProfileDataMutationVariables
>;

/**
 * __useChangeProfileDataMutation__
 *
 * To run a mutation, you first call `useChangeProfileDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProfileDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProfileDataMutation, { data, loading, error }] = useChangeProfileDataMutation({
 *   variables: {
 *      displayName: // value for 'displayName'
 *      shortBio: // value for 'shortBio'
 *      instagramUrl: // value for 'instagramUrl'
 *      facebookUrl: // value for 'facebookUrl'
 *      linkedinUrl: // value for 'linkedinUrl'
 *      timezone: // value for 'timezone'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useChangeProfileDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeProfileDataMutation,
    ChangeProfileDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeProfileDataMutation,
    ChangeProfileDataMutationVariables
  >(ChangeProfileDataDocument, options);
}
export type ChangeProfileDataMutationHookResult = ReturnType<
  typeof useChangeProfileDataMutation
>;
export type ChangeProfileDataMutationResult =
  Apollo.MutationResult<ChangeProfileDataMutation>;
export type ChangeProfileDataMutationOptions = Apollo.BaseMutationOptions<
  ChangeProfileDataMutation,
  ChangeProfileDataMutationVariables
>;
export const ChurnUpsellOffersDocument = gql`
  query churnUpsellOffers {
    churnUpsellOffers {
      ...ChurnUpsellOffer
    }
  }
  ${ChurnUpsellOfferFragmentDoc}
`;

/**
 * __useChurnUpsellOffersQuery__
 *
 * To run a query within a React component, call `useChurnUpsellOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChurnUpsellOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChurnUpsellOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useChurnUpsellOffersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChurnUpsellOffersQuery,
    ChurnUpsellOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChurnUpsellOffersQuery,
    ChurnUpsellOffersQueryVariables
  >(ChurnUpsellOffersDocument, options);
}
export function useChurnUpsellOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChurnUpsellOffersQuery,
    ChurnUpsellOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChurnUpsellOffersQuery,
    ChurnUpsellOffersQueryVariables
  >(ChurnUpsellOffersDocument, options);
}
export type ChurnUpsellOffersQueryHookResult = ReturnType<
  typeof useChurnUpsellOffersQuery
>;
export type ChurnUpsellOffersLazyQueryHookResult = ReturnType<
  typeof useChurnUpsellOffersLazyQuery
>;
export type ChurnUpsellOffersQueryResult = Apollo.QueryResult<
  ChurnUpsellOffersQuery,
  ChurnUpsellOffersQueryVariables
>;
export function refetchChurnUpsellOffersQuery(
  variables?: ChurnUpsellOffersQueryVariables
) {
  return { query: ChurnUpsellOffersDocument, variables: variables };
}
export const ChurnStartDocument = gql`
  mutation churnStart {
    churnStart {
      previousAnswer {
        id
        cause
        feedback
        solution
        state
      }
      answer {
        id
        cause
        feedback
        solution
        state
      }
    }
  }
`;
export type ChurnStartMutationFn = Apollo.MutationFunction<
  ChurnStartMutation,
  ChurnStartMutationVariables
>;

/**
 * __useChurnStartMutation__
 *
 * To run a mutation, you first call `useChurnStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChurnStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [churnStartMutation, { data, loading, error }] = useChurnStartMutation({
 *   variables: {
 *   },
 * });
 */
export function useChurnStartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChurnStartMutation,
    ChurnStartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChurnStartMutation, ChurnStartMutationVariables>(
    ChurnStartDocument,
    options
  );
}
export type ChurnStartMutationHookResult = ReturnType<
  typeof useChurnStartMutation
>;
export type ChurnStartMutationResult =
  Apollo.MutationResult<ChurnStartMutation>;
export type ChurnStartMutationOptions = Apollo.BaseMutationOptions<
  ChurnStartMutation,
  ChurnStartMutationVariables
>;
export const ChurnStepDocument = gql`
  mutation churnStep(
    $answerId: String!
    $action: ActionsKind!
    $cause: String
    $feedback: String
    $solution: String
  ) {
    churnStep(
      answerId: $answerId
      action: $action
      cause: $cause
      feedback: $feedback
      solution: $solution
    ) {
      answer {
        cause
        feedback
        id
        solution
        state
      }
    }
  }
`;
export type ChurnStepMutationFn = Apollo.MutationFunction<
  ChurnStepMutation,
  ChurnStepMutationVariables
>;

/**
 * __useChurnStepMutation__
 *
 * To run a mutation, you first call `useChurnStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChurnStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [churnStepMutation, { data, loading, error }] = useChurnStepMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *      action: // value for 'action'
 *      cause: // value for 'cause'
 *      feedback: // value for 'feedback'
 *      solution: // value for 'solution'
 *   },
 * });
 */
export function useChurnStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChurnStepMutation,
    ChurnStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChurnStepMutation, ChurnStepMutationVariables>(
    ChurnStepDocument,
    options
  );
}
export type ChurnStepMutationHookResult = ReturnType<
  typeof useChurnStepMutation
>;
export type ChurnStepMutationResult = Apollo.MutationResult<ChurnStepMutation>;
export type ChurnStepMutationOptions = Apollo.BaseMutationOptions<
  ChurnStepMutation,
  ChurnStepMutationVariables
>;
export const CompleteProfileDocument = gql`
  mutation completeProfile(
    $email: String
    $lastName: String
    $firstName: String
    $gender: GenderKind
    $country: String
    $countryCode: String = "SE"
    $trialExtensionCode: String = ""
    $oneTimeCode: String = ""
  ) {
    completeProfile(
      firstName: $firstName
      lastName: $lastName
      email: $email
      gender: $gender
      country: $country
      countryCode: $countryCode
      platform: web
      trialExtensionCode: $trialExtensionCode
      oneTimeCode: $oneTimeCode
    ) {
      errors {
        message
      }
      user {
        id
      }
    }
  }
`;
export type CompleteProfileMutationFn = Apollo.MutationFunction<
  CompleteProfileMutation,
  CompleteProfileMutationVariables
>;

/**
 * __useCompleteProfileMutation__
 *
 * To run a mutation, you first call `useCompleteProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeProfileMutation, { data, loading, error }] = useCompleteProfileMutation({
 *   variables: {
 *      email: // value for 'email'
 *      lastName: // value for 'lastName'
 *      firstName: // value for 'firstName'
 *      gender: // value for 'gender'
 *      country: // value for 'country'
 *      countryCode: // value for 'countryCode'
 *      trialExtensionCode: // value for 'trialExtensionCode'
 *      oneTimeCode: // value for 'oneTimeCode'
 *   },
 * });
 */
export function useCompleteProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteProfileMutation,
    CompleteProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteProfileMutation,
    CompleteProfileMutationVariables
  >(CompleteProfileDocument, options);
}
export type CompleteProfileMutationHookResult = ReturnType<
  typeof useCompleteProfileMutation
>;
export type CompleteProfileMutationResult =
  Apollo.MutationResult<CompleteProfileMutation>;
export type CompleteProfileMutationOptions = Apollo.BaseMutationOptions<
  CompleteProfileMutation,
  CompleteProfileMutationVariables
>;
export const FetchCrossPromotionsDocument = gql`
  query fetchCrossPromotions(
    $resourceId: String!
    $resourceType: ResourceTypeKind!
    $page: Int = 1
    $perPage: Int = 12
  ) {
    fetchCrossPromotions(
      resourceId: $resourceId
      resourceType: $resourceType
      page: $page
      perPage: $perPage
    ) {
      bundles {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchBundle {
            ...SearchBundleFields
          }
        }
      }
      blogPosts {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchBlogPost {
            ...SearchBlogPostFields
          }
        }
      }
      challenges {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      genericPrograms {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      challenges {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      courses {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      liveEvents {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchLiveEvent {
            ...SearchLiveEventFields
          }
        }
      }
    }
  }
  ${SearchBundleFieldsFragmentDoc}
  ${SearchBlogPostFieldsFragmentDoc}
  ${SearchGenericProgramFieldsFragmentDoc}
  ${SearchLiveEventFieldsFragmentDoc}
`;

/**
 * __useFetchCrossPromotionsQuery__
 *
 * To run a query within a React component, call `useFetchCrossPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCrossPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCrossPromotionsQuery({
 *   variables: {
 *      resourceId: // value for 'resourceId'
 *      resourceType: // value for 'resourceType'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useFetchCrossPromotionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchCrossPromotionsQuery,
    FetchCrossPromotionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchCrossPromotionsQuery,
    FetchCrossPromotionsQueryVariables
  >(FetchCrossPromotionsDocument, options);
}
export function useFetchCrossPromotionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCrossPromotionsQuery,
    FetchCrossPromotionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchCrossPromotionsQuery,
    FetchCrossPromotionsQueryVariables
  >(FetchCrossPromotionsDocument, options);
}
export type FetchCrossPromotionsQueryHookResult = ReturnType<
  typeof useFetchCrossPromotionsQuery
>;
export type FetchCrossPromotionsLazyQueryHookResult = ReturnType<
  typeof useFetchCrossPromotionsLazyQuery
>;
export type FetchCrossPromotionsQueryResult = Apollo.QueryResult<
  FetchCrossPromotionsQuery,
  FetchCrossPromotionsQueryVariables
>;
export function refetchFetchCrossPromotionsQuery(
  variables: FetchCrossPromotionsQueryVariables
) {
  return { query: FetchCrossPromotionsDocument, variables: variables };
}
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      impersonated
      id
      firstName
      lastName
      email
      accessToken
      accessUntil
      completedAt
      preferredCurrency
      isPremium @client
      isNoneTrialPremium @client
      hasWellness @client
      group
      isEmailActivated
      accountActivatedAt
      isPublic
      kind
      locale
      slug
      previousEmail
      eligibleForDelete
      canUseTrustly
      lastAccessPaymentProvider
      lastWellnessAccessProvider
      trialRequired
      eligibleForStartDiscount
      registrationSource
      trial {
        code
        ...trialFragment
      }
      countryCode
      emailLanguage
      country
      zipcode
      city
      phone
      address
      referralCode
      profile {
        displayName
        shortBio
        instagramURL
        linkedinURL
        facebookURL
        timezone
      }
      assets {
        avatar
      }
      preferences {
        contentLanguages
        preferTerms
        excludeTerms
        videoLevels
      }
      paymentTypeRenews
      subscription {
        provider
        status
        inTrialPeriod
        trial {
          ...trialFragment
        }
        currentSubscriptionPlan {
          platform
          planType
          platform
          productId
          months
          oldSubscriptionPrice
        }
        userSubscription {
          title
          description
          shortDescription
          amount
          discountedAmount
          currency
          months
          days
          accessUntil
          promotionId
          subscriptionName
          subscriptionPlan
          promotionKind
          promotionName
          recurringPromotion
        }
      }
      onboardingCompleted
      onboardingSkipped
      onboardingInterestsSkipped
      onboardingTopicsSkipped
      funnel
      finalFunnel
    }
  }
  ${TrialFragmentFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export function refetchCurrentUserQuery(variables?: CurrentUserQueryVariables) {
  return { query: CurrentUserDocument, variables: variables };
}
export const CurrentUser2Document = gql`
  query currentUser2 {
    currentUser {
      id
      accessUntil
      paymentFailedAt
      gender
      birthday
      profession
      personNumber
      nextBillingCycle {
        paymentStatus
        amount
        currentAmountCharged
        billingDate
        planName
      }
      topics {
        name
        slug
      }
      interests {
        name
        slug
      }
      favorites {
        videos
        playlists
      }
      privacySettings {
        isPrivateFavorites
        isPrivateHistory
        isPrivatePlaylists
        isPrivateProfile
        isPrivateStats
      }
      statistics {
        sessionsLastWeek
        sessionsCompleted
        totalTime
        averageDuration
      }
      receipts {
        ...ReceiptFields
      }
      extraPayments {
        ...ExtraReceiptFields
      }
      liveEventsAccess {
        liveEvent {
          id
          duration
          eventDates
          kind
          language
          level
          theme
          slug
          title
          thumbnail
          accessories
          price {
            originalPrice
            discountApplied
            discountedPrice
            currency
          }
          registrationEndDate
          registrationStartDate
          descriptionLong
          availableSpots
          bookingAvailable
          linkToRecordings {
            link
            id
            eventDate
            expiresAt
            video {
              id
            }
          }
          primaryInstructor {
            id
            name
            slug
            assets {
              squareImage
            }
          }
          otherInstructors {
            id
            name
            slug
            assets {
              squareImage
            }
          }
          instructors @client {
            id
            name
            slug
            assets {
              squareImage
            }
          }
        }
        zoomLink
        zoomPassword
      }
      programAccess {
        ...ProgramAccessFields
      }
      challengeAccesses {
        ...ProgramAccessFields
      }
      courseAccesses {
        accessUntil
        accessStatus
        joinedAt
        course {
          id
          name
          titleImageUrl
          slug
          path
          themes
          legacy
          content {
            lede
          }
        }
      }
      challengeParticipations {
        accessUntil
        challenge {
          name
        }
      }
    }
  }
  ${ReceiptFieldsFragmentDoc}
  ${ExtraReceiptFieldsFragmentDoc}
  ${ProgramAccessFieldsFragmentDoc}
`;

/**
 * __useCurrentUser2Query__
 *
 * To run a query within a React component, call `useCurrentUser2Query` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUser2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUser2Query({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUser2Query(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUser2Query,
    CurrentUser2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUser2Query, CurrentUser2QueryVariables>(
    CurrentUser2Document,
    options
  );
}
export function useCurrentUser2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUser2Query,
    CurrentUser2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUser2Query, CurrentUser2QueryVariables>(
    CurrentUser2Document,
    options
  );
}
export type CurrentUser2QueryHookResult = ReturnType<
  typeof useCurrentUser2Query
>;
export type CurrentUser2LazyQueryHookResult = ReturnType<
  typeof useCurrentUser2LazyQuery
>;
export type CurrentUser2QueryResult = Apollo.QueryResult<
  CurrentUser2Query,
  CurrentUser2QueryVariables
>;
export function refetchCurrentUser2Query(
  variables?: CurrentUser2QueryVariables
) {
  return { query: CurrentUser2Document, variables: variables };
}
export const ChangeUserPreferencesDocument = gql`
  mutation changeUserPreferences(
    $preferredCurrency: CurrencyKind
    $emailLanguage: String
    $country: String
    $countryCode: String
  ) {
    changeUserPreferences(
      preferredCurrency: $preferredCurrency
      emailLanguage: $emailLanguage
      country: $country
      countryCode: $countryCode
    ) {
      user {
        id
      }
    }
  }
`;
export type ChangeUserPreferencesMutationFn = Apollo.MutationFunction<
  ChangeUserPreferencesMutation,
  ChangeUserPreferencesMutationVariables
>;

/**
 * __useChangeUserPreferencesMutation__
 *
 * To run a mutation, you first call `useChangeUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPreferencesMutation, { data, loading, error }] = useChangeUserPreferencesMutation({
 *   variables: {
 *      preferredCurrency: // value for 'preferredCurrency'
 *      emailLanguage: // value for 'emailLanguage'
 *      country: // value for 'country'
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useChangeUserPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserPreferencesMutation,
    ChangeUserPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeUserPreferencesMutation,
    ChangeUserPreferencesMutationVariables
  >(ChangeUserPreferencesDocument, options);
}
export type ChangeUserPreferencesMutationHookResult = ReturnType<
  typeof useChangeUserPreferencesMutation
>;
export type ChangeUserPreferencesMutationResult =
  Apollo.MutationResult<ChangeUserPreferencesMutation>;
export type ChangeUserPreferencesMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserPreferencesMutation,
  ChangeUserPreferencesMutationVariables
>;
export const DashboardRecentVideosDocument = gql`
  query dashboardRecentVideos {
    recentVideos {
      id
      identifier
      content {
        title
        shortDescription
      }
      meta {
        minutes
        publishedAt
      }
      taxonomies {
        flavor {
          name
        }
        instructors {
          name
          slug
        }
        genre {
          name
          slug
        }
      }
      assets {
        thumbnailUrl
      }
    }
  }
`;

/**
 * __useDashboardRecentVideosQuery__
 *
 * To run a query within a React component, call `useDashboardRecentVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardRecentVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardRecentVideosQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardRecentVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardRecentVideosQuery,
    DashboardRecentVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardRecentVideosQuery,
    DashboardRecentVideosQueryVariables
  >(DashboardRecentVideosDocument, options);
}
export function useDashboardRecentVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardRecentVideosQuery,
    DashboardRecentVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardRecentVideosQuery,
    DashboardRecentVideosQueryVariables
  >(DashboardRecentVideosDocument, options);
}
export type DashboardRecentVideosQueryHookResult = ReturnType<
  typeof useDashboardRecentVideosQuery
>;
export type DashboardRecentVideosLazyQueryHookResult = ReturnType<
  typeof useDashboardRecentVideosLazyQuery
>;
export type DashboardRecentVideosQueryResult = Apollo.QueryResult<
  DashboardRecentVideosQuery,
  DashboardRecentVideosQueryVariables
>;
export function refetchDashboardRecentVideosQuery(
  variables?: DashboardRecentVideosQueryVariables
) {
  return { query: DashboardRecentVideosDocument, variables: variables };
}
export const DashboardTaxonomiesDocument = gql`
  query dashboardTaxonomies($filter: String) {
    playlists: taxonomies(filter: $filter) {
      terms {
        id
        name
        titleImageUrl
        slug
      }
    }
  }
`;

/**
 * __useDashboardTaxonomiesQuery__
 *
 * To run a query within a React component, call `useDashboardTaxonomiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardTaxonomiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardTaxonomiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDashboardTaxonomiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardTaxonomiesQuery,
    DashboardTaxonomiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardTaxonomiesQuery,
    DashboardTaxonomiesQueryVariables
  >(DashboardTaxonomiesDocument, options);
}
export function useDashboardTaxonomiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardTaxonomiesQuery,
    DashboardTaxonomiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardTaxonomiesQuery,
    DashboardTaxonomiesQueryVariables
  >(DashboardTaxonomiesDocument, options);
}
export type DashboardTaxonomiesQueryHookResult = ReturnType<
  typeof useDashboardTaxonomiesQuery
>;
export type DashboardTaxonomiesLazyQueryHookResult = ReturnType<
  typeof useDashboardTaxonomiesLazyQuery
>;
export type DashboardTaxonomiesQueryResult = Apollo.QueryResult<
  DashboardTaxonomiesQuery,
  DashboardTaxonomiesQueryVariables
>;
export function refetchDashboardTaxonomiesQuery(
  variables?: DashboardTaxonomiesQueryVariables
) {
  return { query: DashboardTaxonomiesDocument, variables: variables };
}
export const DeleteAccountDocument = gql`
  mutation deleteAccount($deletionReason: String!) {
    deleteAccount(deletionReason: $deletionReason) {
      user {
        id
      }
      errors {
        message
      }
    }
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      deletionReason: // value for 'deletionReason'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<
  typeof useDeleteAccountMutation
>;
export type DeleteAccountMutationResult =
  Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const EmailPreferencesDocument = gql`
  query EmailPreferences($email: String, $token: String) {
    emailPreferences(email: $email, token: $token) {
      id
      isMuted
      key
      name
      description
      mutable
    }
  }
`;

/**
 * __useEmailPreferencesQuery__
 *
 * To run a query within a React component, call `useEmailPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailPreferencesQuery({
 *   variables: {
 *      email: // value for 'email'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useEmailPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EmailPreferencesQuery,
    EmailPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailPreferencesQuery, EmailPreferencesQueryVariables>(
    EmailPreferencesDocument,
    options
  );
}
export function useEmailPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailPreferencesQuery,
    EmailPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmailPreferencesQuery,
    EmailPreferencesQueryVariables
  >(EmailPreferencesDocument, options);
}
export type EmailPreferencesQueryHookResult = ReturnType<
  typeof useEmailPreferencesQuery
>;
export type EmailPreferencesLazyQueryHookResult = ReturnType<
  typeof useEmailPreferencesLazyQuery
>;
export type EmailPreferencesQueryResult = Apollo.QueryResult<
  EmailPreferencesQuery,
  EmailPreferencesQueryVariables
>;
export function refetchEmailPreferencesQuery(
  variables?: EmailPreferencesQueryVariables
) {
  return { query: EmailPreferencesDocument, variables: variables };
}
export const ChangeEmailPreferenceDocument = gql`
  mutation ChangeEmailPreference(
    $mute: Boolean!
    $key: String!
    $email: String
    $token: String
  ) {
    changeEmailPreference(
      mute: $mute
      key: $key
      email: $email
      token: $token
    ) {
      errors
      success
    }
  }
`;
export type ChangeEmailPreferenceMutationFn = Apollo.MutationFunction<
  ChangeEmailPreferenceMutation,
  ChangeEmailPreferenceMutationVariables
>;

/**
 * __useChangeEmailPreferenceMutation__
 *
 * To run a mutation, you first call `useChangeEmailPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailPreferenceMutation, { data, loading, error }] = useChangeEmailPreferenceMutation({
 *   variables: {
 *      mute: // value for 'mute'
 *      key: // value for 'key'
 *      email: // value for 'email'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useChangeEmailPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeEmailPreferenceMutation,
    ChangeEmailPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeEmailPreferenceMutation,
    ChangeEmailPreferenceMutationVariables
  >(ChangeEmailPreferenceDocument, options);
}
export type ChangeEmailPreferenceMutationHookResult = ReturnType<
  typeof useChangeEmailPreferenceMutation
>;
export type ChangeEmailPreferenceMutationResult =
  Apollo.MutationResult<ChangeEmailPreferenceMutation>;
export type ChangeEmailPreferenceMutationOptions = Apollo.BaseMutationOptions<
  ChangeEmailPreferenceMutation,
  ChangeEmailPreferenceMutationVariables
>;
export const StoreEpassiDocument = gql`
  mutation storeEpassi(
    $paymentType: String!
    $redirectToSuccess: String!
    $redirectToFailed: String!
    $sourcePage: String
  ) {
    storeEpassi(
      paymentType: $paymentType
      redirectToSuccess: $redirectToSuccess
      redirectToFailed: $redirectToFailed
      sourcePage: $sourcePage
    ) {
      stamp
      amount
      fee
      mac
      site
      vatValue
      cancelUrl
      rejectUrl
      returnUrl
      notifyUrl
    }
  }
`;
export type StoreEpassiMutationFn = Apollo.MutationFunction<
  StoreEpassiMutation,
  StoreEpassiMutationVariables
>;

/**
 * __useStoreEpassiMutation__
 *
 * To run a mutation, you first call `useStoreEpassiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreEpassiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeEpassiMutation, { data, loading, error }] = useStoreEpassiMutation({
 *   variables: {
 *      paymentType: // value for 'paymentType'
 *      redirectToSuccess: // value for 'redirectToSuccess'
 *      redirectToFailed: // value for 'redirectToFailed'
 *      sourcePage: // value for 'sourcePage'
 *   },
 * });
 */
export function useStoreEpassiMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StoreEpassiMutation,
    StoreEpassiMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StoreEpassiMutation, StoreEpassiMutationVariables>(
    StoreEpassiDocument,
    options
  );
}
export type StoreEpassiMutationHookResult = ReturnType<
  typeof useStoreEpassiMutation
>;
export type StoreEpassiMutationResult =
  Apollo.MutationResult<StoreEpassiMutation>;
export type StoreEpassiMutationOptions = Apollo.BaseMutationOptions<
  StoreEpassiMutation,
  StoreEpassiMutationVariables
>;
export const PayForWellnessItemDocument = gql`
  mutation payForWellnessItem(
    $slug: String!
    $itemKind: ItemKind!
    $wellnessProvider: PaymentWellnessProviderKind!
    $redirectToSuccess: String
    $redirectToFailed: String
    $discountCode: String
    $amountCents: Int
    $sourcePage: String
  ) {
    payForWellnessItem(
      slug: $slug
      itemKind: $itemKind
      wellnessProvider: $wellnessProvider
      redirectToSuccess: $redirectToSuccess
      redirectToFailed: $redirectToFailed
      discountCode: $discountCode
      amountCents: $amountCents
      sourcePage: $sourcePage
    ) {
      amount
      cancelUrl
      fee
      mac
      notifyUrl
      rejectUrl
      returnUrl
      site
      stamp
      vatValue
    }
  }
`;
export type PayForWellnessItemMutationFn = Apollo.MutationFunction<
  PayForWellnessItemMutation,
  PayForWellnessItemMutationVariables
>;

/**
 * __usePayForWellnessItemMutation__
 *
 * To run a mutation, you first call `usePayForWellnessItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayForWellnessItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payForWellnessItemMutation, { data, loading, error }] = usePayForWellnessItemMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      itemKind: // value for 'itemKind'
 *      wellnessProvider: // value for 'wellnessProvider'
 *      redirectToSuccess: // value for 'redirectToSuccess'
 *      redirectToFailed: // value for 'redirectToFailed'
 *      discountCode: // value for 'discountCode'
 *      amountCents: // value for 'amountCents'
 *      sourcePage: // value for 'sourcePage'
 *   },
 * });
 */
export function usePayForWellnessItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayForWellnessItemMutation,
    PayForWellnessItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayForWellnessItemMutation,
    PayForWellnessItemMutationVariables
  >(PayForWellnessItemDocument, options);
}
export type PayForWellnessItemMutationHookResult = ReturnType<
  typeof usePayForWellnessItemMutation
>;
export type PayForWellnessItemMutationResult =
  Apollo.MutationResult<PayForWellnessItemMutation>;
export type PayForWellnessItemMutationOptions = Apollo.BaseMutationOptions<
  PayForWellnessItemMutation,
  PayForWellnessItemMutationVariables
>;
export const EventProxyDocument = gql`
  mutation eventProxy(
    $event: ProxyEventKind!
    $properties: [EventPropertyInput!]
  ) {
    eventProxy(event: $event, properties: $properties) {
      errors {
        message
      }
    }
  }
`;
export type EventProxyMutationFn = Apollo.MutationFunction<
  EventProxyMutation,
  EventProxyMutationVariables
>;

/**
 * __useEventProxyMutation__
 *
 * To run a mutation, you first call `useEventProxyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventProxyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventProxyMutation, { data, loading, error }] = useEventProxyMutation({
 *   variables: {
 *      event: // value for 'event'
 *      properties: // value for 'properties'
 *   },
 * });
 */
export function useEventProxyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EventProxyMutation,
    EventProxyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EventProxyMutation, EventProxyMutationVariables>(
    EventProxyDocument,
    options
  );
}
export type EventProxyMutationHookResult = ReturnType<
  typeof useEventProxyMutation
>;
export type EventProxyMutationResult =
  Apollo.MutationResult<EventProxyMutation>;
export type EventProxyMutationOptions = Apollo.BaseMutationOptions<
  EventProxyMutation,
  EventProxyMutationVariables
>;
export const FlagsDocument = gql`
  query flags($names: [String!]!) {
    flipperFlags(names: $names) {
      name
      value
    }
  }
`;

/**
 * __useFlagsQuery__
 *
 * To run a query within a React component, call `useFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlagsQuery({
 *   variables: {
 *      names: // value for 'names'
 *   },
 * });
 */
export function useFlagsQuery(
  baseOptions: Apollo.QueryHookOptions<FlagsQuery, FlagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlagsQuery, FlagsQueryVariables>(
    FlagsDocument,
    options
  );
}
export function useFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FlagsQuery, FlagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlagsQuery, FlagsQueryVariables>(
    FlagsDocument,
    options
  );
}
export type FlagsQueryHookResult = ReturnType<typeof useFlagsQuery>;
export type FlagsLazyQueryHookResult = ReturnType<typeof useFlagsLazyQuery>;
export type FlagsQueryResult = Apollo.QueryResult<
  FlagsQuery,
  FlagsQueryVariables
>;
export function refetchFlagsQuery(variables: FlagsQueryVariables) {
  return { query: FlagsDocument, variables: variables };
}
export const GetBasicGenericProgramDocument = gql`
  query GetBasicGenericProgram($slug: String!) {
    genericProgram(slug: $slug) {
      ...EssentialGenericFields
    }
  }
  ${EssentialGenericFieldsFragmentDoc}
`;

/**
 * __useGetBasicGenericProgramQuery__
 *
 * To run a query within a React component, call `useGetBasicGenericProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicGenericProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicGenericProgramQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetBasicGenericProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBasicGenericProgramQuery,
    GetBasicGenericProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBasicGenericProgramQuery,
    GetBasicGenericProgramQueryVariables
  >(GetBasicGenericProgramDocument, options);
}
export function useGetBasicGenericProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBasicGenericProgramQuery,
    GetBasicGenericProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBasicGenericProgramQuery,
    GetBasicGenericProgramQueryVariables
  >(GetBasicGenericProgramDocument, options);
}
export type GetBasicGenericProgramQueryHookResult = ReturnType<
  typeof useGetBasicGenericProgramQuery
>;
export type GetBasicGenericProgramLazyQueryHookResult = ReturnType<
  typeof useGetBasicGenericProgramLazyQuery
>;
export type GetBasicGenericProgramQueryResult = Apollo.QueryResult<
  GetBasicGenericProgramQuery,
  GetBasicGenericProgramQueryVariables
>;
export function refetchGetBasicGenericProgramQuery(
  variables: GetBasicGenericProgramQueryVariables
) {
  return { query: GetBasicGenericProgramDocument, variables: variables };
}
export const GetFullGenericProgramDocument = gql`
  query GetFullGenericProgram($slug: String!) {
    genericProgram(slug: $slug) {
      ...FullGenericProgramFields
    }
  }
  ${FullGenericProgramFieldsFragmentDoc}
`;

/**
 * __useGetFullGenericProgramQuery__
 *
 * To run a query within a React component, call `useGetFullGenericProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullGenericProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullGenericProgramQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetFullGenericProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFullGenericProgramQuery,
    GetFullGenericProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFullGenericProgramQuery,
    GetFullGenericProgramQueryVariables
  >(GetFullGenericProgramDocument, options);
}
export function useGetFullGenericProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFullGenericProgramQuery,
    GetFullGenericProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFullGenericProgramQuery,
    GetFullGenericProgramQueryVariables
  >(GetFullGenericProgramDocument, options);
}
export type GetFullGenericProgramQueryHookResult = ReturnType<
  typeof useGetFullGenericProgramQuery
>;
export type GetFullGenericProgramLazyQueryHookResult = ReturnType<
  typeof useGetFullGenericProgramLazyQuery
>;
export type GetFullGenericProgramQueryResult = Apollo.QueryResult<
  GetFullGenericProgramQuery,
  GetFullGenericProgramQueryVariables
>;
export function refetchGetFullGenericProgramQuery(
  variables: GetFullGenericProgramQueryVariables
) {
  return { query: GetFullGenericProgramDocument, variables: variables };
}
export const GenericProgramsDocument = gql`
  query genericPrograms(
    $perPage: Int = 12
    $page: Int
    $search: String
    $languages: [LanguageKind!]
    $instructor: String
    $sortBy: GenericProgramSortAttribute
    $theme: String
    $category: ProgramCategory
    $tags: [String!]
  ) {
    genericPrograms(
      perPage: $perPage
      page: $page
      query: $search
      languages: $languages
      instructor: $instructor
      sort: $sortBy
      theme: $theme
      category: $category
      tags: $tags
    ) {
      page
      totalCount
      data {
        ...EssentialGenericFields
        discount {
          discountType
          discountValue
          discountedPrice
          endDate
          identifier
          startDate
        }
      }
    }
  }
  ${EssentialGenericFieldsFragmentDoc}
`;

/**
 * __useGenericProgramsQuery__
 *
 * To run a query within a React component, call `useGenericProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenericProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenericProgramsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      languages: // value for 'languages'
 *      instructor: // value for 'instructor'
 *      sortBy: // value for 'sortBy'
 *      theme: // value for 'theme'
 *      category: // value for 'category'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGenericProgramsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GenericProgramsQuery,
    GenericProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GenericProgramsQuery, GenericProgramsQueryVariables>(
    GenericProgramsDocument,
    options
  );
}
export function useGenericProgramsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GenericProgramsQuery,
    GenericProgramsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GenericProgramsQuery,
    GenericProgramsQueryVariables
  >(GenericProgramsDocument, options);
}
export type GenericProgramsQueryHookResult = ReturnType<
  typeof useGenericProgramsQuery
>;
export type GenericProgramsLazyQueryHookResult = ReturnType<
  typeof useGenericProgramsLazyQuery
>;
export type GenericProgramsQueryResult = Apollo.QueryResult<
  GenericProgramsQuery,
  GenericProgramsQueryVariables
>;
export function refetchGenericProgramsQuery(
  variables?: GenericProgramsQueryVariables
) {
  return { query: GenericProgramsDocument, variables: variables };
}
export const ProgramThemesDocument = gql`
  query programThemes($category: ProgramCategory) {
    programThemesV2(category: $category) {
      label
      slug
    }
  }
`;

/**
 * __useProgramThemesQuery__
 *
 * To run a query within a React component, call `useProgramThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramThemesQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useProgramThemesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProgramThemesQuery,
    ProgramThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProgramThemesQuery, ProgramThemesQueryVariables>(
    ProgramThemesDocument,
    options
  );
}
export function useProgramThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProgramThemesQuery,
    ProgramThemesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProgramThemesQuery, ProgramThemesQueryVariables>(
    ProgramThemesDocument,
    options
  );
}
export type ProgramThemesQueryHookResult = ReturnType<
  typeof useProgramThemesQuery
>;
export type ProgramThemesLazyQueryHookResult = ReturnType<
  typeof useProgramThemesLazyQuery
>;
export type ProgramThemesQueryResult = Apollo.QueryResult<
  ProgramThemesQuery,
  ProgramThemesQueryVariables
>;
export function refetchProgramThemesQuery(
  variables?: ProgramThemesQueryVariables
) {
  return { query: ProgramThemesDocument, variables: variables };
}
export const SaveProgramProgressDocument = gql`
  mutation saveProgramProgress($id: ID!) {
    saveProgramProgress(programId: $id) {
      errors
    }
  }
`;
export type SaveProgramProgressMutationFn = Apollo.MutationFunction<
  SaveProgramProgressMutation,
  SaveProgramProgressMutationVariables
>;

/**
 * __useSaveProgramProgressMutation__
 *
 * To run a mutation, you first call `useSaveProgramProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProgramProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProgramProgressMutation, { data, loading, error }] = useSaveProgramProgressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaveProgramProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveProgramProgressMutation,
    SaveProgramProgressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveProgramProgressMutation,
    SaveProgramProgressMutationVariables
  >(SaveProgramProgressDocument, options);
}
export type SaveProgramProgressMutationHookResult = ReturnType<
  typeof useSaveProgramProgressMutation
>;
export type SaveProgramProgressMutationResult =
  Apollo.MutationResult<SaveProgramProgressMutation>;
export type SaveProgramProgressMutationOptions = Apollo.BaseMutationOptions<
  SaveProgramProgressMutation,
  SaveProgramProgressMutationVariables
>;
export const SaveGenericProgramProgressDocument = gql`
  mutation saveGenericProgramProgress(
    $videoPartId: ID
    $documentPartId: ID
    $programPartElementId: ID
    $liveEventPartId: ID
  ) {
    savePhasedProgramProgress(
      videoPartId: $videoPartId
      documentPartId: $documentPartId
      programPartElementId: $programPartElementId
      liveEventPartId: $liveEventPartId
    ) {
      errors
    }
  }
`;
export type SaveGenericProgramProgressMutationFn = Apollo.MutationFunction<
  SaveGenericProgramProgressMutation,
  SaveGenericProgramProgressMutationVariables
>;

/**
 * __useSaveGenericProgramProgressMutation__
 *
 * To run a mutation, you first call `useSaveGenericProgramProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGenericProgramProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGenericProgramProgressMutation, { data, loading, error }] = useSaveGenericProgramProgressMutation({
 *   variables: {
 *      videoPartId: // value for 'videoPartId'
 *      documentPartId: // value for 'documentPartId'
 *      programPartElementId: // value for 'programPartElementId'
 *      liveEventPartId: // value for 'liveEventPartId'
 *   },
 * });
 */
export function useSaveGenericProgramProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveGenericProgramProgressMutation,
    SaveGenericProgramProgressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveGenericProgramProgressMutation,
    SaveGenericProgramProgressMutationVariables
  >(SaveGenericProgramProgressDocument, options);
}
export type SaveGenericProgramProgressMutationHookResult = ReturnType<
  typeof useSaveGenericProgramProgressMutation
>;
export type SaveGenericProgramProgressMutationResult =
  Apollo.MutationResult<SaveGenericProgramProgressMutation>;
export type SaveGenericProgramProgressMutationOptions =
  Apollo.BaseMutationOptions<
    SaveGenericProgramProgressMutation,
    SaveGenericProgramProgressMutationVariables
  >;
export const ValidateProgramCodeDocument = gql`
  mutation validateProgramCode($code: String!, $slug: String!) {
    validateProgramCode(code: $code, slug: $slug) {
      code
      codeStatus
      codeType
      codeSource
      error
      price {
        currency
        discountApplied
        discountedPrice
        originalPrice
      }
      program {
        id
        slug
      }
    }
  }
`;
export type ValidateProgramCodeMutationFn = Apollo.MutationFunction<
  ValidateProgramCodeMutation,
  ValidateProgramCodeMutationVariables
>;

/**
 * __useValidateProgramCodeMutation__
 *
 * To run a mutation, you first call `useValidateProgramCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateProgramCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateProgramCodeMutation, { data, loading, error }] = useValidateProgramCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useValidateProgramCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateProgramCodeMutation,
    ValidateProgramCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ValidateProgramCodeMutation,
    ValidateProgramCodeMutationVariables
  >(ValidateProgramCodeDocument, options);
}
export type ValidateProgramCodeMutationHookResult = ReturnType<
  typeof useValidateProgramCodeMutation
>;
export type ValidateProgramCodeMutationResult =
  Apollo.MutationResult<ValidateProgramCodeMutation>;
export type ValidateProgramCodeMutationOptions = Apollo.BaseMutationOptions<
  ValidateProgramCodeMutation,
  ValidateProgramCodeMutationVariables
>;
export const ActivateGenericProgramDocument = gql`
  mutation activateGenericProgram($slug: String!, $code: String!) {
    activateGenericProgram(slug: $slug, accessCode: $code) {
      success
      errors {
        message
      }
    }
  }
`;
export type ActivateGenericProgramMutationFn = Apollo.MutationFunction<
  ActivateGenericProgramMutation,
  ActivateGenericProgramMutationVariables
>;

/**
 * __useActivateGenericProgramMutation__
 *
 * To run a mutation, you first call `useActivateGenericProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateGenericProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateGenericProgramMutation, { data, loading, error }] = useActivateGenericProgramMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useActivateGenericProgramMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateGenericProgramMutation,
    ActivateGenericProgramMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateGenericProgramMutation,
    ActivateGenericProgramMutationVariables
  >(ActivateGenericProgramDocument, options);
}
export type ActivateGenericProgramMutationHookResult = ReturnType<
  typeof useActivateGenericProgramMutation
>;
export type ActivateGenericProgramMutationResult =
  Apollo.MutationResult<ActivateGenericProgramMutation>;
export type ActivateGenericProgramMutationOptions = Apollo.BaseMutationOptions<
  ActivateGenericProgramMutation,
  ActivateGenericProgramMutationVariables
>;
export const GiftGenericProgramDocument = gql`
  query giftGenericProgram($discountCode: String!, $slug: String!) {
    giftGenericProgram(discountCode: $discountCode, slug: $slug) {
      code
      codeStatus
      price {
        currency
        discountApplied
        discountedPrice
        originalPrice
      }
    }
  }
`;

/**
 * __useGiftGenericProgramQuery__
 *
 * To run a query within a React component, call `useGiftGenericProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftGenericProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftGenericProgramQuery({
 *   variables: {
 *      discountCode: // value for 'discountCode'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGiftGenericProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    GiftGenericProgramQuery,
    GiftGenericProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GiftGenericProgramQuery,
    GiftGenericProgramQueryVariables
  >(GiftGenericProgramDocument, options);
}
export function useGiftGenericProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GiftGenericProgramQuery,
    GiftGenericProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GiftGenericProgramQuery,
    GiftGenericProgramQueryVariables
  >(GiftGenericProgramDocument, options);
}
export type GiftGenericProgramQueryHookResult = ReturnType<
  typeof useGiftGenericProgramQuery
>;
export type GiftGenericProgramLazyQueryHookResult = ReturnType<
  typeof useGiftGenericProgramLazyQuery
>;
export type GiftGenericProgramQueryResult = Apollo.QueryResult<
  GiftGenericProgramQuery,
  GiftGenericProgramQueryVariables
>;
export function refetchGiftGenericProgramQuery(
  variables: GiftGenericProgramQueryVariables
) {
  return { query: GiftGenericProgramDocument, variables: variables };
}
export const PlaylistDocument = gql`
  query playlist($id: ID!) {
    playlist(id: $id) {
      ...PlaylistEssentialFields
    }
  }
  ${PlaylistEssentialFieldsFragmentDoc}
`;

/**
 * __usePlaylistQuery__
 *
 * To run a query within a React component, call `usePlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlaylistQuery(
  baseOptions: Apollo.QueryHookOptions<PlaylistQuery, PlaylistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlaylistQuery, PlaylistQueryVariables>(
    PlaylistDocument,
    options
  );
}
export function usePlaylistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlaylistQuery,
    PlaylistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlaylistQuery, PlaylistQueryVariables>(
    PlaylistDocument,
    options
  );
}
export type PlaylistQueryHookResult = ReturnType<typeof usePlaylistQuery>;
export type PlaylistLazyQueryHookResult = ReturnType<
  typeof usePlaylistLazyQuery
>;
export type PlaylistQueryResult = Apollo.QueryResult<
  PlaylistQuery,
  PlaylistQueryVariables
>;
export function refetchPlaylistQuery(variables: PlaylistQueryVariables) {
  return { query: PlaylistDocument, variables: variables };
}
export const VideosDocument = gql`
  query videos(
    $taxonomies: [TaxonomyInput!]
    $page: Int
    $perPage: Int
    $genre: String
    $query: String
    $useLocale: Boolean
    $contentLanguages: [String]
    $excludeTerms: [String]
    $videoLevels: [String]
    $orderBy: VideoOrderBy
  ) {
    videos(
      taxonomies: $taxonomies
      perPage: $perPage
      page: $page
      genre: $genre
      query: $query
      useLocale: $useLocale
      contentLanguages: $contentLanguages
      excludeTerms: $excludeTerms
      videoLevels: $videoLevels
      orderBy: $orderBy
    ) {
      page
      perPage
      totalCount
      data {
        ...VideoFields
      }
    }
  }
  ${VideoFieldsFragmentDoc}
`;

/**
 * __useVideosQuery__
 *
 * To run a query within a React component, call `useVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideosQuery({
 *   variables: {
 *      taxonomies: // value for 'taxonomies'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      genre: // value for 'genre'
 *      query: // value for 'query'
 *      useLocale: // value for 'useLocale'
 *      contentLanguages: // value for 'contentLanguages'
 *      excludeTerms: // value for 'excludeTerms'
 *      videoLevels: // value for 'videoLevels'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<VideosQuery, VideosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VideosQuery, VideosQueryVariables>(
    VideosDocument,
    options
  );
}
export function useVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VideosQuery, VideosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VideosQuery, VideosQueryVariables>(
    VideosDocument,
    options
  );
}
export type VideosQueryHookResult = ReturnType<typeof useVideosQuery>;
export type VideosLazyQueryHookResult = ReturnType<typeof useVideosLazyQuery>;
export type VideosQueryResult = Apollo.QueryResult<
  VideosQuery,
  VideosQueryVariables
>;
export function refetchVideosQuery(variables?: VideosQueryVariables) {
  return { query: VideosDocument, variables: variables };
}
export const ModalInstructorDocument = gql`
  query modalInstructor($id: ID!) {
    instructor(id: $id) {
      id
      slug
      name
      slug
      assets {
        externalImage
      }
      speciality
      location
      webpage
      instructorPage {
        description
      }
    }
  }
`;

/**
 * __useModalInstructorQuery__
 *
 * To run a query within a React component, call `useModalInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalInstructorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModalInstructorQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModalInstructorQuery,
    ModalInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ModalInstructorQuery, ModalInstructorQueryVariables>(
    ModalInstructorDocument,
    options
  );
}
export function useModalInstructorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModalInstructorQuery,
    ModalInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ModalInstructorQuery,
    ModalInstructorQueryVariables
  >(ModalInstructorDocument, options);
}
export type ModalInstructorQueryHookResult = ReturnType<
  typeof useModalInstructorQuery
>;
export type ModalInstructorLazyQueryHookResult = ReturnType<
  typeof useModalInstructorLazyQuery
>;
export type ModalInstructorQueryResult = Apollo.QueryResult<
  ModalInstructorQuery,
  ModalInstructorQueryVariables
>;
export function refetchModalInstructorQuery(
  variables: ModalInstructorQueryVariables
) {
  return { query: ModalInstructorDocument, variables: variables };
}
export const InstructorsDocument = gql`
  query instructors {
    instructors(perPage: 500, page: 1) {
      data {
        ...instructorListingFields
      }
    }
  }
  ${InstructorListingFieldsFragmentDoc}
`;

/**
 * __useInstructorsQuery__
 *
 * To run a query within a React component, call `useInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstructorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstructorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InstructorsQuery,
    InstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstructorsQuery, InstructorsQueryVariables>(
    InstructorsDocument,
    options
  );
}
export function useInstructorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstructorsQuery,
    InstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstructorsQuery, InstructorsQueryVariables>(
    InstructorsDocument,
    options
  );
}
export type InstructorsQueryHookResult = ReturnType<typeof useInstructorsQuery>;
export type InstructorsLazyQueryHookResult = ReturnType<
  typeof useInstructorsLazyQuery
>;
export type InstructorsQueryResult = Apollo.QueryResult<
  InstructorsQuery,
  InstructorsQueryVariables
>;
export function refetchInstructorsQuery(variables?: InstructorsQueryVariables) {
  return { query: InstructorsDocument, variables: variables };
}
export const InstructorDocument = gql`
  query instructor(
    $id: ID!
    $PageBlogPosts: Int
    $PageCourses: Int
    $PageLiveEvents: Int
    $PagePlaylists: Int
    $PagePrograms: Int
    $PageVideos: Int
  ) {
    instructor(id: $id, contentLanguages: []) {
      id
      slug
      name
      shortDescription
      blogDescription
      assets {
        externalImage
      }
      speciality
      location
      webpage
      instructorPage {
        description
        heroShortDescription
        defaultCampaignCode
        favoritedVideos(page: $PageVideos, perPage: 5) {
          page
          totalCount
          data {
            ...VideoFields
          }
        }
        blogPosts(page: $PageBlogPosts, perPage: 5) {
          page
          totalCount
          data {
            id
            title
            featuredImage
            slug
          }
        }
        courses(page: $PageCourses, perPage: 5) {
          page
          totalCount
          data {
            ...EssentialGenericFields
          }
        }
        liveEvents(page: $PageLiveEvents, perPage: 5) {
          page
          totalCount
          data {
            ...LiveEventEssentialFields
          }
        }
        playlists(page: $PagePlaylists, perPage: 5) {
          page
          totalCount
          data {
            ...BasicPlaylistFields
          }
        }
        programs(page: $PagePrograms, perPage: 5) {
          page
          totalCount
          data {
            ...EssentialGenericFields
          }
        }
        videos(page: $PageVideos, perPage: 5) {
          page
          totalCount
          data {
            ...VideoFields
          }
        }
      }
    }
  }
  ${VideoFieldsFragmentDoc}
  ${EssentialGenericFieldsFragmentDoc}
  ${LiveEventEssentialFieldsFragmentDoc}
  ${BasicPlaylistFieldsFragmentDoc}
`;

/**
 * __useInstructorQuery__
 *
 * To run a query within a React component, call `useInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstructorQuery({
 *   variables: {
 *      id: // value for 'id'
 *      PageBlogPosts: // value for 'PageBlogPosts'
 *      PageCourses: // value for 'PageCourses'
 *      PageLiveEvents: // value for 'PageLiveEvents'
 *      PagePlaylists: // value for 'PagePlaylists'
 *      PagePrograms: // value for 'PagePrograms'
 *      PageVideos: // value for 'PageVideos'
 *   },
 * });
 */
export function useInstructorQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstructorQuery,
    InstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstructorQuery, InstructorQueryVariables>(
    InstructorDocument,
    options
  );
}
export function useInstructorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstructorQuery,
    InstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstructorQuery, InstructorQueryVariables>(
    InstructorDocument,
    options
  );
}
export type InstructorQueryHookResult = ReturnType<typeof useInstructorQuery>;
export type InstructorLazyQueryHookResult = ReturnType<
  typeof useInstructorLazyQuery
>;
export type InstructorQueryResult = Apollo.QueryResult<
  InstructorQuery,
  InstructorQueryVariables
>;
export function refetchInstructorQuery(variables: InstructorQueryVariables) {
  return { query: InstructorDocument, variables: variables };
}
export const InstructorDetailsDocument = gql`
  query instructorDetails(
    $id: ID!
    $videosPerPage: Int = 0
    $videosPage: Int = 1
    $favortieVideosPerPage: Int = 0
    $favortieVideosPage: Int = 1
    $playlistsPerPage: Int = 0
    $playlistsPage: Int = 1
    $liveEventsPerPage: Int = 0
    $liveEventsPage: Int = 1
    $programsPerPage: Int = 0
    $programsPage: Int = 1
    $coursesPerPage: Int = 0
    $coursesPage: Int = 1
    $blogPostsPerPage: Int = 0
    $blogPostsPage: Int = 1
  ) {
    instructor(id: $id, contentLanguages: []) {
      id
      slug
      name
      shortDescription
      blogDescription
      assets {
        externalImage
      }
      speciality
      location
      webpage
      instructorPage {
        description
        heroShortDescription
        defaultCampaignCode
        videos(page: $videosPage, perPage: $videosPerPage) {
          page
          totalCount
          data {
            ...VideoFields
          }
        }
        favoritedVideos(
          page: $favortieVideosPage
          perPage: $favortieVideosPerPage
        ) {
          page
          totalCount
          data {
            ...VideoFields
          }
        }
        playlists(page: $playlistsPage, perPage: $playlistsPerPage) {
          page
          totalCount
          data {
            ...BasicPlaylistFields
          }
        }
        liveEvents(page: $liveEventsPage, perPage: $liveEventsPerPage) {
          page
          totalCount
          data {
            ...LiveEventEssentialFields
          }
        }
        programs(page: $programsPage, perPage: $programsPerPage) {
          page
          totalCount
          data {
            ...EssentialGenericFields
          }
        }
        courses(page: $coursesPage, perPage: $coursesPerPage) {
          page
          totalCount
          data {
            ...EssentialGenericFields
          }
        }
        blogPosts(page: $blogPostsPage, perPage: $blogPostsPerPage) {
          page
          totalCount
          data {
            id
            title
            featuredImage
            slug
          }
        }
      }
    }
  }
  ${VideoFieldsFragmentDoc}
  ${BasicPlaylistFieldsFragmentDoc}
  ${LiveEventEssentialFieldsFragmentDoc}
  ${EssentialGenericFieldsFragmentDoc}
`;

/**
 * __useInstructorDetailsQuery__
 *
 * To run a query within a React component, call `useInstructorDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstructorDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstructorDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      videosPerPage: // value for 'videosPerPage'
 *      videosPage: // value for 'videosPage'
 *      favortieVideosPerPage: // value for 'favortieVideosPerPage'
 *      favortieVideosPage: // value for 'favortieVideosPage'
 *      playlistsPerPage: // value for 'playlistsPerPage'
 *      playlistsPage: // value for 'playlistsPage'
 *      liveEventsPerPage: // value for 'liveEventsPerPage'
 *      liveEventsPage: // value for 'liveEventsPage'
 *      programsPerPage: // value for 'programsPerPage'
 *      programsPage: // value for 'programsPage'
 *      coursesPerPage: // value for 'coursesPerPage'
 *      coursesPage: // value for 'coursesPage'
 *      blogPostsPerPage: // value for 'blogPostsPerPage'
 *      blogPostsPage: // value for 'blogPostsPage'
 *   },
 * });
 */
export function useInstructorDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstructorDetailsQuery,
    InstructorDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstructorDetailsQuery,
    InstructorDetailsQueryVariables
  >(InstructorDetailsDocument, options);
}
export function useInstructorDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstructorDetailsQuery,
    InstructorDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstructorDetailsQuery,
    InstructorDetailsQueryVariables
  >(InstructorDetailsDocument, options);
}
export type InstructorDetailsQueryHookResult = ReturnType<
  typeof useInstructorDetailsQuery
>;
export type InstructorDetailsLazyQueryHookResult = ReturnType<
  typeof useInstructorDetailsLazyQuery
>;
export type InstructorDetailsQueryResult = Apollo.QueryResult<
  InstructorDetailsQuery,
  InstructorDetailsQueryVariables
>;
export function refetchInstructorDetailsQuery(
  variables: InstructorDetailsQueryVariables
) {
  return { query: InstructorDetailsDocument, variables: variables };
}
export const LandingPageV2Document = gql`
  query landingPageV2($slug: String!) {
    landingPageV2(slug: $slug) {
      blogSection {
        blog1 {
          id
          featuredImageUrl: featuredImage
          slug
          title
        }
        blog2 {
          id
          featuredImageUrl: featuredImage
          slug
          title
        }
        blog3 {
          id
          featuredImageUrl: featuredImage
          slug
          title
        }
        linkText
        linkUrl
        subtitle
        title
      }
      bundleSection {
        bundle1 {
          ...BundleEssentialFields
        }
        bundle2 {
          ...BundleEssentialFields
        }
        bundle3 {
          ...BundleEssentialFields
        }
        linkText
        linkUrl
        subtitle
        title
      }
      id
      createdAt
      description
      metaDescription
      metaTitle
      name
      path
      slug
      title
      ctaSection1 {
        image {
          altText
          largeImageUrl
          mainImageUrl
          mediumImageUrl
          originalImageUrl
          thumbnailImageUrl
        }
        linkText
        linkUrl
        subtitle
        title
      }
      ctaSection2 {
        image {
          altText
          largeImageUrl
          mainImageUrl
          mediumImageUrl
          originalImageUrl
          thumbnailImageUrl
        }
        linkText
        linkUrl
        subtitle
        title
      }
      qASection {
        answer
        id
        position
        question
      }
      textSection {
        subtitle
        title
        content1
        content2
        content3
        content4
        content5
        image {
          altText
          largeImageUrl
          mainImageUrl
          mediumImageUrl
          originalImageUrl
          thumbnailImageUrl
        }
      }
      metaImage {
        altText
        largeImageUrl
        mainImageUrl
        mediumImageUrl
        originalImageUrl
        thumbnailImageUrl
      }
      trailerImage {
        altText
        largeImageUrl
        mainImageUrl
        mediumImageUrl
        originalImageUrl
        thumbnailImageUrl
      }
      trailerVideo {
        identifier
        minutes
        thumbnailUrl
        hls
        assets {
          thumbnailUrl
        }
        player {
          assets {
            resolution
            src
          }
        }
      }
      updatedAt
      videoSection {
        linkText
        linkUrl
        subtitle
        title
        video1 {
          ...VideoFields
        }
        video2 {
          ...VideoFields
        }
        video3 {
          ...VideoFields
        }
      }
    }
  }
  ${BundleEssentialFieldsFragmentDoc}
  ${VideoFieldsFragmentDoc}
`;

/**
 * __useLandingPageV2Query__
 *
 * To run a query within a React component, call `useLandingPageV2Query` and pass it any options that fit your needs.
 * When your component renders, `useLandingPageV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingPageV2Query({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useLandingPageV2Query(
  baseOptions: Apollo.QueryHookOptions<
    LandingPageV2Query,
    LandingPageV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingPageV2Query, LandingPageV2QueryVariables>(
    LandingPageV2Document,
    options
  );
}
export function useLandingPageV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LandingPageV2Query,
    LandingPageV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingPageV2Query, LandingPageV2QueryVariables>(
    LandingPageV2Document,
    options
  );
}
export type LandingPageV2QueryHookResult = ReturnType<
  typeof useLandingPageV2Query
>;
export type LandingPageV2LazyQueryHookResult = ReturnType<
  typeof useLandingPageV2LazyQuery
>;
export type LandingPageV2QueryResult = Apollo.QueryResult<
  LandingPageV2Query,
  LandingPageV2QueryVariables
>;
export function refetchLandingPageV2Query(
  variables: LandingPageV2QueryVariables
) {
  return { query: LandingPageV2Document, variables: variables };
}
export const LandingPagesDocument = gql`
  query landingPages($path: String!) {
    pageByRoute(path: $path) {
      id
      kind
      name
      internalTitle
      internalLede
      externalTitle
      externalLede
      testimonials {
        name
        title
        quote
        imageUrl
      }
      assets {
        titleVideo {
          id
          kind
          content {
            title
            shortDescription
          }
          assets {
            thumbnailUrl
          }
          hls
          player {
            assets {
              src
              resolution
              size
            }
          }
        }
        ledeVideos {
          id
          identifier
          assets {
            thumbnailUrl
          }
          content {
            title
            shortDescription
          }
        }
      }
      programs {
        id
        path
        internalTitle
        internalLede
        assets {
          titleImage
        }
        name
        slug
      }
      playlists {
        id
        identifier
        languages
        title
        description
        assets {
          thumbnailUrl
        }
        meta {
          minutes
          videoCount
        }
        owner {
          id
          firstName
        }
      }
      videos {
        id
        identifier
        kind
        hls
        player {
          assets {
            src
            resolution
            size
          }
        }
        content {
          title
          shortDescription
        }
        assets {
          thumbnailUrl
        }
        taxonomies {
          flavor {
            id
            name
          }
          genre {
            id
            name
            slug
          }
          instructors {
            id
            name
          }
        }
        meta {
          publishedAt
          minutes
        }
      }
      assets {
        titleImage
        internalImage
        externalImage
      }
      content {
        lede
        subtitle
        body
        body1
        body2
        body3
        body4
        body5
        body6
        englishTitle
        sanskritTitle
        localLanguageTitle
        internalTitle
        internalLede
        externalTitle
        extraImages
      }
      taxonomies {
        instructors {
          slug
          name
          shortDescription
          assets {
            squareImage
          }
        }
      }
    }
  }
`;

/**
 * __useLandingPagesQuery__
 *
 * To run a query within a React component, call `useLandingPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandingPagesQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useLandingPagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LandingPagesQuery,
    LandingPagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LandingPagesQuery, LandingPagesQueryVariables>(
    LandingPagesDocument,
    options
  );
}
export function useLandingPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LandingPagesQuery,
    LandingPagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LandingPagesQuery, LandingPagesQueryVariables>(
    LandingPagesDocument,
    options
  );
}
export type LandingPagesQueryHookResult = ReturnType<
  typeof useLandingPagesQuery
>;
export type LandingPagesLazyQueryHookResult = ReturnType<
  typeof useLandingPagesLazyQuery
>;
export type LandingPagesQueryResult = Apollo.QueryResult<
  LandingPagesQuery,
  LandingPagesQueryVariables
>;
export function refetchLandingPagesQuery(
  variables: LandingPagesQueryVariables
) {
  return { query: LandingPagesDocument, variables: variables };
}
export const LiveEventCodesDocument = gql`
  query liveEventCodes {
    liveEventCodes {
      trial {
        ...LiveCodeDataFields
      }
      wellness {
        ...LiveCodeDataFields
      }
      campaign {
        ...LiveCodeDataFields
      }
    }
  }
  ${LiveCodeDataFieldsFragmentDoc}
`;

/**
 * __useLiveEventCodesQuery__
 *
 * To run a query within a React component, call `useLiveEventCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveEventCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveEventCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLiveEventCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LiveEventCodesQuery,
    LiveEventCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LiveEventCodesQuery, LiveEventCodesQueryVariables>(
    LiveEventCodesDocument,
    options
  );
}
export function useLiveEventCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LiveEventCodesQuery,
    LiveEventCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LiveEventCodesQuery, LiveEventCodesQueryVariables>(
    LiveEventCodesDocument,
    options
  );
}
export type LiveEventCodesQueryHookResult = ReturnType<
  typeof useLiveEventCodesQuery
>;
export type LiveEventCodesLazyQueryHookResult = ReturnType<
  typeof useLiveEventCodesLazyQuery
>;
export type LiveEventCodesQueryResult = Apollo.QueryResult<
  LiveEventCodesQuery,
  LiveEventCodesQueryVariables
>;
export function refetchLiveEventCodesQuery(
  variables?: LiveEventCodesQueryVariables
) {
  return { query: LiveEventCodesDocument, variables: variables };
}
export const ParticipateInAnEventDocument = gql`
  mutation participateInAnEvent($slug: String!) {
    participateInAnEvent(slug: $slug) {
      success
      errors {
        message
      }
    }
  }
`;
export type ParticipateInAnEventMutationFn = Apollo.MutationFunction<
  ParticipateInAnEventMutation,
  ParticipateInAnEventMutationVariables
>;

/**
 * __useParticipateInAnEventMutation__
 *
 * To run a mutation, you first call `useParticipateInAnEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParticipateInAnEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [participateInAnEventMutation, { data, loading, error }] = useParticipateInAnEventMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useParticipateInAnEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ParticipateInAnEventMutation,
    ParticipateInAnEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ParticipateInAnEventMutation,
    ParticipateInAnEventMutationVariables
  >(ParticipateInAnEventDocument, options);
}
export type ParticipateInAnEventMutationHookResult = ReturnType<
  typeof useParticipateInAnEventMutation
>;
export type ParticipateInAnEventMutationResult =
  Apollo.MutationResult<ParticipateInAnEventMutation>;
export type ParticipateInAnEventMutationOptions = Apollo.BaseMutationOptions<
  ParticipateInAnEventMutation,
  ParticipateInAnEventMutationVariables
>;
export const ActivateLiveEventDocument = gql`
  mutation activateLiveEvent($slug: String!, $code: String!) {
    activateLiveEvent(slug: $slug, code: $code) {
      success
      errors
    }
  }
`;
export type ActivateLiveEventMutationFn = Apollo.MutationFunction<
  ActivateLiveEventMutation,
  ActivateLiveEventMutationVariables
>;

/**
 * __useActivateLiveEventMutation__
 *
 * To run a mutation, you first call `useActivateLiveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateLiveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateLiveEventMutation, { data, loading, error }] = useActivateLiveEventMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useActivateLiveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateLiveEventMutation,
    ActivateLiveEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateLiveEventMutation,
    ActivateLiveEventMutationVariables
  >(ActivateLiveEventDocument, options);
}
export type ActivateLiveEventMutationHookResult = ReturnType<
  typeof useActivateLiveEventMutation
>;
export type ActivateLiveEventMutationResult =
  Apollo.MutationResult<ActivateLiveEventMutation>;
export type ActivateLiveEventMutationOptions = Apollo.BaseMutationOptions<
  ActivateLiveEventMutation,
  ActivateLiveEventMutationVariables
>;
export const LiveEventDocument = gql`
  query liveEvent($slug: String!) {
    liveEvent(slug: $slug) {
      ...FullLiveEventFields
    }
  }
  ${FullLiveEventFieldsFragmentDoc}
`;

/**
 * __useLiveEventQuery__
 *
 * To run a query within a React component, call `useLiveEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveEventQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useLiveEventQuery(
  baseOptions: Apollo.QueryHookOptions<LiveEventQuery, LiveEventQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LiveEventQuery, LiveEventQueryVariables>(
    LiveEventDocument,
    options
  );
}
export function useLiveEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LiveEventQuery,
    LiveEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LiveEventQuery, LiveEventQueryVariables>(
    LiveEventDocument,
    options
  );
}
export type LiveEventQueryHookResult = ReturnType<typeof useLiveEventQuery>;
export type LiveEventLazyQueryHookResult = ReturnType<
  typeof useLiveEventLazyQuery
>;
export type LiveEventQueryResult = Apollo.QueryResult<
  LiveEventQuery,
  LiveEventQueryVariables
>;
export function refetchLiveEventQuery(variables: LiveEventQueryVariables) {
  return { query: LiveEventDocument, variables: variables };
}
export const ValidateLiveEventCodeDocument = gql`
  query validateLiveEventCode($slug: String!, $code: String!) {
    validateLiveEventCode(slug: $slug, code: $code) {
      code
      message
      status
    }
  }
`;

/**
 * __useValidateLiveEventCodeQuery__
 *
 * To run a query within a React component, call `useValidateLiveEventCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateLiveEventCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateLiveEventCodeQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateLiveEventCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateLiveEventCodeQuery,
    ValidateLiveEventCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateLiveEventCodeQuery,
    ValidateLiveEventCodeQueryVariables
  >(ValidateLiveEventCodeDocument, options);
}
export function useValidateLiveEventCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateLiveEventCodeQuery,
    ValidateLiveEventCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateLiveEventCodeQuery,
    ValidateLiveEventCodeQueryVariables
  >(ValidateLiveEventCodeDocument, options);
}
export type ValidateLiveEventCodeQueryHookResult = ReturnType<
  typeof useValidateLiveEventCodeQuery
>;
export type ValidateLiveEventCodeLazyQueryHookResult = ReturnType<
  typeof useValidateLiveEventCodeLazyQuery
>;
export type ValidateLiveEventCodeQueryResult = Apollo.QueryResult<
  ValidateLiveEventCodeQuery,
  ValidateLiveEventCodeQueryVariables
>;
export function refetchValidateLiveEventCodeQuery(
  variables: ValidateLiveEventCodeQueryVariables
) {
  return { query: ValidateLiveEventCodeDocument, variables: variables };
}
export const LiveEventsDocument = gql`
  query liveEvents(
    $perPage: Int = 12
    $page: Int
    $search: String
    $languages: [LanguageKind!]
    $instructor: String
    $sortBy: LiveEventSortAttribute
    $kind: LiveEventKind
  ) {
    liveEvents(
      perPage: $perPage
      page: $page
      keyword: $search
      languages: $languages
      instructor: $instructor
      sortBy: $sortBy
      kind: $kind
    ) {
      page
      totalCount
      availableKinds
      data {
        ...LiveEventEssentialFields
      }
    }
  }
  ${LiveEventEssentialFieldsFragmentDoc}
`;

/**
 * __useLiveEventsQuery__
 *
 * To run a query within a React component, call `useLiveEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveEventsQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      languages: // value for 'languages'
 *      instructor: // value for 'instructor'
 *      sortBy: // value for 'sortBy'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useLiveEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LiveEventsQuery,
    LiveEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LiveEventsQuery, LiveEventsQueryVariables>(
    LiveEventsDocument,
    options
  );
}
export function useLiveEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LiveEventsQuery,
    LiveEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LiveEventsQuery, LiveEventsQueryVariables>(
    LiveEventsDocument,
    options
  );
}
export type LiveEventsQueryHookResult = ReturnType<typeof useLiveEventsQuery>;
export type LiveEventsLazyQueryHookResult = ReturnType<
  typeof useLiveEventsLazyQuery
>;
export type LiveEventsQueryResult = Apollo.QueryResult<
  LiveEventsQuery,
  LiveEventsQueryVariables
>;
export function refetchLiveEventsQuery(variables?: LiveEventsQueryVariables) {
  return { query: LiveEventsDocument, variables: variables };
}
export const MarketplaceEventsDocument = gql`
  query marketplaceEvents(
    $category: String
    $archive: Boolean
    $page: Int = 1
    $perPage: Int = 12
  ) {
    marketplaceEvents(
      category: $category
      archive: $archive
      page: $page
      perPage: $perPage
    ) {
      page
      perPage
      totalCount
      data {
        ...marketplaceEventFields
      }
    }
  }
  ${MarketplaceEventFieldsFragmentDoc}
`;

/**
 * __useMarketplaceEventsQuery__
 *
 * To run a query within a React component, call `useMarketplaceEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceEventsQuery({
 *   variables: {
 *      category: // value for 'category'
 *      archive: // value for 'archive'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useMarketplaceEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketplaceEventsQuery,
    MarketplaceEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MarketplaceEventsQuery,
    MarketplaceEventsQueryVariables
  >(MarketplaceEventsDocument, options);
}
export function useMarketplaceEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketplaceEventsQuery,
    MarketplaceEventsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MarketplaceEventsQuery,
    MarketplaceEventsQueryVariables
  >(MarketplaceEventsDocument, options);
}
export type MarketplaceEventsQueryHookResult = ReturnType<
  typeof useMarketplaceEventsQuery
>;
export type MarketplaceEventsLazyQueryHookResult = ReturnType<
  typeof useMarketplaceEventsLazyQuery
>;
export type MarketplaceEventsQueryResult = Apollo.QueryResult<
  MarketplaceEventsQuery,
  MarketplaceEventsQueryVariables
>;
export function refetchMarketplaceEventsQuery(
  variables?: MarketplaceEventsQueryVariables
) {
  return { query: MarketplaceEventsDocument, variables: variables };
}
export const MarketplaceEventDocument = gql`
  query marketplaceEvent($slug: String!) {
    marketplaceEvent(slug: $slug) {
      ...marketplaceEventFields
    }
  }
  ${MarketplaceEventFieldsFragmentDoc}
`;

/**
 * __useMarketplaceEventQuery__
 *
 * To run a query within a React component, call `useMarketplaceEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceEventQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useMarketplaceEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketplaceEventQuery,
    MarketplaceEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MarketplaceEventQuery, MarketplaceEventQueryVariables>(
    MarketplaceEventDocument,
    options
  );
}
export function useMarketplaceEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketplaceEventQuery,
    MarketplaceEventQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MarketplaceEventQuery,
    MarketplaceEventQueryVariables
  >(MarketplaceEventDocument, options);
}
export type MarketplaceEventQueryHookResult = ReturnType<
  typeof useMarketplaceEventQuery
>;
export type MarketplaceEventLazyQueryHookResult = ReturnType<
  typeof useMarketplaceEventLazyQuery
>;
export type MarketplaceEventQueryResult = Apollo.QueryResult<
  MarketplaceEventQuery,
  MarketplaceEventQueryVariables
>;
export function refetchMarketplaceEventQuery(
  variables: MarketplaceEventQueryVariables
) {
  return { query: MarketplaceEventDocument, variables: variables };
}
export const SubmitEventApplicationDocument = gql`
  mutation submitEventApplication(
    $slug: String!
    $name: String!
    $email: String!
    $subject: String!
    $body: String!
  ) {
    submitEventApplication(
      slug: $slug
      name: $name
      email: $email
      subject: $subject
      body: $body
    ) {
      message
      success
    }
  }
`;
export type SubmitEventApplicationMutationFn = Apollo.MutationFunction<
  SubmitEventApplicationMutation,
  SubmitEventApplicationMutationVariables
>;

/**
 * __useSubmitEventApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitEventApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEventApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEventApplicationMutation, { data, loading, error }] = useSubmitEventApplicationMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      subject: // value for 'subject'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useSubmitEventApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitEventApplicationMutation,
    SubmitEventApplicationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitEventApplicationMutation,
    SubmitEventApplicationMutationVariables
  >(SubmitEventApplicationDocument, options);
}
export type SubmitEventApplicationMutationHookResult = ReturnType<
  typeof useSubmitEventApplicationMutation
>;
export type SubmitEventApplicationMutationResult =
  Apollo.MutationResult<SubmitEventApplicationMutation>;
export type SubmitEventApplicationMutationOptions = Apollo.BaseMutationOptions<
  SubmitEventApplicationMutation,
  SubmitEventApplicationMutationVariables
>;
export const OrderDocument = gql`
  query Order($id: String!) {
    order(id: $id) {
      ... on BundlePayment {
        orderId
        state
        status @client
        sourcePage
        amountCents
        amountCurrency
        originalAmountCents
        paymentProvider
        paymentProviderVariant
        success
        bundle {
          id
          title
        }
      }
      ... on GenericProgramPayment {
        orderId
        state
        status @client
        sourcePage
        amountCents
        amountCurrency
        originalAmountCents
        paymentProvider
        paymentProviderVariant
        voucher
        program {
          id
          slug
          title
          category
        }
      }
      ... on LiveEventPayment {
        orderId
        state
        status @client
        sourcePage
        amountCents
        amountCurrency
        originalAmountCents
        paymentProvider
        paymentProviderVariant
        voucher
        live {
          id
          slug
          title
        }
      }
      ... on SubscriptionPayment {
        orderId
        state
        status @client
        sourcePage
        amountCents
        amountCurrency
        months
        originalAmountCents
        recurringAmountCents
        paymentProvider
        paymentProviderVariant
        recurringDate
      }
      ... on AddPayment {
        orderId
        state
        status @client
        sourcePage
        amountCents
        amountCurrency
        paymentProvider
        failedReason
      }
      ... on WellnessPackagePayment {
        ...WellnessPackagePaymentFields
      }
    }
  }
  ${WellnessPackagePaymentFieldsFragmentDoc}
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options
  );
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(
    OrderDocument,
    options
  );
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<
  OrderQuery,
  OrderQueryVariables
>;
export function refetchOrderQuery(variables: OrderQueryVariables) {
  return { query: OrderDocument, variables: variables };
}
export const PlayOverviewDocument = gql`
  query playOverview($page: Int = 1) {
    playOverview(page: $page, perPage: 12) {
      videos {
        page
        perPage
        totalCount
        data {
          ...VideoFields
        }
      }
      playlists {
        ...BasicPlaylistFields
      }
      challenges {
        ...EssentialGenericFields
      }
      programs {
        ...EssentialGenericFields
      }
      liveEvents {
        ...LiveEventEssentialFields
      }
      needs {
        imageUrl
        index
        name
        settings
        settingsV2
        slug
      }
    }
    startOverview {
      genresSection {
        genre {
          title
          slug
          name
          description
          imageUrl
          otherType
          sectionType
        }
      }
      mobileAppSection {
        description
        imageUrl
        title
      }
    }
  }
  ${VideoFieldsFragmentDoc}
  ${BasicPlaylistFieldsFragmentDoc}
  ${EssentialGenericFieldsFragmentDoc}
  ${LiveEventEssentialFieldsFragmentDoc}
`;

/**
 * __usePlayOverviewQuery__
 *
 * To run a query within a React component, call `usePlayOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayOverviewQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePlayOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PlayOverviewQuery,
    PlayOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlayOverviewQuery, PlayOverviewQueryVariables>(
    PlayOverviewDocument,
    options
  );
}
export function usePlayOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlayOverviewQuery,
    PlayOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlayOverviewQuery, PlayOverviewQueryVariables>(
    PlayOverviewDocument,
    options
  );
}
export type PlayOverviewQueryHookResult = ReturnType<
  typeof usePlayOverviewQuery
>;
export type PlayOverviewLazyQueryHookResult = ReturnType<
  typeof usePlayOverviewLazyQuery
>;
export type PlayOverviewQueryResult = Apollo.QueryResult<
  PlayOverviewQuery,
  PlayOverviewQueryVariables
>;
export function refetchPlayOverviewQuery(
  variables?: PlayOverviewQueryVariables
) {
  return { query: PlayOverviewDocument, variables: variables };
}
export const PayForItemDocument = gql`
  mutation payForItem(
    $slug: String!
    $itemKind: ItemKind!
    $paymentMethodId: String
    $paymentMethod: PaymentMethodInput!
    $riskData: RiskDataInput
    $redirectToSuccess: String
    $redirectToFailed: String
    $browserInfo: BrowserInfoInput
    $discountCode: String
    $amountCents: Int
    $sourcePage: String
  ) {
    payForItem(
      slug: $slug
      paymentMethod: $paymentMethod
      riskData: $riskData
      browserInfo: $browserInfo
      itemKind: $itemKind
      discountCode: $discountCode
      redirectToSuccess: $redirectToSuccess
      redirectToFailed: $redirectToFailed
      paymentMethodId: $paymentMethodId
      amountCents: $amountCents
      sourcePage: $sourcePage
    ) {
      orderId
      resultAction {
        authorisationToken
        httpMethod
        paymentData
        paymentMethodType
        redirectData
        qrCodeData
        subtype
        token
        type
        url
      }
      resultCode
      transaction
      errors {
        message
      }
    }
  }
`;
export type PayForItemMutationFn = Apollo.MutationFunction<
  PayForItemMutation,
  PayForItemMutationVariables
>;

/**
 * __usePayForItemMutation__
 *
 * To run a mutation, you first call `usePayForItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayForItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payForItemMutation, { data, loading, error }] = usePayForItemMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      itemKind: // value for 'itemKind'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      paymentMethod: // value for 'paymentMethod'
 *      riskData: // value for 'riskData'
 *      redirectToSuccess: // value for 'redirectToSuccess'
 *      redirectToFailed: // value for 'redirectToFailed'
 *      browserInfo: // value for 'browserInfo'
 *      discountCode: // value for 'discountCode'
 *      amountCents: // value for 'amountCents'
 *      sourcePage: // value for 'sourcePage'
 *   },
 * });
 */
export function usePayForItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayForItemMutation,
    PayForItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PayForItemMutation, PayForItemMutationVariables>(
    PayForItemDocument,
    options
  );
}
export type PayForItemMutationHookResult = ReturnType<
  typeof usePayForItemMutation
>;
export type PayForItemMutationResult =
  Apollo.MutationResult<PayForItemMutation>;
export type PayForItemMutationOptions = Apollo.BaseMutationOptions<
  PayForItemMutation,
  PayForItemMutationVariables
>;
export const PayForVoucherDocument = gql`
  mutation payForVoucher(
    $slug: String!
    $paymentMethod: PaymentMethodInput!
    $paymentMethodId: String
    $itemKind: ItemKind!
    $redirectToSuccess: String
    $redirectToFailed: String
    $riskData: RiskDataInput
    $browserInfo: BrowserInfoInput
    $discountCode: String
    $senderName: String!
    $recipientName: String!
    $recipientEmail: String!
    $sendGiftEmail: Boolean!
    $customMessage: String
    $amountCents: Int
  ) {
    payForVoucher(
      slug: $slug
      paymentMethod: $paymentMethod
      paymentMethodId: $paymentMethodId
      itemKind: $itemKind
      redirectToSuccess: $redirectToSuccess
      redirectToFailed: $redirectToFailed
      riskData: $riskData
      browserInfo: $browserInfo
      discountCode: $discountCode
      senderName: $senderName
      recipientName: $recipientName
      recipientEmail: $recipientEmail
      customMessage: $customMessage
      sendGiftEmail: $sendGiftEmail
      amountCents: $amountCents
    ) {
      orderId
      resultCode
      transaction
      resultAction {
        url
        httpMethod
        paymentMethodType
        paymentData
        qrCodeData
        redirectData
        subtype
        token
        type
        authorisationToken
      }
      errors {
        message
      }
    }
  }
`;
export type PayForVoucherMutationFn = Apollo.MutationFunction<
  PayForVoucherMutation,
  PayForVoucherMutationVariables
>;

/**
 * __usePayForVoucherMutation__
 *
 * To run a mutation, you first call `usePayForVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayForVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payForVoucherMutation, { data, loading, error }] = usePayForVoucherMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      paymentMethod: // value for 'paymentMethod'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      itemKind: // value for 'itemKind'
 *      redirectToSuccess: // value for 'redirectToSuccess'
 *      redirectToFailed: // value for 'redirectToFailed'
 *      riskData: // value for 'riskData'
 *      browserInfo: // value for 'browserInfo'
 *      discountCode: // value for 'discountCode'
 *      senderName: // value for 'senderName'
 *      recipientName: // value for 'recipientName'
 *      recipientEmail: // value for 'recipientEmail'
 *      sendGiftEmail: // value for 'sendGiftEmail'
 *      customMessage: // value for 'customMessage'
 *      amountCents: // value for 'amountCents'
 *   },
 * });
 */
export function usePayForVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayForVoucherMutation,
    PayForVoucherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayForVoucherMutation,
    PayForVoucherMutationVariables
  >(PayForVoucherDocument, options);
}
export type PayForVoucherMutationHookResult = ReturnType<
  typeof usePayForVoucherMutation
>;
export type PayForVoucherMutationResult =
  Apollo.MutationResult<PayForVoucherMutation>;
export type PayForVoucherMutationOptions = Apollo.BaseMutationOptions<
  PayForVoucherMutation,
  PayForVoucherMutationVariables
>;
export const MyPaymentMethodsDocument = gql`
  query myPaymentMethods {
    myPaymentMethods {
      id
      name
      expiryMonth
      expiryYear
      isDefault
      lastFour
      variant
      provider
      status
      fallback
    }
  }
`;

/**
 * __useMyPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useMyPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPaymentMethodsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyPaymentMethodsQuery,
    MyPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyPaymentMethodsQuery, MyPaymentMethodsQueryVariables>(
    MyPaymentMethodsDocument,
    options
  );
}
export function useMyPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyPaymentMethodsQuery,
    MyPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyPaymentMethodsQuery,
    MyPaymentMethodsQueryVariables
  >(MyPaymentMethodsDocument, options);
}
export type MyPaymentMethodsQueryHookResult = ReturnType<
  typeof useMyPaymentMethodsQuery
>;
export type MyPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useMyPaymentMethodsLazyQuery
>;
export type MyPaymentMethodsQueryResult = Apollo.QueryResult<
  MyPaymentMethodsQuery,
  MyPaymentMethodsQueryVariables
>;
export function refetchMyPaymentMethodsQuery(
  variables?: MyPaymentMethodsQueryVariables
) {
  return { query: MyPaymentMethodsDocument, variables: variables };
}
export const AddTrustlyPaymentMethodDocument = gql`
  mutation addTrustlyPaymentMethod($redirectTo: String) {
    addTrustlyPaymentMethod(redirectTo: $redirectTo) {
      errors {
        message
      }
      orderId
      resultUrl
      transaction
    }
  }
`;
export type AddTrustlyPaymentMethodMutationFn = Apollo.MutationFunction<
  AddTrustlyPaymentMethodMutation,
  AddTrustlyPaymentMethodMutationVariables
>;

/**
 * __useAddTrustlyPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddTrustlyPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrustlyPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrustlyPaymentMethodMutation, { data, loading, error }] = useAddTrustlyPaymentMethodMutation({
 *   variables: {
 *      redirectTo: // value for 'redirectTo'
 *   },
 * });
 */
export function useAddTrustlyPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTrustlyPaymentMethodMutation,
    AddTrustlyPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddTrustlyPaymentMethodMutation,
    AddTrustlyPaymentMethodMutationVariables
  >(AddTrustlyPaymentMethodDocument, options);
}
export type AddTrustlyPaymentMethodMutationHookResult = ReturnType<
  typeof useAddTrustlyPaymentMethodMutation
>;
export type AddTrustlyPaymentMethodMutationResult =
  Apollo.MutationResult<AddTrustlyPaymentMethodMutation>;
export type AddTrustlyPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  AddTrustlyPaymentMethodMutation,
  AddTrustlyPaymentMethodMutationVariables
>;
export const AddAdyenPaymentMethodDocument = gql`
  mutation addAdyenPaymentMethod(
    $paymentMethod: PaymentMethodInput!
    $redirectToSuccess: String
    $redirectToFailed: String
    $riskData: RiskDataInput
    $browserInfo: BrowserInfoInput
    $recurringModel: RecurringModelInputKind = CardOnFile
  ) {
    addAdyenPaymentMethod(
      paymentMethod: $paymentMethod
      redirectToSuccess: $redirectToSuccess
      redirectToFailed: $redirectToFailed
      riskData: $riskData
      browserInfo: $browserInfo
      recurringModel: $recurringModel
    ) {
      errors {
        message
      }
      orderId
      newRecord
      resultCode
      transaction
      resultAction {
        url
        httpMethod
        authorisationToken
        paymentMethodType
        paymentData
        redirectData
        subtype
        token
        type
      }
    }
  }
`;
export type AddAdyenPaymentMethodMutationFn = Apollo.MutationFunction<
  AddAdyenPaymentMethodMutation,
  AddAdyenPaymentMethodMutationVariables
>;

/**
 * __useAddAdyenPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAddAdyenPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdyenPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdyenPaymentMethodMutation, { data, loading, error }] = useAddAdyenPaymentMethodMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *      redirectToSuccess: // value for 'redirectToSuccess'
 *      redirectToFailed: // value for 'redirectToFailed'
 *      riskData: // value for 'riskData'
 *      browserInfo: // value for 'browserInfo'
 *      recurringModel: // value for 'recurringModel'
 *   },
 * });
 */
export function useAddAdyenPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAdyenPaymentMethodMutation,
    AddAdyenPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAdyenPaymentMethodMutation,
    AddAdyenPaymentMethodMutationVariables
  >(AddAdyenPaymentMethodDocument, options);
}
export type AddAdyenPaymentMethodMutationHookResult = ReturnType<
  typeof useAddAdyenPaymentMethodMutation
>;
export type AddAdyenPaymentMethodMutationResult =
  Apollo.MutationResult<AddAdyenPaymentMethodMutation>;
export type AddAdyenPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  AddAdyenPaymentMethodMutation,
  AddAdyenPaymentMethodMutationVariables
>;
export const DeletePaymentMethodDocument = gql`
  mutation deletePaymentMethod($id: ID!) {
    deletePaymentMethod(id: $id) {
      errors
      success
    }
  }
`;
export type DeletePaymentMethodMutationFn = Apollo.MutationFunction<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;

/**
 * __useDeletePaymentMethodMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMethodMutation, { data, loading, error }] = useDeletePaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePaymentMethodMutation,
    DeletePaymentMethodMutationVariables
  >(DeletePaymentMethodDocument, options);
}
export type DeletePaymentMethodMutationHookResult = ReturnType<
  typeof useDeletePaymentMethodMutation
>;
export type DeletePaymentMethodMutationResult =
  Apollo.MutationResult<DeletePaymentMethodMutation>;
export type DeletePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentMethodMutation,
  DeletePaymentMethodMutationVariables
>;
export const UpdatePaymentMethodSettingsDocument = gql`
  mutation updatePaymentMethodSettings(
    $id: ID!
    $setAsDefault: Boolean
    $setAsFallback: Boolean
  ) {
    updatePaymentMethodSettings(
      id: $id
      setAsDefault: $setAsDefault
      setAsFallback: $setAsFallback
    ) {
      success
      errors
    }
  }
`;
export type UpdatePaymentMethodSettingsMutationFn = Apollo.MutationFunction<
  UpdatePaymentMethodSettingsMutation,
  UpdatePaymentMethodSettingsMutationVariables
>;

/**
 * __useUpdatePaymentMethodSettingsMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodSettingsMutation, { data, loading, error }] = useUpdatePaymentMethodSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      setAsDefault: // value for 'setAsDefault'
 *      setAsFallback: // value for 'setAsFallback'
 *   },
 * });
 */
export function useUpdatePaymentMethodSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePaymentMethodSettingsMutation,
    UpdatePaymentMethodSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePaymentMethodSettingsMutation,
    UpdatePaymentMethodSettingsMutationVariables
  >(UpdatePaymentMethodSettingsDocument, options);
}
export type UpdatePaymentMethodSettingsMutationHookResult = ReturnType<
  typeof useUpdatePaymentMethodSettingsMutation
>;
export type UpdatePaymentMethodSettingsMutationResult =
  Apollo.MutationResult<UpdatePaymentMethodSettingsMutation>;
export type UpdatePaymentMethodSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdatePaymentMethodSettingsMutation,
    UpdatePaymentMethodSettingsMutationVariables
  >;
export const PhasedProgramDocument = gql`
  query phasedProgram($slug: String!) {
    phasedProgram(slug: $slug) {
      ...PhasedProgramFields
    }
  }
  ${PhasedProgramFieldsFragmentDoc}
`;

/**
 * __usePhasedProgramQuery__
 *
 * To run a query within a React component, call `usePhasedProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhasedProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhasedProgramQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePhasedProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    PhasedProgramQuery,
    PhasedProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhasedProgramQuery, PhasedProgramQueryVariables>(
    PhasedProgramDocument,
    options
  );
}
export function usePhasedProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PhasedProgramQuery,
    PhasedProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhasedProgramQuery, PhasedProgramQueryVariables>(
    PhasedProgramDocument,
    options
  );
}
export type PhasedProgramQueryHookResult = ReturnType<
  typeof usePhasedProgramQuery
>;
export type PhasedProgramLazyQueryHookResult = ReturnType<
  typeof usePhasedProgramLazyQuery
>;
export type PhasedProgramQueryResult = Apollo.QueryResult<
  PhasedProgramQuery,
  PhasedProgramQueryVariables
>;
export function refetchPhasedProgramQuery(
  variables: PhasedProgramQueryVariables
) {
  return { query: PhasedProgramDocument, variables: variables };
}
export const AddFavoritePlaylistDocument = gql`
  mutation addFavoritePlaylist($playlistId: ID!) {
    addFavoritePlaylist(playlistId: $playlistId) {
      playlist {
        favorited
      }
    }
  }
`;
export type AddFavoritePlaylistMutationFn = Apollo.MutationFunction<
  AddFavoritePlaylistMutation,
  AddFavoritePlaylistMutationVariables
>;

/**
 * __useAddFavoritePlaylistMutation__
 *
 * To run a mutation, you first call `useAddFavoritePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoritePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoritePlaylistMutation, { data, loading, error }] = useAddFavoritePlaylistMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function useAddFavoritePlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFavoritePlaylistMutation,
    AddFavoritePlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddFavoritePlaylistMutation,
    AddFavoritePlaylistMutationVariables
  >(AddFavoritePlaylistDocument, options);
}
export type AddFavoritePlaylistMutationHookResult = ReturnType<
  typeof useAddFavoritePlaylistMutation
>;
export type AddFavoritePlaylistMutationResult =
  Apollo.MutationResult<AddFavoritePlaylistMutation>;
export type AddFavoritePlaylistMutationOptions = Apollo.BaseMutationOptions<
  AddFavoritePlaylistMutation,
  AddFavoritePlaylistMutationVariables
>;
export const DeleteFavoritePlaylistDocument = gql`
  mutation deleteFavoritePlaylist($playlistId: ID!) {
    deleteFavoritePlaylist(playlistId: $playlistId) {
      playlist {
        favorited
      }
    }
  }
`;
export type DeleteFavoritePlaylistMutationFn = Apollo.MutationFunction<
  DeleteFavoritePlaylistMutation,
  DeleteFavoritePlaylistMutationVariables
>;

/**
 * __useDeleteFavoritePlaylistMutation__
 *
 * To run a mutation, you first call `useDeleteFavoritePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoritePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoritePlaylistMutation, { data, loading, error }] = useDeleteFavoritePlaylistMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function useDeleteFavoritePlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFavoritePlaylistMutation,
    DeleteFavoritePlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFavoritePlaylistMutation,
    DeleteFavoritePlaylistMutationVariables
  >(DeleteFavoritePlaylistDocument, options);
}
export type DeleteFavoritePlaylistMutationHookResult = ReturnType<
  typeof useDeleteFavoritePlaylistMutation
>;
export type DeleteFavoritePlaylistMutationResult =
  Apollo.MutationResult<DeleteFavoritePlaylistMutation>;
export type DeleteFavoritePlaylistMutationOptions = Apollo.BaseMutationOptions<
  DeleteFavoritePlaylistMutation,
  DeleteFavoritePlaylistMutationVariables
>;
export const PricingPageDocument = gql`
  query pricingPage {
    pricingPage {
      title
      description
      buttonLink
      buttonName
      image
    }
  }
`;

/**
 * __usePricingPageQuery__
 *
 * To run a query within a React component, call `usePricingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingPageQuery({
 *   variables: {
 *   },
 * });
 */
export function usePricingPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PricingPageQuery,
    PricingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PricingPageQuery, PricingPageQueryVariables>(
    PricingPageDocument,
    options
  );
}
export function usePricingPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PricingPageQuery,
    PricingPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PricingPageQuery, PricingPageQueryVariables>(
    PricingPageDocument,
    options
  );
}
export type PricingPageQueryHookResult = ReturnType<typeof usePricingPageQuery>;
export type PricingPageLazyQueryHookResult = ReturnType<
  typeof usePricingPageLazyQuery
>;
export type PricingPageQueryResult = Apollo.QueryResult<
  PricingPageQuery,
  PricingPageQueryVariables
>;
export function refetchPricingPageQuery(variables?: PricingPageQueryVariables) {
  return { query: PricingPageDocument, variables: variables };
}
export const RelatedVideosDocument = gql`
  query relatedVideos($id: ID!) {
    relatedVideos(id: $id) {
      id
      identifier
      recommended
      content {
        title
        shortDescription
      }
      cursorAt
      assets {
        thumbnailUrlMedium
      }
      meta {
        minutes
      }
    }
  }
`;

/**
 * __useRelatedVideosQuery__
 *
 * To run a query within a React component, call `useRelatedVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedVideosQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRelatedVideosQuery(
  baseOptions: Apollo.QueryHookOptions<
    RelatedVideosQuery,
    RelatedVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RelatedVideosQuery, RelatedVideosQueryVariables>(
    RelatedVideosDocument,
    options
  );
}
export function useRelatedVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RelatedVideosQuery,
    RelatedVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RelatedVideosQuery, RelatedVideosQueryVariables>(
    RelatedVideosDocument,
    options
  );
}
export type RelatedVideosQueryHookResult = ReturnType<
  typeof useRelatedVideosQuery
>;
export type RelatedVideosLazyQueryHookResult = ReturnType<
  typeof useRelatedVideosLazyQuery
>;
export type RelatedVideosQueryResult = Apollo.QueryResult<
  RelatedVideosQuery,
  RelatedVideosQueryVariables
>;
export function refetchRelatedVideosQuery(
  variables: RelatedVideosQueryVariables
) {
  return { query: RelatedVideosDocument, variables: variables };
}
export const AutocompleteDocument = gql`
  query autocomplete($query: String!) {
    autocomplete(query: $query) {
      query
      error
      results
    }
  }
`;

/**
 * __useAutocompleteQuery__
 *
 * To run a query within a React component, call `useAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocompleteQuery,
    AutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AutocompleteQuery, AutocompleteQueryVariables>(
    AutocompleteDocument,
    options
  );
}
export function useAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocompleteQuery,
    AutocompleteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AutocompleteQuery, AutocompleteQueryVariables>(
    AutocompleteDocument,
    options
  );
}
export type AutocompleteQueryHookResult = ReturnType<
  typeof useAutocompleteQuery
>;
export type AutocompleteLazyQueryHookResult = ReturnType<
  typeof useAutocompleteLazyQuery
>;
export type AutocompleteQueryResult = Apollo.QueryResult<
  AutocompleteQuery,
  AutocompleteQueryVariables
>;
export function refetchAutocompleteQuery(
  variables: AutocompleteQueryVariables
) {
  return { query: AutocompleteDocument, variables: variables };
}
export const SpecificSearchDocument = gql`
  query specificSearch(
    $query: String!
    $page: Int = 1
    $perPage: Int = 10
    $modelType: ModelNameKind!
    $languages: [LanguageKind!]!
  ) {
    specificSearch(
      query: $query
      page: $page
      perPage: $perPage
      modelType: $modelType
      languages: $languages
    ) {
      query
      blogPosts {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchBlogPost {
            ...SearchBlogPostFields
          }
        }
      }
      challenges {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      events {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchEvent {
            ...SearchEventFields
          }
        }
      }
      genericPrograms {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      challenges {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      courses {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      instructors {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchInstructor {
            ...SearchInstructorFields
          }
        }
      }
      landingPages {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchLandingPage {
            ...SearchLandingPageFields
          }
        }
      }
      liveEvents {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchLiveEvent {
            ...SearchLiveEventFields
          }
        }
      }
      playlists {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchPlaylist {
            ...SearchPlaylistFields
          }
        }
      }
      videos {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchVideo {
            ...SearchVideoFields
          }
        }
      }
    }
  }
  ${SearchBlogPostFieldsFragmentDoc}
  ${SearchGenericProgramFieldsFragmentDoc}
  ${SearchEventFieldsFragmentDoc}
  ${SearchInstructorFieldsFragmentDoc}
  ${SearchLandingPageFieldsFragmentDoc}
  ${SearchLiveEventFieldsFragmentDoc}
  ${SearchPlaylistFieldsFragmentDoc}
  ${SearchVideoFieldsFragmentDoc}
`;

/**
 * __useSpecificSearchQuery__
 *
 * To run a query within a React component, call `useSpecificSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      modelType: // value for 'modelType'
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useSpecificSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    SpecificSearchQuery,
    SpecificSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SpecificSearchQuery, SpecificSearchQueryVariables>(
    SpecificSearchDocument,
    options
  );
}
export function useSpecificSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SpecificSearchQuery,
    SpecificSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SpecificSearchQuery, SpecificSearchQueryVariables>(
    SpecificSearchDocument,
    options
  );
}
export type SpecificSearchQueryHookResult = ReturnType<
  typeof useSpecificSearchQuery
>;
export type SpecificSearchLazyQueryHookResult = ReturnType<
  typeof useSpecificSearchLazyQuery
>;
export type SpecificSearchQueryResult = Apollo.QueryResult<
  SpecificSearchQuery,
  SpecificSearchQueryVariables
>;
export function refetchSpecificSearchQuery(
  variables: SpecificSearchQueryVariables
) {
  return { query: SpecificSearchDocument, variables: variables };
}
export const SearchDocument = gql`
  query Search(
    $query: String!
    $searchType: GlobalSearch!
    $languages: [LanguageKind!]!
  ) {
    search(query: $query, searchType: $searchType, languages: $languages) {
      query
      blogPosts {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchBlogPost {
            ...SearchBlogPostFields
          }
        }
      }
      challenges {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      events {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchEvent {
            ...SearchEventFields
          }
        }
      }
      genericPrograms {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      challenges {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      courses {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchGenericProgram {
            ...SearchGenericProgramFields
          }
        }
      }
      instructors {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchInstructor {
            ...SearchInstructorFields
          }
        }
      }
      landingPages {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchLandingPage {
            ...SearchLandingPageFields
          }
        }
      }
      liveEvents {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchLiveEvent {
            ...SearchLiveEventFields
          }
        }
      }
      playlists {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchPlaylist {
            ...SearchPlaylistFields
          }
        }
      }
      videos {
        page
        perPage
        totalCount
        results {
          __typename
          ... on SearchVideo {
            ...SearchVideoFields
          }
        }
      }
    }
  }
  ${SearchBlogPostFieldsFragmentDoc}
  ${SearchGenericProgramFieldsFragmentDoc}
  ${SearchEventFieldsFragmentDoc}
  ${SearchInstructorFieldsFragmentDoc}
  ${SearchLandingPageFieldsFragmentDoc}
  ${SearchLiveEventFieldsFragmentDoc}
  ${SearchPlaylistFieldsFragmentDoc}
  ${SearchVideoFieldsFragmentDoc}
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      searchType: // value for 'searchType'
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}
export function useSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(
    SearchDocument,
    options
  );
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<
  SearchQuery,
  SearchQueryVariables
>;
export function refetchSearchQuery(variables: SearchQueryVariables) {
  return { query: SearchDocument, variables: variables };
}
export const AddSearchResultDocument = gql`
  mutation addSearchResult(
    $modelType: ModelNameKind!
    $documentId: String!
    $query: String!
  ) {
    addSearchResult(
      modelType: $modelType
      documentId: $documentId
      query: $query
    ) {
      documentId
    }
  }
`;
export type AddSearchResultMutationFn = Apollo.MutationFunction<
  AddSearchResultMutation,
  AddSearchResultMutationVariables
>;

/**
 * __useAddSearchResultMutation__
 *
 * To run a mutation, you first call `useAddSearchResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSearchResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSearchResultMutation, { data, loading, error }] = useAddSearchResultMutation({
 *   variables: {
 *      modelType: // value for 'modelType'
 *      documentId: // value for 'documentId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddSearchResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSearchResultMutation,
    AddSearchResultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSearchResultMutation,
    AddSearchResultMutationVariables
  >(AddSearchResultDocument, options);
}
export type AddSearchResultMutationHookResult = ReturnType<
  typeof useAddSearchResultMutation
>;
export type AddSearchResultMutationResult =
  Apollo.MutationResult<AddSearchResultMutation>;
export type AddSearchResultMutationOptions = Apollo.BaseMutationOptions<
  AddSearchResultMutation,
  AddSearchResultMutationVariables
>;
export const SignUpDocument = gql`
  mutation signUp(
    $email: String!
    $password: String!
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $paymentType: String
    $campaignCode: String
    $registrationSource: RegistrationSourceKind
    $registrationSourceId: String
    $funnel: FunnelKind
  ) {
    signUpV2(
      email: $email
      password: $password
      paymentType: $paymentType
      campaignCode: $campaignCode
      utmSource: $utmSource
      utmMedium: $utmMedium
      utmCampaign: $utmCampaign
      registrationSource: $registrationSource
      registrationSourceId: $registrationSourceId
      funnel: $funnel
      platform: web
    ) {
      user {
        id
        email
        gender
        country
        accessUntil
        funnel
        trialRequired
      }
      errors {
        message
      }
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      utmSource: // value for 'utmSource'
 *      utmMedium: // value for 'utmMedium'
 *      utmCampaign: // value for 'utmCampaign'
 *      paymentType: // value for 'paymentType'
 *      campaignCode: // value for 'campaignCode'
 *      registrationSource: // value for 'registrationSource'
 *      registrationSourceId: // value for 'registrationSourceId'
 *      funnel: // value for 'funnel'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const SocialSignUpDocument = gql`
  mutation socialSignUp(
    $provider: ProviderTypes!
    $method: MethodTypes!
    $code: String
    $accessToken: String
    $identityToken: String
    $firstName: String
    $lastName: String
    $avatarFromUrl: String
    $paymentType: String
    $campaignCode: String
    $utmSource: String
    $utmMedium: String
    $utmCampaign: String
    $registrationSource: RegistrationSourceKind
    $registrationSourceId: String
  ) {
    socialSignUp(
      provider: $provider
      method: $method
      code: $code
      accessToken: $accessToken
      identityToken: $identityToken
      firstName: $firstName
      lastName: $lastName
      avatarFromUrl: $avatarFromUrl
      paymentType: $paymentType
      campaignCode: $campaignCode
      utmSource: $utmSource
      utmMedium: $utmMedium
      utmCampaign: $utmCampaign
      registrationSource: $registrationSource
      registrationSourceId: $registrationSourceId
      platform: web
    ) {
      success
      user {
        id
      }
    }
  }
`;
export type SocialSignUpMutationFn = Apollo.MutationFunction<
  SocialSignUpMutation,
  SocialSignUpMutationVariables
>;

/**
 * __useSocialSignUpMutation__
 *
 * To run a mutation, you first call `useSocialSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialSignUpMutation, { data, loading, error }] = useSocialSignUpMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      method: // value for 'method'
 *      code: // value for 'code'
 *      accessToken: // value for 'accessToken'
 *      identityToken: // value for 'identityToken'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      avatarFromUrl: // value for 'avatarFromUrl'
 *      paymentType: // value for 'paymentType'
 *      campaignCode: // value for 'campaignCode'
 *      utmSource: // value for 'utmSource'
 *      utmMedium: // value for 'utmMedium'
 *      utmCampaign: // value for 'utmCampaign'
 *      registrationSource: // value for 'registrationSource'
 *      registrationSourceId: // value for 'registrationSourceId'
 *   },
 * });
 */
export function useSocialSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SocialSignUpMutation,
    SocialSignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SocialSignUpMutation,
    SocialSignUpMutationVariables
  >(SocialSignUpDocument, options);
}
export type SocialSignUpMutationHookResult = ReturnType<
  typeof useSocialSignUpMutation
>;
export type SocialSignUpMutationResult =
  Apollo.MutationResult<SocialSignUpMutation>;
export type SocialSignUpMutationOptions = Apollo.BaseMutationOptions<
  SocialSignUpMutation,
  SocialSignUpMutationVariables
>;
export const SocialSignInDocument = gql`
  mutation socialSignIn(
    $provider: ProviderTypes!
    $method: MethodTypes!
    $code: String
    $accessToken: String
    $identityToken: String
  ) {
    socialSignIn(
      provider: $provider
      method: $method
      code: $code
      accessToken: $accessToken
      identityToken: $identityToken
      platform: web
    ) {
      success
      user {
        id
      }
    }
  }
`;
export type SocialSignInMutationFn = Apollo.MutationFunction<
  SocialSignInMutation,
  SocialSignInMutationVariables
>;

/**
 * __useSocialSignInMutation__
 *
 * To run a mutation, you first call `useSocialSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialSignInMutation, { data, loading, error }] = useSocialSignInMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      method: // value for 'method'
 *      code: // value for 'code'
 *      accessToken: // value for 'accessToken'
 *      identityToken: // value for 'identityToken'
 *   },
 * });
 */
export function useSocialSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SocialSignInMutation,
    SocialSignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SocialSignInMutation,
    SocialSignInMutationVariables
  >(SocialSignInDocument, options);
}
export type SocialSignInMutationHookResult = ReturnType<
  typeof useSocialSignInMutation
>;
export type SocialSignInMutationResult =
  Apollo.MutationResult<SocialSignInMutation>;
export type SocialSignInMutationOptions = Apollo.BaseMutationOptions<
  SocialSignInMutation,
  SocialSignInMutationVariables
>;
export const RegisterSourceLiveDocument = gql`
  query registerSourceLive($slug: String!) {
    liveEvent(slug: $slug) {
      id
      slug
      paymentKind
      price {
        discountedPrice
        originalPrice
      }
    }
  }
`;

/**
 * __useRegisterSourceLiveQuery__
 *
 * To run a query within a React component, call `useRegisterSourceLiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterSourceLiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterSourceLiveQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRegisterSourceLiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisterSourceLiveQuery,
    RegisterSourceLiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegisterSourceLiveQuery,
    RegisterSourceLiveQueryVariables
  >(RegisterSourceLiveDocument, options);
}
export function useRegisterSourceLiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisterSourceLiveQuery,
    RegisterSourceLiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegisterSourceLiveQuery,
    RegisterSourceLiveQueryVariables
  >(RegisterSourceLiveDocument, options);
}
export type RegisterSourceLiveQueryHookResult = ReturnType<
  typeof useRegisterSourceLiveQuery
>;
export type RegisterSourceLiveLazyQueryHookResult = ReturnType<
  typeof useRegisterSourceLiveLazyQuery
>;
export type RegisterSourceLiveQueryResult = Apollo.QueryResult<
  RegisterSourceLiveQuery,
  RegisterSourceLiveQueryVariables
>;
export function refetchRegisterSourceLiveQuery(
  variables: RegisterSourceLiveQueryVariables
) {
  return { query: RegisterSourceLiveDocument, variables: variables };
}
export const RegisterSourceGenericProgramDocument = gql`
  query registerSourceGenericProgram($slug: String!) {
    genericProgram(slug: $slug) {
      id
      slug
      category
    }
  }
`;

/**
 * __useRegisterSourceGenericProgramQuery__
 *
 * To run a query within a React component, call `useRegisterSourceGenericProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterSourceGenericProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterSourceGenericProgramQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRegisterSourceGenericProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisterSourceGenericProgramQuery,
    RegisterSourceGenericProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegisterSourceGenericProgramQuery,
    RegisterSourceGenericProgramQueryVariables
  >(RegisterSourceGenericProgramDocument, options);
}
export function useRegisterSourceGenericProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisterSourceGenericProgramQuery,
    RegisterSourceGenericProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegisterSourceGenericProgramQuery,
    RegisterSourceGenericProgramQueryVariables
  >(RegisterSourceGenericProgramDocument, options);
}
export type RegisterSourceGenericProgramQueryHookResult = ReturnType<
  typeof useRegisterSourceGenericProgramQuery
>;
export type RegisterSourceGenericProgramLazyQueryHookResult = ReturnType<
  typeof useRegisterSourceGenericProgramLazyQuery
>;
export type RegisterSourceGenericProgramQueryResult = Apollo.QueryResult<
  RegisterSourceGenericProgramQuery,
  RegisterSourceGenericProgramQueryVariables
>;
export function refetchRegisterSourceGenericProgramQuery(
  variables: RegisterSourceGenericProgramQueryVariables
) {
  return { query: RegisterSourceGenericProgramDocument, variables: variables };
}
export const RegisterSourceInstructorDocument = gql`
  query registerSourceInstructor($slug: ID!) {
    instructor(id: $slug, contentLanguages: []) {
      id
      slug
    }
  }
`;

/**
 * __useRegisterSourceInstructorQuery__
 *
 * To run a query within a React component, call `useRegisterSourceInstructorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterSourceInstructorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterSourceInstructorQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRegisterSourceInstructorQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisterSourceInstructorQuery,
    RegisterSourceInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegisterSourceInstructorQuery,
    RegisterSourceInstructorQueryVariables
  >(RegisterSourceInstructorDocument, options);
}
export function useRegisterSourceInstructorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisterSourceInstructorQuery,
    RegisterSourceInstructorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegisterSourceInstructorQuery,
    RegisterSourceInstructorQueryVariables
  >(RegisterSourceInstructorDocument, options);
}
export type RegisterSourceInstructorQueryHookResult = ReturnType<
  typeof useRegisterSourceInstructorQuery
>;
export type RegisterSourceInstructorLazyQueryHookResult = ReturnType<
  typeof useRegisterSourceInstructorLazyQuery
>;
export type RegisterSourceInstructorQueryResult = Apollo.QueryResult<
  RegisterSourceInstructorQuery,
  RegisterSourceInstructorQueryVariables
>;
export function refetchRegisterSourceInstructorQuery(
  variables: RegisterSourceInstructorQueryVariables
) {
  return { query: RegisterSourceInstructorDocument, variables: variables };
}
export const RegisterSourceBundleDocument = gql`
  query registerSourceBundle($slug: String!, $client: String!) {
    bundle(slug: $slug, client: $client) {
      id
      bundleCategory
    }
  }
`;

/**
 * __useRegisterSourceBundleQuery__
 *
 * To run a query within a React component, call `useRegisterSourceBundleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterSourceBundleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterSourceBundleQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      client: // value for 'client'
 *   },
 * });
 */
export function useRegisterSourceBundleQuery(
  baseOptions: Apollo.QueryHookOptions<
    RegisterSourceBundleQuery,
    RegisterSourceBundleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RegisterSourceBundleQuery,
    RegisterSourceBundleQueryVariables
  >(RegisterSourceBundleDocument, options);
}
export function useRegisterSourceBundleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegisterSourceBundleQuery,
    RegisterSourceBundleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegisterSourceBundleQuery,
    RegisterSourceBundleQueryVariables
  >(RegisterSourceBundleDocument, options);
}
export type RegisterSourceBundleQueryHookResult = ReturnType<
  typeof useRegisterSourceBundleQuery
>;
export type RegisterSourceBundleLazyQueryHookResult = ReturnType<
  typeof useRegisterSourceBundleLazyQuery
>;
export type RegisterSourceBundleQueryResult = Apollo.QueryResult<
  RegisterSourceBundleQuery,
  RegisterSourceBundleQueryVariables
>;
export function refetchRegisterSourceBundleQuery(
  variables: RegisterSourceBundleQueryVariables
) {
  return { query: RegisterSourceBundleDocument, variables: variables };
}
export const StartOverviewDocument = gql`
  query startOverview {
    startOverview {
      metaSection {
        description
        imageUrl
        title
      }
      heroSection {
        title
        description
        smallNote
        imageUrl
        mobileImageUrl
      }
      coursesSection {
        title
        description
        group1Title
        group1Description
        group1ButtonLink
        group1ButtonText
        group1ImageUrl
        group2Title
        group2Description
        group2ButtonLink
        group2ButtonText
        group2ImageUrl
      }
      campaignSection {
        title
        description
        buttonLink
        buttonText
        imageUrl
        tag
      }
      campaignSection2 {
        title
        description
        buttonLink
        buttonText
        imageUrl
        tag
      }
      campaignSection3 {
        title
        description
        buttonLink
        buttonText
        imageUrl
        tag
      }
      featuresSection {
        description
        featureBullets
        imageUrl
        title
      }
      genresSection {
        title
        description
        genre {
          title
          slug
          name
          description
          imageUrl
          otherType
          sectionType
        }
      }
      mobileAppSection {
        description
        imageUrl
        title
      }
      otherFeaturesSection {
        description
        title
        otherFeatures {
          description
          imageUrl
          name
          url
          otherType
          sectionType
          slug
          title
        }
      }
      videoLibrarySection {
        description
        title
        videosLibrary {
          slug
          title
          name
          description
          url
          imageUrl
          otherType
          sectionType
        }
      }
      highlightedBundles {
        ...BundleEssentialFields
      }
    }
  }
  ${BundleEssentialFieldsFragmentDoc}
`;

/**
 * __useStartOverviewQuery__
 *
 * To run a query within a React component, call `useStartOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useStartOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StartOverviewQuery,
    StartOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StartOverviewQuery, StartOverviewQueryVariables>(
    StartOverviewDocument,
    options
  );
}
export function useStartOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StartOverviewQuery,
    StartOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StartOverviewQuery, StartOverviewQueryVariables>(
    StartOverviewDocument,
    options
  );
}
export type StartOverviewQueryHookResult = ReturnType<
  typeof useStartOverviewQuery
>;
export type StartOverviewLazyQueryHookResult = ReturnType<
  typeof useStartOverviewLazyQuery
>;
export type StartOverviewQueryResult = Apollo.QueryResult<
  StartOverviewQuery,
  StartOverviewQueryVariables
>;
export function refetchStartOverviewQuery(
  variables?: StartOverviewQueryVariables
) {
  return { query: StartOverviewDocument, variables: variables };
}
export const VideoWatchDocument = gql`
  mutation videoWatch($videoId: String!, $seconds: Int!, $cursorAt: Int!) {
    videoWatch(videoId: $videoId, seconds: $seconds, cursorAt: $cursorAt) {
      errors {
        message
      }
    }
  }
`;
export type VideoWatchMutationFn = Apollo.MutationFunction<
  VideoWatchMutation,
  VideoWatchMutationVariables
>;

/**
 * __useVideoWatchMutation__
 *
 * To run a mutation, you first call `useVideoWatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoWatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoWatchMutation, { data, loading, error }] = useVideoWatchMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      seconds: // value for 'seconds'
 *      cursorAt: // value for 'cursorAt'
 *   },
 * });
 */
export function useVideoWatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideoWatchMutation,
    VideoWatchMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VideoWatchMutation, VideoWatchMutationVariables>(
    VideoWatchDocument,
    options
  );
}
export type VideoWatchMutationHookResult = ReturnType<
  typeof useVideoWatchMutation
>;
export type VideoWatchMutationResult =
  Apollo.MutationResult<VideoWatchMutation>;
export type VideoWatchMutationOptions = Apollo.BaseMutationOptions<
  VideoWatchMutation,
  VideoWatchMutationVariables
>;
export const VideoWatchCompletedDocument = gql`
  mutation videoWatchCompleted(
    $videoId: String!
    $seconds: Int!
    $cursorAt: Int!
  ) {
    videoWatchCompleted(
      videoId: $videoId
      seconds: $seconds
      cursorAt: $cursorAt
    ) {
      errors {
        message
      }
    }
  }
`;
export type VideoWatchCompletedMutationFn = Apollo.MutationFunction<
  VideoWatchCompletedMutation,
  VideoWatchCompletedMutationVariables
>;

/**
 * __useVideoWatchCompletedMutation__
 *
 * To run a mutation, you first call `useVideoWatchCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoWatchCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoWatchCompletedMutation, { data, loading, error }] = useVideoWatchCompletedMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      seconds: // value for 'seconds'
 *      cursorAt: // value for 'cursorAt'
 *   },
 * });
 */
export function useVideoWatchCompletedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideoWatchCompletedMutation,
    VideoWatchCompletedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VideoWatchCompletedMutation,
    VideoWatchCompletedMutationVariables
  >(VideoWatchCompletedDocument, options);
}
export type VideoWatchCompletedMutationHookResult = ReturnType<
  typeof useVideoWatchCompletedMutation
>;
export type VideoWatchCompletedMutationResult =
  Apollo.MutationResult<VideoWatchCompletedMutation>;
export type VideoWatchCompletedMutationOptions = Apollo.BaseMutationOptions<
  VideoWatchCompletedMutation,
  VideoWatchCompletedMutationVariables
>;
export const UserStatsDocument = gql`
  query userStats($period: StatsPeriodKind) {
    userStats(period: $period) {
      numberOfChallengesStarted
      numberOfCoursesStarted
      numberOfLiveEventsBooked
      numberOfProgramsStarted
      playlistsCreated
      sessionsCompleted
      sessionsFavorited
      threeMostWatchedInstructors {
        id
        name
        assets {
          squareImage
        }
      }
      threeMostWatchedStyles {
        slug
        name
      }
      totalWatchTime
    }
  }
`;

/**
 * __useUserStatsQuery__
 *
 * To run a query within a React component, call `useUserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatsQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useUserStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserStatsQuery, UserStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserStatsQuery, UserStatsQueryVariables>(
    UserStatsDocument,
    options
  );
}
export function useUserStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserStatsQuery,
    UserStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserStatsQuery, UserStatsQueryVariables>(
    UserStatsDocument,
    options
  );
}
export type UserStatsQueryHookResult = ReturnType<typeof useUserStatsQuery>;
export type UserStatsLazyQueryHookResult = ReturnType<
  typeof useUserStatsLazyQuery
>;
export type UserStatsQueryResult = Apollo.QueryResult<
  UserStatsQuery,
  UserStatsQueryVariables
>;
export function refetchUserStatsQuery(variables?: UserStatsQueryVariables) {
  return { query: UserStatsDocument, variables: variables };
}
export const ActivateFullAccessCodeDocument = gql`
  mutation activateFullAccessCode($promotionName: String!, $code: String!) {
    activateFullAccessCode(promotionName: $promotionName, code: $code) {
      success
      errors {
        message
      }
    }
  }
`;
export type ActivateFullAccessCodeMutationFn = Apollo.MutationFunction<
  ActivateFullAccessCodeMutation,
  ActivateFullAccessCodeMutationVariables
>;

/**
 * __useActivateFullAccessCodeMutation__
 *
 * To run a mutation, you first call `useActivateFullAccessCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateFullAccessCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateFullAccessCodeMutation, { data, loading, error }] = useActivateFullAccessCodeMutation({
 *   variables: {
 *      promotionName: // value for 'promotionName'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useActivateFullAccessCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateFullAccessCodeMutation,
    ActivateFullAccessCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateFullAccessCodeMutation,
    ActivateFullAccessCodeMutationVariables
  >(ActivateFullAccessCodeDocument, options);
}
export type ActivateFullAccessCodeMutationHookResult = ReturnType<
  typeof useActivateFullAccessCodeMutation
>;
export type ActivateFullAccessCodeMutationResult =
  Apollo.MutationResult<ActivateFullAccessCodeMutation>;
export type ActivateFullAccessCodeMutationOptions = Apollo.BaseMutationOptions<
  ActivateFullAccessCodeMutation,
  ActivateFullAccessCodeMutationVariables
>;
export const ActivateOneTimeCodeDocument = gql`
  mutation activateOneTimeCode($promotionName: String!, $code: String!) {
    activateOneTimeCode(promotionName: $promotionName, code: $code) {
      success
      errors {
        message
      }
    }
  }
`;
export type ActivateOneTimeCodeMutationFn = Apollo.MutationFunction<
  ActivateOneTimeCodeMutation,
  ActivateOneTimeCodeMutationVariables
>;

/**
 * __useActivateOneTimeCodeMutation__
 *
 * To run a mutation, you first call `useActivateOneTimeCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateOneTimeCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateOneTimeCodeMutation, { data, loading, error }] = useActivateOneTimeCodeMutation({
 *   variables: {
 *      promotionName: // value for 'promotionName'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useActivateOneTimeCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateOneTimeCodeMutation,
    ActivateOneTimeCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActivateOneTimeCodeMutation,
    ActivateOneTimeCodeMutationVariables
  >(ActivateOneTimeCodeDocument, options);
}
export type ActivateOneTimeCodeMutationHookResult = ReturnType<
  typeof useActivateOneTimeCodeMutation
>;
export type ActivateOneTimeCodeMutationResult =
  Apollo.MutationResult<ActivateOneTimeCodeMutation>;
export type ActivateOneTimeCodeMutationOptions = Apollo.BaseMutationOptions<
  ActivateOneTimeCodeMutation,
  ActivateOneTimeCodeMutationVariables
>;
export const PaymentsStoreAdyenDropinDocument = gql`
  mutation paymentsStoreAdyenDropin(
    $redirectTo: String
    $paymentType: String!
    $riskData: RiskDataInput
    $paymentMethod: PaymentMethodInput!
    $paymentMethodId: String
    $browserInfo: BrowserInfoInput
    $code: String
    $sourcePage: String
  ) {
    paymentsStoreAdyenDropin(
      redirectTo: $redirectTo
      paymentType: $paymentType
      riskData: $riskData
      paymentMethod: $paymentMethod
      paymentMethodId: $paymentMethodId
      browserInfo: $browserInfo
      code: $code
      sourcePage: $sourcePage
    ) {
      orderId
      transaction
      recurringDate
      recurringPaymentAmount
      resultCode
      resultAction {
        url
        httpMethod
        paymentMethodType
        paymentData
        redirectData
        subtype
        token
        type
        authorisationToken
      }
      errors {
        message
      }
    }
  }
`;
export type PaymentsStoreAdyenDropinMutationFn = Apollo.MutationFunction<
  PaymentsStoreAdyenDropinMutation,
  PaymentsStoreAdyenDropinMutationVariables
>;

/**
 * __usePaymentsStoreAdyenDropinMutation__
 *
 * To run a mutation, you first call `usePaymentsStoreAdyenDropinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentsStoreAdyenDropinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsStoreAdyenDropinMutation, { data, loading, error }] = usePaymentsStoreAdyenDropinMutation({
 *   variables: {
 *      redirectTo: // value for 'redirectTo'
 *      paymentType: // value for 'paymentType'
 *      riskData: // value for 'riskData'
 *      paymentMethod: // value for 'paymentMethod'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      browserInfo: // value for 'browserInfo'
 *      code: // value for 'code'
 *      sourcePage: // value for 'sourcePage'
 *   },
 * });
 */
export function usePaymentsStoreAdyenDropinMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaymentsStoreAdyenDropinMutation,
    PaymentsStoreAdyenDropinMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaymentsStoreAdyenDropinMutation,
    PaymentsStoreAdyenDropinMutationVariables
  >(PaymentsStoreAdyenDropinDocument, options);
}
export type PaymentsStoreAdyenDropinMutationHookResult = ReturnType<
  typeof usePaymentsStoreAdyenDropinMutation
>;
export type PaymentsStoreAdyenDropinMutationResult =
  Apollo.MutationResult<PaymentsStoreAdyenDropinMutation>;
export type PaymentsStoreAdyenDropinMutationOptions =
  Apollo.BaseMutationOptions<
    PaymentsStoreAdyenDropinMutation,
    PaymentsStoreAdyenDropinMutationVariables
  >;
export const PaymentsStoreTrustlyDocument = gql`
  mutation paymentsStoreTrustly(
    $paymentMethodId: String
    $paymentType: String!
    $redirectTo: String
    $code: String
    $sourcePage: String
  ) {
    paymentsStoreTrustly(
      paymentMethodId: $paymentMethodId
      paymentType: $paymentType
      redirectTo: $redirectTo
      code: $code
      sourcePage: $sourcePage
    ) {
      resultUrl
      recurringPaymentAmount
      recurringPaymentCurrency
      recurringDate
      transaction
      orderId
      errors {
        path
        message
      }
    }
  }
`;
export type PaymentsStoreTrustlyMutationFn = Apollo.MutationFunction<
  PaymentsStoreTrustlyMutation,
  PaymentsStoreTrustlyMutationVariables
>;

/**
 * __usePaymentsStoreTrustlyMutation__
 *
 * To run a mutation, you first call `usePaymentsStoreTrustlyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentsStoreTrustlyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsStoreTrustlyMutation, { data, loading, error }] = usePaymentsStoreTrustlyMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      paymentType: // value for 'paymentType'
 *      redirectTo: // value for 'redirectTo'
 *      code: // value for 'code'
 *      sourcePage: // value for 'sourcePage'
 *   },
 * });
 */
export function usePaymentsStoreTrustlyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaymentsStoreTrustlyMutation,
    PaymentsStoreTrustlyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaymentsStoreTrustlyMutation,
    PaymentsStoreTrustlyMutationVariables
  >(PaymentsStoreTrustlyDocument, options);
}
export type PaymentsStoreTrustlyMutationHookResult = ReturnType<
  typeof usePaymentsStoreTrustlyMutation
>;
export type PaymentsStoreTrustlyMutationResult =
  Apollo.MutationResult<PaymentsStoreTrustlyMutation>;
export type PaymentsStoreTrustlyMutationOptions = Apollo.BaseMutationOptions<
  PaymentsStoreTrustlyMutation,
  PaymentsStoreTrustlyMutationVariables
>;
export const PaymentsStorePaymentDetailsDocument = gql`
  mutation paymentsStorePaymentDetails(
    $threedsResult: String
    $paymentData: String
    $payload: String
    $orderId: String!
  ) {
    paymentsStorePaymentDetails(
      threedsResult: $threedsResult
      paymentData: $paymentData
      payload: $payload
      orderId: $orderId
    ) {
      orderId
      resultCode
      newRecord
      errors {
        message
      }
    }
  }
`;
export type PaymentsStorePaymentDetailsMutationFn = Apollo.MutationFunction<
  PaymentsStorePaymentDetailsMutation,
  PaymentsStorePaymentDetailsMutationVariables
>;

/**
 * __usePaymentsStorePaymentDetailsMutation__
 *
 * To run a mutation, you first call `usePaymentsStorePaymentDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentsStorePaymentDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentsStorePaymentDetailsMutation, { data, loading, error }] = usePaymentsStorePaymentDetailsMutation({
 *   variables: {
 *      threedsResult: // value for 'threedsResult'
 *      paymentData: // value for 'paymentData'
 *      payload: // value for 'payload'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function usePaymentsStorePaymentDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaymentsStorePaymentDetailsMutation,
    PaymentsStorePaymentDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaymentsStorePaymentDetailsMutation,
    PaymentsStorePaymentDetailsMutationVariables
  >(PaymentsStorePaymentDetailsDocument, options);
}
export type PaymentsStorePaymentDetailsMutationHookResult = ReturnType<
  typeof usePaymentsStorePaymentDetailsMutation
>;
export type PaymentsStorePaymentDetailsMutationResult =
  Apollo.MutationResult<PaymentsStorePaymentDetailsMutation>;
export type PaymentsStorePaymentDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    PaymentsStorePaymentDetailsMutation,
    PaymentsStorePaymentDetailsMutationVariables
  >;
export const SurveyDocument = gql`
  query survey($slug: String!) {
    survey(slug: $slug) {
      ...ProgramSurveyFields
    }
  }
  ${ProgramSurveyFieldsFragmentDoc}
`;

/**
 * __useSurveyQuery__
 *
 * To run a query within a React component, call `useSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<SurveyQuery, SurveyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SurveyQuery, SurveyQueryVariables>(
    SurveyDocument,
    options
  );
}
export function useSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SurveyQuery, SurveyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SurveyQuery, SurveyQueryVariables>(
    SurveyDocument,
    options
  );
}
export type SurveyQueryHookResult = ReturnType<typeof useSurveyQuery>;
export type SurveyLazyQueryHookResult = ReturnType<typeof useSurveyLazyQuery>;
export type SurveyQueryResult = Apollo.QueryResult<
  SurveyQuery,
  SurveyQueryVariables
>;
export function refetchSurveyQuery(variables: SurveyQueryVariables) {
  return { query: SurveyDocument, variables: variables };
}
export const GetSurveyDocument = gql`
  query GetSurvey($surveyPartId: Int!) {
    surveyPart(surveyPartId: $surveyPartId) {
      ...ProgramSurveyElementFields
    }
  }
  ${ProgramSurveyElementFieldsFragmentDoc}
`;

/**
 * __useGetSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuery({
 *   variables: {
 *      surveyPartId: // value for 'surveyPartId'
 *   },
 * });
 */
export function useGetSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSurveyQuery, GetSurveyQueryVariables>(
    GetSurveyDocument,
    options
  );
}
export function useGetSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveyQuery,
    GetSurveyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSurveyQuery, GetSurveyQueryVariables>(
    GetSurveyDocument,
    options
  );
}
export type GetSurveyQueryHookResult = ReturnType<typeof useGetSurveyQuery>;
export type GetSurveyLazyQueryHookResult = ReturnType<
  typeof useGetSurveyLazyQuery
>;
export type GetSurveyQueryResult = Apollo.QueryResult<
  GetSurveyQuery,
  GetSurveyQueryVariables
>;
export function refetchGetSurveyQuery(variables: GetSurveyQueryVariables) {
  return { query: GetSurveyDocument, variables: variables };
}
export const StartTrialPlanSubscriptionDocument = gql`
  mutation startTrialPlanSubscription {
    startTrialPlanSubscription {
      success
      errors {
        message
      }
    }
  }
`;
export type StartTrialPlanSubscriptionMutationFn = Apollo.MutationFunction<
  StartTrialPlanSubscriptionMutation,
  StartTrialPlanSubscriptionMutationVariables
>;

/**
 * __useStartTrialPlanSubscriptionMutation__
 *
 * To run a mutation, you first call `useStartTrialPlanSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTrialPlanSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTrialPlanSubscriptionMutation, { data, loading, error }] = useStartTrialPlanSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartTrialPlanSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartTrialPlanSubscriptionMutation,
    StartTrialPlanSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartTrialPlanSubscriptionMutation,
    StartTrialPlanSubscriptionMutationVariables
  >(StartTrialPlanSubscriptionDocument, options);
}
export type StartTrialPlanSubscriptionMutationHookResult = ReturnType<
  typeof useStartTrialPlanSubscriptionMutation
>;
export type StartTrialPlanSubscriptionMutationResult =
  Apollo.MutationResult<StartTrialPlanSubscriptionMutation>;
export type StartTrialPlanSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    StartTrialPlanSubscriptionMutation,
    StartTrialPlanSubscriptionMutationVariables
  >;
export const AssignPlanToTrialDocument = gql`
  mutation assignPlanToTrial(
    $planType: PlanTypeKind!
    $subscriptionPromotionId: String
    $code: String
  ) {
    assignPlanToTrial(
      planType: $planType
      subscriptionPromotionId: $subscriptionPromotionId
      code: $code
    ) {
      success
      errors
    }
  }
`;
export type AssignPlanToTrialMutationFn = Apollo.MutationFunction<
  AssignPlanToTrialMutation,
  AssignPlanToTrialMutationVariables
>;

/**
 * __useAssignPlanToTrialMutation__
 *
 * To run a mutation, you first call `useAssignPlanToTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPlanToTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPlanToTrialMutation, { data, loading, error }] = useAssignPlanToTrialMutation({
 *   variables: {
 *      planType: // value for 'planType'
 *      subscriptionPromotionId: // value for 'subscriptionPromotionId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAssignPlanToTrialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignPlanToTrialMutation,
    AssignPlanToTrialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignPlanToTrialMutation,
    AssignPlanToTrialMutationVariables
  >(AssignPlanToTrialDocument, options);
}
export type AssignPlanToTrialMutationHookResult = ReturnType<
  typeof useAssignPlanToTrialMutation
>;
export type AssignPlanToTrialMutationResult =
  Apollo.MutationResult<AssignPlanToTrialMutation>;
export type AssignPlanToTrialMutationOptions = Apollo.BaseMutationOptions<
  AssignPlanToTrialMutation,
  AssignPlanToTrialMutationVariables
>;
export const ProcessTrialPlanDocument = gql`
  mutation processTrialPlan {
    processTrialPlan {
      errors
      success
    }
  }
`;
export type ProcessTrialPlanMutationFn = Apollo.MutationFunction<
  ProcessTrialPlanMutation,
  ProcessTrialPlanMutationVariables
>;

/**
 * __useProcessTrialPlanMutation__
 *
 * To run a mutation, you first call `useProcessTrialPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessTrialPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processTrialPlanMutation, { data, loading, error }] = useProcessTrialPlanMutation({
 *   variables: {
 *   },
 * });
 */
export function useProcessTrialPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProcessTrialPlanMutation,
    ProcessTrialPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProcessTrialPlanMutation,
    ProcessTrialPlanMutationVariables
  >(ProcessTrialPlanDocument, options);
}
export type ProcessTrialPlanMutationHookResult = ReturnType<
  typeof useProcessTrialPlanMutation
>;
export type ProcessTrialPlanMutationResult =
  Apollo.MutationResult<ProcessTrialPlanMutation>;
export type ProcessTrialPlanMutationOptions = Apollo.BaseMutationOptions<
  ProcessTrialPlanMutation,
  ProcessTrialPlanMutationVariables
>;
export const UpsellOffersDocument = gql`
  query upsellOffers {
    upsellOffers {
      title
      description
      image
      amountCents
      amountCurrency
      promotionName
      subscriptionPlanType
      subscriptionPlan {
        months
        amountCents
        amountCurrency
        planType
        platform
        productId
      }
    }
  }
`;

/**
 * __useUpsellOffersQuery__
 *
 * To run a query within a React component, call `useUpsellOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpsellOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpsellOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpsellOffersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UpsellOffersQuery,
    UpsellOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpsellOffersQuery, UpsellOffersQueryVariables>(
    UpsellOffersDocument,
    options
  );
}
export function useUpsellOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpsellOffersQuery,
    UpsellOffersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpsellOffersQuery, UpsellOffersQueryVariables>(
    UpsellOffersDocument,
    options
  );
}
export type UpsellOffersQueryHookResult = ReturnType<
  typeof useUpsellOffersQuery
>;
export type UpsellOffersLazyQueryHookResult = ReturnType<
  typeof useUpsellOffersLazyQuery
>;
export type UpsellOffersQueryResult = Apollo.QueryResult<
  UpsellOffersQuery,
  UpsellOffersQueryVariables
>;
export function refetchUpsellOffersQuery(
  variables?: UpsellOffersQueryVariables
) {
  return { query: UpsellOffersDocument, variables: variables };
}
export const AvailableAllCodesDocument = gql`
  query availableAllCodes($code: String, $registration: Boolean = false) {
    availableAllCodes(code: $code, registration: $registration, platform: web) {
      code
      codeType
      isCodeValid
      validationMessage
      subscriptions {
        ...PricingSubscriptionFields
      }
      fullAccesses {
        ...PricingSubscriptionFields
      }
      oneTimeCodes {
        ...PricingSubscriptionFields
      }
      trialReferralCodes {
        ...PricingSubscriptionFields
      }
      trials {
        title
        description
        shortDescription
        amount
        discountedAmount
        currency
        months
        days
        subscriptionName
        subscriptionPlan
        promotionName
        recurringPromotion
      }
    }
  }
  ${PricingSubscriptionFieldsFragmentDoc}
`;

/**
 * __useAvailableAllCodesQuery__
 *
 * To run a query within a React component, call `useAvailableAllCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableAllCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableAllCodesQuery({
 *   variables: {
 *      code: // value for 'code'
 *      registration: // value for 'registration'
 *   },
 * });
 */
export function useAvailableAllCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableAllCodesQuery,
    AvailableAllCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableAllCodesQuery,
    AvailableAllCodesQueryVariables
  >(AvailableAllCodesDocument, options);
}
export function useAvailableAllCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableAllCodesQuery,
    AvailableAllCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableAllCodesQuery,
    AvailableAllCodesQueryVariables
  >(AvailableAllCodesDocument, options);
}
export type AvailableAllCodesQueryHookResult = ReturnType<
  typeof useAvailableAllCodesQuery
>;
export type AvailableAllCodesLazyQueryHookResult = ReturnType<
  typeof useAvailableAllCodesLazyQuery
>;
export type AvailableAllCodesQueryResult = Apollo.QueryResult<
  AvailableAllCodesQuery,
  AvailableAllCodesQueryVariables
>;
export function refetchAvailableAllCodesQuery(
  variables?: AvailableAllCodesQueryVariables
) {
  return { query: AvailableAllCodesDocument, variables: variables };
}
export const RegistrationPageDocument = gql`
  query registrationPage {
    registrationPage {
      contentHubImageUrl
      courseImageUrl
      healthcareImageUrl
      liveImageUrl
      playImageUrl
      subscriptionPlansImageUrl
      trialImageUrl
    }
  }
`;

/**
 * __useRegistrationPageQuery__
 *
 * To run a query within a React component, call `useRegistrationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegistrationPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegistrationPageQuery,
    RegistrationPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegistrationPageQuery, RegistrationPageQueryVariables>(
    RegistrationPageDocument,
    options
  );
}
export function useRegistrationPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistrationPageQuery,
    RegistrationPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegistrationPageQuery,
    RegistrationPageQueryVariables
  >(RegistrationPageDocument, options);
}
export type RegistrationPageQueryHookResult = ReturnType<
  typeof useRegistrationPageQuery
>;
export type RegistrationPageLazyQueryHookResult = ReturnType<
  typeof useRegistrationPageLazyQuery
>;
export type RegistrationPageQueryResult = Apollo.QueryResult<
  RegistrationPageQuery,
  RegistrationPageQueryVariables
>;
export function refetchRegistrationPageQuery(
  variables?: RegistrationPageQueryVariables
) {
  return { query: RegistrationPageDocument, variables: variables };
}
export const AssignFinalFunnelDocument = gql`
  mutation assignFinalFunnel($funnel: FunnelKind!) {
    assignFinalFunnel(funnel: $funnel) {
      success
    }
  }
`;
export type AssignFinalFunnelMutationFn = Apollo.MutationFunction<
  AssignFinalFunnelMutation,
  AssignFinalFunnelMutationVariables
>;

/**
 * __useAssignFinalFunnelMutation__
 *
 * To run a mutation, you first call `useAssignFinalFunnelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignFinalFunnelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignFinalFunnelMutation, { data, loading, error }] = useAssignFinalFunnelMutation({
 *   variables: {
 *      funnel: // value for 'funnel'
 *   },
 * });
 */
export function useAssignFinalFunnelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignFinalFunnelMutation,
    AssignFinalFunnelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignFinalFunnelMutation,
    AssignFinalFunnelMutationVariables
  >(AssignFinalFunnelDocument, options);
}
export type AssignFinalFunnelMutationHookResult = ReturnType<
  typeof useAssignFinalFunnelMutation
>;
export type AssignFinalFunnelMutationResult =
  Apollo.MutationResult<AssignFinalFunnelMutation>;
export type AssignFinalFunnelMutationOptions = Apollo.BaseMutationOptions<
  AssignFinalFunnelMutation,
  AssignFinalFunnelMutationVariables
>;
export const RegistrationStepDocument = gql`
  mutation registrationStep(
    $step: RegistrationStepEnum!
    $funnel: FunnelKind!
  ) {
    registrationStep(step: $step, funnel: $funnel) {
      success
    }
  }
`;
export type RegistrationStepMutationFn = Apollo.MutationFunction<
  RegistrationStepMutation,
  RegistrationStepMutationVariables
>;

/**
 * __useRegistrationStepMutation__
 *
 * To run a mutation, you first call `useRegistrationStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationStepMutation, { data, loading, error }] = useRegistrationStepMutation({
 *   variables: {
 *      step: // value for 'step'
 *      funnel: // value for 'funnel'
 *   },
 * });
 */
export function useRegistrationStepMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegistrationStepMutation,
    RegistrationStepMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegistrationStepMutation,
    RegistrationStepMutationVariables
  >(RegistrationStepDocument, options);
}
export type RegistrationStepMutationHookResult = ReturnType<
  typeof useRegistrationStepMutation
>;
export type RegistrationStepMutationResult =
  Apollo.MutationResult<RegistrationStepMutation>;
export type RegistrationStepMutationOptions = Apollo.BaseMutationOptions<
  RegistrationStepMutation,
  RegistrationStepMutationVariables
>;
export const PatchUserDocument = gql`
  mutation patchUser(
    $contentLanguages: [String]
    $excludeTerms: [String]
    $videoLevels: [String]
  ) {
    patchUser(
      contentLanguages: $contentLanguages
      excludeTerms: $excludeTerms
      videoLevels: $videoLevels
    ) {
      user {
        id
      }
    }
  }
`;
export type PatchUserMutationFn = Apollo.MutationFunction<
  PatchUserMutation,
  PatchUserMutationVariables
>;

/**
 * __usePatchUserMutation__
 *
 * To run a mutation, you first call `usePatchUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchUserMutation, { data, loading, error }] = usePatchUserMutation({
 *   variables: {
 *      contentLanguages: // value for 'contentLanguages'
 *      excludeTerms: // value for 'excludeTerms'
 *      videoLevels: // value for 'videoLevels'
 *   },
 * });
 */
export function usePatchUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchUserMutation,
    PatchUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PatchUserMutation, PatchUserMutationVariables>(
    PatchUserDocument,
    options
  );
}
export type PatchUserMutationHookResult = ReturnType<
  typeof usePatchUserMutation
>;
export type PatchUserMutationResult = Apollo.MutationResult<PatchUserMutation>;
export type PatchUserMutationOptions = Apollo.BaseMutationOptions<
  PatchUserMutation,
  PatchUserMutationVariables
>;
export const UserPlaylistsDocument = gql`
  query userPlaylists($id: ID, $page: Int, $perPage: Int) {
    userPlaylists(id: $id, page: $page, perPage: $perPage) {
      page
      perPage
      totalCount
      data {
        id
        identifier
        title
        visibility
        owner {
          id
          firstName
        }
        meta {
          videoCount
          minutes
        }
        description
        assets {
          thumbnailUrl
        }
        videos {
          id
        }
      }
    }
  }
`;

/**
 * __useUserPlaylistsQuery__
 *
 * To run a query within a React component, call `useUserPlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPlaylistsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useUserPlaylistsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserPlaylistsQuery,
    UserPlaylistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserPlaylistsQuery, UserPlaylistsQueryVariables>(
    UserPlaylistsDocument,
    options
  );
}
export function useUserPlaylistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserPlaylistsQuery,
    UserPlaylistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserPlaylistsQuery, UserPlaylistsQueryVariables>(
    UserPlaylistsDocument,
    options
  );
}
export type UserPlaylistsQueryHookResult = ReturnType<
  typeof useUserPlaylistsQuery
>;
export type UserPlaylistsLazyQueryHookResult = ReturnType<
  typeof useUserPlaylistsLazyQuery
>;
export type UserPlaylistsQueryResult = Apollo.QueryResult<
  UserPlaylistsQuery,
  UserPlaylistsQueryVariables
>;
export function refetchUserPlaylistsQuery(
  variables?: UserPlaylistsQueryVariables
) {
  return { query: UserPlaylistsDocument, variables: variables };
}
export const RemoveVideoFromPlaylistDocument = gql`
  mutation removeVideoFromPlaylist($playlistId: ID!, $videoId: ID!) {
    removeVideoFromPlaylist(playlistId: $playlistId, videoId: $videoId) {
      playlist {
        id
      }
    }
  }
`;
export type RemoveVideoFromPlaylistMutationFn = Apollo.MutationFunction<
  RemoveVideoFromPlaylistMutation,
  RemoveVideoFromPlaylistMutationVariables
>;

/**
 * __useRemoveVideoFromPlaylistMutation__
 *
 * To run a mutation, you first call `useRemoveVideoFromPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVideoFromPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVideoFromPlaylistMutation, { data, loading, error }] = useRemoveVideoFromPlaylistMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useRemoveVideoFromPlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveVideoFromPlaylistMutation,
    RemoveVideoFromPlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveVideoFromPlaylistMutation,
    RemoveVideoFromPlaylistMutationVariables
  >(RemoveVideoFromPlaylistDocument, options);
}
export type RemoveVideoFromPlaylistMutationHookResult = ReturnType<
  typeof useRemoveVideoFromPlaylistMutation
>;
export type RemoveVideoFromPlaylistMutationResult =
  Apollo.MutationResult<RemoveVideoFromPlaylistMutation>;
export type RemoveVideoFromPlaylistMutationOptions = Apollo.BaseMutationOptions<
  RemoveVideoFromPlaylistMutation,
  RemoveVideoFromPlaylistMutationVariables
>;
export const AddVideoToPlaylistDocument = gql`
  mutation addVideoToPlaylist($playlistId: ID!, $videoId: ID!) {
    addVideoToPlaylist(playlistId: $playlistId, videoId: $videoId) {
      playlist {
        id
      }
    }
  }
`;
export type AddVideoToPlaylistMutationFn = Apollo.MutationFunction<
  AddVideoToPlaylistMutation,
  AddVideoToPlaylistMutationVariables
>;

/**
 * __useAddVideoToPlaylistMutation__
 *
 * To run a mutation, you first call `useAddVideoToPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVideoToPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVideoToPlaylistMutation, { data, loading, error }] = useAddVideoToPlaylistMutation({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useAddVideoToPlaylistMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddVideoToPlaylistMutation,
    AddVideoToPlaylistMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddVideoToPlaylistMutation,
    AddVideoToPlaylistMutationVariables
  >(AddVideoToPlaylistDocument, options);
}
export type AddVideoToPlaylistMutationHookResult = ReturnType<
  typeof useAddVideoToPlaylistMutation
>;
export type AddVideoToPlaylistMutationResult =
  Apollo.MutationResult<AddVideoToPlaylistMutation>;
export type AddVideoToPlaylistMutationOptions = Apollo.BaseMutationOptions<
  AddVideoToPlaylistMutation,
  AddVideoToPlaylistMutationVariables
>;
export const DeleteFavoriteVideoDocument = gql`
  mutation deleteFavoriteVideo($videoId: ID!) {
    deleteFavoriteVideo(videoId: $videoId) {
      video {
        favorited
      }
    }
  }
`;
export type DeleteFavoriteVideoMutationFn = Apollo.MutationFunction<
  DeleteFavoriteVideoMutation,
  DeleteFavoriteVideoMutationVariables
>;

/**
 * __useDeleteFavoriteVideoMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteVideoMutation, { data, loading, error }] = useDeleteFavoriteVideoMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useDeleteFavoriteVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFavoriteVideoMutation,
    DeleteFavoriteVideoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFavoriteVideoMutation,
    DeleteFavoriteVideoMutationVariables
  >(DeleteFavoriteVideoDocument, options);
}
export type DeleteFavoriteVideoMutationHookResult = ReturnType<
  typeof useDeleteFavoriteVideoMutation
>;
export type DeleteFavoriteVideoMutationResult =
  Apollo.MutationResult<DeleteFavoriteVideoMutation>;
export type DeleteFavoriteVideoMutationOptions = Apollo.BaseMutationOptions<
  DeleteFavoriteVideoMutation,
  DeleteFavoriteVideoMutationVariables
>;
export const AddFavoriteVideoDocument = gql`
  mutation addFavoriteVideo($videoId: ID!) {
    addFavoriteVideo(videoId: $videoId) {
      video {
        favorited
      }
    }
  }
`;
export type AddFavoriteVideoMutationFn = Apollo.MutationFunction<
  AddFavoriteVideoMutation,
  AddFavoriteVideoMutationVariables
>;

/**
 * __useAddFavoriteVideoMutation__
 *
 * To run a mutation, you first call `useAddFavoriteVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteVideoMutation, { data, loading, error }] = useAddFavoriteVideoMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *   },
 * });
 */
export function useAddFavoriteVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFavoriteVideoMutation,
    AddFavoriteVideoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddFavoriteVideoMutation,
    AddFavoriteVideoMutationVariables
  >(AddFavoriteVideoDocument, options);
}
export type AddFavoriteVideoMutationHookResult = ReturnType<
  typeof useAddFavoriteVideoMutation
>;
export type AddFavoriteVideoMutationResult =
  Apollo.MutationResult<AddFavoriteVideoMutation>;
export type AddFavoriteVideoMutationOptions = Apollo.BaseMutationOptions<
  AddFavoriteVideoMutation,
  AddFavoriteVideoMutationVariables
>;
export const TaxonomiesDocument = gql`
  query taxonomies($genre: String, $languages: [String!]) {
    taxonomies(genre: $genre, languages: $languages) {
      id
      name
      slug
      terms {
        id
        name
        slug
        important
      }
    }
  }
`;

/**
 * __useTaxonomiesQuery__
 *
 * To run a query within a React component, call `useTaxonomiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxonomiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxonomiesQuery({
 *   variables: {
 *      genre: // value for 'genre'
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useTaxonomiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TaxonomiesQuery,
    TaxonomiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxonomiesQuery, TaxonomiesQueryVariables>(
    TaxonomiesDocument,
    options
  );
}
export function useTaxonomiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxonomiesQuery,
    TaxonomiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxonomiesQuery, TaxonomiesQueryVariables>(
    TaxonomiesDocument,
    options
  );
}
export type TaxonomiesQueryHookResult = ReturnType<typeof useTaxonomiesQuery>;
export type TaxonomiesLazyQueryHookResult = ReturnType<
  typeof useTaxonomiesLazyQuery
>;
export type TaxonomiesQueryResult = Apollo.QueryResult<
  TaxonomiesQuery,
  TaxonomiesQueryVariables
>;
export function refetchTaxonomiesQuery(variables?: TaxonomiesQueryVariables) {
  return { query: TaxonomiesDocument, variables: variables };
}
export const TaxonomiesV2Document = gql`
  query taxonomiesV2($genre: String, $languages: [String!]) {
    taxonomiesV2(genre: $genre, languages: $languages) {
      ...NewTaxonomyItemFields
    }
  }
  ${NewTaxonomyItemFieldsFragmentDoc}
`;

/**
 * __useTaxonomiesV2Query__
 *
 * To run a query within a React component, call `useTaxonomiesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useTaxonomiesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxonomiesV2Query({
 *   variables: {
 *      genre: // value for 'genre'
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useTaxonomiesV2Query(
  baseOptions?: Apollo.QueryHookOptions<
    TaxonomiesV2Query,
    TaxonomiesV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaxonomiesV2Query, TaxonomiesV2QueryVariables>(
    TaxonomiesV2Document,
    options
  );
}
export function useTaxonomiesV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaxonomiesV2Query,
    TaxonomiesV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaxonomiesV2Query, TaxonomiesV2QueryVariables>(
    TaxonomiesV2Document,
    options
  );
}
export type TaxonomiesV2QueryHookResult = ReturnType<
  typeof useTaxonomiesV2Query
>;
export type TaxonomiesV2LazyQueryHookResult = ReturnType<
  typeof useTaxonomiesV2LazyQuery
>;
export type TaxonomiesV2QueryResult = Apollo.QueryResult<
  TaxonomiesV2Query,
  TaxonomiesV2QueryVariables
>;
export function refetchTaxonomiesV2Query(
  variables?: TaxonomiesV2QueryVariables
) {
  return { query: TaxonomiesV2Document, variables: variables };
}
export const CrossPromotionalBannersDocument = gql`
  query crossPromotionalBanners {
    crossPromotionalBanners {
      link
      imageUrl
      name
    }
  }
`;

/**
 * __useCrossPromotionalBannersQuery__
 *
 * To run a query within a React component, call `useCrossPromotionalBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrossPromotionalBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrossPromotionalBannersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCrossPromotionalBannersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CrossPromotionalBannersQuery,
    CrossPromotionalBannersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CrossPromotionalBannersQuery,
    CrossPromotionalBannersQueryVariables
  >(CrossPromotionalBannersDocument, options);
}
export function useCrossPromotionalBannersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CrossPromotionalBannersQuery,
    CrossPromotionalBannersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CrossPromotionalBannersQuery,
    CrossPromotionalBannersQueryVariables
  >(CrossPromotionalBannersDocument, options);
}
export type CrossPromotionalBannersQueryHookResult = ReturnType<
  typeof useCrossPromotionalBannersQuery
>;
export type CrossPromotionalBannersLazyQueryHookResult = ReturnType<
  typeof useCrossPromotionalBannersLazyQuery
>;
export type CrossPromotionalBannersQueryResult = Apollo.QueryResult<
  CrossPromotionalBannersQuery,
  CrossPromotionalBannersQueryVariables
>;
export function refetchCrossPromotionalBannersQuery(
  variables?: CrossPromotionalBannersQueryVariables
) {
  return { query: CrossPromotionalBannersDocument, variables: variables };
}
export const VideoDocument = gql`
  query video($id: ID!) {
    video(id: $id) {
      id
      kind
      hls
      identifier
      hasAccess
      cursorAt
      categories
      removedText
      publishedAt
      player {
        assets {
          src
          resolution
        }
      }
      assets {
        thumbnailUrl
        thumbnailUrlMedium
      }
      primaryStyle {
        name
        slug
      }
      taxonomiesV2 {
        ...taxonomyV2Fields
      }
      taxonomies {
        flavor {
          id
          name
          slug
        }
        needs {
          id
          name
          slug
        }
        instructors {
          id
          name
          slug
          shortDescription
          assets {
            squareImage
          }
        }
        genre {
          id
          name
          slug
        }
        props {
          id
          name
          slug
          link
        }
      }
      meta {
        minutes
      }
      content {
        title
        shortDescription
        longDescription
      }
    }
  }
  ${TaxonomyV2FieldsFragmentDoc}
`;

/**
 * __useVideoQuery__
 *
 * To run a query within a React component, call `useVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoQuery(
  baseOptions: Apollo.QueryHookOptions<VideoQuery, VideoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VideoQuery, VideoQueryVariables>(
    VideoDocument,
    options
  );
}
export function useVideoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VideoQuery, VideoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VideoQuery, VideoQueryVariables>(
    VideoDocument,
    options
  );
}
export type VideoQueryHookResult = ReturnType<typeof useVideoQuery>;
export type VideoLazyQueryHookResult = ReturnType<typeof useVideoLazyQuery>;
export type VideoQueryResult = Apollo.QueryResult<
  VideoQuery,
  VideoQueryVariables
>;
export function refetchVideoQuery(variables: VideoQueryVariables) {
  return { query: VideoDocument, variables: variables };
}
export const InstructorByGenreDocument = gql`
  query instructorByGenre(
    $genre: String
    $instructor: String!
    $languages: [String]
  ) {
    instructorByGenre(
      genre: $genre
      instructor: $instructor
      languages: $languages
    ) {
      instructor {
        id
        name
        assets {
          squareImage
        }
      }
      videosCountInGenres {
        channel
        slug
        count
      }
    }
  }
`;

/**
 * __useInstructorByGenreQuery__
 *
 * To run a query within a React component, call `useInstructorByGenreQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstructorByGenreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstructorByGenreQuery({
 *   variables: {
 *      genre: // value for 'genre'
 *      instructor: // value for 'instructor'
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useInstructorByGenreQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstructorByGenreQuery,
    InstructorByGenreQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstructorByGenreQuery,
    InstructorByGenreQueryVariables
  >(InstructorByGenreDocument, options);
}
export function useInstructorByGenreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstructorByGenreQuery,
    InstructorByGenreQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstructorByGenreQuery,
    InstructorByGenreQueryVariables
  >(InstructorByGenreDocument, options);
}
export type InstructorByGenreQueryHookResult = ReturnType<
  typeof useInstructorByGenreQuery
>;
export type InstructorByGenreLazyQueryHookResult = ReturnType<
  typeof useInstructorByGenreLazyQuery
>;
export type InstructorByGenreQueryResult = Apollo.QueryResult<
  InstructorByGenreQuery,
  InstructorByGenreQueryVariables
>;
export function refetchInstructorByGenreQuery(
  variables: InstructorByGenreQueryVariables
) {
  return { query: InstructorByGenreDocument, variables: variables };
}
export const LatestViewedVideosDocument = gql`
  query latestViewedVideos($page: Int = 1, $perPage: Int = 9) {
    latestViewedVideos(page: $page, perPage: $perPage) {
      page
      perPage
      totalCount
      data {
        ...VideoFields
      }
    }
  }
  ${VideoFieldsFragmentDoc}
`;

/**
 * __useLatestViewedVideosQuery__
 *
 * To run a query within a React component, call `useLatestViewedVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestViewedVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestViewedVideosQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useLatestViewedVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LatestViewedVideosQuery,
    LatestViewedVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LatestViewedVideosQuery,
    LatestViewedVideosQueryVariables
  >(LatestViewedVideosDocument, options);
}
export function useLatestViewedVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestViewedVideosQuery,
    LatestViewedVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LatestViewedVideosQuery,
    LatestViewedVideosQueryVariables
  >(LatestViewedVideosDocument, options);
}
export type LatestViewedVideosQueryHookResult = ReturnType<
  typeof useLatestViewedVideosQuery
>;
export type LatestViewedVideosLazyQueryHookResult = ReturnType<
  typeof useLatestViewedVideosLazyQuery
>;
export type LatestViewedVideosQueryResult = Apollo.QueryResult<
  LatestViewedVideosQuery,
  LatestViewedVideosQueryVariables
>;
export function refetchLatestViewedVideosQuery(
  variables?: LatestViewedVideosQueryVariables
) {
  return { query: LatestViewedVideosDocument, variables: variables };
}
export const PopularVideosDocument = gql`
  query popularVideos {
    popularVideos {
      ...VideoFields
    }
  }
  ${VideoFieldsFragmentDoc}
`;

/**
 * __usePopularVideosQuery__
 *
 * To run a query within a React component, call `usePopularVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularVideosQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopularVideosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PopularVideosQuery,
    PopularVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PopularVideosQuery, PopularVideosQueryVariables>(
    PopularVideosDocument,
    options
  );
}
export function usePopularVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PopularVideosQuery,
    PopularVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PopularVideosQuery, PopularVideosQueryVariables>(
    PopularVideosDocument,
    options
  );
}
export type PopularVideosQueryHookResult = ReturnType<
  typeof usePopularVideosQuery
>;
export type PopularVideosLazyQueryHookResult = ReturnType<
  typeof usePopularVideosLazyQuery
>;
export type PopularVideosQueryResult = Apollo.QueryResult<
  PopularVideosQuery,
  PopularVideosQueryVariables
>;
export function refetchPopularVideosQuery(
  variables?: PopularVideosQueryVariables
) {
  return { query: PopularVideosDocument, variables: variables };
}
export const WeeklyProgramDocument = gql`
  query weeklyProgram($slug: String!) {
    weeklyProgram(slug: $slug) {
      ...WeeklyProgramFields
    }
  }
  ${WeeklyProgramFieldsFragmentDoc}
`;

/**
 * __useWeeklyProgramQuery__
 *
 * To run a query within a React component, call `useWeeklyProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeeklyProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeeklyProgramQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useWeeklyProgramQuery(
  baseOptions: Apollo.QueryHookOptions<
    WeeklyProgramQuery,
    WeeklyProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WeeklyProgramQuery, WeeklyProgramQueryVariables>(
    WeeklyProgramDocument,
    options
  );
}
export function useWeeklyProgramLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WeeklyProgramQuery,
    WeeklyProgramQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WeeklyProgramQuery, WeeklyProgramQueryVariables>(
    WeeklyProgramDocument,
    options
  );
}
export type WeeklyProgramQueryHookResult = ReturnType<
  typeof useWeeklyProgramQuery
>;
export type WeeklyProgramLazyQueryHookResult = ReturnType<
  typeof useWeeklyProgramLazyQuery
>;
export type WeeklyProgramQueryResult = Apollo.QueryResult<
  WeeklyProgramQuery,
  WeeklyProgramQueryVariables
>;
export function refetchWeeklyProgramQuery(
  variables: WeeklyProgramQueryVariables
) {
  return { query: WeeklyProgramDocument, variables: variables };
}
export const WellnessesDocument = gql`
  query wellnesses {
    wellnesses {
      id
      logo
      name
      slug
      status
      instructions
    }
  }
`;

/**
 * __useWellnessesQuery__
 *
 * To run a query within a React component, call `useWellnessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellnessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellnessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWellnessesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WellnessesQuery,
    WellnessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WellnessesQuery, WellnessesQueryVariables>(
    WellnessesDocument,
    options
  );
}
export function useWellnessesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WellnessesQuery,
    WellnessesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WellnessesQuery, WellnessesQueryVariables>(
    WellnessesDocument,
    options
  );
}
export type WellnessesQueryHookResult = ReturnType<typeof useWellnessesQuery>;
export type WellnessesLazyQueryHookResult = ReturnType<
  typeof useWellnessesLazyQuery
>;
export type WellnessesQueryResult = Apollo.QueryResult<
  WellnessesQuery,
  WellnessesQueryVariables
>;
export function refetchWellnessesQuery(variables?: WellnessesQueryVariables) {
  return { query: WellnessesDocument, variables: variables };
}
export const WellnessCustomBreakdownDocument = gql`
  query wellnessCustomBreakdown($slug: String!, $allowance: Float) {
    wellness(slug: $slug, allowance: $allowance) {
      customPackage {
        id
        name
        thumbnail
        instructions
        description
        amountCents
        amountCurrency
        packageable {
          ...WellnessAccessFields
        }
        priceBreakdown {
          amountPerDay
          currency
          extraDays
          extraDaysAmount
          planCount
          planType
          originalPlanAmount
          totalAmount
          accessDays @client
        }
      }
    }
  }
  ${WellnessAccessFieldsFragmentDoc}
`;

/**
 * __useWellnessCustomBreakdownQuery__
 *
 * To run a query within a React component, call `useWellnessCustomBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellnessCustomBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellnessCustomBreakdownQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      allowance: // value for 'allowance'
 *   },
 * });
 */
export function useWellnessCustomBreakdownQuery(
  baseOptions: Apollo.QueryHookOptions<
    WellnessCustomBreakdownQuery,
    WellnessCustomBreakdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WellnessCustomBreakdownQuery,
    WellnessCustomBreakdownQueryVariables
  >(WellnessCustomBreakdownDocument, options);
}
export function useWellnessCustomBreakdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WellnessCustomBreakdownQuery,
    WellnessCustomBreakdownQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WellnessCustomBreakdownQuery,
    WellnessCustomBreakdownQueryVariables
  >(WellnessCustomBreakdownDocument, options);
}
export type WellnessCustomBreakdownQueryHookResult = ReturnType<
  typeof useWellnessCustomBreakdownQuery
>;
export type WellnessCustomBreakdownLazyQueryHookResult = ReturnType<
  typeof useWellnessCustomBreakdownLazyQuery
>;
export type WellnessCustomBreakdownQueryResult = Apollo.QueryResult<
  WellnessCustomBreakdownQuery,
  WellnessCustomBreakdownQueryVariables
>;
export function refetchWellnessCustomBreakdownQuery(
  variables: WellnessCustomBreakdownQueryVariables
) {
  return { query: WellnessCustomBreakdownDocument, variables: variables };
}
export const WellnessDocument = gql`
  query wellness(
    $slug: String!
    $allowance: Float
    $perPage: Int = 6
    $livePage: Int
    $accessPage: Int
    $coursePage: Int
  ) {
    wellness(slug: $slug, allowance: $allowance) {
      id
      logo
      name
      slug
      status
      instructions
      platformUrl
      platformUrl2
      subscriptionPlans {
        ...PricingSubscriptionFields
      }
      customPackage {
        id
        name
        thumbnail
        instructions
        description
        amountCents
        amountCurrency
        packageable {
          ...WellnessAccessFields
        }
        priceBreakdown {
          amountPerDay
          currency
          extraDays
          extraDaysAmount
          planCount
          planType
          originalPlanAmount
          totalAmount
        }
      }
      liveEventPackages(page: $livePage, perPage: $perPage) {
        page
        totalCount
        data {
          id
          name
          thumbnail
          amountCents
          discountedAmount
          amountCurrency
          instructions
          packageable {
            ...PromotionFields
          }
        }
      }
      liveEvents(page: $livePage, perPage: $perPage) {
        page
        totalCount
        data {
          ...LiveEventEssentialFields
        }
      }
      wellnessAccesses(page: $accessPage, perPage: 30) {
        page
        totalCount
        data {
          id
          name
          thumbnail
          description
          amountCents
          discountedAmount
          amountCurrency
          instructions
          packageable {
            ...WellnessAccessFields
          }
        }
      }
      courses(page: $coursePage, perPage: $perPage) {
        page
        totalCount
        data {
          id
          name
          thumbnail
          amountCents
          discountedAmount
          amountCurrency
          description
          instructions
          packageable {
            ...GenericProgramFields
          }
        }
      }
    }
  }
  ${PricingSubscriptionFieldsFragmentDoc}
  ${WellnessAccessFieldsFragmentDoc}
  ${PromotionFieldsFragmentDoc}
  ${LiveEventEssentialFieldsFragmentDoc}
  ${GenericProgramFieldsFragmentDoc}
`;

/**
 * __useWellnessQuery__
 *
 * To run a query within a React component, call `useWellnessQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellnessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellnessQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      allowance: // value for 'allowance'
 *      perPage: // value for 'perPage'
 *      livePage: // value for 'livePage'
 *      accessPage: // value for 'accessPage'
 *      coursePage: // value for 'coursePage'
 *   },
 * });
 */
export function useWellnessQuery(
  baseOptions: Apollo.QueryHookOptions<WellnessQuery, WellnessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WellnessQuery, WellnessQueryVariables>(
    WellnessDocument,
    options
  );
}
export function useWellnessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WellnessQuery,
    WellnessQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WellnessQuery, WellnessQueryVariables>(
    WellnessDocument,
    options
  );
}
export type WellnessQueryHookResult = ReturnType<typeof useWellnessQuery>;
export type WellnessLazyQueryHookResult = ReturnType<
  typeof useWellnessLazyQuery
>;
export type WellnessQueryResult = Apollo.QueryResult<
  WellnessQuery,
  WellnessQueryVariables
>;
export function refetchWellnessQuery(variables: WellnessQueryVariables) {
  return { query: WellnessDocument, variables: variables };
}
export const WellnessCoursesDocument = gql`
  query wellnessCourses(
    $slug: String!
    $allowance: Float
    $perPage: Int = 9
    $coursePage: Int
  ) {
    wellness(slug: $slug, allowance: $allowance) {
      id
      name
      slug
      platformUrl
      platformUrl2
      courses(page: $coursePage, perPage: $perPage) {
        page
        totalCount
        data {
          id
          name
          thumbnail
          amountCents
          discountedAmount
          amountCurrency
          description
          instructions
          packageable {
            ...GenericProgramFields
          }
        }
      }
    }
  }
  ${GenericProgramFieldsFragmentDoc}
`;

/**
 * __useWellnessCoursesQuery__
 *
 * To run a query within a React component, call `useWellnessCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellnessCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellnessCoursesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      allowance: // value for 'allowance'
 *      perPage: // value for 'perPage'
 *      coursePage: // value for 'coursePage'
 *   },
 * });
 */
export function useWellnessCoursesQuery(
  baseOptions: Apollo.QueryHookOptions<
    WellnessCoursesQuery,
    WellnessCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WellnessCoursesQuery, WellnessCoursesQueryVariables>(
    WellnessCoursesDocument,
    options
  );
}
export function useWellnessCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WellnessCoursesQuery,
    WellnessCoursesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WellnessCoursesQuery,
    WellnessCoursesQueryVariables
  >(WellnessCoursesDocument, options);
}
export type WellnessCoursesQueryHookResult = ReturnType<
  typeof useWellnessCoursesQuery
>;
export type WellnessCoursesLazyQueryHookResult = ReturnType<
  typeof useWellnessCoursesLazyQuery
>;
export type WellnessCoursesQueryResult = Apollo.QueryResult<
  WellnessCoursesQuery,
  WellnessCoursesQueryVariables
>;
export function refetchWellnessCoursesQuery(
  variables: WellnessCoursesQueryVariables
) {
  return { query: WellnessCoursesDocument, variables: variables };
}
export const WellnessLivePackagesDocument = gql`
  query wellnessLivePackages(
    $slug: String!
    $allowance: Float
    $perPage: Int = 9
    $livePage: Int
  ) {
    wellness(slug: $slug, allowance: $allowance) {
      id
      name
      slug
      platformUrl
      platformUrl2
      liveEventPackages(page: $livePage, perPage: $perPage) {
        page
        totalCount
        data {
          id
          name
          thumbnail
          amountCents
          discountedAmount
          amountCurrency
          instructions
          packageable {
            ...PromotionFields
          }
        }
      }
    }
  }
  ${PromotionFieldsFragmentDoc}
`;

/**
 * __useWellnessLivePackagesQuery__
 *
 * To run a query within a React component, call `useWellnessLivePackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellnessLivePackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellnessLivePackagesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      allowance: // value for 'allowance'
 *      perPage: // value for 'perPage'
 *      livePage: // value for 'livePage'
 *   },
 * });
 */
export function useWellnessLivePackagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    WellnessLivePackagesQuery,
    WellnessLivePackagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WellnessLivePackagesQuery,
    WellnessLivePackagesQueryVariables
  >(WellnessLivePackagesDocument, options);
}
export function useWellnessLivePackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WellnessLivePackagesQuery,
    WellnessLivePackagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WellnessLivePackagesQuery,
    WellnessLivePackagesQueryVariables
  >(WellnessLivePackagesDocument, options);
}
export type WellnessLivePackagesQueryHookResult = ReturnType<
  typeof useWellnessLivePackagesQuery
>;
export type WellnessLivePackagesLazyQueryHookResult = ReturnType<
  typeof useWellnessLivePackagesLazyQuery
>;
export type WellnessLivePackagesQueryResult = Apollo.QueryResult<
  WellnessLivePackagesQuery,
  WellnessLivePackagesQueryVariables
>;
export function refetchWellnessLivePackagesQuery(
  variables: WellnessLivePackagesQueryVariables
) {
  return { query: WellnessLivePackagesDocument, variables: variables };
}
export const WellnessCustomPackagesDocument = gql`
  query wellnessCustomPackages(
    $slug: String!
    $allowance: Float
    $perPage: Int = 9
    $accessPage: Int
  ) {
    wellness(slug: $slug, allowance: $allowance) {
      id
      name
      slug
      platformUrl
      platformUrl2
      customPackage {
        id
        name
        thumbnail
        instructions
        description
        amountCents
        amountCurrency
        packageable {
          ...WellnessAccessFields
        }
        priceBreakdown {
          amountPerDay
          currency
          extraDays
          extraDaysAmount
          planCount
          planType
          originalPlanAmount
          totalAmount
          accessDays @client
        }
      }
      wellnessAccesses(page: $accessPage, perPage: $perPage) {
        page
        totalCount
        data {
          id
          name
          thumbnail
          description
          amountCents
          discountedAmount
          amountCurrency
          instructions
          packageable {
            ...WellnessAccessFields
          }
        }
      }
    }
  }
  ${WellnessAccessFieldsFragmentDoc}
`;

/**
 * __useWellnessCustomPackagesQuery__
 *
 * To run a query within a React component, call `useWellnessCustomPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWellnessCustomPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWellnessCustomPackagesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      allowance: // value for 'allowance'
 *      perPage: // value for 'perPage'
 *      accessPage: // value for 'accessPage'
 *   },
 * });
 */
export function useWellnessCustomPackagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    WellnessCustomPackagesQuery,
    WellnessCustomPackagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WellnessCustomPackagesQuery,
    WellnessCustomPackagesQueryVariables
  >(WellnessCustomPackagesDocument, options);
}
export function useWellnessCustomPackagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WellnessCustomPackagesQuery,
    WellnessCustomPackagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WellnessCustomPackagesQuery,
    WellnessCustomPackagesQueryVariables
  >(WellnessCustomPackagesDocument, options);
}
export type WellnessCustomPackagesQueryHookResult = ReturnType<
  typeof useWellnessCustomPackagesQuery
>;
export type WellnessCustomPackagesLazyQueryHookResult = ReturnType<
  typeof useWellnessCustomPackagesLazyQuery
>;
export type WellnessCustomPackagesQueryResult = Apollo.QueryResult<
  WellnessCustomPackagesQuery,
  WellnessCustomPackagesQueryVariables
>;
export function refetchWellnessCustomPackagesQuery(
  variables: WellnessCustomPackagesQueryVariables
) {
  return { query: WellnessCustomPackagesDocument, variables: variables };
}
export const StartSubscriptionDocument = gql`
  mutation startSubscription($planType: PaidPlanTypeKind!) {
    startSubscription(planType: $planType) {
      success
      errors {
        message
      }
    }
  }
`;
export type StartSubscriptionMutationFn = Apollo.MutationFunction<
  StartSubscriptionMutation,
  StartSubscriptionMutationVariables
>;

/**
 * __useStartSubscriptionMutation__
 *
 * To run a mutation, you first call `useStartSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSubscriptionMutation, { data, loading, error }] = useStartSubscriptionMutation({
 *   variables: {
 *      planType: // value for 'planType'
 *   },
 * });
 */
export function useStartSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartSubscriptionMutation,
    StartSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartSubscriptionMutation,
    StartSubscriptionMutationVariables
  >(StartSubscriptionDocument, options);
}
export type StartSubscriptionMutationHookResult = ReturnType<
  typeof useStartSubscriptionMutation
>;
export type StartSubscriptionMutationResult =
  Apollo.MutationResult<StartSubscriptionMutation>;
export type StartSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  StartSubscriptionMutation,
  StartSubscriptionMutationVariables
>;
export const YearlyPageDocument = gql`
  query YearlyPage {
    startOverview {
      featuresSection {
        description
        featureBullets
        imageUrl
        title
      }
    }
  }
`;

/**
 * __useYearlyPageQuery__
 *
 * To run a query within a React component, call `useYearlyPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useYearlyPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYearlyPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useYearlyPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    YearlyPageQuery,
    YearlyPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<YearlyPageQuery, YearlyPageQueryVariables>(
    YearlyPageDocument,
    options
  );
}
export function useYearlyPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    YearlyPageQuery,
    YearlyPageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<YearlyPageQuery, YearlyPageQueryVariables>(
    YearlyPageDocument,
    options
  );
}
export type YearlyPageQueryHookResult = ReturnType<typeof useYearlyPageQuery>;
export type YearlyPageLazyQueryHookResult = ReturnType<
  typeof useYearlyPageLazyQuery
>;
export type YearlyPageQueryResult = Apollo.QueryResult<
  YearlyPageQuery,
  YearlyPageQueryVariables
>;
export function refetchYearlyPageQuery(variables?: YearlyPageQueryVariables) {
  return { query: YearlyPageDocument, variables: variables };
}
export const AnswerSurveyDocument = gql`
  mutation answerSurvey(
    $surveyPartId: ID!
    $surveyAnswerId: ID
    $answers: [AnswerInput!]
    $surveyAction: ActionInput!
  ) {
    answerSurvey(
      surveyPartId: $surveyPartId
      surveyAnswerId: $surveyAnswerId
      answers: $answers
      surveyAction: $surveyAction
    ) {
      errors {
        message
        status
      }
    }
  }
`;
export type AnswerSurveyMutationFn = Apollo.MutationFunction<
  AnswerSurveyMutation,
  AnswerSurveyMutationVariables
>;

/**
 * __useAnswerSurveyMutation__
 *
 * To run a mutation, you first call `useAnswerSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnswerSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [answerSurveyMutation, { data, loading, error }] = useAnswerSurveyMutation({
 *   variables: {
 *      surveyPartId: // value for 'surveyPartId'
 *      surveyAnswerId: // value for 'surveyAnswerId'
 *      answers: // value for 'answers'
 *      surveyAction: // value for 'surveyAction'
 *   },
 * });
 */
export function useAnswerSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AnswerSurveyMutation,
    AnswerSurveyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AnswerSurveyMutation,
    AnswerSurveyMutationVariables
  >(AnswerSurveyDocument, options);
}
export type AnswerSurveyMutationHookResult = ReturnType<
  typeof useAnswerSurveyMutation
>;
export type AnswerSurveyMutationResult =
  Apollo.MutationResult<AnswerSurveyMutation>;
export type AnswerSurveyMutationOptions = Apollo.BaseMutationOptions<
  AnswerSurveyMutation,
  AnswerSurveyMutationVariables
>;
export const GetFavoritePlaylistsDocument = gql`
  query getFavoritePlaylists($id: ID!, $page: Int, $perPage: Int) {
    getFavoritePlaylists(id: $id, page: $page, perPage: $perPage) {
      totalCount
      perPage
      page
      data {
        id
        identifier
        title
        owner {
          id
          firstName
        }
        meta {
          videoCount
          minutes
        }
        description
        assets {
          thumbnailUrl
        }
      }
    }
  }
`;

/**
 * __useGetFavoritePlaylistsQuery__
 *
 * To run a query within a React component, call `useGetFavoritePlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoritePlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoritePlaylistsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetFavoritePlaylistsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFavoritePlaylistsQuery,
    GetFavoritePlaylistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFavoritePlaylistsQuery,
    GetFavoritePlaylistsQueryVariables
  >(GetFavoritePlaylistsDocument, options);
}
export function useGetFavoritePlaylistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFavoritePlaylistsQuery,
    GetFavoritePlaylistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFavoritePlaylistsQuery,
    GetFavoritePlaylistsQueryVariables
  >(GetFavoritePlaylistsDocument, options);
}
export type GetFavoritePlaylistsQueryHookResult = ReturnType<
  typeof useGetFavoritePlaylistsQuery
>;
export type GetFavoritePlaylistsLazyQueryHookResult = ReturnType<
  typeof useGetFavoritePlaylistsLazyQuery
>;
export type GetFavoritePlaylistsQueryResult = Apollo.QueryResult<
  GetFavoritePlaylistsQuery,
  GetFavoritePlaylistsQueryVariables
>;
export function refetchGetFavoritePlaylistsQuery(
  variables: GetFavoritePlaylistsQueryVariables
) {
  return { query: GetFavoritePlaylistsDocument, variables: variables };
}
export const GetFavoriteVideosDocument = gql`
  query getFavoriteVideos($id: ID!, $page: Int, $perPage: Int) {
    getFavoriteVideos(id: $id, page: $page, perPage: $perPage) {
      totalCount
      perPage
      page
      data {
        id
        identifier
        content {
          title
          shortDescription
        }
        cursorAt
        meta {
          minutes
          publishedAt
        }
        taxonomies {
          flavor {
            name
          }
          instructors {
            id
            name
            slug
          }
          genre {
            id
            name
            slug
          }
        }
        assets {
          thumbnailUrl
        }
      }
    }
  }
`;

/**
 * __useGetFavoriteVideosQuery__
 *
 * To run a query within a React component, call `useGetFavoriteVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteVideosQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetFavoriteVideosQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFavoriteVideosQuery,
    GetFavoriteVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFavoriteVideosQuery,
    GetFavoriteVideosQueryVariables
  >(GetFavoriteVideosDocument, options);
}
export function useGetFavoriteVideosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFavoriteVideosQuery,
    GetFavoriteVideosQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFavoriteVideosQuery,
    GetFavoriteVideosQueryVariables
  >(GetFavoriteVideosDocument, options);
}
export type GetFavoriteVideosQueryHookResult = ReturnType<
  typeof useGetFavoriteVideosQuery
>;
export type GetFavoriteVideosLazyQueryHookResult = ReturnType<
  typeof useGetFavoriteVideosLazyQuery
>;
export type GetFavoriteVideosQueryResult = Apollo.QueryResult<
  GetFavoriteVideosQuery,
  GetFavoriteVideosQueryVariables
>;
export function refetchGetFavoriteVideosQuery(
  variables: GetFavoriteVideosQueryVariables
) {
  return { query: GetFavoriteVideosDocument, variables: variables };
}
export const VideoClickDocument = gql`
  mutation videoClick(
    $videoId: String!
    $sourceId: String
    $sourceType: String
    $recommended: Boolean
  ) {
    videoClick(
      videoId: $videoId
      sourceId: $sourceId
      sourceType: $sourceType
      recommended: $recommended
    ) {
      errors {
        message
      }
    }
  }
`;
export type VideoClickMutationFn = Apollo.MutationFunction<
  VideoClickMutation,
  VideoClickMutationVariables
>;

/**
 * __useVideoClickMutation__
 *
 * To run a mutation, you first call `useVideoClickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoClickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoClickMutation, { data, loading, error }] = useVideoClickMutation({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      sourceId: // value for 'sourceId'
 *      sourceType: // value for 'sourceType'
 *      recommended: // value for 'recommended'
 *   },
 * });
 */
export function useVideoClickMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideoClickMutation,
    VideoClickMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VideoClickMutation, VideoClickMutationVariables>(
    VideoClickDocument,
    options
  );
}
export type VideoClickMutationHookResult = ReturnType<
  typeof useVideoClickMutation
>;
export type VideoClickMutationResult =
  Apollo.MutationResult<VideoClickMutation>;
export type VideoClickMutationOptions = Apollo.BaseMutationOptions<
  VideoClickMutation,
  VideoClickMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Accessable: ['Bundle', 'GenericProgram', 'LiveEvent'],
    Item: [
      'BlogPost',
      'Document',
      'GenericProgram',
      'LiveEvent',
      'Playlist',
      'Video',
    ],
    Order: [
      'AddPayment',
      'BundlePayment',
      'GenericProgramPayment',
      'LiveEventPayment',
      'SubscriptionPayment',
      'WellnessPackagePayment',
    ],
    OrderPaymentInterface: [
      'AddPayment',
      'BundlePayment',
      'GenericProgramPayment',
      'LiveEventPayment',
      'SubscriptionPayment',
      'WellnessPackagePayment',
    ],
    PagedInterface: [
      'PagedBlogPosts',
      'PagedBundles',
      'PagedEvents',
      'PagedGenericPrograms',
      'PagedInstructors',
      'PagedLiveEvents',
      'PagedPages',
      'PagedPlaylists',
      'PagedSurveys',
      'PagedUsers',
      'PagedVideos',
      'PagedWellnessPackage',
      'SearchResults',
    ],
    ProgramElement: [
      'ProgramDocument',
      'ProgramLiveEvent',
      'ProgramPartElement',
      'ProgramSurveyElement',
      'ProgramVideo',
    ],
    ProgramElementable: ['LiveEvent'],
    PromotionCodeResource: ['Bundle', 'GenericProgram', 'LiveEvent'],
    SearchResult: [
      'SearchBlogPost',
      'SearchBundle',
      'SearchEvent',
      'SearchGenericProgram',
      'SearchInstructor',
      'SearchLandingPage',
      'SearchLiveEvent',
      'SearchPlaylist',
      'SearchVideo',
    ],
    UserInterface: ['CurrentUser', 'User'],
    VideoInterface: ['Video'],
    WellnessPackagable: [
      'GenericProgram',
      'LiveEvent',
      'Promotion',
      'WellnessAccess',
    ],
  },
};
export default result;
